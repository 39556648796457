// This is a fallback variant for old browsers
// If they dont natively support ResizeObserver, they will use the imported one now and will not error out
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

// import jquery
window.$ = window.jQuery = require('jquery');

// import jquery.csv
require('jquery-csv');

// import jquery-ui stuff
// each component has to be included separately; so only import what we really need
require('jquery-ui/ui/widgets/slider'); // slider currently used at the map for the value ranges
require('jquery-ui/ui/widgets/datepicker'); // used for selecting a day at energy pie webpage
require("jquery-ui-touch-punch"); // adds touch support for jquery-ui

// import jquery datetimepicker
require('jquery-datetimepicker');

// import highstock (contains highcharts) and some modules
window.Highcharts = require('highcharts/highstock');
// require('highcharts/modules/annotations')(window.Highcharts);
require('highcharts/modules/exporting')(window.Highcharts);
require('highcharts/modules/map')(window.Highcharts);
require('highcharts/modules/offline-exporting')(window.Highcharts);
require('highcharts/modules/variable-pie')(window.Highcharts);
require('highcharts/modules/pattern-fill')(window.Highcharts);
require('highcharts/modules/heatmap')(window.Highcharts);
require('highcharts/modules/export-data')(window.Highcharts);
require('highcharts/highcharts-more')(window.Highcharts);
// require('highcharts/modules/accessibility')(window.Highcharts);

// From doc:
// Note: Boost should be the last module included. This is because it overrides both standard Highcharts functionality, and functionality in certain modules (namely treemap, heatmap, bubble, and scatter).
require('highcharts/modules/boost')(window.Highcharts);

// import bootstrap and popper.js
require('popper.js');
require('bootstrap');

window.XLSX = require("xlsx");

//import List.js
window.List = require('list.js'); // used at the map for the powerplant list; (ordering/sorting)

// Leaflet and plugins
require('leaflet');
require('leaflet.markercluster');
require('leaflet.locatecontrol');
require('leaflet-control-geocoder');
require('leaflet-search');
require('leaflet-rotatedmarker');
require('leaflet-velocity');

// select2 library for climate_annual_average dropdown
require('select2');

// for simulated power chart
import PolynomialRegression from "js-polynomial-regression";
window.PolynomialRegression = PolynomialRegression;

// include timezones.json in the dependency_bundle.js to prevent race conditions; see issue #211
import timezones from "../data/timezones.json";
window.timezones = timezones;

// include dictionary.js
import dictionary from "../data/dictionary";
window.dictionary = dictionary;

// import the menuMap
// import menuMap from "../data/menuMap"
// window.menuMap = menuMap;
import { allAvailableOptions, allAvailableOptionsWithBLAndTSO, allAvailableOptionsForEnergies, allEnergyOptions, allOptionsWithoutEUandALLandUA, menuMap } from '../data/menuMap';

// require("../data/menuMap.js")

// include translation function
import { useTranslation } from "../hooks/customHooks";
const { t } = useTranslation();
window.translate = t;
