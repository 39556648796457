const dictionary = {
    // ### PAGETITLES ###

    // ===== startpage, html subpages, meta description =====
    //meta - index (home)
    "meta.pagetitle.index": {
        "de": "Energy-Charts",
        "en": "Energy-Charts",
        "fr": "Energy-Charts",
        "it": "Energy-Charts",
        "es": "Energy-Charts",
    },
    "meta.description.index": {
        "de": "Die Energy-Charts bieten interaktive Grafiken zu: Stromproduktion, Stromerzeugung, Emissionen, Klimadaten, Spotmarktpreisen, Szenarien zur Energiewende und eine umfangreiche Kartenanwendung zu: Kraftwerken, Übertragungsleitungen und Meteodaten.",
        "en": "The Energy-Charts provide interactive graphics on: electricity production, power generation, emissions, climate data, spot market prices, energy transition scenarios and an extensive map application on: power plants, transmission lines and meteo data.",
        "fr": "Les Energy-Charts offrent des graphiques interactifs sur : la production d'&eacute;lectricit&eacute;, les &eacute;missions, les donn&eacute;es climatiques, les prix du march&eacute; au comptant, les sc&eacute;narios de transition &eacute;nerg&eacute;tique et une application cartographique &eacute;tendue sur : les centrales &eacute;lectriques, les lignes de transmission et les donn&eacute;es m&eacute;t&eacute;orologiques.",
        "it": "Gli Energy-Charts offrono grafici interattivi su: produzione di elettricità, generazione di elettricità, emissioni, dati climatici, prezzi del mercato spot, scenari sulla transizione energetica e una vasta applicazione di mappe su: centrali elettriche, linee di trasmissione e dati meteorologici.",
        "es": "Las Cartas de Energ&iacute;a ofrecen gr&aacute;ficos interactivos sobre: producci&oacute;n de electricidad, generaci&oacute;n de electricidad, emisiones, datos clim&aacute;ticos, precios del mercado al contado, escenarios de transici&oacute;n energ&eacute;tica y una amplia aplicaci&oacute;n cartogr&aacute;fica sobre: centrales el&eacute;ctricas, l&iacute;neas de transmisi&oacute;n y datos meteorol&oacute;gicos.",
    },
    "meta.keywords.index": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende, Kartenanwendung, Kraftwerke, Übertragungsleitungen,Meteodaten",
        "en": "visualization,diagrams,interactive,power production,power generation,energy,emissions,climate data,spot market prices,scenarios,energy transition,map application,power plants,transmission lines, meteo data",
        "fr": "Visualisation, diagrammes, interactif, production d'&eacute;lectricit&eacute;, &eacute;nergie, &eacute;missions, donn&eacute;es climatiques, prix du march&eacute; spot, sc&eacute;narios, transition &eacute;nerg&eacute;tique, application cartographique, centrales &eacute;lectriques, lignes de transmission,les donn&eacute;es m&eacute;t&eacute;orologiques.",
        "it": "Visualizzazione, diagrammi, interattivo, produzione di energia, generazione di energia, energia, emissioni, dati climatici, prezzi del mercato spot, scenari, transizione energetica, applicazione della mappa, centrali elettriche, linee di trasmissione,dati meteorologici",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n de energ&iacute;a,energ&iacute;a,emisiones,datos clim&aacute;ticos,precios del mercado al contado,escenarios,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n cartogr&aacute;fica,centrales el&eacute;ctricas,l&iacute;neas de transmisi&oacute;n,meteodatos",
    },
    //CH
    "meta.pagetitle.ch.index": {
        "de": "Swiss Energy-Charts",
        "en": "Swiss Energy-Charts",
        "fr": "Swiss Energy-Charts",
        "it": "Swiss Energy-Charts",
        "es": "Swiss Energy-Charts",
    },
    "meta.description.ch.index": {
        "de": "Die Swiss Energy-Charts bieten interaktive Grafiken der Schweiz zu: Stromproduktion, Stromerzeugung, Klimadaten, Spotmarktpreisen und eine umfangreiche Kartenanwendung zu: Kraftwerken und Meteodaten.",
        "en": "The Swiss Energy-charts provide interactive graphics of Switzerland on: electricity production, electricity generation, climate data, spot market prices and an extensive map application on: power plants and meteo data.",
        "fr": "Les Swiss Energy-Charts offrent des graphiques interactifs de la Suisse sur : la production d'&eacute;lectricit&eacute;, la production d'&eacute;lectricit&eacute;, les donn&eacute;es climatiques, les prix du march&eacute; spot et une application cartographique &eacute;tendue sur : les centrales &eacute;lectriques et les donn&eacute;es m&eacute;t&eacute;orologiques.",
        "it": "Le Swiss Energy-Charts offrono grafici interattivi della Svizzera su: produzione di elettricità, generazione di elettricità, dati climatici, prezzi del mercato spot e una vasta applicazione cartografica su: centrali elettriche e dati meteorologici.",
        "es": "Los gr&aacute;ficos de Swiss Energy-Charts gr&aacute;ficos interactivos de Suiza sobre: producci&oacute;n de electricidad, generaci&oacute;n de electricidad, datos clim&aacute;ticos, precios del mercado al contado y una amplia aplicaci&oacute;n cartogr&aacute;fica sobre: centrales el&eacute;ctricas y datos meteorol&oacute;gicos.",
    },
    "meta.keywords.ch.index": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Klimadaten,Spotmarktpreise,Energiewende,Kartenanwendung,Kraftwerke,Meteodaten",
        "en": "visualization,diagrams,interactive,power production,power generation,energy,climate data,spot market prices,energy transition,map application,power plants, meteo data",
        "fr": "Visualisation,diagrammes,interactif,production d'&eacute;lectricit&eacute;,&eacute;nergie,donn&eacute;es climatiques,prix du march&eacute; spot,transition &eacute;nerg&eacute;tique,application cartographique,centrales &eacute;lectriques,les donn&eacute;es m&eacute;t&eacute;orologiques",
        "it": "Visualizzazione,diagrammi,interattivo,produzione di energia,generazione di energia,energia,dati climatici,prezzi del mercato spot, transizione energetica,applicazione della mappa,centrali elettriche,dati meteorologici",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n de energ&iacute;a,energ&iacute;a,datos clim&aacute;ticos,precios del mercado al contado,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n cartogr&aacute;fica,centrales el&eacute;ctricas,meteodatos",
    },
    //meta - api
    "meta.pagetitle.api": {
        "de": "API | Energy-Charts",
        "en": "API | Energy-Charts",
        "fr": "API | Energy-Charts",
        "it": "API | Energy-Charts",
        "es": "API | Energy-Charts",
    },
    "meta.description.api": {
        "de": "API der Energy-Charts.",
        "en": "API of the Energy-Charts.",
        "fr": "API des Energy-Charts.",
        "it": "API dei Energy-Charts.",
        "es": "API de los Energy-Charts.",
    },
    "meta.keywords.api": {
        "de": "API,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende, Kartenanwendung, Kraftwerke, Übertragungsleitungen,Meteodaten",
        "en": "API,visualization,diagrams,interactive,power production,power generation,energy,emissions,climate data,spot market prices,scenarios,energy transition,map application,power plants,transmission lines",
        "fr": "API,Visualisation, diagrammes, interactif, production d'&eacute;lectricit&eacute;, &eacute;nergie, &eacute;missions, donn&eacute;es climatiques, prix du march&eacute; spot, sc&eacute;narios, transition &eacute;nerg&eacute;tique, application cartographique, centrales &eacute;lectriques, lignes de transmission.",
        "it": "API,visualizzazione, diagrammi, interattivo, produzione di energia, generazione di energia, energia, emissioni, dati climatici, prezzi del mercato spot, scenari, transizione energetica, applicazione della mappa, centrali elettriche, linee di trasmissione",
        "es": "API,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,generaci&oacute;n de electricidad,energ&iacute;a,emisiones,datos clim&aacute;ticos,precios del mercado al contado,escenarios,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n cartogr&aacute;fica,centrales el&eacute;ctricas,l&iacute;neas de transmisi&oacute;n,meteodatos",
    },
    //meta - downloads
    "meta.pagetitle.downloads": {
        "de": "Downloads | Energy-Charts",
        "en": "Downloads | Energy-Charts",
        "fr": "T&eacute;l&eacute;chargements | Energy-Charts",
        "it": "Scarica | Energy-Charts",
        "es": "Descargas | Energy-Charts",
    },
    "meta.description.downloads": {
        "de": "Downloads der Energy-Charts.",
        "en": "Downloads of the Energy-Charts.",
        "fr": "T&eacute;l&eacute;chargements des Energy-Charts.",
        "it": "Download dei Energy-Charts.",
        "es": "Descargas de las Cartas de Energ&iacute;a.",
    },
    "meta.keywords.downloads": {
        "de": "Downloads,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende, Kartenanwendung, Kraftwerke, Übertragungsleitungen,Meteodaten",
        "en": "downloads, visualization,diagrams,interactive,power production,power generation,energy,emissions,climate data,spot market prices,scenarios,energy transition,map application,power plants,transmission lines",
        "fr": "t&eacute;l&eacute;chargements,Visualisation, diagrammes, interactif, production d'&eacute;lectricit&eacute;, &eacute;nergie, &eacute;missions, donn&eacute;es climatiques, prix du march&eacute; spot, sc&eacute;narios, transition &eacute;nerg&eacute;tique, application cartographique, centrales &eacute;lectriques, lignes de transmission.",
        "it": "downloads,visualizzazione, diagrammi, interattivo, produzione di energia, generazione di energia, energia, emissioni, dati climatici, prezzi del mercato spot, scenari, transizione energetica, applicazione della mappa, centrali elettriche, linee di trasmissione",
        "es": "Descargas,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,generaci&oacute;n de electricidad,energ&iacute;a,emisiones,datos clim&aacute;ticos,precios del mercado al contado,escenarios,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n cartogr&aacute;fica,centrales el&eacute;ctricas,l&iacute;neas de transmisi&oacute;n,meteodatos",
    },
    //CH (atm not active!)
    "meta.pagetitle.ch.downloads": {
        "de": "Downloads | Swiss Energy-Charts",
        "en": "Downloads | Swiss Energy-Charts",
        "fr": "T&eacute;l&eacute;chargements | Swiss Energy-Charts",
        "it": "Scarica | Swiss Energy-Charts",
        "es": "Descargas | Swiss Energy-Charts",
    },
    "meta.description.ch.downloads": {
        "de": "Downloads der Swiss Energy-Charts.",
        "en": "Downloads of the Swiss Energy-Charts.",
        "fr": "T&eacute;l&eacute;chargements des Swiss Energy-Charts.",
        "it": "Download dei Swiss Energy-Charts.",
        "es": "Descargas de las cartas suizas de la energ&iacute;a.",
    },
    "meta.keywords.ch.downloads": {
        "de": "Downloads,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Klimadaten,Spotmarktpreise,Energiewende,Kartenanwendung, Kraftwerke",
        "en": "Downloads, visualization,diagrams,interactive,power production,power generation,energy,climate data,spot market prices,energy transition,map application,power plants",
        "fr": "T&eacute;l&eacute;chargements,visualisation,diagrammes,interactif,production d'&eacute;lectricit&eacute;,&eacute;nergie,donn&eacute;es climatiques,prix du march&eacute; spot,transition &eacute;nerg&eacute;tique,application cartographique,centrales &eacute;lectriques",
        "it": "Downloads,visualizzazione,diagrammi,interattivo,produzione di energia,generazione di energia,energia,dati climatici,prezzi del mercato spot,transizione energetica,applicazione della mappa,centrali elettriche",
        "es": "descargas,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n de energ&iacute;a,energ&iacute;a,datos clim&aacute;ticos,precios del mercado al contado,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n de mapas,centrales el&eacute;ctricas",
    },
    // FALSCHER PLATZ !!
    "pageTitle_price_heatmaps": {
        "de": "Heatmaps zu B&ouml;rsenstrompreisen | Energy-Charts",
        "en": "Heatmaps on exchange electricity prices | Energy-Charts",
        "fr": "Heatmaps sur les prix de l'&eacute;lectricit&eacute; en bourse | Energy-Charts",
        "it": "Heatmap sui prezzi dell'elettricit&agrave; in borsa | Energy-Charts",
        "es": "Mapas t&eacute;rmicos de los precios de la electricidad en bolsa | Energy-Charts",
    },
    //meta - presentations
    "meta.pagetitle.presentations": {
        "de": "Vorträge | Energy-Charts",
        "en": "Lectures | Energy-Charts",
        "fr": "Conf&eacute;rences | Energy-Charts",
        "it": "Conferenze | Energy-Charts",
        "es": "Conferencias | Energy-Charts",
    },
    "meta.description.presentations": {
        "de": "Vorträge der Energy-Charts",
        "en": "Lectures of the Energy-Charts",
        "fr": "Conf&eacute;rences des Energy-Charts",
        "it": "Conferenze dei Energy-Charts",
        "es": "Conferencias Energy-Charts",
    },
    "meta.keywords.presentations": {
        "de": "",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },
    //meta - podcasts
    "meta.pagetitle.podcasts": {
        "de": "Podcasts | Energy-Charts",
        "en": "Podcasts | Energy-Charts",
        "fr": "Podcasts | Energy-Charts",
        "it": "Podcasts | Energy-Charts",
        "es": "Podcasts | Energy-Charts",
    },
    "meta.description.podcasts": {
        "de": "Podcasts der Energy-Charts",
        "en": "Podcasts of the Energy-Charts",
        "fr": "Podcasts des Energy-Charts",
        "it": "Podcasts dei Energy-Charts",
        "es": "Podcasts de los Energy-Charts",
    },
    "meta.keywords.podcasts": {
        "de": "",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },    
    //meta - Energy-Charts Talks
    "meta.pagetitle.energy-charts-talks": {
        "de": "Energy-Charts Talks | Energy-Charts",
        "en": "Energy-Charts Talks | Energy-Charts",
        "fr": "Energy-Charts Talks | Energy-Charts",
        "it": "Energy-Charts Talks | Energy-Charts",
        "es": "Energy-Charts Talks | Energy-Charts",
    },
    "meta.description.energy-charts-talks": {
        "de": "Aufzeichnungen der Energy-Charts Talks",
        "en": "Recordings of the Energy-Charts Talks",
        "fr": "Enregistrements des Energy-Charts Talks",
        "it": "Registrazioni dei Energy-Charts Talks",
        "es": "Grabaciones de las charlas sobre gr&aacute;ficos energ&eacute;ticos",
    },
    "meta.keywords.energy-charts-talks": {
        "de": "Aufzeichnungen",
        "en": "Recordings",
        "fr": "Enregistrements",
        "it": "Registrazioni",
        "es": "Registros",
    },
    //meta - explanations
    "meta.pagetitle.explanations": {
        "de": "Erläuterungen | Energy-Charts",
        "en": "Explanations | Energy-Charts",
        "fr": "Notes Explicatives | Energy-Charts",
        "it": "Note Esplicative | Energy-Charts",
        "es": "Explicaciones | Energy-Charts",
    },
    "meta.description.explanations": {
        "de": "Erläuterungen zu den Energy-Charts.",
        "en": "Explanations regarding the Energy-Charts.",
        "fr": "Explication des Energy-Charts.",
        "it": "Spiegazione dei Energy-Charts.",
        "es": "Explicaci&oacute;n de los Energy-Charts.",
    },
    "meta.keywords.explanations": {
        "de": "Erläuterungen,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende",
        "en": "Explanations,power production,power generation,energy,emissions,climate data,spot market prices,scenarios,energy transition",
        "fr": "Explications,Production d'&eacute;lectricit&eacute;,&eacute;nergie,&eacute;missions,Donn&eacute;es climatiques,Prix du march&eacute; au comptant,Sc&eacute;narios,Transition &eacute;nerg&eacute;tique",
        "it": "Spiegazioni,produzione di energia,generazione di energia,energia,emissioni,dati climatici,prezzi del mercato a pronti,scenari,transizione energetica",
        "es": "Explicaciones,Producci&oacute;n de energ&iacute;a,Generaci&oacute;n de energ&iacute;a,Energ&iacute;a,Emisiones,Datos clim&aacute;ticos,Precios del mercado al contado,Escenarios,Transici&oacute;n energ&eacute;tica",
    },
    //CH
    "meta.pagetitle.ch.explanations": {
        "de": "Erläuterungen | Swiss Energy-Charts",
        "en": "Explanations | Swiss Energy-Charts",
        "fr": "Notes Explicatives | Swiss Energy-Charts",
        "it": "Note Esplicative | Swiss Energy-Charts",
        "es": "Explicaciones | Gr&aacute;ficos suizos de la energ&iacute;a",
    },
    "meta.description.ch.explanations": {
        "de": "Erläuterungen zu den Swiss Energy-Charts.",
        "en": "Explanations regarding the Swiss Energy-Charts.",
        "fr": "Explication des Swiss Energy-Charts.",
        "it": "Spiegazione dei Swiss Energy-Charts.",
        "es": "Explicaciones de los gr&aacute;ficos suizos de la energ&iacute;a.",
    },
    "meta.keywords.ch.explanations": {
        "de": "Erläuterungen,Stromproduktion,Stromerzeugung,Energie,Klimadaten,Spotmarktpreise,Energiewende",
        "en": "Explanations,power production,power generation,energy,climate data,spot market prices,energy transition",
        "fr": "Explications,Production d'&eacute;lectricit&eacute;,&eacute;nergie,Donn&eacute;es climatiques,Prix du march&eacute; au comptant,Transition &eacute;nerg&eacute;tique",
        "it": "Spiegazioni,produzione di energia,generazione di energia,energia,dati climatici,prezzi del mercato a pronti,transizione energetica",
        "es": "Explicaciones,Producci&oacute;n de energ&iacute;a,Generaci&oacute;n de energ&iacute;a,Energ&iacute;a,Datos clim&aacute;ticos,Precios del mercado al contado,Transici&oacute;n energ&eacute;tica",
    },
    //meta - faqs
    "meta.pagetitle.faqs": {
        "de": "FAQs | Energy-Charts",
        "en": "FAQs | Energy-Charts",
        "fr": "FAQs | Energy-Charts",
        "it": "FAQs | Energy-Charts",
        "es": "FAQs | Energy-Charts",
    },
    "meta.description.faqs": {
        "de": "FAQs der Energy-Charts.",
        "en": "FAQs of the Energy-Charts.",
        "fr": "FAQ sur les Energy-Charts.",
        "it": "FAQ dei Energy-Charts.",
        "es": "Preguntas frecuentes sobre los Energy-Charts.",
    },
    "meta.keywords.faqs": {
        "de": "FAQs",
        "en": "FAQs",
        "fr": "FAQs",
        "it": "FAQs",
        "es": "FAQs",
    },
    //CH
    "meta.pagetitle.ch.faqs": {
        "de": "FAQs | Swiss Energy-Charts",
        "en": "FAQs | Swiss Energy-Charts",
        "fr": "FAQs | Swiss Energy-Charts",
        "it": "FAQs | Swiss Energy-Charts",
        "es": "FAQs | Swiss Energy-Charts",
    },
    "meta.description.ch.faqs": {
        "de": "FAQs der Swiss Energy-Charts.",
        "en": "FAQs of the Swiss Energy-Charts.",
        "fr": "FAQs sur les Swiss Energy-Charts.",
        "it": "FAQs dei Swiss Energy-Charts.",
        "es": "Preguntas frecuentes sobre los gr&aacute;ficos suizos de la energ&iacute;a.",
    },
    "meta.keywords.ch.faqs": {
        "de": "FAQs",
        "en": "FAQs",
        "fr": "FAQs",
        "it": "FAQs",
        "es": "FAQs",
    },
    //meta - sources
    "meta.pagetitle.sources": {
        "de": "Quellen | Energy-Charts",
        "en": "Sources | Energy-Charts",
        "fr": "Sources | Energy-Charts",
        "it": "Fonti | Energy-Charts",
        "es": "Fuentes | Energy-Charts",
    },
    "meta.description.sources": {
        "de": "Verwendete Quellen für Daten und Visualisierungsbibliotheken der Energy-Charts.",
        "en": "Sources used for data and visualization libraries of the Energy-Charts.",
        "fr": "Sources utilis&eacute;es pour les librairies de donn&eacute;es et de visualisation des Energy-Charts.",
        "it": "Fonti utilizzate per i dati e le librerie di visualizzazione dei Energy-Charts.",
        "es": "Fuentes utilizadas para los datos y las bibliotecas de visualizaci&oacute;n de las Cartas de Energ&iacute;a.",
    },
    "meta.keywords.sources": {
        "de": "Datenquellen,Visualisierungsbibliotheken",
        "en": "Datasources, visualization libraries",
        "fr": "Sources de donn&eacute;es,Librairies de visualisation",
        "it": "Fonti di dati,Librerie di visualizzazione",
        "es": "Fuentes de datos,Bibliotecas de visualizaci&oacute;n",
    },
    //CH
    "meta.pagetitle.ch.sources": {
        "de": "Quellen | Swiss Energy-Charts",
        "en": "Sources | Swiss Energy-Charts",
        "fr": "Sources | Swiss Energy-Charts",
        "it": "Fonti | Swiss Energy-Charts",
        "es": "Fuentes | Swiss Energy Charts",
    },
    "meta.description.ch.sources": {
        "de": "Verwendete Quellen für Daten und Visualisierungsbibliotheken der Swiss Energy-Charts.",
        "en": "Sources used for data and visualization libraries of the Swiss Energy-Charts.",
        "fr": "Sources utilis&eacute;es pour les librairies de donn&eacute;es et de visualisation des Swiss Energy-Charts.",
        "it": "Fonti utilizzate per i dati e le librerie di visualizzazione dei Swiss Energy-Charts.",
        "es": "Fuentes utilizadas para los datos y las bibliotecas de visualizaci&oacute;n de Swiss Energy Charts.",
    },
    "meta.keywords.ch.sources": {
        "de": "Datenquellen,Visualisierungsbibliotheken",
        "en": "Datasources,visualization libraries",
        "fr": "Sources de donn&eacute;es,Librairies de visualisation",
        "it": "Fonti di dati,Librerie di visualizzazione",
        "es": "Fuentes de datos,Bibliotecas de visualizaci&oacute;n",
    },
    //meta - contact
    "meta.pagetitle.contact": {
        "de": "Kontakt | Energy-Charts",
        "en": "Contact | Energy-Charts",
        "fr": "Nous Contacter | Energy-Charts",
        "it": "Contatti | Energy-Charts",
        "es": "Contacto | Energy-Charts",
    },
    "meta.description.contact": {
        "de": "Kontaktdaten Energy-Charts.",
        "en": "Contact Energy-Charts.",
        "fr": "Nous contacter Energy-Charts.",
        "it": "Contatti Energy-Charts.",
        "es": "Datos de contacto Energy-Charts.",
    },
    "meta.keywords.contact": {
        "de": "Kontakt",
        "en": "Contact",
        "fr": "Nous contacter",
        "it": "Contatti",
        "es": "P&oacute;ngase en contacto con",
    },
    //CH
    "meta.pagetitle.ch.contact": {
        "de": "Kontakt | Swiss Energy-Charts",
        "en": "Contact | Swiss Energy-Charts",
        "fr": "Nous Contacter | Swiss Energy-Charts",
        "it": "Contatti | Swiss Energy-Charts",
        "es": "Contacto | Swiss Energy Charts",
    },
    "meta.description.ch.contact": {
        "de": "Kontaktdaten Swiss Energy-Charts.",
        "en": "Contact Swiss Energy-Charts.",
        "fr": "Nous contacter Swiss Energy-Charts.",
        "it": "Contatti Swiss Energy-Charts.",
        "es": "Datos de contacto Swiss Energy-Charts.",
    },
    "meta.keywords.ch.contact": {
        "de": "Kontakt",
        "en": "Contact",
        "fr": "Nous contacter",
        "it": "Contatti",
        "es": "P&oacute;ngase en contacto con",
    },
    //meta - data-protection
    "meta.pagetitle.data-protection": {
        "de": "Datenschutz | Energy-Charts",
        "en": "Data Protection | Energy-Charts",
        "fr": "Protection des Donn&eacute;es | Energy-Charts",
        "it": "Privacy | Energy-Charts",
        "es": "Protecci&oacute;n de datos | Energy-Charts",
    },
    "meta.description.data-protection": {
        "de": "Datenschutz Energy-Charts.",
        "en": "Data Protection Energy-Charts.",
        "fr": "Protection des donn&eacute;es Energy-Charts.",
        "it": "Privacy Energy-Charts.",
        "es": "Energy-Charts de privacidad.",
    },
    "meta.keywords.data-protection": {
        "de": "Datenschutz",
        "en": "Data Protection",
        "fr": "Protection des donn&eacute;es",
        "it": "Privacy",
        "es": "Protecci&oacute;n de datos",
    },
    //CH
    "meta.pagetitle.ch.data-protection": {
        "de": "Datenschutz | Swiss Energy-Charts",
        "en": "Data Protection | Swiss Energy-Charts",
        "fr": "Protection des donn&eacute;es | Swiss Energy-Charts",
        "it": "Privacy | Swiss Energy-Charts",
        "es": "Protecci&oacute;n de datos | Cartas suizas de la energ&iacute;a",
    },
    "meta.description.ch.data-protection": {
        "de": "Datenschutz Swiss Energy-Charts.",
        "en": "Data Protection Swiss Energy-Charts.",
        "fr": "Protection des donn&eacute;es Swiss Energy-Charts.",
        "it": "Privacy Swiss Energy-Charts.",
        "es": "Protecci&oacute;n de datos Swiss Energy charts.",
    },
    "meta.keywords.ch.data-protection": {
        "de": "Datenschutz",
        "en": "Data Protection",
        "fr": "Protection des donn&eacute;es",
        "it": "Privacy",
        "es": "Protecci&oacute;n de datos",
    },
    //meta - publishing-notes
    "meta.pagetitle.publishing-notes": {
        "de": "Impressum | Energy-Charts",
        "en": "Publishing Notes | Energy-Charts",
        "fr": "Mentions L&eacute;gales | Energy-Charts",
        "it": "Impronta | Energy-Charts",
        "es": "Impressum | Energy-Charts",
    },
    "meta.description.publishing-notes": {
        "de": "Impressum Energy-Charts.",
        "en": "Publishing notes Energy-Charts.",
        "fr": "Mentions l&eacute;gales Energy-Charts.",
        "it": "Impronta Energy-Charts.",
        "es": "Impressum Energy-Charts.",
    },
    "meta.keywords.publishing-notes": {
        "de": "Impressum,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende, Kartenanwendung, Kraftwerke, Übertragungsleitungen",
        "en": "Publishing notes",
        "fr": "Mentions l&eacute;gales",
        "it": "Impronta",
        "es": "Pie de imprenta,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,generaci&oacute;n de electricidad,energ&iacute;a,emisiones,datos clim&aacute;ticos,precios del mercado al contado,escenarios,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n cartogr&aacute;fica,centrales el&eacute;ctricas,l&iacute;neas de transmisi&oacute;n",
    },
    //CH
    "meta.pagetitle.ch.publishing-notes": {
        "de": "Impressum | Swiss Energy-Charts",
        "en": "Publishing Notes | Swiss Energy-Charts",
        "fr": "Mentions L&eacute;gales | Swiss Energy-Charts",
        "it": "Impronta | Swiss Energy-Charts",
        "es": "Impressum | Swiss Energy-Charts",
    },
    "meta.description.ch.publishing-notes": {
        "de": "Impressum Swiss Energy-Charts.",
        "en": "Publishing notes Swiss Energy-Charts.",
        "fr": "Mentions l&eacute;gales Swiss Energy-Charts.",
        "it": "Impronta Swiss Energy-Charts.",
        "es": "Impressum Swiss Energy-Charts.",
    },
    "meta.keywords.ch.publishing-notes": {
        "de": "Impressum",
        "en": "Publishing notes",
        "fr": "Mentions l&eacute;gales",
        "it": "Impronta",
        "es": "Impresionante",
    },
    //meta - blog
    "meta.pagetitle.blog": {
        "de": "Blogeinträge | Energy-Charts",
        "en": "Blog Posts | Energy-Charts",
        "fr": "Articles de Blog | Energy-Charts",
        "it": "Messaggi del Blog | Energy-Charts",
        "es": "Entradas del blog | Energy-Charts",
    },
    "meta.description.blog": {
        "de": "Blogeinträge Energy-Charts.",
        "en": "Blog posts Energy-Charts.",
        "fr": "Articles de blog Energy-Charts.",
        "it": "Messaggi del blog Energy-Charts.",
        "es": "Entradas de blog Energy-Charts.",
    },
    "meta.keywords.blog": {
        "de": "Blogeinträge,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende, Kartenanwendung, Kraftwerke, Übertragungsleitungen",
        "en": "Blog posts",
        "fr": "Articles de blog",
        "it": "Messaggi del blog",
        "es": "Entradas de blog,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n de energ&iacute;a,energ&iacute;a,emisiones,datos clim&aacute;ticos,precios del mercado al contado,escenarios,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n de mapas,centrales el&eacute;ctricas,l&iacute;neas de transmisi&oacute;n",
    },
    //CH
    "meta.pagetitle.ch.blog": {
        "de": "Blogeinträge | Swiss Energy-Charts",
        "en": "Blog Posts | Swiss Energy-Charts",
        "fr": "Articles de Blog | Swiss Energy-Charts",
        "it": "Messaggi del Blog | Swiss Energy-Charts",
        "es": "Entradas del blog | Gr&aacute;ficos de Swiss Energy",
    },
    "meta.description.ch.blog": {
        "de": "Blogeinträge Swiss Energy-Charts.",
        "en": "Blog posts Swiss Energy-Charts.",
        "fr": "Articles de blog Swiss Energy-Charts.",
        "it": "Messaggi del blog Swiss Energy-Charts.",
        "es": "Entradas del blog Swiss Energy charts.",
    },
    "meta.keywords.ch.blog": {
        "de": "Blogeinträge,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Klimadaten,Spotmarktpreise,Energiewende, Kartenanwendung, Kraftwerke",
        "en": "Blog posts",
        "fr": "Articles de blog",
        "it": "Messaggi del blog",
        "es": "Entradas de blog,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n de energ&iacute;a,energ&iacute;a,datos clim&aacute;ticos,precios del mercado al contado,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n de mapas,centrales el&eacute;ctricas",
    },
    //meta - post
    "meta.pagetitle.post": {
        "de": "Blogeintrag | Energy-Charts",
        "en": "Blog Post | Energy-Charts",
        "fr": "Article de Blog | Energy-Charts",
        "it": "Post del Blog | Energy-Charts",
        "es": "Entrada de blog | Energy-Charts",
    },
    "meta.description.post": {
        "de": "Blogeintrag Energy-Charts.",
        "en": "Blog post Energy-Charts.",
        "fr": "Article de blog Energy-Charts.",
        "it": "Post del blog Energy-Charts.",
        "es": "Entrada del blog Energy-Charts.",
    },
    "meta.keywords.post": {
        "de": "Blogeintrag,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Emissionen,Klimadaten,Spotmarktpreise,Szenarien, Energiewende, Kartenanwendung, Kraftwerke, Übertragungsleitungen",
        "en": "Blog post",
        "fr": "Article de blog",
        "it": "Post del blog",
        "es": "Entrada de blog,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n de energ&iacute;a,energ&iacute;a,emisiones,datos clim&aacute;ticos,precios del mercado al contado,escenarios,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n de mapas,centrales el&eacute;ctricas,l&iacute;neas de transmisi&oacute;n",
    },
    //CH
    "meta.pagetitle.ch.post": {
        "de": "Blogeintrag | Swiss Energy-Charts",
        "en": "Blog Post | Swiss Energy-Charts",
        "fr": "Article de Blog | Swiss Energy-Charts",
        "it": "Post del Blog | Swiss Energy-Charts",
        "es": "Entrada del blog | Swiss Energy Charts",
    },
    "meta.description.ch.post": {
        "de": "Blogeintrag Swiss Energy-Charts.",
        "en": "Blog post Swiss Energy-Charts.",
        "fr": "Article de blog Swiss Energy-Charts.",
        "it": "Post del blog Swiss Energy-Charts.",
        "es": "Entrada del blog Swiss Energy Charts.",
    },
    "meta.keywords.ch.post": {
        "de": "Blogeintrag,Visualisierung,Diagramme,interaktiv,Stromproduktion,Stromerzeugung,Energie,Klimadaten,Spotmarktpreise,Energiewende,Kartenanwendung,Kraftwerke,Meteodaten",
        "en": "Blog post,visualization,diagrams,interactive,power production,power generation,energy,climate data,spot market prices,energy transition,map application,power plants, meteo data",
        "fr": "Article de blog,Visualisation,diagrammes,interactif,production d'&eacute;lectricit&eacute;,&eacute;nergie,donn&eacute;es climatiques,prix du march&eacute; spot,transition &eacute;nerg&eacute;tique,application cartographique,centrales &eacute;lectriques,les donn&eacute;es m&eacute;t&eacute;orologiques",
        "it": "Post del blog,Visualizzazione,diagrammi,interattivo,produzione di energia,generazione di energia,energia,dati climatici,prezzi del mercato spot, transizione energetica,applicazione della mappa,centrali elettriche,dati meteorologici",
        "es": "entrada de blog,visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,generaci&oacute;n de electricidad,energ&iacute;a,datos clim&aacute;ticos,precios del mercado al contado,transici&oacute;n energ&eacute;tica,aplicaci&oacute;n cartogr&aacute;fica,centrales el&eacute;ctricas,meteodatos",
    },

    //meta - charts-power
    "meta.pagetitle.charts.power.chart": {
        "de": "Stromproduktion | Energy-Charts",
        "en": "Electricity Production | Energy-Charts",
        "fr": "Production d'\u00e9lectricit\u00e9 | Energy-Charts",
        "it": "Produzione di elettricit\u00e0 | Energy-Charts",
        "es": "Producci&oacute;n de energ&iacute;a | Energy-Charts",
    },
    "meta.description.charts.power.chart": {
        "de": "Nettoerzeugung von Kraftwerken zur öffentlichen Stromversorgung",
        "en": "Electricity Production",
        "fr": "Production d'\u00e9lectricit\u00e9",
        "it": "Produzione di elettricit\u00e0",
        "es": "Generaci&oacute;n neta de las centrales de suministro p&uacute;blico de electricidad",
    },
    "meta.keywords.charts.power.chart": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Nettostromerzeugung",
        "en": "",
        "fr": "",
        "it": "",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,generaci&oacute;n neta de electricidad",
    },
    //meta CH - charts-power
    "meta.pagetitle.ch.charts.power.chart": {
        "de": "Stromproduktion | Swiss Energy-Charts",
        "en": "Electricity Production | Swiss Energy-Charts",
        "fr": "Production d'\u00e9lectricit\u00e9 | Swiss Energy-Charts",
        "it": "Produzione di elettricit\u00e0 | Swiss Energy-Charts",
        "es": "Producci&oacute;n de electricidad | Swiss Energy charts",
    },
    "meta.description.ch.charts.power.chart": {
        "de": "Stromerzeugung in der Schweiz",
        "en": "Electricity generation in Switzerland",
        "fr": "Production \u00e9lectrique en Suisse",
        "it": "La produzione di elettricit\u00e0 in Svizzera",
        "es": "Producci&oacute;n de electricidad en Suiza",
    },
    "meta.keywords.ch.charts.power.chart": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Nettostromerzeugung",
        "en": "",
        "fr": "",
        "it": "",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,generaci&oacute;n neta de electricidad",
    },
    //meta - charts simulated_power
    "meta.pagetitle.charts.power_simulated.chart": {
        "de": "Simulierte Stromerzeugung | Energy-Charts",
        "en": "Simulated Electricity Production | Energy-Charts",
        "fr": "Production \u00e9lectrique simul\u00e9e | Energy-Charts",
        "it": "Produzione di elettricit\u00e0 simulata | Energy-Charts",
        "es": "Generaci&oacute;n de energ&iacute;a simulada | Energy-Charts",
    },
    "meta.description.charts.power_simulated.chart": {
        "de": "Simulation: Nettoerzeugung von Kraftwerken zur öffentlichen Stromversorgung",
        "en": "Simulated Electricity Production",
        "fr": "Production \u00e9lectrique simul\u00e9e",
        "it": "Produzione di elettricit\u00e0 simulata",
        "es": "Simulaci&oacute;n: Generaci&oacute;n neta de centrales el&eacute;ctricas para el suministro p&uacute;blico de electricidad",
    },
    "meta.keywords.charts.power_simulated.chart": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Nettostromerzeugung,Simulation",
        "en": "",
        "fr": "",
        "it": "",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de energ&iacute;a,generaci&oacute;n neta de energ&iacute;a,simulaci&oacute;n",
    },
    //meta - charts power_installed
    "meta.pagetitle.charts.installed_power.chart": {
        "de": "Installierte Leistung | Energy-Charts",
        "en": "Installed Power | Energy-Charts",
        "fr": "Puissance install\u00e9e | Energy-Charts",
        "it": "Capacit\u00e0 installata | Energy-Charts",
        "es": "Capacidad instalada | Energy-Charts",
    },
    "meta.description.charts.installed_power.chart": {
        "de": "Installierte Netto-Leistung von Kraftwerken zur öffentlichen Stromversorgung",
        "en": "Net installed electricity generation capacity",
        "fr": "Capacit\u00e9 nette de production d'&eacute;lectricit&eacute; install&eacute;e",
        "it": "Capacit\u00e0 di generazione elettrica installata netta",
        "es": "Capacidad neta instalada de centrales el&eacute;ctricas para el suministro p&uacute;blico de electricidad",
    },
    "meta.keywords.charts.installed_power.chart": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Nettostromerzeugung,installierte Leistung",
        "en": "",
        "fr": "",
        "it": "",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,producci&oacute;n neta de electricidad,capacidad instalada",
    },
    //meta CH - charts power_installed
    "meta.pagetitle.ch.charts.installed_power.chart": {
        "de": "Installierte Leistung | Swiss Energy-Charts",
        "en": "Installed Power | Swiss Energy-Charts",
        "fr": "Puissance install\u00e9e | Swiss Energy-Charts",
        "it": "Capacit\u00e0 installata | Swiss Energy-Charts",
        "es": "Capacidad instalada | Swiss Energy charts",
    },
    "meta.description.ch.charts.installed_power.chart": {
        "de": "Installierte Netto-Leistung zur Stromerzeugung in der Schweiz",
        "en": "Net installed electricity generation capacity in Switzerland",
        "fr": "Capacit\u00e9 nette de production d'&eacute;lectricit&eacute; install&eacute;e en Suisse",
        "it": "Capacit\u00e0 di generazione elettrica installata netta in Svizzera",
        "es": "Capacidad neta instalada de producci&oacute;n de electricidad en Suiza",
    },
    "meta.keywords.ch.charts.installed_power.chart": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Nettostromerzeugung,installierte Leistung",
        "en": "",
        "fr": "",
        "it": "",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,producci&oacute;n neta de electricidad,capacidad instalada",
    },
    //meta - charts power scatter
    "meta.pagetitle.charts.power_scatter.chart": {
        "de": "Punktediagramme zur Stromproduktion | Energy-Charts",
        "en": "Scatter Charts Electricity Production | Energy-Charts",
        "fr": "Diagrammes de dispersion de la production '\u00e9lectricit\u00e9 | Energy-Charts",
        "it": "Diagrammi a punti per la produzione di energia elettric\u00e0 | Energy-Charts",
        "es": "Gr&aacute;ficos de producci&oacute;n de electricidad | Energy-Charts",
    },
    "meta.description.charts.power_scatter.chart": {
        "de": "Punktediagramme zur Nettoerzeugung von Kraftwerken zur öffentlichen Stromversorgung",
        "en": "Scatter Charts Electricity Production",
        "fr": "Diagrammes de dispersion de la production '\u00e9lectricit\u00e9",
        "it": "Diagrammi a punti per la produzione di energia elettric\u00e0",
        "es": "Diagramas de puntos para la generaci&oacute;n neta de centrales el&eacute;ctricas para el suministro p&uacute;blico de electricidad",
    },
    "meta.keywords.charts.power_scatter.chart": {
        "de": "Visualisierung,Diagramme,interaktiv,Stromproduktion,Nettostromerzeugung,Punktediagramme",
        "en": "",
        "fr": "",
        "it": "",
        "es": "Visualizaci&oacute;n,diagramas,interactivo,producci&oacute;n de electricidad,producci&oacute;n neta de electricidad,gr&aacute;ficos de puntos",
    },

    // general meta tag translations
    "meta.page-topic":{
        "de": "Wissenschaft,Energiedaten",
        "en": "Science, energy data",
        "fr": "Science, donn&eacute;es &eacute;nerg&eacute;tiques",
        "it": "Scienza, dati energetici",
        "es": "Ciencia,Datos energ&eacute;ticos",
    },

    // ===== POWER =====
    "pageTitle_power": {
        "de": "Stromproduktion | Energy-Charts",
        "en": "Electricity Production | Energy-Charts",
        "fr": "Production d'\u00e9lectricit\u00e9 | Energy-Charts",
        "it": "Produzione di elettricit\u00e0 | Energy-Charts",
        "es": "Producci&oacute;n de energ&iacute;a | Energy-Charts",
    },
    "pageTitle_co2_intensity": {
        "de": "CO2-Intensität | Energy-Charts",
        "en": "CO2 intensity | Energy-Charts",
        "fr": "Intensit&eacute; CO2 | Energy-Charts",
        "it": "Intensità CO2 | Energy-Charts",
        "es": "Intensidad de CO2 | Energy-Charts",
    },
    "pageTitle_power_simulated": {
        "de": "Simulierte Stromerzeugung | Energy-Charts",
        "en": "Simulated Electricity Production | Energy-Charts",
        "fr": "Production \u00e9lectrique simul\u00e9e | Energy-Charts",
        "it": "Produzione di elettricit\u00e0 simulata | Energy-Charts",
        "es": "Generaci&oacute;n de energ&iacute;a simulada | Energy-Charts",
    },
    "pageTitle_installedPower": {
        "de": "Installierte Leistung | Energy-Charts",
        "en": "Installed Power | Energy-Charts",
        "fr": "Puissance install\u00e9e | Energy-Charts",
        "it": "Capacit\u00e0 installata | Energy-Charts",
        "es": "Capacidad instalada | Energy-Charts",
    },
    "pageTitle_power_forecast": {
		"de": "Prognosen und Ist-Werte | Energy-Charts",
		"en": "Forecasts and Actual Values | Energy-Charts",
		"fr": "Prévisions et valeurs réelles | Energy-Charts",
		"it": "Previsioni e valori effettivi | Energy-Charts",
		"es": "Pronósticos y valores reales | Energy-Charts",
    },
    "pageTitle_power_scatter": {
        "de": "Punktediagramme zur Stromproduktion | Energy-Charts",
        "en": "Scatter Charts Electricity Production | Energy-Charts",
        "fr": "Diagrammes de dispersion de la production '\u00e9lectricit\u00e9 | Energy-Charts",
        "it": "Diagrammi a punti per la produzione di energia elettric\u00e0 | Energy-Charts",
        "es": "Gr&aacute;ficos de producci&oacute;n de electricidad | Energy-Charts",
    },
    "pageTitle_frequency": {
        "de": "Frequenz | Energy-Charts",
        "en": "Frequency | Energy-Charts",
        "fr": "Fr&eacute;quence | Energy-Charts",
        "it": "Frequenza  | Energy-Charts",
        "es": "Frecuencia | Energy-Charts",
    },

    // ===== ENEGRY =====
    "pageTitle_energy": {
        "de": "Säulendiagramme zur Stromerzeugung | Energy-Charts",
        "en": "Column charts on electricity generation | Energy-Charts",
        "fr": "Diagrammes à colonnes pour la production d'\u00e9lectricit\u00e9 | Energy-Charts",
        "it": "Diagrammi a colonna per la generazione di elettricità | Energy-Charts",
        "es": "Diagramas de columnas para la generación de electricidad | Energy-Charts",
    },
    "pageTitle_energy_pie": {
        "de": "Kreisdiagramme zur Stromerzeugung | Energy-Charts",
        "en": "Pie Charts Electricity Generation | Energy-Charts",
        "fr": "Diagrammes circulaires de la production d'\u00e9lectricit\u00e9 | Energy-Charts",
        "it": "Grafici a torta della generazione di elettricit\u00e0 | Energy-Charts",
        "es": "Gr&aacute;ficos circulares de generaci&oacute;n de electricidad | Energy-Charts",
    },
    "pageTitle_renewable_share": {
        "de": "Anteil erneuerbarer Energien | Energy-Charts",
        "en": "Renewable Shares | Energy-Charts",
        "fr": "Part des \u00e9nergies renouvelables | Energy-Charts",
        "it": "Quota di energie rinnovabili | Energy-Charts",
        "es": "Cuota de energ&iacute;as renovables | Energy-Charts",
    },
    "pageTitle_percentag_full_load": {
        "de": "Prozentuale Volllast | Energy-Charts",
        "en": "Percentage of Full Load | Energy-Charts",
        "fr": "Pourcentage de pleine charge | Energy-Charts",
        "it": "Percentuale a pieno carico | Energy-Charts",
        "es": "Porcentaje de carga completa | Energy-Charts",
    },
    "pageTitle_filling_level": {
        "de": "Speicherf\u00fcllst\u00e4nde | Energy-Charts",
        "en": "Storage Filling Level | Energy-Charts",
        "fr": "Niveaux des r\u00e9servoirs | Energy-Charts",
        "it": "Livelli di archiviazione | Energy-Charts",
        "es": "Energy-Charts | Energy-Charts | Energy-Charts | Energy-Charts",
    },
    "pageTitle_import_export": {
        "de": "Import und Export | Energy-Charts",
        "en": "Import and Export | Energy-Charts",
        "fr": "Importations et exportations | Energy-Charts",
        "it": "Importazione e esportazione | Energy-Charts",
        "es": "Importaci&oacute;n y exportaci&oacute;n | Energy-Charts",
    },

    // ===== ENVOIRONMENT =====
    "pageTitle_climate_hours": {
        "de": "Stundenwerte von Klimadaten | Energy-Charts",
        "en": "Hourly Values on Climatedata | Energy-Charts",
        "fr": "Donn\u00e9es climatique par heure  | Energy-Charts",
        "it": "Valori orari dei dati climatici | Energy-Charts",
        "es": "Valores horarios de los datos clim&aacute;ticos | Energy-Charts",
    },
    "pageTitle_climate_annual": {
        "de": "Jahresmittelwerte von Klimadaten | Energy-Charts",
        "en": "Annual Averages on Climatedata | Energy-Charts",
        "fr": "Moyennes annuelles des donn\u00e9es climatiques | Energy-Charts",
        "it": "Valori medi annuali dei dati climatici | Energy-Charts",
        "es": "Valores medios anuales de los datos clim&aacute;ticos | Energy-Charts",
    },
    "pageTitle_co2_emissions": {
        "de": "CO2-Emissionen der Stromerzeugung | Energy-Charts",
        "en": "CO2 emissions from electricity generation | Energy-Charts",
        "fr": "&Eacute;missions de CO2 liées à la production d'électricité | Energy-Charts",
        "it": "Emissioni di CO2 derivanti dalla produzione di energia elettrica | Energy-Charts",
        "es": "Emisiones de CO2 procedentes de la generación de electricidad | Energy-Charts",
    },
    "pageTitle_emissions": {
        "de": "Emissionen der Kraftwerke | Energy-Charts",
        "en": "Emissions of Powerplants | Energy-Charts",
        "fr": "\u00c9missions des centrales \u00e9lectriques | Energy-Charts",
        "it": "Emissioni da centrali elettriche | Energy-Charts",
        "es": "Emisiones de las centrales el&eacute;ctricas | Energy-Charts",
    },        
    "pageTitle_hydrology": {
        "de": "Hydrologie | Energy-Charts",
        "en": "Hydrology | Energy-Charts",
        "fr": "Hydrologie | Energy-Charts",
        "it": "Idrologia | Energy-Charts",
        "es": "Hidrolog&iacute;a | Energy-Charts",
    },

    // ===== PRICE =====
    "pageTitle_price_spot_market": {
        "de": "B\u00f6rsenstrompreise | Energy-Charts",
        "en": "Spot Market Prices | Energy-Charts",
        "fr": "Prix au comptant | Energy-Charts",
        "it": "Scambia i prezzi dell'elettricit\u00e0 | Energy-Charts",
        "es": "Precios de la electricidad en B\u00f6rsen | Energy-Charts",
    },
    "pageTitle_price_volume": {
        "de": "B\u00f6rsenstrompreise und Handelsvolumen | Energy-Charts",
        "en": "Price Volume | Energy-Charts",
        "fr": "Prix au comptant et volume des \u00e9changes | Energy-Charts",
        "it": "Prezzi dell'energia di scambio e volume degli scambi | Energy-Charts",
        "es": "Precios de la electricidad en Bucarest y vol&uacute;menes negociados | Energy-Charts",
    },
    "pageTitle_price_futures": {
        "de": "Preis-Futures | Energy-Charts",
        "en": "Price futures | Energy-Charts",
        "fr": "Prix à terme | Energy-Charts",
        "it": "Futures di prezzo | Energy-Charts",
        "es": "Futuros de precio | Energy-Charts",
    },
    "pageTitle_price_futures_bars": {
        "de": "Preis-Futures | Energy-Charts",
        "en": "Price futures | Energy-Charts",
        "fr": "Prix à terme | Energy-Charts",
        "it": "Futures di prezzo | Energy-Charts",
        "es": "Futuros de precio | Energy-Charts",
    },
    "pageTitle_price_average": {
        "de": "Durchschnittliche B\u00f6rsenstrompreise | Energy-Charts",
        "en": "Average Spot Market Prices | Energy-Charts",
        "fr": "Prix au comptant moyens | Energy-Charts",
        "it": "Prezzi medi dell'elettricit\u00e0 in borsa | Energy-Charts",
        "es": "Precios medios de la electricidad a granel | Energy-Charts",
    },
    "pageTitle_price_dots": {
        "de": "Punktediagramme zu B\u00f6rsenstrompreise | Energy-Charts",
        "en": "Scatter Charts on Spot Market Prices | Energy-Charts",
        "fr": "Diagrammes de dispersion des prix au comptant | Energy-Charts",
        "it": "Diagrammi a punti per i prezzi di scambio dell'energia elettrica | Energy-Charts",
        "es": "Gr&aacute;ficos de precios de la electricidad | Energy-Charts",
    },
    "pageTitle_power_trading": {
        "de": "Aussenhandelsstatistik | Energy-Charts",
        "en": "Power Trade Statistics | Energy-Charts",
        "fr": "Statistiques du commerce ext\u00e9rieur | Energy-Charts",
        "it": "Statistiche del commercio estero | Energy-Charts",
        "es": "Estad&iacute;sticas de comercio exterior | Energy-Charts",
    },

    // ===== SCENARIOS =====
    "pageTitle_remodInstalledPower": {
        "de": "Transformationspfade | Installierte Leistung",
        "en": "Transformation Paths | Installed Power",
        "fr": "Parcours de Transformation | Puissance install\u00e9e",
        "it": "Percorsi di Trasformazione | Capacit\u00e0 installata",
        "es": "V&iacute;as de transformaci&oacute;n | Potencia instalada",
    },
    "pageTitle_applicationTechnologies": {
        "de": "Transformationspfade | Anwendungstechnologien",
        "en": "Transformation Paths | Application Technologies",
        "fr": "Parcours de Transformation | Technologies d'Application",
        "it": "Percorsi di Trasformazione | Tecnologie Applicative",
        "es": "V&iacute;as de transformaci&oacute;n | Tecnolog&iacute;as de aplicaci&oacute;n",
    },
    "pageTitle_remodEmissions": {
        "de": "Transformationspfade | Emissionen",
        "en": "Transformation Paths | Emissions",
        "fr": "Parcours de Transformation | \u00c9missions",
        "it": "Percorsi di Trasformazione | Emissioni",
        "es": "V&iacute;as de transformaci&oacute;n | Emisiones",
    },
    "pageTitle_remodPowerProfiles": {
        "de": "Transformationspfade | Stromprofile",
        "en": "Transformation Paths | Power Profiles",
        "fr": "Parcours de Transformation | Profils de courant",
        "it": "Percorsi di Trasformazione | Profili attuali",
        "es": "V&iacute;as de transformaci&oacute;n | Perfiles de potencia",
    },
    "pageTitle_remodEnergies": {
        "de": "Transformationspfade | Energien",
        "en": "Transformation Paths | Power Sources",
        "fr": "Parcours de Transformation | Sources d'&Eacute;nergie",
        "it": "Percorsi di Trasformazione | Energies",
        "es": "V&iacute;as de transformaci&oacute;n | Energ&iacute;as",
    },
    "pageTitle_remodFlexibility": {
        "de": "Transformationspfade | Flexibilitätsbeiträge",
        "en": "Transformation Paths | Flexibility contributions",
        "fr": "Parcours de Transformation | Cotisations de flexibilit&eacute;",
        "it": "Percorsi di Trasformazione | Contributi di flessibilità",
        "es": "V&iacute;as de transformaci&oacute;n | Aportaciones de flexibilidad",
    },
    "pageTitle_power_heatmaps": {
        "de": "Heatmaps zur Stromerzeugung | Energy-Charts",
        "en": "Power Generation Heatmaps | Energy Charts",
        "fr": "Cartes de chaleur pour la production d'&eacute;lectricit&eacute; | Energy-Charts",
        "it": "Mappe di calore della produzione di energia e grafici energetici",
        "es": "Mapas t&eacute;rmicos de generaci&oacute;n de energ&iacute;a | Energy-Charts",
    },

    // ===== PAGETITLES END =====

    // ===== Slider, Carousel Titles (to be added)=====
    "carousel_title_tba": {
        "de": "",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },
    // ===== Slider, Carousel Titles END =====

    // CHARTS
    "nuclear_unit": {
        "de": "Kernenergie",
        "en": "Nuclear",
        "fr": "Nucl&eacute;aire",
        "it": "Energia Nucleare",
        "es": "Energ&iacute;a nuclear",
    },
    "nuclear": {
        "de": "Kernenergie",
        "en": "Nuclear",
        "fr": "Nucl&eacute;aire",
        "it": "Energia Nucleare",
        "es": "Energ&iacute;a nuclear",
    },
    "biomass_unit": {
        "de": "Biomasse",
        "en": "Biomass",
        "fr": "Bio&eacute;nergies",
        "it": "Biomassa",
        "es": "Biomasse",
    },
    "biomass": {
        "de": "Biomasse",
        "en": "Biomass",
        "fr": "Bio&eacute;nergies",
        "it": "Biomassa",
        "es": "Biomasse",
    },
    "fossil_brown_coal_lignite_unit": {
        "de": "Braunkohle",
        "en": "Fossil Brown Coal/Lignite",
        "fr": "Lignite",
        "it": "Lignite",
        "es": "Lignito",
    },
    "fossil_brown_coal_lignite": {
        "de": "Braunkohle",
        "en": "Fossil Brown Coal/Lignite",
        "fr": "Lignite",
        "it": "Lignite",
        "es": "Lignito",
    },
    "derived_gas_unit": {
        "de": "Gas aus Kohle",
        "en": "Derived Gas",
        "fr": "Gaz de Charbon",
        "it": "Gas da Carbone",
        "es": "Gas de hulla",
    },
    "derived_gas": {
        "de": "Gas aus Kohle",
        "en": "Derived Gas",
        "fr": "Gaz de Charbon",
        "it": "Gas da Carbone",
        "es": "Gas de hulla",
    },
    "map.coal_derived_gas": {
        "de": "Gas aus Kohle",
        "en": "Derived Gas",
        "fr": "Gaz de Charbon",
        "it": "Gas da Carbone",
        "es": "Gas de hulla",
    },
    "coal_derived_gas": {
        "de": "Gas aus Kohle",
        "en": "Derived Gas",
        "fr": "Gaz de Charbon",
        "it": "Gas da Carbone",
        "es": "Gas de hulla",
    },
    "fossil_hard_coal_unit": {
        "de": "Steinkohle",
        "en": "Fossil Hard Coal",
        "fr": "Houille",
        "it": "Carbon Fossile",
        "es": "Hulla",
    },
    "fossil_hard_coal": {
        "de": "Steinkohle",
        "en": "Fossil Hard Coal",
        "fr": "Houille",
        "it": "Carbon Fossile",
        "es": "Hulla",
    },
    "hard_coal": {
        "de": "Steinkohle",
        "en": "Hard Coal",
        "fr": "Houille",
        "it": "Carbon Fossile",
        "es": "Hulla",
    },
    "fossil_peat_unit": {
        "de": "Torf",
        "en": "Peat",
        "fr": "Tourbe",
        "it": "Torba",
        "es": "Torf",
    },
    "fossil_peat": {
        "de": "Torf",
        "en": "Peat",
        "fr": "Tourbe",
        "it": "Torba",
        "es": "Torf",
    },
    "waste_unit": {
        "de": "M&uuml;ll",
        "en": "Waste",
        "fr": "D&eacute;chets",
        "it": "Sciocchezze",
        "es": "Basura",
    },
    "fossil_oil_unit": {
        "de": "&Ouml;l",
        "en": "Fossil Oil",
        "fr": "Fioul",
        "it": "Olio",
        "es": "Aceite",
    },
    "fossil_oil": {
        "de": "&Ouml;l",
        "en": "Fossil Oil",
        "fr": "Fioul",
        "it": "Olio",
        "es": "Aceite",
    },
    "fossil_oil_shale_unit": {
        "de": "Schiefer-&Ouml;l",
        "en": "Shale Oil",
        "fr": "Huile de Schiste",
        "it": "Olio di Scisto",
        "es": "Aceite de pizarra",
    },
    "fossil_oil_shale": {
        "de": "Schiefer-&Ouml;l",
        "en": "Shale Oil",
        "fr": "Huile de Schiste",
        "it": "Olio di Scisto",
        "es": "Aceite de pizarra",
    },
    "fossil_coal-derived_gas_unit": {
        "de": "Gas aus Kohle",
        "en": "Fossil Coal-derived gas",
        "fr": "Gaz de Charbon",
        "it": "Gas da Carbone",
        "es": "Gas de hulla",
    },
    "fossil_coal-derived_gas": {
        "de": "Gas aus Kohle",
        "en": "Fossil Coal-derived gas",
        "fr": "Gaz de Charbon",
        "it": "Gas da Carbone",
        "es": "Gas de hulla",
    },
    "fossil_gas_unit": {
        "de": "Gas",
        "en": "Fossil Gas",
        "fr": "Gaz",
        "it": "Gas",
        "es": "Gas",
    },
    "fossil_gas": {
        "de": "Gas",
        "en": "Fossil Gas",
        "fr": "Gaz",
        "it": "Gas",
        "es": "Gas",
    },
    "energy_crops": {
        "de": "Energiepflanzen",
        "en": "Energy Crops",
        "fr": "Cultures &Eacute;nerg&eacute;tiques",
        "it": "Colture Energetiche",
        "es": "Cultivos energ&eacute;ticos",
    },
    "geothermal_unit": {
        "de": "Geothermie",
        "en": "Geothermal Energy",
        "fr": "G&eacute;othermie",
        "it": "Energia Geotermica",
        "es": "Geotermia",
    },
    "geothermal": {
        "de": "Geothermie",
        "en": "Geothermal Energy",
        "fr": "G&eacute;othermie",
        "it": "Energia Geotermica",
        "es": "Geotermia",
    },
    "hydro_power": {
        "de": "Wasserkraft",
        "en": "Hydropower",
        "fr": "&Eacute;nergie Hydro&eacute;lectrique",
        "it": "Energia Idroelettrica",
        "es": "Energ&iacute;a hidroel&eacute;ctrica",
    },
    "hydro_pumped_storage_unit": {
        "de": "Pumpspeicher",
        "en": "Hydro Pumped Storage",
        "fr": "Hydraulique STEP",
        "it": "Accumulazione con Pompaggio",
        "es": "Almacenamiento por bombeo",
    },
    "hydro_pumped_storage": {
        "de": "Pumpspeicher",
        "en": "Hydro Pumped Storage",
        "fr": "Hydraulique STEP",
        "it": "Accumulazione con Pompaggio",
        "es": "Almacenamiento por bombeo",
    },
    "hydro_run-of-river_unit": {
        "de": "Laufwasser",
        "en": "Hydro Run-of-River",
        "fr": "Hydraulique fil de l'eau",
        "it": "Acqua Fluente",
        "es": "Agua corriente",
    },
    "water": {
        "de": "Wasser",
        "en": "Hydro",
        "fr": "Energie hydraulique",
        "it": "Acqua",
        "es": "Agua",
    },
    "hydro_run-of-river": {
        "de": "Laufwasser",
        "en": "Hydro Run-of-River",
        "fr": "Hydraulique fil de l'eau",
        "it": "Acqua Fluente",
        "es": "Agua corriente",
    },
    "hydro_water_reservoir_unit": {
        "de": "Speicherwasser",
        "en": "Hydro Water Reservoir",
        "fr": "Hydraulique lacs",
        "it": "Accumulazione",
        "es": "Agua almacenada",
    },
    "hydro_water_reservoir": {
        "de": "Speicherwasser",
        "en": "Hydro Water Reservoir",
        "fr": "Hydraulique lacs",
        "it": "Accumulazione",
        "es": "Agua almacenada",
    },
    "marine_unit": {
        "de": "Maritim",
        "en": "Maritim",
        "fr": "Maritime",
        "it": "Marittimo",
        "es": "Mar&iacute;timo",
    },
    "wind_offshore_unit": {
        "de": "Wind Offshore",
        "en": "Wind Offshore",
        "fr": "&Eacute;olien en mer",
        "it": "Eolico Offshore",
        "es": "E&oacute;lica marina",
    },
    "wind_offshore": {
        "de": "Wind Offshore",
        "en": "Wind Offshore",
        "fr": "&Eacute;olien en mer",
        "it": "Eolico Offshore",
        "es": "E&oacute;lica marina",
    },
    "wind_offshore_DayAhead": {
        "de": "Wind Offshore (Day-Ahead)",
        "en": "Wind Offshore (Day-Ahead)",
        "fr": "&Eacute;olien en mer (Day-Ahead)",
        "it": "Eolico Offshore (Day-Ahead)",
        "es": "E&oacute;lica marina (Day-Ahead)",
    },
    "wind_offshore_Intraday": {
        "de": "Wind Offshore (Intraday)",
        "en": "Wind Offshore (Intraday)",
        "fr": "&Eacute;olien en mer (Intraday)",
        "it": "Eolico Offshore (Intraday)",
        "es": "E&oacute;lica marina (Intraday)",
    },
    "wind_offshore_Current": {
        "de": "Wind Offshore (Current)",
        "en": "Wind Offshore (Current)",
        "fr": "&Eacute;olien en mer (Current)",
        "it": "Eolico Offshore (Current)",
        "es": "E&oacute;lica marina (Current)",
    },
    "solar_unit": {
        "de": "Solar",
        "en": "Solar",
        "fr": "Solaire",
        "it": "Solare",
        "es": "Solar",
    },
    "solar_DayAhead": {
        "de": "Solar (Day-Ahead)",
        "en": "Solar (Day-Ahead)",
        "fr": "Solaire (Day-Ahead)",
        "it": "Solare (Day-Ahead)",
        "es": "Solar (Day-Ahead)",
    },
    "solar_Intraday": {
        "de": "Solar (Intraday)",
        "en": "Solar (Intraday)",
        "fr": "Solaire (Intraday)",
        "it": "Solare (Intraday)",
        "es": "Solar (Intraday)",
    },
    "solar_Current": {
        "de": "Solar (Current)",
        "en": "Solar (Current)",
        "fr": "Solaire (Current)",
        "it": "Solare (Current)",
        "es": "Solar (Current)",
    },
    "wind_onshore_unit": {
        "de": "Wind Onshore",
        "en": "Wind Onshore",
        "fr": "&Eacute;olien terrestre",
        "it": "Eolico Onshore",
        "es": "E&oacute;lica terrestre",
    },
    "wind_onshore": {
        "de": "Wind Onshore",
        "en": "Wind Onshore",
        "fr": "&Eacute;olien terrestre",
        "it": "Eolico Onshore",
        "es": "E&oacute;lica terrestre",
    },
    "wind_onshore_DayAhead": {
        "de": "Wind Onshore (Day-Ahead)",
        "en": "Wind Onshore (Day-Ahead)",
        "fr": "&Eacute;olien terrestre (Day-Ahead)",
        "it": "Eolico Onshore (Day-Ahead)",
        "es": "E&oacute;lica terrestre (Day-Ahead)",
    },
    "wind_onshore_Intraday": {
        "de": "Wind Onshore (Intraday)",
        "en": "Wind Onshore (Intraday)",
        "fr": "&Eacute;olien terrestre (Intraday)",
        "it": "Eolico Onshore (Intraday)",
        "es": "E&oacute;lica terrestre (Intraday)",
    },
    "wind_onshore_Current": {
        "de": "Wind Onshore (Current)",
        "en": "Wind Onshore (Current)",
        "fr": "&Eacute;olien terrestre (Current)",
        "it": "Eolico Onshore (Current)",
        "es": "E&oacute;lica terrestre (Current)",
    },
    "other_unit": {
        "de": "Andere",
        "en": "Other",
        "fr": "Autre",
        "it": "Altro",
        "es": "Otros",
    },
    "other_renewable_unit": {
        "de": "Andere Erneuerbare",
        "en": "Other Renewables",
        "fr": "Autres Renouvelables",
        "it": "Altri Rinnovabili",
        "es": "Otras energ&iacute;as renovables",
    },
    "other_renewable": {
        "de": "Andere Erneuerbare",
        "en": "Other Renewables",
        "fr": "Autres Renouvelables",
        "it": "Altri Rinnovabili",
        "es": "Otras energ&iacute;as renovables",
    },
    "battery_unit": {
        "de": "Batteriespeicher",
        "en": "Battery Storage",
        "fr": "Stockage sur Batteries",
        "it": "Accumulo a Batteria",
        "es": "Almacenamiento en bater&iacute;a",
    },
    "prognosis": {
        "de": "Prognose",
        "en": "Prognosis",
        "fr": "Projection",
        "it": "Previsione",
        "es": "Previsi&oacute;n",
    },
    "wind": {
        "de": "Wind",
        "en": "Wind",
        "fr": "&Eacute;olien",
        "it": "Eolico",
        "es": "Viento",
    },
    "Werte": {
        "de": "Werte",
        "en": "Values",
        "fr": "Valeurs",
        "it": "Valori",
        "es": "Valores",
    },


    "load": {
        "de": "Last",
        "en": "Load",
        "fr": "Charge",
        "it": "Carico",
        "es": "Carga",
    },
    "residual_load": {
        "de": "Residuale Last",
        "en": "Residual Load",
        "fr": "Charge R&eacute;siduelle",
        "it": "Carico esiduo",
        "es": "Carga residual",
    },
    "wind_solar": {
        "de": "Wind + Solar",
        "en": "Wind + Solar",
        "fr": "&Eacute;olien + Solaire",
        "it": "Eolico + Solare",
        "es": "E&oacute;lica + Solar",
    },
    "solar_wind": {
        "de": "Photovoltaik und Wind",
        "en": "Photovoltaic and wind",
        "fr": "Photovoltaïque et éolien",
        "it": "Fotovoltaico ed eolico",
        "es": "Fotovoltaica y eólica",
    },
    "sw": {
        "de": "Solar, Wind",
        "en": "Solar, wind",
        "fr": "Solaire, &eacute;olien",
        "it": "Eolico, solare",
        "es": "Solar, e&oacute;lica",
    },
    "sw_total": {
        "de": "Solar, Wind gesamt",
        "en": "Solar, wind total",
        "fr": "Solaire, &eacute;olien total",
        "it": "Solare, eolico totale",
        "es": "Solar, e&oacute;lica total",
    },
    "sw_public": {
        "de": "Solar, Wind öffentlich",
        "en": "Solar, wind public",
        "fr": "Solaire, &eacute;olien public",
        "it": "Eolico, solare pubblico",
        "es": "Solar, e&oacute;lica p&uacute;blica",
    },
    "exp": {
        "de": "Import, Export",
        "en": "Import, Export",
        "fr": "Importation, Exportation",
        "it": "Importazioni, Esportazioni",
        "es": "Importaci&oacute;n, Exportaci&oacute;n",
    },
    "conventional": {
        "de": "Konventionell",
        "en": "Conventional",
        "fr": "Conventionnelle",
        "it": "Convenzionale",
        "es": "Convencional",
    },
    "fossil": {
        "de": "Fossil",
        "en": "Fossil",
        "fr": "Fossile",
        "it": "Fossile",
        "es": "F&oacute;sil",
    },
    "blocksharp_production": {
        "de": "Blockscharfe Erzeugung",
        "en": "Generation per Unit",
        "fr": "Production par Unit&eacute;",
        "it": "Produzione per Unit&agrave;",
        "es": "Generaci&oacute;n de bloques afilados",
    },
    "blocksharp_production_eex": {
        "de": "Blockscharfe Erzeugung (EEX)",
        "en": "Generation per Unit (EEX)",
        "fr": "Production par Unit&eacute; (EEX)",
        "it": "Produzione per Unit&agrave; (EEX)",
        "es": "Generaci&oacute;n de bloques compartidos (EEX)",
    },
    "blocksharp_production_entsoe": {
        "de": "Blockscharfe Erzeugung (ENTSO-E)",
        "en": "Generation per Unit (ENTSO-E)",
        "fr": "Production par Unit&eacute; (ENTSO-E)",
        "it": "Produzione per Unit&agrave; (ENTSO-E)",
        "es": "Generaci&oacute;n por bloques (ENTSO-E)",
    },
    "production": {
        "de": "Erzeugung",
        "en": "Generation",
        "fr": "Production",
        "it": "Produzione",
        "es": "Generaci&oacute;n",
    },
    "import": {
        "de": "Import",
        "en": "Import",
        "fr": "Importer",
        "it": "Importare",
        "es": "Importar",
    },
    "storage": {
        "de": "Speicher",
        "en": "Storage",
        "fr": "Stockage",
        "it": "Conservazione",
        "es": "Memoria",
    },
    "dateselection": {
        "de": "Datumsauswahl",
        "en": "Date Selection",
        "fr": "S&eacute;lection de la Date",
        "it": "Selezione della Data",
        "es": "Selecci&oacute;n de fecha",
    },
    "intervalselection": {
        "de": "Intervallauswahl",
        "en": "Interval selection",
        "fr": "S&eacute;lection des intervalles",
        "it": "Selezione dell'intervallo",
        "es": "Selecci&oacute;n de intervalo",
    },
    // deprecated! should not be used for chartmenu stacking translation
    "stacked": {
        "de": "Gestapelt (Absolut)",
        "en": "Stacked (Absolute)",
        "fr": "Empil&eacute; (Absolu)",
        "it": "Barre Impilate (Valori Assoluti)",
        "es": "Apilado (absoluto)",
    },
    // deprecated! should not be used for chartmenu stacking translation
    "grouped": {
        "de": "Gruppiert",
        "en": "Grouped",
        "fr": "Group&eacute;s",
        "it": "Raggruppati",
        "es": "Agrupado",
    },
    // deprecated! sorted will be replaced by single to celarify the desired view of barcharts!
    "sorted": {
        "de": "Sortiert",
        "en": "Sorted",
        "fr": "Tri&eacute;",
        "it": "Smistato",
        "es": "Clasificado",
    },
    "stacked_absolute": {
        "de": "Gestapelt (Absolut)",
        "en": "Stacked (Absolute)",
        "fr": "Empil&eacute; (Absolu)",
        "it": "Barre Impilate (Valori Assoluti)",
        "es": "Apilado (absoluto)",
    },
    "stacked_percent": {
        "de": "Gestapelt (Prozent)",
        "en": "Stacked (Percent)",
        "fr": "Empil&eacute; (Pourcentage)",
        "it": "Barre Impilate (Percentuali)",
        "es": "Apilado (porcentaje)",
    },
    "stacked_grouped": {
        "de": "Gestapelt (Gruppiert)",
        "en": "Stacked (Grouped)",
        "fr": "Empil&eacute; (Group&eacute;s)",
        "it": "Barre Impilate (Raggruppati)",
        "es": "Apilados (agrupados)",
    },
    "single": {
        "de": "Einzeln",
        "en": "Single",
        "fr": "Individuel",
        "it": "Singolo",
        "es": "&Uacute;nico",
    },
    "annual": {
        "de": "J&auml;hrlich",
        "en": "Annual",
        "fr": "Par An",
        "it": "Annuale",
        "es": "Anual",
    },
    "monthly": {
        "de": "Monatlich",
        "en": "Monthly",
        "fr": "Par Mois",
        "it": "Al Mese",
        "es": "Mensualmente",
    },
    "all_sources": {
        "de": "Alle Quellen",
        "en": "All Sources",
        "fr": "Toutes Sources",
        "it": "Tutte le Fonti",
        "es": "Todas las fuentes",
    },
    "year": {
        "de": "Jahr",
        "en": "Year",
        "fr": "Ann&eacute;e",
        "it": "Anno",
        "es": "A&ntilde;o",
    },
    "halfyear": {
        "de": "Halbjahr",
        "en": "Half year",
        "fr": "Semestre",
        "it": "Mezzo anno",
        "es": "Semestre",
    },
    "quarter_in": {
        "de": "Quartal in",
        "en": "Quarter in ",
        "fr": "Trimestre en",
        "it": "Trimestre nel",
        "es": "Cuartal en",
    },
    "halfyear_in": {
        "de": "Halbjahr in",
        "en": "Half year in",
        "fr": "Semestre en",
        "it": "Mezzo anno nel",
        "es": "Medio a&ntilde;o en",
    },
    "quarter": {
        "de": "Quartal",
        "en": "Quarter",
        "fr": "Trimestre",
        "it": "Trimestre",
        "es": "Cuartal",
    },
"season": {
        "de": "Jahreszeit",
        "en": "Season",
        "fr": "Saison",
        "it": "Stagione",
        "es": "Temporada",
    }, 
    "season_in": {
        "de": "Jahreszeit in",
        "en": "Season in ",
        "fr": "Saison en",
        "it": "Stagione in",
        "es": "Temporada en",
    },
    "spring": {
        "de": "Frühling",
        "en": "Spring",
        "fr": "Printemps",
        "it": "Primavera",
        "es": "Primavera",
    }, 
    "summer": {
        "de": "Sommer",
        "en": "Summer",
        "fr": "Été",
        "it": "Estate",
        "es": "Verano",
    }, 
    "autumn": {
        "de": "Herbst",
        "en": "Autumn",
        "fr": "Automne",
        "it": "Autunno",
        "es": "Otoño",
    }, 
    "winter": {
        "de": "Winter",
        "en": "Winter",
        "fr": "Hiver",
        "it": "Inverno",
        "es": "Invierno",
    },              
    "month": {
        "de": "Monat",
        "en": "Month",
        "fr": "Mois",
        "it": "Mese",
        "es": "Mes",
    },
    "week": {
        "de": "Woche",
        "en": "Week",
        "fr": "Semaine",
        "it": "Settimana",
        "es": "Semana",
    },
    "day": {
        "de": "Tag",
        "en": "Day",
        "fr": "Journ&eacute;e",
        "it": "Giorno",
        "es": "Etiqueta",
    },
    "interval": {
        "de": "Intervall",
        "en": "Interval",
        "fr": "Intervalle",
        "it": "Intervallo",
        "es": "Intervalo",
    },
    "week_in": {
        "de": "Woche in",
        "en": "Week in",
        "fr": "Semaine en",
        "it": "Settimana dopo",
        "es": "Semana en",
    },
    "month_in": {
        "de": "Monat in",
        "en": "Month in",
        "fr": "Mois en",
        "it": "Mese in",
        "es": "Mes en",
    },
    "installed_power": {
        "de": "Installierte Leistung",
        "en": "Installed Power",
        "fr": "Puissance Install&eacute;e",
        "it": "Potenza Installata",
        "es": "Potencia instalada",
    },
    "installation_decommission": {
        "de": "Zubau, R&uuml;ckbau",
        "en": "Installation, Decommission",
        "fr": "Installation, D&eacute;classement",
        "it": "Installazione, Disattivazione",
        "es": "Ampliaci&oacute;n, construcci&oacute;n R&amp;uuml;ck",
    },
    "solar_size": {
        "de": "Solarenergie nach Anlagentyp",
        "en": "Solar power according to production type",
        "fr": "Capacit\u00e9 solaire selon le type d'unit\u00e9",
        "it": "Capacit\u00e0 solare secondo il tipo di unit\u00e0",
        "es": "Energ&iacute;a solar por tipo de sistema",
    },
    "states_absolute_values": {
        "de": "Bundesl&auml;nder, absolute Werte",
        "en": "Federal states, absolute values",
        "fr": "&Eacute;tats f&eacute;d&eacute;raux, valeurs absolues",
        "it": "Stati federali, valori assoluti",
        "es": "Estados federales, valores absolutos",
    },
    "nationwide_absolute_values": {
        "de": "Bundesweit, absolute Werte",
        "en": "Nationwide, absolute values",
        "fr": "Au niveau national, valeurs absolues",
        "it": "A livello nazionale, valori assoluti",
        "es": "A escala nacional, valores absolutos",
    },
    "absolute_values": {
        "de": "absolute Werte",
        "en": "absolute values",
        "fr": "valeurs absolues",
        "it": "valori assoluti",
        "es": "valores absolutos",
    },
    "states_per_head": {
        "de": "Bundesländer, Wert je Einwohner",
        "en": "Federal states, value per inhabitant",
        "fr": "&Eacute;tats f&eacute;d&eacute;raux, valeur par habitant",
        "it": "Stati federali, valore per abitante",
        "es": "Estados federales, valor por habitante",
    },
    "states_per_area": {
        "de": "Bundesländer, Wert pro Fl&aumlche",
        "en": "Federal states, value per area",
        "fr": "&Eacute;tats f&eacute;d&eacute;raux, valeur par zone",
        "it": "Stati federali, valore per area",
        "es": "Estados federales, valor por superficie",
    },


    "print": {
        "de": "Drucken",
        "en": "Print",
        "fr": "Imprimer",
        "it": "Stampa",
        "es": "Imprimir",
    },
    "hints": {
        "de": "Hinweise",
        "en": "Hints",
        "fr": "Aide",
        "it": "Suggerimenti",
        "es": "Notas",
    },

    "hint_1": {
        "de": "Fahren Sie mit der Maus &uuml;ber die Grafik, um die Zahlenwerte anzuzeigen.",
        "en": "Move the mouse over the chart to display values on the selected date.",
        "fr": "Passez la souris sur le graphique pour afficher les valeurs &agrave; la date choisie.",
        "it": "Passate con il mouse sul grafico per visualizzare i valori numerici.",
        "es": "Mueva el rat&oacute;n sobre el gr&aacute;fico para visualizar los valores num&eacute;ricos.",
    },
    "hint_2": {
        "de": "Ein einfacher Klick auf ein Element der Legende blendet die Quelle ein oder aus.",
        "en": "A single click on one of the legend elements shows/hides a source.",
        "fr": "Un simple clic sur un &eacute;l&eacute;ment de la l&eacute;gende affiche ou masque la source.",
        "it": "Con un semplice clic su un elemento della legenda la fonte viene mostrata o nascosta.",
        "es": "Un solo clic en un elemento de la leyenda muestra u oculta la fuente.",
    },

    "hint_3": {
        "de": "Ein doppelter Klick (oder Ctrl+Klick) auf ein Element der Legende blendet ausschlie&szlig;lich diese Quelle ein.",
        "en": "A double click (or Ctrl+Click) on one of the legend items displays only this source.",
        "fr": "Un double clic sur (ou Ctrl+clic) un &eacute;l&eacute;ment de la l&eacute;gende affiche uniquement cette source.",
        "it": "Cliccando due volte su un elemento della legenda viene visualizzata unicamente tale fonte.",
        "es": "Un doble clic (o Ctrl+clic) en un elemento de la leyenda s&oacute;lo mostrar&aacute; esta fuente.",
    },
    "hint_4": {
        "de": "Ein dreifacher Klick (oder Shift+Klick) auf ein Element der Legende zeigt alle Quellen an.",
        "en": "A triple click (or Shift+Click) on one of the legend items displays all sources.",
        "fr": "Un triple clic (ou Shift+clic) sur un &eacute;l&eacute;ment de la l&eacute;gende affiche toutes les sources.",
        "it": "Cliccando tre volte su un elemento della legenda vengono visualizzate tutte le fonti.",
        "es": "Un triple clic (o May&uacute;s+clic) en un elemento de la leyenda muestra todas las fuentes.",
    },


    "timezone_germany": {
        "de": "Ortszeit",
        "en": "Local Time",
        "fr": "Heure Locale",
        "it": "Ora Locale",
        "es": "Hora local",
    },
    "timezone_ec_country": {
        "de": "Ortszeit",
        "en": "Local Time",
        "fr": "Heure Locale",
        "it": "Ora Locale",
        "es": "Hora local",
    },
    "timezone_user": {
        "de": "Ihre Zeitzone",
        "en": "Your Time Zone",
        "fr": "Votre Fuseau Horaire",
        "it": "Vostro Fuso Orario",
        "es": "Su huso horario",
    },

    "utc": {
        "de": "Koordinierte Weltzeit",
        "en": "Coordinated Universal Time",
        "fr": "Temps universel coordonn&eacute;",
        "it": "Tempo universale coordinato",
        "es": "Tiempo universal coordinado",
    },

    "absolute_area": {
        "de": "Absolut (Fl&auml;che)",
        "en": "Absolute (Area)",
        "fr": "Absolue (Surface)",
        "it": "Valori Assoluti (Area)",
        "es": "Absoluto (Zona)",
    },
    "absolute_line": {
        "de": "Absolut (Linie)",
        "en": "Absolute (Line)",
        "fr": "Absolue (Ligne)",
        "it": "Valori Assoluti (Linea)",
        "es": "Absoluto (l&iacute;nea)",
    },
    "percent_area": {
        "de": "Prozent (Fl&auml;che)",
        "en": "Percent (Area)",
        "fr": "Pourcentage (Surface)",
        "it": "Percentuale (Area)",
        "es": "Porcentaje (&aacute;rea)",
    },


    "time_data": {
        "de": "Zeitangaben",
        "en": "Time Data",
        "fr": "Donn&eacute;es de Temps",
        "it": "Informazioni Remporali",
        "es": "Datos temporales",
    },
    "description": {
        "de": "Beschreibung",
        "en": "Description",
        "fr": "Description",
        "it": "Descrizione",
        "es": "Descripci&oacute;n",
    },
    "no_description_available": {
        "de": "Keine Beschreibung verf&uuml;gbar.",
        "en": "No description available.",
        "fr": "Pas de description disponible.",
        "it": "Nessuna descrizione disponibile.",
        "es": "No hay descripci&oacute;n disponible.",
    },
    "no_data": {
        "de": "F&uuml;r diese Auswahl sind aktuell keine Daten verf&uuml;gbar.",
        "en": "No data currently available for this selection.",
        "fr": "Aucune donn&eacute;e actuellement disponible pour cette s&eacute;lection.",
        "it": "Nessun dato &egrave; attualmente disponibile per questa selezione.",
        "es": "Actualmente no se dispone de datos para esta selecci&oacute;n.",
    },
    "hourly": {
        "de": "St&uuml;ndlich",
        "en": "Hourly",
        "fr": "&Agrave; l'heure",
        "it": "Ogni Ora",
        "es": "Steady",
    },
    "30min": {
        "de": "Halbst&uuml;ndlich",
        "en": "Half-hourly",
        "fr": "&Agrave; la Demi-heure",
        "it": "Ogni 30 Minuti",
        "es": "Semestral",
    },
    "15min": {
        "de": "Viertelst&uuml;ndlich",
        "en": "Quarter-hourly",
        "fr": "Au Quart d'heure",
        "it": "Ogni 15 minuti",
        "es": "Trimestral",
    },

    "15min_pronovo": {
        "de": "Viertelst&uuml;ndlich (Pronovo)",
        "en": "Quarter-hourly (Pronovo)",
        "fr": "Au Quart d'heure (Pronovo)",
        "it": "Ogni 15 Minuti (Pronovo)",
        "es": "Trimestral (Pronovo)",
    },
    "5min_solarlog": {
        "de": "5-Min-Werte (Solarlog)",
        "en": "5-min-values (Solarlog)",
        "fr": "Valeurs 5 min (Solarlog)",
        "it": "Valori a 5 minuti (Solarlog)",
        "es": "Valores 5-Min (Solarlog)",
    },
    "solarlog": {
        "de": "Solarlog",
        "en": "Solarlog",
        "fr": "Solarlog",
        "it": "Solarlog",
        "es": "Solarlog",
    },
    "hourly_entsoe": {
        "de": "St&uuml;ndlich (ENTSO-E)",
        "en": "Hourly (ENTSO-E)",
        "fr": "&Agrave; l'heure (ENTSO-E)",
        "it": "Ogni Ora (ENTSO-E)",
        "es": "Por hora (ENTSO-E)",
    },
    "hourly_entsoe_bfe": {
        "de": "St&uuml;ndlich (ENTSO-E, BFE)",
        "en": "Hourly (ENTSO-E, BFE)",
        "fr": "&Agrave; l'heure (ENTSO-E, BFE)",
        "it": "Ogni Ora (ENTSO-E, BFE)",
        "es": "Por hora (ENTSO-E, BFE)",
    },
    "pronovo": {
        "de": "Pronovo",
        "en": "Pronovo",
        "fr": "Pronovo",
        "it": "Pronovo",
        "es": "Pronovo",
    },
    "entsoe": {
        "de": "ENTSO-E",
        "en": "ENTSO-E",
        "fr": "ENTSO-E",
        "it": "ENTSO-E",
        "es": "ENTSO-E",
    },
    "entsoe_bfe": {
        "de": "ENTSO-E + BFE",
        "en": "ENTSO-E + BFE",
        "fr": "ENTSO-E + BFE",
        "it": "ENTSO-E + BFE",
        "es": "ENTSO-E + BFE",
    },

    "price": {
        "de": "Preis",
        "en": "Price",
        "fr": "Prix",
        "it": "Prezzo",
        "es": "Precio",
    },
    "prices": {
        "de": "Preise",
        "en": "Prices",
        "fr": "Prix",
        "it": "Prezzi",
        "es": "Precios",
    },
    "nominal_price": {
        "de": "Nominalpreise",
        "en": "Nominal Prices",
        "fr": "Prix Nominal",
        "it": "Prezzi Nominali",
        "es": "Precios nominales",
    },
    "real_price": {
        "de": "Realpreise",
        "en": "Real Prices",
        "fr": "Prix R&eacute;el",
        "it": "Prezzi Reali",
        "es": "Precios reales",
    },

    "wind_direction": {
        "de": "Windrichtung",
        "en": "Wind Direction",
        "fr": "Direction du Vent",
        "it": "Direzione del Vento",
        "es": "Direcci&oacute;n del viento",
    },
    "wind_speed": {
        "de": "Windgeschwindigkeit",
        "en": "Wind Speed",
        "fr": "Vitesse du Vent",
        "it": "Velocit&agrave; del Vento",
        "es": "Velocidad del viento",
    },
    "solar_globe": {
        "de": "Globalstrahlung",
        "en": "Global Radiation",
        "fr": "Rayonnement Global",
        "it": "Radiazione Globale",
        "es": "Radiaci&oacute;n Global",
    },
    "solar_diffuse": {
        "de": "Diffusstrahlung",
        "en": "Diffuse Radiation",
        "fr": "Rayonnement Diffus",
        "it": "Radiazione Diffusa",
        "es": "Radiaci&oacute;n Difusa",
    },
    "air_temperature": {
        "de": "Lufttemperatur",
        "en": "Air Temperature",
        "fr": "Temp&eacute;rature de l'Air",
        "it": "Temperatura dell’aria",
        "es": "Temperatura del aire",
    },
    "air_humidity": {
        "de": "Relative Feuchte",
        "en": "Relative Humidity",
        "fr": "Humidit&eacute; Relative",
        "it": "Umidit&agrave; Relativa",
        "es": "Humedad relativa",
    },
    "air": {
        "de": "Luft",
        "en": "Air",
        "fr": "Air",
        "it": "Aria",
        "es": "Aire",
    },

    "sun_dur": {
        "de": "Sonnenstunden",
        "en": "Sunshine Hours",
        "fr": "Heures d'ensoleillement",
        "it": "Ore di Insolazione",
        "es": "Horas de sol",
    },
    "precipitation": {
        "de": "Niederschlag",
        "en": "Precipitation",
        "fr": "Pr&eacute;cipitations",
        "it": "Precipitazioni",
        "es": "Precipitaci&oacute;n",
    },
    "air_color": {
        "de": "Lufttemperatur",
        "en": "Air Temperature",
        "fr": "Temp&eacute;rature",
        "it": "Temperatura dell’aria",
        "es": "Temperatura del aire",
    },
    "air_color_flat": {
        "de": "W&auml;rmestreifen",
        "en": "Temperature Stripes",
        "fr": "Bandes de R&eacute;chauffement",
        "it": "Strisce di Riscaldamento",
        "es": "W&amp;auml;rm tiras",
    },
    "sum": {
        "de": "Summe",
        "en": "Sum",
        "fr": "Somme",
        "it": "Somma",
        "es": "Total",
    },
    "sum_generation": {
        "de": "Summe Anteil an Erzeugung",
        "en": "Sum of share of generation",
        "fr": "Somme de la part de la g&eacute;n&eacute;ration",
        "it": "Somma della quota di generazione",
        "es": "Cuota total de producci&oacute;n",
    },
    "sum_load": {
        "de": "Summe Anteil an Last",
        "en": "Sum of share of load",
        "fr": "Somme de la part de la charge",
        "it": "Somma della quota nel carico",
        "es": "Cuota total de carga",
    },
    "line": {
        "de": "Liniendiagramm",
        "en": "Line Chart",
        "fr": "Graphique Lin&eacute;aires",
        "it": "Schema di Linea",
        "es": "Diagrama de l&iacute;neas",
    },
    "linecharts": {
        "de": "Liniendiagramme",
        "en": "Line Charts",
        "fr": "Graphiques Lin&eacute;aires",
        "it": "Grafici a Linee",
        "es": "Gr&aacute;ficos lineales",
    },
    "column": {
        "de": "Säulendiagramm",
        "en": "Column chart",
        "fr": "Diagramme à colonnes",
        "it": "Diagramma a colonna",
        "es": "Diagrama de columnas",
    },
    "barcharts": {
        "de": "Säulendiagramme",
        "en": "Column charts",
        "fr": "Diagrammes à colonnes",
        "it": "Diagrammi a colonna",
        "es": "Diagramas de columnas",
    },
    "local_resolution": {
        "de": "Ortsaufl&ouml;sung",
        "en": "Local Resolution",
        "fr": "R&eacute;solution Locale",
        "it": "Risoluzione Locale",
        "es": "Resoluci&oacute;n local",
    },
    "level": {
        "de": "Ebene",
        "en": "Level",
        "fr": "Niveau",
        "it": "Livello",
        "es": "Nivel",
    },

    "stacking": {
        "de": "Anordnung",
        "en": "Stacking",
        "fr": "Empilage",
        "it": "Disposizione",
        "es": "Disposici&oacute;n",
    },
    "bar_labeling": {
        "de": "Säulenbeschriftung",
        "en": "Column labeling",
        "fr": "Inscription sur la colonne",
        "it": "Etichettatura della colonna",
        "es": "Etiquetado de columnas",
    },
    "total_amount": {
        "de": "Gesamtsumme",
        "en": "Total Amount",
        "fr": "Montant Total",
        "it": "Totale Complessivo",
        "es": "Total",
    },
    "subtotals": {
        "de": "Teilsummen",
        "en": "Subtotals",
        "fr": "Sous-totaux",
        "it": "Totali Parziali",
        "es": "Subtotales",
    },
    "source": {
        "de": "Quelle",
        "en": "Source",
        "fr": "Source",
        "it": "Fonte",
        "es": "Esos",
    },
    "sources": {
        "de": "Quellen",
        "en": "Sources",
        "fr": "Sources",
        "it": "Fonti",
        "es": "Fuentes",
    },
    "minimum": {
        "de": "Minimum",
        "en": "Minimum",
        "fr": "Minimum",
        "it": "Minimo",
        "es": "M&iacute;nimo",
    },
    "maximum": {
        "de": "Maximum",
        "en": "Maximum",
        "fr": "Maximum",
        "it": "Massimo",
        "es": "M&aacute;ximo",
    },
    "deviation" : {
        "de": "Abweichung",
        "en": "Deviation",
        "fr": "D&eacute;viation",
        "it": "Deviazione",
        "es": "Desviaci&oacute;n",
    },
    "last_update": {
        "de": "Letztes Update",
        "en": "Last Update",
        "fr": "Derni&egrave;re Actualisation",
        "it": "Ultimo Aggiornamento",
        "es": "&Uacute;ltima actualizaci&oacute;n",
    },
    "last_update_ec": {
        "de": "Energy-Charts.info - letztes Update",
        "en": "Energy-Charts.info - last update",
        "fr": "Energy-Charts.info - derni\u00e8re actualisation",
        "it": "Energy-Charts.info - ultimo aggiornamento",
        "es": "Energy-Charts.info - &uacute;ltima actualizaci&oacute;n",
    },
    "data_source": {
        "de": "Datenquelle",
        "en": "Data Source",
        "fr": "Source de Donn&eacute;es",
        "it": "Fonte dei Dati",
        "es": "Fuente de datos",
    },
    "solar_vs_wind": {
        "de": "Solar vs. Wind",
        "en": "Solar vs. Wind",
        "fr": "Solaire vs. &Eacute;olien",
        "it": "Solare Vs. Eolico",
        "es": "Energ&iacute;a solar frente a energ&iacute;a e&oacute;lica",
    },
    "solar_vs_hydro_water_reservoir": {
        "en": "Solar vs. hydro water reservoir",
        "de": "Solar vs. Speicherwasser",
        "fr": "Solaire vs. réservoir d'eau hydraulique",
        "it": "Solare vs. serbatoio dell'acqua idroelettrica",
        "es": "Solar vs depósito de agua hidroeléctrico",
    },
    "prognosis_vs_real": {
        "de": "Prognose vs. Real",
        "en": "Prognosis vs. Real",
        "fr": "Pronostic vs. r&eacute;el",
        "it": "Previsione Vs. Real",
        "es": "Previsi&oacute;n vs. Real",
    },
    "expansion": {
        "de": "Ausbau",
        "en": "Expansion",
        "fr": "Expansion",
        "it": "Ampliamento",
        "es": "Expansi&oacute;n",
    },
    "renewables": {
        "de": "Erneuerbare",
        "en": "Renewables",
        "fr": "Renouvelable",
        "it": "Rinnovabili",
        "es": "Renovable",
    },
    "renewable": {
        "de": "Erneuerbar",
        "en": "Renewable",
        "fr": "Renouvelable",
        "it": "Rinnovabile",
        "es": "Renovable",
    },
    "non_renewable": {
        "de": "Nicht Erneuerbare",
        "en": "Non-Renewable",
        "fr": "Non Renouvelable",
        "it": "Non Rinnovabili",
        "es": "No renovables",
    },
    "of": {
        "de": "von",
        "en": "of",
        "fr": "de",
        "it": "di",
        "es": "de",
    },
    "solar_radiation": {
        "de": "Solarstrahlung",
        "en": "Solar Radiation",
        "fr": "Rayonnement Solaire",
        "it": "Radiazione Solare",
        "es": "Radiaci&oacute;n solar",
    },
    "renewable_share": {
        "de": "Anteil Erneuerbare",
        "en": "Renewable Share",
        "fr": "Part Renouvelable",
        "it": "Quota di Rinnovabili",
        "es": "Porcentaje de energ&iacute;as renovables",
    },
    "renewable_share_of_load": {
        "de": "Anteil EE an der Last",
        "en": "Share of RE in the load",
        "fr": "Part des SER dans la charge",
        "it": "Quota di RE nel carico",
        "es": "Porcentaje de ER en la carga",
    },
    "renewable_share_of_generation": {
        "de": "Anteil EE an der Erzeugung",
        "en": "Share of RE in generation",
        "fr": "Part des SER dans la production",
        "it": "Quota di RE nella generazione",
        "es": "Cuota de las ER en la generaci&oacute;n",
    },
    "wind_share": {
        "de": "Anteil Wind",
        "en": "Wind Share",
        "fr": "Part de l'&Eacute;olien",
        "it": "Quota di Vento",
        "es": "Compartir viento",
    },
    "solar_share": {
        "de": "Anteil Solar",
        "en": "Solar Share",
        "fr": "Part du Solaire",
        "it": "Quota di Solare",
        "es": "Compartir energ&iacute;a solar",
    },
    "energy_carrier": {
        "de": "Energietr&auml;ger",
        "en": "Energy Sources",
        "fr": "Sources d'&Eacute;nergie",
        "it": "Fonti di Energia",
        "es": "Portador de energ&iacute;a",
    },
    "energy_source": {
        "de": "Energiequelle",
        "en": "Energy Source",
        "fr": "Source d'&Eacute;nergie",
        "it": "Fonte di Energia",
        "es": "Fuente de energ&iacute;a",
    },
    "total": {
        "de": "Gesamt",
        "en": "Total",
        "fr": "Total",
        "it": "Totale",
        "es": "Total",
    },
    "public": {
        "de": "&#214;ffentlich",
        "en": "Public",
        "fr": "Public",
        "it": "Pubblico",
        "es": "p&uacute;blico",
    },
    "total_public": {
        "de": "Gesamt / &#214;ffentlich",
        "en": "Total / public",
        "fr": "Total / public",
        "it": "Totale / Pubblico",
        "es": "Total / &amp;#214;p&uacute;blico",
    },
    "historical": {
        "de": "Historische Daten",
        "en": "Historical data",
        "fr": "Données historiques",
        "it": "Dati storici",
        "es": "Datos históricos",
    },    
    "absolute": {
        "de": "Absolut",
        "en": "Absolute",
        "fr": "Absolu",
        "it": "Assolutamente",
        "es": "Absolut",
    },
    "percent": {
        "de": "Prozent",
        "en": "Percent",
        "fr": "Pourcentage",
        "it": "Per Cento",
        "es": "Porcentaje",
    },
    "specific": {
        "de": "Spezifisch",
        "en": "Specific",
        "fr": "Sp&eacute;cifique",
        "it": "Specifica",
        "es": "Espec&iacute;fico",
    },
    "emissions": {
        "de": "Emissionen",
        "en": "Emissions",
        "fr": "&Eacute;missions",
        "it": "Emissioni",
        "es": "Emisiones",
    },  
    "greenhouse_gases": {
        "de": "Treibhausgase",
        "en": "Greenhouse Gases",
        "fr": "Gaz &agrave; effet de serre",
        "it": "Gas Serra",
        "es": "Gases de efecto invernadero",
    },
    "chemical_n2o": {
        "de": "Distickoxid",
        "en": "Nitrous Oxide",
        "fr": "Oxyde Nitrique",
        "it": "Diossido di Azoto",
        "es": "Distickoxide",
    },
    "chemical_co2": {
        "de": "Kohlendioxid",
        "en": "Carbon Dioxide",
        "fr": "Dioxyde de Carbone",
        "it": "Diossido di Carbonio",
        "es": "Di&oacute;xido de carbono",
    },
    "chemical_ch4": {
        "en": "Methane",
        "de": "Methan",
        "fr": "M&eacute;thane",
        "it": "Metano",
        "es": "Metano",
    },
    "heavy_metals": {
        "de": "Schwermetalle",
        "en": "Heavy Metals",
        "fr": "M&eacute;taux Lourds",
        "it": "Metalli Pesanti",
        "es": "Metales pesados",
    },
    "chemical_as": {
        "de": "Arsen",
        "en": "Arsenic",
        "fr": "Arsenic",
        "it": "Arsenico",
        "es": "Arsen",
    },
    "chemical_pb": {
        "de": "Blei",
        "en": "Lead",
        "fr": "Plomb",
        "it": "Piombo",
        "es": "Plomo",
    },
    "chemical_cd": {
        "de": "Cadmium",
        "en": "Cadmium",
        "fr": "Cadmium",
        "it": "Cadmio",
        "es": "Cadmio",
    },
    "chemical_cr": {
        "de": "Chrom",
        "en": "Chrome",
        "fr": "Chrome",
        "it": "Cromo",
        "es": "Cromo",
    },
    "chemical_cu": {
        "de": "Kupfer",
        "en": "Copper",
        "fr": "Cuivre",
        "it": "Rame",
        "es": "Cobre",
    },
    "chemical_ni": {
        "de": "Nickel",
        "en": "Nickel",
        "fr": "Nickel",
        "it": "Nichel",
        "es": "N&iacute;quel",
    },
    "chemical_hg": {
        "de": "Quecksilber",
        "en": "Mercury",
        "fr": "Mercure",
        "it": "Mercurio",
        "es": "Mercurio",
    },
    "chemical_zn": {
        "de": "Zink",
        "en": "Zinc",
        "fr": "Zinc",
        "it": "Zinco",
        "es": "Zinc",
    },
    "other_substances": {
        "de": "Andere Stoffe",
        "en": "Other Substances",
        "fr": "Autres Substances",
        "it": "Altre Sostanze",
        "es": "Otras sustancias",
    },
    "chemical_nh3": {
        "de": "Ammoniak",
        "en": "Ammonia",
        "fr": "Ammoniac",
        "it": "Ammoniaca",
        "es": "Ammoniak",
    },
    "chemical_c6h6": {
        "de": "Benzol",
        "en": "Benzene",
        "fr": "Benz&egrave;ne",
        "it": "Benzene",
        "es": "Benzol",
    },
    "chemical_cl": {
        "de": "Chlor",
        "en": "Chlorine",
        "fr": "Chlore",
        "it": "Cloro",
        "es": "Cloro",
    },
    "chemical_pcdd": {
        "de": "Dioxine, Furane",
        "en": "Dioxins, Furans",
        "fr": "Dioxines, Furannes",
        "it": "Diossine, Furani",
        "es": "Dioxina, Furano",
    },
    "chemical_pm10": {
        "de": "Feinstaub",
        "en": "Particulate Matter",
        "fr": "Poussi&egrave;re fine",
        "it": "Polveri Sottili",
        "es": "Polvo fino",
    },
    "chemical_f": {
        "de": "Fluor",
        "en": "Fluorine",
        "fr": "Fluor",
        "it": "Fluoro",
        "es": "Fl&uacute;or",
    },
    "chemical_co": {
        "de": "Kohlenmonoxid",
        "en": "Carbon Monoxide",
        "fr": "Monoxyde de Carbone",
        "it": "Monossido di Carbonio",
        "es": "Mon&oacute;xido de carbono",
    },
    "chemical_sox": {
        "de": "Schwefeloxide",
        "en": "Sulphur Oxides",
        "fr": "Oxydes de Soufre",
        "it": "Ossidi di Zolfo",
        "es": "&Oacute;xidos de azufre",
    },
    "chemical_nox": {
        "de": "Stickoxide",
        "en": "Nitrogen Oxides",
        "fr": "Oxyde Nitrique",
        "it": "Ossidi di Azoto",
        "es": "&Oacute;xidos de nitr&oacute;geno",
    },

    "europe": {
        "de": "Europa",
        "en": "Europe",
        "fr": "Europe",
        "it": "Europa",
        "es": "Europa",
    },
    "germany": {
        "de": "Deutschland",
        "en": "Germany",
        "fr": "Allemagne",
        "it": "Germania",
        "es": "Alemania",
    },
    "switzerland": {
        "de": "Schweiz",
        "en": "Switzerland",
        "fr": "Suisse",
        "it": "Svizzera",
        "es": "Suiza",
    },
    "federal_state": {
        "de": "Bundesland",
        "en": "State",
        "fr": "&Eacute;tat f&eacute;d&eacute;ral",
        "it": "Stato",
        "es": "Estado",
    },
    "federal_states": {
        "de": "Bundesl&auml;nder",
        "en": "States",
        "fr": "&Eacute;tats f&eacute;d&eacute;raux",
        "it": "Stati Federali",
        "es": "Estados federales",
    },
    "city_station": {
        "de": "Stadt / Station",
        "en": "City / Station",
        "fr": "Station / Ville",
        "it": "Citt&agrave; / Stazione",
        "es": "Ciudad / Estaci&oacute;n",
    },
    "cities_stations": {
        "de": "St&auml;dte / Stationen",
        "en": "Cities / Stations",
        "fr": "Villes / Stations",
        "it": "Citt&agrave; / Stazion",
        "es": "Ciudades / Estaciones",
    },
    "trade": {
        "de": "Handel",
        "en": "Trade",
        "fr": "March&eacute;",
        "it": "Scambi",
        "es": "Comercio",
    },
    "zoom": {
        "de": "Zoom",
        "en": "Zoom",
        "fr": "Zoom",
        "it": "Zoom",
        "es": "Zoom",
    },
    "zoom_price_plus": {
        "de": "Zoom Preis +",
        "en": "Zoom Price +",
        "fr": "Zoom Prix +",
        "it": "Zoom Prezzo +",
        "es": "Zoom Precio +",
    },
    "zoom_price_minus": {
        "de": "Zoom Preis -",
        "en": "Zoom Price -",
        "fr": "Zoom Prix -",
        "it": "Zoom Prezzo -",
        "es": "Zoom Precio -",
    },
    "zoom_price_zero": {
        "de": "Zoom Preis 0",
        "en": "Zoom Price 0",
        "fr": "Zoom Prix 0",
        "it": "Zoom Prezzo 0",
        "es": "Precio del zoom 0",
    },

    "time_period": {
        "de": "Zeitraum",
        "en": "Time Period",
        "fr": "P&eacute;riode",
        "it": "Periodo",
        "es": "Periodo",
    },
    "price_vs_wind": {
        "de": "Preis vs. Wind",
        "en": "Price vs. Wind",
        "fr": "Prix vs. &Eacute;olien",
        "it": "Prezzo Vs. Eolico",
        "es": "Precio frente a viento",
    },
    "price_vs_solar": {
        "de": "Preis vs. Solar",
        "en": "Price vs. Solar",
        "fr": "Prix vs. Solaire",
        "it": "Prezzo Vs. Solare",
        "es": "Precio frente a energ&iacute;a solar",
    },
    "price_vs_wind_solar": {
        "de": "Preis vs. Wind + Solar",
        "en": "Price vs. Wind + Solar",
        "fr": "Prix vs. &Eacute;olien + Solaire",
        "it": "Prezzo Vs. Eolico + Solare",
        "es": "Precio frente a e&oacute;lica + solar",
    },
    "price_vs_load": {
        "de": "Preis vs. Last",
        "en": "Price vs. Load",
        "fr": "Prix vs. Charge",
        "it": "Prezzo Vs. Carico",
        "es": "Precio vs. carga",
    },
    "price_vs_residual_load": {
        "de": "Preis vs. residuale Last",
        "en": "Price vs. residual Load",
        "fr": "Prix vs. charge r&eacute;siduelle",
        "it": "Prezzo Vs. Carico Residuo",
        "es": "Preis vs. &Uacute;ltimo residual",
    },

    "sum_countries": {
        "de": "Summe L&auml;nder",
        "en": "Sum total of Countries",
        "fr": "somme pays",
        "it": "Somma Land",
        "es": "Total pa&iacute;ses",
    },
    "value_euro": {
        "de": "Wert (&euro;)",
        "en": "Value (&euro;)",
        "fr": "Valeur (&euro;)",
        "it": "Valore (&euro;)",
        "es": "Valor (&amp;euro;)",
    },
    "energy_twh": {
        "de": "Energie (TWh)",
        "en": "Energy (TWh)",
        "fr": "&Eacute;nergie (TWh)",
        "it": "Energia (TWh)",
        "es": "Energ&iacute;a (TWh)",
    },
    "price_euro_mwh": {
        "de": "Preis (&euro;/MWh)",
        "en": "Price (&euro;/MWh)",
        "fr": "Prix (&euro;/MWh)",
        "it": "Prezzo (&euro;/MWh)",
        "es": "Precio (&amp;euro;/MWh)",
    },
    "country_specific_balance": {
        "de": "L&auml;nderspezifischer Saldo",
        "en": "Country-specific Balance",
        "fr": "Solde par pays",
        "it": "Equilibrio Specifico per Paese",
        "es": "Balance por pa&iacute;ses",
    },
    "country_specific_import": {
        "de": "L&auml;nderspezifischer Import",
        "en": "Country-specific Import",
        "fr": "Importations par pays",
        "it": "Importazione Specifica per Paese",
        "es": "Importaci&oacute;n espec&iacute;fica por pa&iacute;s",
    },
    "country_specific_export": {
        "de": "L&auml;nderspezifischer Export",
        "en": "Country-specific Export",
        "fr": "Exportations par pays",
        "it": "Esportazione Specifica per Paese",
        "es": "Exportaci&oacute;n espec&iacute;fica de un pa&iacute;s",
    },
    "commercial_exchanges": {
        "de": "Grenzüberschreitender Stromhandel",
        "en": "Cross border electricity trading",
        "fr": "Les échanges commerciaux aux frontières",
        "it": "Commercio transfrontaliero di energia elettrica",
        "es": "Comercio transfronterizo de electricidad",
    },
    "physical_flows": {
        "de": "Grenzüberschreitende physikalische Stromflüsse",
        "en": "Cross border physical flows",
        "fr": "Flux physique transfrontali&egrave;re",
        "it": "Flusso fisico transfrontaliera",
        "es": "Flujo f&iacute;sico de electricidad transfronteriza",
    },

    "dayahead": {
        "de": "Day-Ahead",
        "en": "Day-Ahead",
        "fr": "Day-Ahead",
        "it": "Day-Ahead",
        "es": "Day-Ahead",
    },
    "intraday": {
        "de": "Intraday",
        "en": "Intraday",
        "fr": "Intraday",
        "it": "Intraday",
        "es": "Intraday",
    },

    "all": {
        "de": "Alle",
        "en": "All",
        "fr": "Tous",
        "it": "Tutti",
        "es": "Todos",
    },
    "sum_1_3": {
        "de": "Summe 1-3",
        "en": "Sum 1-3",
        "fr": "Total 1-3",
        "it": "Somma 1-3",
        "es": "Suma 1-3",
    },
    "all_month": {
        "de": "alle Monate",
        "en": "all Months",
        "fr": "tous les Mois",
        "it": "ogni Mese",
        "es": "en Monate",
    },
    "all_weeks": {
        "de": "alle Wochen",
        "en": "all Weeks",
        "fr": "toutes les Semaines",
        "it": "ogni Settimana",
        "es": "Cada semana",
    },
    "all_days_in": {
        "de": "alle Tage in",
        "en": "all Days in",
        "fr": "tous les Jours en",
        "it": "tutti i Giorni dentro",
        "es": "todos los d&iacute;as en",
    },

    "daysharp_year": {
        "de": "Tagesscharfe Jahreswerte",
        "en": "Daysharp Yearly Values",
        "fr": "Des Valeurs Annuelles Fortes et Quotidiennes",
        "it": "Valori Annuali Giornalieri",
        "es": "Valores diarios anuales",
    },
    "daysharp_month": {
        "de": "Tagesscharfe Monatswerte",
        "en": "Daysharp Monthly Values",
        "fr": "Des Valeurs Mensuelles Fortes et Quotidiennes",
        "it": "Valori Mensili Giornalieri",
        "es": "Valores diarios mensuales",
    },
    "daysharp_week": {
        "de": "Tagesscharfe Wochenwerte",
        "en": "Daysharp Weekly Values",
        "fr": "Des Valeurs Nettes au Quotidien",
        "it": "Valori Giornalieri per la Settimana",
        "es": "Valores diarios semanales",
    },

    "daily_values_for": {
        "de": "Tageswerte f&uuml;r",
        "en": "Daily Values for",
        "fr": "Valeurs Quotidiennes pour",
        "it": "Valori Giornalieri per",
        "es": "Valores diarios para",
    },

    "trading_day": {
        "de": "Handelstag",
        "en": "Trading day",
        "fr": "Jour de n&eacute;gociation",
        "es": "D&iacute;a de negociaci&oacute;n",
        "it": "Giorno di negoziazione",
    },

    "extremes": {
        "de": "Extremwerte",
        "en": "Extreme Values",
        "fr": "Des Valeurs Extr&ecirc;mes",
        "it": "Valori Estremi",
        "es": "Valores extremos",
    },
    "legend": {
        "de": "Legende",
        "en": "Legend",
        "fr": "L&eacute;gende",
        "it": "Leggenda",
        "es": "Leyenda",
    },
    "Kraftwerksstandorte": {
        "de": "Kraftwerksstandorte",
        "en": "Power Plant Locations",
        "fr": "Emplacement des Centrales &Eacute;lectriques",
        "it": "Posizioni delle Centrali Elettriche",
        "es": "Ubicaci&oacute;n de las centrales el&eacute;ctricas",
    },
    "climate_vars": {
        "de": "Klimavariablen",
        "en": "Climate Variables",
        "fr": "Variables Climatiques",
        "it": "Variabili climatiche",
        "es": "Klimavariablen",
    },

    "import_export": {
        "de": "Import, Export",
        "en": "Import, Export",
        "fr": "Importation, Exportation",
        "it": "Importazioni, Esportazioni",
        "es": "Importaci&oacute;n, Exportaci&oacute;n",
    },

    "cbpf_saldo": {
        "de": "Grenzüberschreitende physikalische Stromflüsse",
        "en": "Cross border physical flows",
        "fr": "Flux physique transfrontali&egrave;re",
        "it": "Flusso fisico transfrontaliera",
        "es": "Flujo f&iacute;sico de electricidad transfronteriza",
    },
    "scheduled_commercial_exchanges": {
		"de": "Grenzüberschreitender Stromhandel",
        "en": "Cross border electricity trading",
        "fr": "Les échanges commerciaux aux frontières",
        "it": "Commercio transfrontaliero di energia elettrica",
        "es": "Comercio transfronterizo de electricidad",
    },

    "stacking_area": {
        "de": "F&uuml;r Fl&auml;chendiagramme",
        "en": "For Area Charts",
        "fr": "Diagramme de Zone",
        "it": "Per Grafici ad Area",
        "es": "Para diagramas de &aacute;reas",
    },

    "stacking_column": {
        "de": "F&uuml;r Säulendiagramme",
        "en": "For column charts",
        "fr": "Pour diagrammes à colonnes",
        "it": "Per i diagrammi a colonna",
        "es": "Para diagramas de columnas",
    },
    // CHARTS END

    // REMOD / TRANSF. Paths / SCENARIOS
    // ----- remod energies --------
    "primary_and_final_energies": {
        "de": "Primär- und Endenergie",
        "en": "Primary and final energies",
        "fr": "&eacute;nergie primaire et finale",
        "it": "Energia primaria e finale",
        "es": "Energ&iacute;a primaria y final",
    },
    "primary_energy": {
        "de": "Primärenergie",
        "en": "Primary energies",
        "fr": "&eacute;nergie primaire",
        "it": "Energia primaria",
        "es": "Energ&iacute;a primaria",
    },
    "final_energy": {
        "de": "Endenergie",
        "en": "Final energies",
        "fr": "&eacute;nergie finale",
        "it": "Energia finale",
        "es": "Energ&iacute;a final",
    },
    "supply": {
        "de": "Bereitstellung ",
        "en": "Supply",
        "fr": "Fourniture",
        "it": "Fornitura",
        "es": "Provisi&oacute;n",
    },
    "use": {
        "de": "Verwendung",
        "en": "Use",
        "fr": "Utilisation",
        "it": "Uso",
        "es": "Utilice",
    },
    "electricity_provision_and_use": {
        "de": "Strom: Bereitstellung und Nutzung",
        "en": "Electricity: provision and use",
        "fr": "&eacute;lectricit&eacute;: fourniture et utilisation",
        "it": "Elettricità: fornitura e uso",
        "es": "Electricidad: suministro y uso",
    },
    "h2_provision_and_use": {
        "de": "Wasserstoff: Bereitstellung und Nutzung",
        "en": "Hydrogen: provision and use",
        "fr": "Hydrog&egrave;ne: fourniture et utilisation",
        "it": "Idrogeno: fornitura e uso",
        "es": "Hidr&oacute;geno: suministro y uso",
    },
    "gas_provision_and_use": {
        "de": "Gase: Bereitstellung und Nutzung",
        "en": "Gases: provision and use",
        "fr": "Des gaz: fourniture et utilisation",
        "it": "Gas: fornitura e uso",
        "es": "Gases: Suministro y uso",
    },
    "liquid_fuel_provision_and_use": {
        "de": "Flüssigkraftstoffe: Bereitstellung und Nutzung",
        "en": "Liquid fuels: provision and use",
        "fr": "Combustibles liquides: fourniture et utilisation",
        "it": "Combustibili liquidi: fornitura e uso",
        "es": "Combustibles l&iacute;quidos: suministro y uso",
    },
    "fossil_and_imported_synthetic_fuels": {
        "de": "Fossile und importierte synthetische Energieträger",
        "en": "Fossil and imported synthetic fuels",
        "fr": "Combustibles fossiles et de synth&egrave;se import&eacute;s",
        "it": "Combustibili fossili e sintetici importati",
        "es": "Fuentes de energ&iacute;a f&oacute;siles y sint&eacute;ticas importadas",
    },
    // ----- remod flexibility --------
    "excess_electricity": {
        "de": "Stromüberschuss",
        "en": "Excess electricity",
        "fr": "Exc&egrave;s d'&eacute;lectricit&eacute;",
        "it": "Elettricità in eccesso",
        "es": "Excedente de electricidad",
    },
    "excess_electricity_monthly": {
        "de": "Stromüberschuss monatlich (Referenz-Szenario)",
        "en": "Monthly electricity surplus (reference scenario)",
        "fr": "Exc&eacute;dent mensuel d'&eacute;lectricit&eacute; (sc&eacute;nario de r&eacute;f&eacute;rence)",
        "it": "Eccedenza mensile di energia elettrica (scenario di riferimento)",
        "es": "Excedente mensual de electricidad (escenario de referencia)",
    },
    "lack_of_electricity": {
        "de": "Strommangel",
        "en": "Lack of electricity",
        "fr": "Manque d'&eacute;lectricit&eacute;",
        "it": "Mancanza di elettricità",
        "es": "Escasez de electricidad",
    },
    "lack_of_electricity_monthly": {
        "de": "Strommangel monatlich (Referenz-Szenario)",
        "en": "Monthly lack of electricity (reference scenario)",
        "fr": "Manque d'&eacute;lectricit&eacute; mensuel (sc&eacute;nario de r&eacute;f&eacute;rence)",
        "it": "Mancanza mensile di elettricità (scenario di riferimento)",
        "es": "Escasez mensual de electricidad (escenario de referencia)",
    },
    // --------- remod installed power --------
    "renewables_remod": {
        "de": "Erneuerbare (fEE)",
        "en": "Renewables (VRE)",
        "fr": "Renouvelables",
        "it": "Rinnovabili",
        "es": "Renovables (fEE)",
    },
    "conv_power_plants": {
        "de": "Konventionelle Kraftwerke",
        "en": "Conventional Power Plants",
        "fr": "Centrales &Eacute;lectriques Eonventionnelles",
        "it": "Centrali Elettriche Convenzionali",
        "es": "Centrales el&eacute;ctricas convencionales",
    },
    "storagecapacities": {
        "de": "Speicherkapazit&auml;ten",
        "en": "Storage Capacities",
        "fr": "Capacit&eacute;s de Stockage",
        "it": "Capacit&agrave; di Stoccaggio",
        "es": "Capacidad de almacenamiento",
    },
    "ptx": {
        "de": "Elektrolyseure",
        "en": "Electrolyzers",
        "fr": "Electrolyseurs",
        "it": "Elettrolizzatori",
        "es": "Electrolizadores",
    },
    "capacity": {
        "de": "Kapazit&auml;t",
        "en": "Capacity",
        "fr": "Capacit&eacute;",
        "it": "Capacit&agrave;",
        "es": "Capacidad",
    },
    "scenario": {
        "de": "Szenario",
        "en": "Scenario",
        "fr": "Sc&eacute;nario",
        "it": "Scenario",
        "es": "Escenario",
    },
    "scenarios": {
        "de": "Szenarien",
        "en": "Scenarios",
        "fr": "Sc&eacute;narios",
        "it": "Scenari",
        "es": "Escenarios",
    },
    "scenario_reference": {
        "de": "Szenario Referenz",
        "en": "Scenario Reference",
        "fr": "Sc&eacute;nario R&eacute;f&eacute;rence",
        "it": "Scenario Riferimento",
        "es": "Szenario Referenz",
    },
    "Referenz": {
        "de": "Referenz",
        "en": "Reference",
        "fr": "R&eacute;f&eacute;rence",
        "it": "Riferimento",
        "es": "Referencia",
    },
    "Beharrung": {
        "de": "Beharrung",
        "en": "Persistence",
        "fr": "Persistance",
        "it": "Persistenza",
        "es": "Insistencia",
    },
    "Inakzeptanz": {
        "de": "Inakzeptanz",
        "en": "Non-Acceptance",
        "fr": "Inacceptation",
        "it": "Mancanza di Accettazione",
        "es": "Inaceptaci&oacute;n",
    },
    "Suffizienz": {
        "de": "Suffizienz",
        "en": "Sufficiency",
        "fr": "Suffisance",
        "it": "Sufficienza",
        "es": "Suficiencia",
    },
    "Referenz100": {
        "de": "Referenz 100",
        "en": "Reference 100",
        "fr": "R&eacute;f&eacute;rence 100",
        "it": "Riferimento 100",
        "es": "Referencia 100",
    },
    "Suffizienz2035": {
        "de": "Suffizienz 2035",
        "en": "Sufficiency 2035",
        "fr": "Suffisance 2035",
        "it": "Sufficienza 2035",
        "es": "Suficiencia 2035",
    },

    "2045_15": {
        "de": "Woche 15, 2045",
        "en": "Week 15, 2045",
        "fr": "Semaine 15, 2045",
        "it": "Settimana 15, 2045",
        "es": "Woche 15, 2045",
    },
    "2045_42": {
        "de": "Woche 42, 2045",
        "en": "Week 42, 2045",
        "fr": "Semaine 42, 2045",
        "it": "Settimana 42, 2045",
        "es": "Semana 42, 2045",
    },
    "Strombereitstellung": {
        "de": "Bereitstellung Strom",
        "en": "Power Provisioning",
        "fr": "Alimentation en &Eacute;lectricit&eacute;",
        "it": "Fornitura di Elettricit&agrave;",
        "es": "Suministro de electricidad",
    },
    "Stromverwendung": {
        "de": "Verwendung Strom",
        "en": "Power Usage",
        "fr": "Utilisation de l'&Eacute;lectricit&eacute;",
        "it": "Usa l'elettricit&agrave;",
        "es": "Utilizar la corriente",
    },
    "sector": {
        "de": "Sektordaten",
        "en": "Sector Data",
        "fr": "Secteur",
        "it": "Dati di settore",
        "es": "Datos sectoriales",
    },
    "heating_tech": {
        "de": "Heizwärmetechnologien",
        "en": "Heating Technologies",
        "fr": "Technologies de Chauffage",
        "it": "Tecnologie di Riscaldamento",
        "es": "Tecnolog&iacute;as de calefacci&oacute;n",
    },
    "renovation": {
        "de": "Sanierung Gebäude",
        "en": "Building Renovation",
        "fr": "R&eacute;novation de Bâtiments",
        "it": "Ristrutturazione dell'Edificio",
        "es": "Edificio de rehabilitaci&oacute;n",
    },
    "process_heat": {
        "de": "Prozessw&auml;rmetechnologien",
        "en": "Process Heat Technologies",
        "fr": "Technologies de la Chaleur Industrielle",
        "it": "Tecnologie del Calore di Processo",
        "es": "Tecnolog&iacute;as de calor de proceso",
    },
    "car": {
        "de": "PKW-Verkehr",
        "en": "Car Traffic",
        "fr": "Voitures Particuli&egrave;res",
        "it": "Traffico Automobilistico",
        "es": "Tr&aacute;fico de veh&iacute;culos",
    },
    "truck": {
        "de": "LKW-Verkehr",
        "en": "Truck Traffic",
        "fr": "Trafic Routier",
        "it": "Traffico di Camion",
        "es": "Tr&aacute;fico de camiones",
    },
    "drive_tech": {
        "de": "Antriebstechnologien",
        "en": "Drive Technologies",
        "fr": "Technologies de Propulsion",
        "it": "Tecnologie di Azionamento",
        "es": "Tecnolog&iacute;as de accionamiento",
    },


    "scenario_1_name": {
        "de": "Referenz",
        "en": "Reference",
        "fr": "R&eacute;f&eacute;rence",
        "it": "Riferimento",
        "es": "Referencia",
    },
    "scenario_2_name": {
        "de": "Beharrung",
        "en": "Persistence",
        "fr": "Persistance",
        "it": "Persistenza",
        "es": "Insistencia",
    },
    "scenario_3_name": {
        "de": "Inakzeptanz",
        "en": "Non-Acceptance",
        "fr": "Inacceptation",
        "it": "Mancata di accettazione",
        "es": "Inaceptaci&oacute;n",
    },
    "scenario_4_name": {
        "de": "Suffizienz",
        "en": "Sufficiency",
        "fr": "Suffisance",
        "it": "Sufficienza",
        "es": "Suficiencia",
    },
    "scenario_1_description": {
        "de": "Das Szenario 'Referenz' dient als Grundlage für den Vergleich mit den weiteren Szenarien. Das Szenario stellt ein volloptimiertes Szenario mit Emissionsreduktion energiebedingter CO2-Emissionen von 65% in 2030, 88% in 2040 und 100% im Jahr 2045 dar.",
        "en": "The 'Reference' scenario serves as the basis for comparison with the other scenarios. The scenario represents a fully optimised scenario with reduction of energy-related CO2 emissions of 65% in 2030, 88% in 2040 and 100% in 2045.",
        "fr": "Le sc&eacute;nario ' R&eacute;f&eacute;rence' sert de base de comparaison avec les autres sc&eacute;narios. Il s’agit d’un sc&eacute;nario enti&egrave;rement optimis&eacute; avec des objectifs de r&eacute;duction des &eacute;missions de CO2 li&eacute;es à l'&eacute;nergie de 65% en 2030, de 88% en 2040, et de 100% en 2045.",
        "it": "Lo scenario 'Riferimento' serve come base per il confronto con gli altri scenari. Lo scenario rappresenta uno scenario completamente ottimizzato con obiettivi di riduzione delle emissioni di CO2 legate all'energia del 65% nel 2030, del 88% nel 2040 e del 100% nel 2045.",
        "es": "El escenario &quot;de referencia&quot; sirve de base para la comparaci&oacute;n con los dem&aacute;s escenarios. Representa un escenario totalmente optimizado con una reducci&oacute;n de las emisiones de CO2 relacionadas con la energ&iacute;a del 65% en 2030, del 88% en 2040 y del 100% en 2045.",
    },
    "scenario_2_description": {
        "de": "Das Szenario 'Beharrung' basiert auf dem Szenario 'Referenz'. Es wird jedoch von stärkeren Widerständen gegen den Einsatz neuer Techniken im Endanwenderbereich ausgegangen. Im Gebäudebereich wird deshalb von einer teilweisen Beharrung auf Gaskessel und einer geringeren Sanierungsrate und im motorisierten Individualverkehr von einem verbleibenden Anteil Verbrennungsantriebe ausgegangen.",
        "en": "The 'Persistence' scenario is based on the 'Reference' scenario. However, it is assumed that there will be greater resistance to the use of new technologies in the end-user sector. In the building sector, it is therefore assumed that there will be a partial insistence on gas boilers, a lower renovation rate, and a remaining proportion of combustion engines in individual motorized transport.",
        "fr": "Le sc&eacute;nario 'Persistance' est bas&eacute; sur le sc&eacute;nario 'R&eacute;f&eacute;rence'. Toutefois, on suppose qu'il y aura une plus grande r&eacute;sistance à l'utilisation des nouvelles technologies dans le secteur d’utilisation finale. Dans le secteur du bâtiment, on suppose donc qu'il y aura une persistance partielle des chaudi&egrave;res à gaz et un taux de r&eacute;novation plus faible, et dans le transport motoris&eacute; priv&eacute; qu'il subsistera une proportion de moteurs à combustion.",
        "it": "Lo scenario 'Persistenza' &egrave; basato sullo scenario 'Riferimento'. Tuttavia, si presume che ci sarà una forte resistenza alla diffusione delle nuove tecnologie nel settore degli utenti finali. Nel settore dell'edilizia, si presume quindi una persistenza parziale con le caldaie a gas e un tasso di risanamento inferiore. Nel trasporto privato a motore si presume una proporzione rimanente di motori a combustione.",
        "es": "El escenario &quot;Persistencia&quot; se basa en el escenario &quot;Referencia&quot;. Sin embargo, se supone una mayor resistencia al uso de nuevas tecnolog&iacute;as en el sector de los usuarios finales. As&iacute;, en el sector de la construcci&oacute;n se supone una insistencia parcial en las calderas de gas y un menor &iacute;ndice de renovaci&oacute;n, y en el transporte privado motorizado se mantiene la proporci&oacute;n de motores de combusti&oacute;n.",
    },
    "scenario_3_description": {
        "de": "Das Szenario 'Inakzeptanz' basiert auf dem Szenario 'Referenz'. Es geht aber von einer geringeren Akzeptanz von großen Infrastrukturmaßnahmen aus, wie den Ausbau von Windenergieanlagen, Übertragungsnetzen und Autobahn-Oberleitungen für Lastkraftwagen.",
        "en": "The 'Non-Acceptance' scenario is based on the 'Reference' scenario. However, it assumes a lower acceptance of large infrastructure measures, such as the expansion of wind energy plants, transmission grids and motorway overhead lines for heavy goods vehicles.",
        "fr": "Le sc&eacute;nario 'Non-Acceptation' est bas&eacute; sur le sc&eacute;nario 'R&eacute;f&eacute;rence'. Il suppose toutefois une acceptation plus faible envers de grands travaux d'infrastructure, telles que l'expansion des &eacute;oliennes, des r&eacute;seaux de transmission et des lignes a&eacute;riennes d'autoroute pour les camions. ",
        "it": "Lo scenario 'Mancata di Accettazione' &egrave; basato sullo scenario 'Riferimento'. Tuttavia, presuppone una minore accettazione di grandi misure infrastrutturali, come l'espansione delle turbine eoliche, le reti di trasmissione e le linee aeree autostradali per i camion.",
        "es": "El escenario &quot;Inaceptaci&oacute;n&quot; se basa en el escenario &quot;Referencia&quot;. Sin embargo, supone una menor aceptaci&oacute;n de las grandes medidas de infraestructura, como la expansi&oacute;n de las turbinas e&oacute;licas, las redes de transmisi&oacute;n y las l&iacute;neas a&eacute;reas de autopistas para camiones.",
    },
    "scenario_4_description": {
        "de": "Das Szenario 'Suffizienz' basiert auf dem Szenario 'Referenz'. Es nimmt aber einen gesellschaftlichen Wandel hin zu einem suffizienteren Verhalten und eine Erhöhung der Effizienz in relevanter Breite an, die zu einer deutlichen Reduzierung des Energieverbrauchs führen.",
        "en": "The 'Sufficiency' scenario is based on the 'Reference' scenario. However, it assumes a societal shift towards more sufficient behaviour and an increase in efficiency in a relevant range, which lead to a significant reduction in energy consumption.",
        "fr": "Le sc&eacute;nario 'Suffisance' est bas&eacute; sur le sc&eacute;nario 'R&eacute;f&eacute;rence'. Toutefois, il suppose un virage soci&eacute;tal vers des comportements ax&eacute;s sur la suffisance et une augmentation significative de l’efficacit&eacute;, entraînant une r&eacute;duction significative de la consommation d'&eacute;nergie. ",
        "it": "Lo scenario 'Sufficienza' &egrave; basato sullo scenario 'Riferimento'. Tuttavia, presuppone un cambiamento sociale verso un comportamento più sufficiente e un aumento dell'efficienza in una gamma rilevante. Risulta una riduzione significativa del consumo di energia.",
        "es": "El escenario &quot;Suficiencia&quot; se basa en el escenario &quot;Referencia&quot;. Sin embargo, supone un cambio social hacia un comportamiento m&aacute;s orientado a la suficiencia y un aumento de la eficiencia a una escala relevante, lo que conduce a una reducci&oacute;n significativa del consumo de energ&iacute;a.",
    },
    "explain_scenarios": {
        "de": "Erkl&auml;rungen zu den Szenarien",
        "en": "Explanations on the Scenarios",
        "fr": "Explications des Sc&eacute;narios",
        "it": "Spiegazioni degli Scenari",
        "es": "Explicaciones de los escenarios",
    },
    "link_to_study_desc": {
        "de": "Fraunhofer ISE Studie 2021: Wege zu einem klimaneutralen Energiesystem",
        "en": "Fraunhofer ISE Study 2021: Towards a climate-neutral energy system",
        "fr": "Enqu&ecirc;te Fraunhofer ISE 2021: Voies vers un syst&egrave;me &eacute;nerg&eacute;tique climatiquement neutre",
        "it": "Studio Fraunhofer ISE 2021: Percorsi verso un sistema energetico climaticamente neutro",
        "es": "Estudio Fraunhofer ISE 2021: V&iacute;as hacia un sistema energ&eacute;tico neutro desde el punto de vista clim&aacute;tico",
    },

    //REMOD 2024
    
    //new 2024 sources
    "gases": {
        "de": "Gase",
        "en": "Gases",
        "fr": "",
        "it": "",
        "es": "",
    },
    "liquids": {
        "de": "Flüssigkeiten",
        "en": "Liquids",
        "fr": "",
        "it": "",
        "es": "",
    },
    "heat": {
        "de": "Wärme",
        "en": "Heat",
        "fr": "",
        "it": "",
        "es": "",
    },
    "space_water_heating": {
        "de": "Raumheizung und Warmwasserbereitung",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },
    "solar_thermal": {
        "de": "Solarthermie",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },
    "process_heat": {
        "de": "Industrielle Prozesswärme",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },    
    "process_heat_lt": {
        "de": "Industrielle Niedertemperatur-Prozesswärme",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },
    "process_heat_ht": {
        "de": "Industrielle Hochtemperatur-Prozesswärme",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },    
    "NET": {
        "de": "Negativemissionstechnologien",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },                  


    "scenario_2024_1_name": {
        "de": "Technologieoffenheit",
        "en": "Technology openness",
        "fr": "Technologie ouverte",
        "it": "Tecnologia aperta",
        "es": "Tecnología abierta",
    },
    "scenario_2024_2_name": {
        "de": "Effizienz",
        "en": "Efficiency",
        "fr": "Efficacité ",
        "it": "Efficienza",
        "es": "Eficiencia",
    },
    "scenario_2024_3_name": {
        "de": "Beharrung",
        "en": "Persistence",
        "fr": "Persistance",
        "it": "Persistenza",
        "es": "Insistencia",
    },
    "scenario_2024_4_name": {
        "de": "Robustheit",
        "en": "Robust",
        "fr": "Robustesse",
        "it": "Robustezza",
        "es": "Robustez",
    },
    "scenario_2024_1_description": {
        "de": "TODO!!",
        /*Das Szenario 'Referenz' dient als Grundlage für den Vergleich mit den weiteren Szenarien. Das Szenario stellt ein volloptimiertes Szenario mit Emissionsreduktion energiebedingter CO2-Emissionen von 65% in 2030, 88% in 2040 und 100% im Jahr 2045 dar.",
        "en": "The 'Reference' scenario serves as the basis for comparison with the other scenarios. The scenario represents a fully optimised scenario with reduction of energy-related CO2 emissions of 65% in 2030, 88% in 2040 and 100% in 2045.",
        "fr": "Le sc&eacute;nario ' R&eacute;f&eacute;rence' sert de base de comparaison avec les autres sc&eacute;narios. Il s’agit d’un sc&eacute;nario enti&egrave;rement optimis&eacute; avec des objectifs de r&eacute;duction des &eacute;missions de CO2 li&eacute;es à l'&eacute;nergie de 65% en 2030, de 88% en 2040, et de 100% en 2045.",
        "it": "Lo scenario 'Riferimento' serve come base per il confronto con gli altri scenari. Lo scenario rappresenta uno scenario completamente ottimizzato con obiettivi di riduzione delle emissioni di CO2 legate all'energia del 65% nel 2030, del 88% nel 2040 e del 100% nel 2045.",
        "es": "El escenario &quot;de referencia&quot; sirve de base para la comparaci&oacute;n con los dem&aacute;s escenarios. Representa un escenario totalmente optimizado con una reducci&oacute;n de las emisiones de CO2 relacionadas con la energ&iacute;a del 65% en 2030, del 88% en 2040 y del 100% en 2045.",
        */
    },
    "scenario_2024_2_description": {
        "de": "TODO!!",
        /*Das Szenario 'Beharrung' basiert auf dem Szenario 'Referenz'. Es wird jedoch von stärkeren Widerständen gegen den Einsatz neuer Techniken im Endanwenderbereich ausgegangen. Im Gebäudebereich wird deshalb von einer teilweisen Beharrung auf Gaskessel und einer geringeren Sanierungsrate und im motorisierten Individualverkehr von einem verbleibenden Anteil Verbrennungsantriebe ausgegangen.",
        "en": "The 'Persistence' scenario is based on the 'Reference' scenario. However, it is assumed that there will be greater resistance to the use of new technologies in the end-user sector. In the building sector, it is therefore assumed that there will be a partial insistence on gas boilers, a lower renovation rate, and a remaining proportion of combustion engines in individual motorized transport.",
        "fr": "Le sc&eacute;nario 'Persistance' est bas&eacute; sur le sc&eacute;nario 'R&eacute;f&eacute;rence'. Toutefois, on suppose qu'il y aura une plus grande r&eacute;sistance à l'utilisation des nouvelles technologies dans le secteur d’utilisation finale. Dans le secteur du bâtiment, on suppose donc qu'il y aura une persistance partielle des chaudi&egrave;res à gaz et un taux de r&eacute;novation plus faible, et dans le transport motoris&eacute; priv&eacute; qu'il subsistera une proportion de moteurs à combustion.",
        "it": "Lo scenario 'Persistenza' &egrave; basato sullo scenario 'Riferimento'. Tuttavia, si presume che ci sarà una forte resistenza alla diffusione delle nuove tecnologie nel settore degli utenti finali. Nel settore dell'edilizia, si presume quindi una persistenza parziale con le caldaie a gas e un tasso di risanamento inferiore. Nel trasporto privato a motore si presume una proporzione rimanente di motori a combustione.",
        "es": "El escenario &quot;Persistencia&quot; se basa en el escenario &quot;Referencia&quot;. Sin embargo, se supone una mayor resistencia al uso de nuevas tecnolog&iacute;as en el sector de los usuarios finales. As&iacute;, en el sector de la construcci&oacute;n se supone una insistencia parcial en las calderas de gas y un menor &iacute;ndice de renovaci&oacute;n, y en el transporte privado motorizado se mantiene la proporci&oacute;n de motores de combusti&oacute;n.",
        */
    },
    "scenario_2024_3_description": {
        "de": "TODO!!",
        /*Das Szenario 'Inakzeptanz' basiert auf dem Szenario 'Referenz'. Es geht aber von einer geringeren Akzeptanz von großen Infrastrukturmaßnahmen aus, wie den Ausbau von Windenergieanlagen, Übertragungsnetzen und Autobahn-Oberleitungen für Lastkraftwagen.",
        "en": "The 'Non-Acceptance' scenario is based on the 'Reference' scenario. However, it assumes a lower acceptance of large infrastructure measures, such as the expansion of wind energy plants, transmission grids and motorway overhead lines for heavy goods vehicles.",
        "fr": "Le sc&eacute;nario 'Non-Acceptation' est bas&eacute; sur le sc&eacute;nario 'R&eacute;f&eacute;rence'. Il suppose toutefois une acceptation plus faible envers de grands travaux d'infrastructure, telles que l'expansion des &eacute;oliennes, des r&eacute;seaux de transmission et des lignes a&eacute;riennes d'autoroute pour les camions. ",
        "it": "Lo scenario 'Mancata di Accettazione' &egrave; basato sullo scenario 'Riferimento'. Tuttavia, presuppone una minore accettazione di grandi misure infrastrutturali, come l'espansione delle turbine eoliche, le reti di trasmissione e le linee aeree autostradali per i camion.",
        "es": "El escenario &quot;Inaceptaci&oacute;n&quot; se basa en el escenario &quot;Referencia&quot;. Sin embargo, supone una menor aceptaci&oacute;n de las grandes medidas de infraestructura, como la expansi&oacute;n de las turbinas e&oacute;licas, las redes de transmisi&oacute;n y las l&iacute;neas a&eacute;reas de autopistas para camiones.",
        */
    },
    "scenario_2024_4_description": {
        "de": "TODO!!",
        /*Das Szenario 'Suffizienz' basiert auf dem Szenario 'Referenz'. Es nimmt aber einen gesellschaftlichen Wandel hin zu einem suffizienteren Verhalten und eine Erhöhung der Effizienz in relevanter Breite an, die zu einer deutlichen Reduzierung des Energieverbrauchs führen.",
        "en": "The 'Sufficiency' scenario is based on the 'Reference' scenario. However, it assumes a societal shift towards more sufficient behaviour and an increase in efficiency in a relevant range, which lead to a significant reduction in energy consumption.",
        "fr": "Le sc&eacute;nario 'Suffisance' est bas&eacute; sur le sc&eacute;nario 'R&eacute;f&eacute;rence'. Toutefois, il suppose un virage soci&eacute;tal vers des comportements ax&eacute;s sur la suffisance et une augmentation significative de l’efficacit&eacute;, entraînant une r&eacute;duction significative de la consommation d'&eacute;nergie. ",
        "it": "Lo scenario 'Sufficienza' &egrave; basato sullo scenario 'Riferimento'. Tuttavia, presuppone un cambiamento sociale verso un comportamento più sufficiente e un aumento dell'efficienza in una gamma rilevante. Risulta una riduzione significativa del consumo di energia.",
        "es": "El escenario &quot;Suficiencia&quot; se basa en el escenario &quot;Referencia&quot;. Sin embargo, supone un cambio social hacia un comportamiento m&aacute;s orientado a la suficiencia y un aumento de la eficiencia a una escala relevante, lo que conduce a una reducci&oacute;n significativa del consumo de energ&iacute;a.",
        */
    },    
    "link_to_study_desc_2024": {
        "de": "Fraunhofer ISE Studie 2024: Wege zu einem klimaneutralen Energiesystem",
        "en": "Fraunhofer ISE Study 2024: Towards a climate-neutral energy system",
        "fr": "Enqu&ecirc;te Fraunhofer ISE 2024: Voies vers un syst&egrave;me &eacute;nerg&eacute;tique climatiquement neutre",
        "it": "Studio Fraunhofer ISE 2024: Percorsi verso un sistema energetico climaticamente neutro",
        "es": "Estudio Fraunhofer ISE 2024: V&iacute;as hacia un sistema energ&eacute;tico neutro desde el punto de vista clim&aacute;tico",
    },
    // REMOD / TRANSF. Paths / SCENARIOS END

    "export": {
        "de": "Export",
        "en": "Export",
        "fr": "Exportation",
        "it": "Esportazione",
        "es": "Exportar",
    },

    "export_generating": {
        "de": "Export wird generiert...",
        "en": "Export is being rendered...",
        "fr": "Exportation est g&eacute;n&eacute;r&eacute;e",
        "it": "L'esportazione viene generata ...",
        "es": "La exportaci&oacute;n se genera...",
    },

    "charttype": {
        "de": "Diagrammtyp",
        "en": "Chart Type",
        "fr": "Type de Diagramme",
        "it": "Tipo di Grafico",
        "es": "Tipo de diagrama",
    },

    // Translations for the countries
    "de": {
        "de": "Deutschland",
        "en": "Germany",
        "fr": "Allemagne",
        "it": "Germania",
        "es": "Alemania",
    },
    "ch": {
        "de": "Schweiz",
        "en": "Switzerland",
        "fr": "Suisse",
        "it": "Svizzera",
        "es": "Suiza",
    },
    "eu": {
        "de": "Europ\u00e4ische Union",
        "en": "European Union",
        "fr": "Union europ\u00e9enne",
        "it": "Unione Europea",
        "es": "Uni&oacute;n Europea",
    },
    "al": {
        "de": "Albanien",
        "en": "Albania",
        "fr": "Albanie",
        "it": "Albania",
        "es": "Albanien",
    },
    "am": {
        "de": "Armenien",
        "en": "Armenia",
        "fr": "Arm&eacute;nie",
        "it": "Armenia",
        "es": "Armenia",
    },
    "at": {
        "de": "\u00d6sterreich",
        "en": "Austria",
        "fr": "L'Autriche",
        "it": "Austria",
        "es": "\Austria",
    },
    "az": {
        "de": "Aserbaidschan",
        "en": "Azerbaijan",
        "fr": "Azerbaïdjan",
        "it": "Azerbaijan",
        "es": "Azerbaiy&aacute;n",
    },
    "ba": {
        "de": "Bosnien-Herzegowina",
        "en": "Bosnia-Herzegovina",
        "fr": "Bosnie-Herz&eacute;govine",
        "it": "Bosnia ed Erzegovina",
        "es": "Bosnia-Herzegovina",
    },
    "be": {
        "de": "Belgien",
        "en": "Belgium",
        "fr": "Belgique",
        "it": "Belgio",
        "es": "B&eacute;lgica",
    },
    "bg": {
        "de": "Bulgarien",
        "en": "Bulgaria",
        "fr": "Bulgarie",
        "it": "Bulgaria",
        "es": "B&uacute;lgaro",
    },
    "by": {
        "de": "Belarus",
        "en": "Belarus",
        "fr": "Bi&eacute;lorussie",
        "it": "Belarus",
        "es": "Bielorrusia",
    },
    "cy": {
        "de": "Zypern",
        "en": "Cyprus",
        "fr": "Chypre",
        "it": "Cipro",
        "es": "Chipre",
    },
    "cz": {
        "de": "Tschechien",
        "en": "Czech Republic",
        "fr": "R&eacute;publique tch&egrave;que",
        "it": "Repubblica Ceca",
        "es": "Rep&uacute;blica Checa",
    },
    "dk": {
        "de": "Dänemark",
        "en": "Denmark",
        "fr": "Danemark",
        "it": "Danimarca",
        "es": "Dinamarca",
    },
    "ee": {
        "de": "Estland",
        "en": "Estonia",
        "fr": "Estonie",
        "it": "Estonia",
        "es": "Estonia",
    },
    "es": {
        "de": "Spanien",
        "en": "Spain",
        "fr": "Espagne",
        "it": "Spagna",
        "es": "Espa&ntilde;a",
    },
    "fi": {
        "de": "Finnland",
        "en": "Finland",
        "fr": "Finlande",
        "it": "Finlandia",
        "es": "Finlandia",
    },
    "fr": {
        "de": "Frankreich",
        "en": "France",
        "fr": "France",
        "it": "Francia",
        "es": "Francia",
    },
    "gb": {
        "de": "Großbritannien",
        "en": "Great Britain",
        "fr": "Grande-Bretagne",
        "it": "Regno Unito",
        "es": "Gran Breta&ntilde;a",
    },
    "ge": {
        "de": "Georgien",
        "en": "Georgia",
        "fr": "G&eacute;orgie",
        "it": "Georgia",
        "es": "Georgia",
    },
    "gr": {
        "de": "Griechenland",
        "en": "Greece",
        "fr": "Gr&egrave;ce",
        "it": "Grecia",
        "es": "Grecia",
    },
    "hr": {
        "de": "Kroatien",
        "en": "Croatia",
        "fr": "Croatie",
        "it": "Croazia",
        "es": "Croacia",
    },
    "hu": {
        "de": "Ungarn",
        "en": "Hungary",
        "fr": "Hongrie",
        "it": "Ungheria",
        "es": "Hungr&iacute;a",
    },
    "ie": {
        "de": "Irland",
        "en": "Ireland",
        "fr": "Irlande",
        "it": "Irlanda",
        "es": "Irlanda",
    },
    "it": {
        "de": "Italien",
        "en": "Italy",
        "fr": "Italie",
        "it": "Italia",
        "es": "Italiano",
    },
    "lt": {
        "de": "Litauen",
        "en": "Lithuania",
        "fr": "Lituanie",
        "it": "Lituania",
        "es": "Lituania",
    },
    "lu": {
        "de": "Luxemburg",
        "en": "Luxembourg",
        "fr": "Luxembourg",
        "it": "Lussemburgo",
        "es": "Luxemburgo",
    },
    "lv": {
        "de": "Lettland",
        "en": "Latvia",
        "fr": "Lettonie",
        "it": "Lettonia",
        "es": "Letonia",
    },
    "md": {
        "de": "Moldau",
        "en": "Moldova",
        "fr": "Moldavie",
        "it": "Moldavia",
        "es": "Moldavia",
    },
    "me": {
        "de": "Montenegro",
        "en": "Montenegro",
        "fr": "Mont&eacute;n&eacute;gro",
        "it": "Montenegro",
        "es": "Montenegro",
    },
    "mk": {
        "de": "Nordmazedonien",
        "en": "North Macedonia",
        "fr": "Mac&eacute;doine du Nord",
        "it": "Macedonia del Nord",
        "es": "Macedonia del Norte",
    },
    "mt": {
        "de": "Malta",
        "en": "Malta",
        "fr": "Malte",
        "it": "Malta",
        "es": "Malta",
    },
    "nie": {
        "de": "Nord Irland",
        "en": "Northern Ireland",
        "fr": "Irlande du Nord",
        "it": "Irlanda del Nord",
        "es": "Irlanda del Norte",
    },
    "nl": {
        "de": "Niederlande",
        "en": "Netherlands",
        "fr": "Pays-Bas",
        "it": "Paesi Bassi",
        "es": "Niederlande",
    },
    "no": {
        "de": "Norwegen",
        "en": "Norway",
        "fr": "Norv&egrave;ge",
        "it": "Norvegia",
        "es": "Noruega",
    },
    "pl": {
        "de": "Polen",
        "en": "Poland",
        "fr": "Pologne",
        "it": "Polonia",
        "es": "Polonia",
    },
    "pt": {
        "de": "Portugal",
        "en": "Portugal",
        "fr": "Portugal",
        "it": "Portogallo",
        "es": "Portugal",
    },
    "ro": {
        "de": "Rumänien",
        "en": "Romania",
        "fr": "Roumanie",
        "it": "Romania",
        "es": "Ruman&iacute;a",
    },
    "rs": {
        "de": "Serbien",
        "en": "Serbia",
        "fr": "Serbie",
        "it": "Serbia",
        "es": "Serbia",
    },
    "ru": {
        "de": "Russland",
        "en": "Russia",
        "fr": "Russie",
        "it": "Russia",
        "es": "Rusia",
    },
    "se": {
        "de": "Schweden",
        "en": "Sweden",
        "fr": "Su&egrave;de",
        "it": "Svezia",
        "es": "Suecia",
    },
    "si": {
        "de": "Slowenien",
        "en": "Slovenia",
        "fr": "Slov&eacute;nie",
        "it": "Slovenia",
        "es": "Eslovenia",
    },
    "sk": {
        "de": "Slowakei",
        "en": "Slovak Republic",
        "fr": "Slovaquie",
        "it": "Slovacchia",
        "es": "Eslovaquia",
    },
    "tr": {
        "de": "Türkei",
        "en": "Turkey",
        "fr": "Turquie",
        "it": "Turchia",
        "es": "Turqu&iacute;a",
    },
    "ua": {
        "de": "Ukraine",
        "en": "Ukraine",
        "fr": "Ukraine",
        "it": "Ucraina",
        "es": "Ucrania",
    },
    "uk": {
        "de": "Vereinigtes Königreich",
        "en": "United Kingdom",
        "fr": "Royaume-Uni",
        "it": "Regno Unito",
        "es": "Reino Unido",
    },
    "xk": {
        "de": "Kosovo",
        "en": "Kosovo",
        "fr": "Kosovo",
        "it": "Kosovo",
        "es": "Kosovo",
    },
    "ad": {
        "de": "Andorra",
        "en": "Andorra",
        "fr": "Andorre",
        "it": "Andorra",
        "es": "Andorra",
    },
    "va": {
        "de": "Vatikan",
        "en": "Vatican",
        "fr": "Vatican",
        "it": "Vaticano",
        "es": "Vaticano",
    },
    "sm": {
        "de": "San Marino",
        "en": "San Marino",
        "fr": "Saint-Marin",
        "it": "San Marino",
        "es": "San Marino",
    },
    "mc": {
        "de": "Monaco",
        "en": "Monaco",
        "fr": "Monaco",
        "it": "Monaco",
        "es": "Monaco",
    },
    "li": {
        "de": "Liechtenstein",
        "en": "Liechtenstein",
        "fr": "Liechtenstein",
        "it": "Liechtenstein",
        "es": "Liechtenstein",
    },

    "download": {
        "de": "Download",
        "en": "Download",
        "fr": "Download",
        "it": "Download",
        "es": "Download",
    },

    "daily_values_in": {
        "de": "Tageswerte in",
        "en": "Daily Values in",
        "fr": "Valeurs Quotidiennes en",
        "it": "Valori Giornalieri in",
        "es": "Valores diarios en",
    },

    "daily_values": {
        "de": "Tageswerte",
        "en": "Daily Values",
        "fr": "Valeurs Quotidiennes",
        "it": "Valori Giornalieri",
        "es": "Valores diarios",
    },

    "chart": {
        "de": "Diagramm",
        "en": "Chart",
        "fr": "Diagramme",
        "it": "Diagramma",
        "es": "Diagrama",
    },

    "sparkline": {
        "de": "Einzeln (Flächen & Linien)",
        "en": "Single (Areas & Lines)",
        "fr": "Unique (Zones & Lignes)",
        "it": "Singolo (Aree & linee )",
        "es": "Individual (&aacute;reas y l&iacute;neas)",
    },
    "average": {
        "de": "Durchschnitt",
        "en": "Average",
        "fr": "Moyenne",
        "it": "Media",
        "es": "Media",
    },

    // days of the week
    "monday": {
        "de": "Montag",
        "en": "Monday",
        "fr": "Lundi",
        "it": "Luned&igrave;",
        "es": "Montag",
    },
    "tuesday": {
        "de": "Dienstag",
        "en": "Tuesday",
        "fr": "Mardi",
        "it": "Marted&igrave;",
        "es": "Martes",
    },
    "wednesday": {
        "de": "Mittwoch",
        "en": "Wednesday",
        "fr": "Mercredi",
        "it": "Mercoled&igrave;",
        "es": "Mi&eacute;rcoles",
    },
    "thursday": {
        "de": "Donnerstag",
        "en": "Thursday",
        "fr": "Jeudi",
        "it": "Gioved&igrave;",
        "es": "Jueves",
    },
    "friday": {
        "de": "Freitag",
        "en": "Friday",
        "fr": "Vendredi",
        "it": "Venerd&igrave;",
        "es": "Viernes",
    },
    "saturday": {
        "de": "Samstag",
        "en": "Saturday",
        "fr": "Samedi",
        "it": "Sabato",
        "es": "S&aacute;bado",
    },
    "sunday": {
        "de": "Sonntag",
        "en": "Sunday",
        "fr": "Dimanche",
        "it": "Domenica",
        "es": "Domingo",
    },


    // Mainmenu - Country Toggle

    "fr_Ctoggle": {
        "de": "FR - Frankreich",
        "en": "FR - France",
        "fr": "FR - France",
        "it": "FR - Francia",
        "es": "FR - Francia",
    },
    // Mainmenu - LANG/Country Toggle END

    //contact page
    "projectmanager": {
        "de": "Projektverantwortlicher",
        "en": "Project manager",
        "fr": "Responsable du projet",
        "it": "Responsabile del progetto",
        "es": "Jefe de proyecto",
    },
    "editor-m": {
        "de": "Redakteur",
        "en": "Editor",
        "fr": "&Eacute;diteur",
        "it": "Editor",
        "es": "Editor",
    },
    "editor-f": {
        "de": "Redakteurin",
        "en": "Editor",
        "fr": "R&eacute;dactrice",
        "it": "Editor",
        "es": "Editor",
    },
    "phone": {
        "de": "Tel.",
        "en": "Phone",
        "fr": "T&eacute;l.",
        "it": "Tel.",
        "es": "Tel.",
    },

    // ==============================================
    // The following translations are converted by a python script from the json files in jsx/translations
    // ==============================================
    "ISEUrl": {
        "de": "https://www.ise.fraunhofer.de",
        "en": "https://www.ise.fraunhofer.de/en/",
        "fr": "https://www.ise.fraunhofer.de/en/",
        "it": "https://www.ise.fraunhofer.de/en/",
        "es": "https://www.ise.fraunhofer.de/en/",
    },
    "blog-posts": {
        "de": "Blogeinträge",
        "en": "Blog Posts",
        "fr": "Articles",
        "it": "Messaggi del Blog",
        "es": "Entradas de blog",
    },
    "recent-news": {
        "de": "Newsblog & Soziale Medien",
        "en": "Newsblog & Social Media",
        "fr": "Newsblog & Médias Sociaux",
        "it": "Newsblog & Social Media",
        "es": "Newsblog & Redes Sociales",
    },    
    "more-news": {
        "de": "Weiteres",
        "en": "More",
        "fr": "Plus",
        "it": "Altro",
        "es": "Más",
    },
    "visit-blog": {
        "de": "Energy-Charts Newsblog",
        "en": "Energy-Charts Newsblog",
        "fr": "Energy-Charts Newsblog",
        "it": "Energy-Charts Newsblog",
        "es": "Energy-Charts Newsblog",
    },
    "visit-tutorial": {
        "de": "Energy-Charts Guide",
        "en": "Energy-Charts Guide",
        "fr": "Energy-Charts Guide",
        "it": "Energy-Charts Guida",
        "es": "Energy-Charts Guía",
    },    
    "visit-app-downloads": {
        "de": "App & Downloads",
        "en": "App & Downloads",
        "fr": "App & Downloads",
        "it": "App & Download",
        "es": "App y descargas",
    }, 
    "ecTalks-text_1": {
        "de": "Die nächsten Energy-Charts Talks Termine",
        "en": "Upcoming Energy-Charts Talks",
        "fr": "Les prochains Energy-Charts Talks",
        "it": "Prossimi colloqui Energy-Charts",
        "es": "Próximas charlas sobre Energy-Charts",
    },
    "ecTalks-text_2": {
        "de": "Live um 17Uhr (MEZ), über Teams",
        "en": "Live at 5pm (CET), via Teams",
        "fr": "En direct à 17h (CET), via Teams",
        "it": "In diretta alle 17:00 (CET), via Teams",
        "es": "En directo a las 17.00 horas (CET), vía Teams",
    },    
    "ecTalks-records": {
        "de": "Aufzeichnungen",
        "en": "Records",
        "fr": "Dossiers",
        "it": "Registri",
        "es": "Registros",
    },       
    "your-guide": {
        "de": "Ihr Fremdenf&uuml;hrer auf dem Weg in eine erneuerbare Zukunft",
        "en": "Your guide on the journey to a renewable future",
        "fr": "Votre guide de voyage vers un avenir renouvelable",
        "it": "La tua guida per un futuro rinnovabile",
        "es": "Su gu&iacute;a hacia un futuro renovable",
    },
    "follow-us": {
        "de": "Folgen Sie uns",
        "en": "Follow us",
        "fr": "Suivez-nous",
        "it": "Seguiteci",
        "es": "S&iacute;guenos",
    },   
    "linkedIn-follow": {
        "de": "Folgen Sie uns auf LinkedIn:",
        "en": "Follow us on LinkedIn:",
        "fr": "Suivez-nous sur LinkedIn:",
        "it": "Seguiteci su LinkedIn:",
        "es": "S&iacute;ganos en LinkedIn:",
    },    
    "mastodon-follow": {
        "de": "Folgen Sie uns auf Mastodon:",
        "en": "Follow us on Mastodon:",
        "fr": "Suivez-nous sur Mastodon:",
        "it": "Seguiteci su Mastodon:",
        "es": "S&iacute;ganos en Mastodon:",
    },
    "mastodon-load-toots": {
        "de": "Toots laden",
        "en": "Load Toots",
        "fr": "Afficher les Toots",
        "it": "Toots Carico",
        "es": "Cargando Toots",
    },
    "mastodon-policy-1": {
        "de": "Durch anklicken dieses Feldes wird das Mastodon Plugin aktiviert und Daten an https://wisskomm.social gesendet.",
        "en": "By clicking on this field the Mastodon plugin will be activated and data sent to https://wisskomm.social.",
        "fr": "En cliquant sur cette case, le plugin Mastodon sera activ&eacute; et les donn&eacute;es seront envoy&eacute;es &agrave; https://wisskomm.social.",
        "it": "Cliccando su questo campo, il plugin di Mastodon viene attivato e i dati vengono inviati a https://wisskomm.social.",
        "es": "Al hacer clic en este campo se activa el plugin de Mastodon y se env&iacute;an datos a https://wisskomm.social",
    },
    "mastodon-policy-2": {
        "de": "Sie erkl&auml;ren sich mit den Datenschutzbestimmungen der Mastodon Instanz https://wisskomm.social einverstanden.",
        "en": "Do you agree to the terms and conditions of Mastodon instance https://wisskomm.social?",
        "fr": "Vous acceptez la politique de confidentialit&eacute; de instance Mastodon https://wisskomm.social",
        "it": "Accetti l'informativa sulla privacy di istanza Mastodon https://wisskomm.social",
        "es": "Usted acepta la pol&iacute;tica de privacidad de instancia Mastodon https://wisskomm.social",
    },
    "mastodon-terms": {
        "de": "Datenschutzbestimmungen von wisskomm.social",
        "en": "Terms and conditions of wisskomm.social",
        "fr": "Politique de confidentialit&eacute; de wisskomm.social",
        "it": "Informativa sulla privacy di wisskomm.social",
        "es": "Pol&iacute;tica de privacidad de wisskomm.social",
    },
    "mastodon-toots": {
        "de": "Toots ",
        "en": "Toots ",
        "fr": "Toots ",
        "it": "Toots ",
        "es": "Toots ",
    },    
    "mastodon-by": {
        "de": "von ",
        "en": "by ",
        "fr": "de ",
        "it": "da ",
        "es": "de ",
    },        
    "mastodon-collapse-toots": {
        "de": "Toots reduzieren",
        "en": "Collapse Toots",
        "fr": "Minimiser les Toots",
        "it": "Riduci i Toots",
        "es": "Reducir Toots",
    },    
    "twitter-follow": {
        "de": "Folgen Sie uns auf Twitter:",
        "en": "Follow us on Twitter:",
        "fr": "Suivez-nous sur Twitter:",
        "it": "Seguiteci su Twitter:",
        "es": "S&iacute;ganos en Twitter:",
    },
    "load-tweets": {
        "de": "Tweets laden",
        "en": "Load Tweets",
        "fr": "Afficher les Tweets",
        "it": "Tweets Carico",
        "es": "Cargando tweets",
    },
    "twitter-policy-1": {
        "de": "Durch anklicken dieses Feldes wird das Twitter-Plugin aktiviert und Daten an twitter.com gesendet.",
        "en": "By clicking on this field the twitter plugin will be activated and data sent to twitter.com.",
        "fr": "En cliquant sur cette case, le plugin Twitter sera activ&eacute; et les donn&eacute;es seront envoy&eacute;es &agrave; Twitter.com.",
        "it": "Cliccando su questo campo, il plugin di Twitter viene attivato e i dati vengono inviati a twitter.com.",
        "es": "Al hacer clic en este campo se activa el plugin de Twitter y se env&iacute;an datos a twitter.com.",
    },
    "twitter-policy-2": {
        "de": "Sie erkl&auml;ren sich mit den Datenschutzbestimmungen von Twitter Inc. einverstanden.",
        "en": "Do you agree to the terms and conditions of Twitter Inc.?",
        "fr": "Vous acceptez la politique de confidentialit&eacute; de Twitter Inc.",
        "it": "Accetti l'informativa sulla privacy di Twitter Inc.",
        "es": "Usted acepta la pol&iacute;tica de privacidad de Twitter Inc.",
    },
    "twitter-terms": {
        "de": "Datenschutzbestimmungen von Twitter Inc.",
        "en": "Terms and conditions of Twitter Inc.",
        "fr": "Politique de confidentialit&eacute; de Twitter Inc.",
        "it": "Informativa sulla privacy di Twitter Inc.",
        "es": "Pol&iacute;tica de privacidad de Twitter Inc.",
    },
    "collapse-tweets": {
        "de": "Tweets reduzieren",
        "en": "Collapse Tweets",
        "fr": "Minimiser les tweets",
        "it": "Riduci i tweets",
        "es": "Reducir tuits",
    },
    "no_button": {
        "de": "Nein, danke",
        "en": "No, thanks",
        "fr": "Non, merci",
        "it": "No, grazie",
        "es": "No, gracias.",
    },
    "yes_button": {
        "de": "Ja",
        "en": "Yes",
        "fr": "Oui",
        "it": "Sì",
        "es": "Ja",
    },
    "austria": {
        "de": "Österreich",
        "en": "Austria",
        "fr": "Autriche",
        "it": "Austria",
        "es": "Austria",
    },
    "belgium": {
        "de": "Belgien",
        "en": "Belgium",
        "fr": "Belgique",
        "it": "Belgio",
        "es": "B&eacute;lgica",
    },
    "swiss": {
        "de": "Schweiz",
        "en": "Switzerland",
        "fr": "Suisse",
        "it": "Svizzera",
        "es": "Suiza",
    },
    "czech-republic": {
        "de": "Tschechien",
        "en": "Czech Republic",
        "fr": "R&eacute;publique tch&egrave;que",
        "it": "Repubblica Ceca",
        "es": "Rep&uacute;blica Checa",
    },
    "england": {
        "de": "England",
        "en": "England",
        "fr": "Angleterre",
        "it": "Inghilterra",
        "es": "Inglaterra",
    },
    "hungary": {
        "de": "Ungarn",
        "en": "Hungary",
        "fr": "Hongrie",
        "it": "Ungheria",
        "es": "Hungr&iacute;a",
    },
    "netherlands": {
        "de": "Niederlande",
        "en": "Netherlands",
        "fr": "Pays-Bas",
        "it": "Paesi Bassi",
        "es": "Niederlande",
    },
    "slovakia": {
        "de": "Slowakei",
        "en": "Slovakia",
        "fr": "Slovaquie",
        "it": "Slovacchia",
        "es": "Eslovaquia",
    },
    "january": {
        "de": "Januar",
        "en": "January",
        "fr": "Janvier",
        "it": "Gennaio",
        "es": "Enero",
    },
    "february": {
        "de": "Februar",
        "en": "February",
        "fr": "F&eacute;vrier",
        "it": "Febbraio",
        "es": "Febrero",
    },
    "march": {
        "de": "März",
        "en": "March",
        "fr": "Mars",
        "it": "Marzo",
        "es": "Marzo",
    },
    "april": {
        "de": "April",
        "en": "April",
        "fr": "Avril",
        "it": "Aprile",
        "es": "Abril",
    },
    "may": {
        "de": "Mai",
        "en": "May",
        "fr": "Mai",
        "it": "Maggio",
        "es": "Mai",
    },
    "june": {
        "de": "Juni",
        "en": "June",
        "fr": "Juin",
        "it": "Giugno",
        "es": "Junio",
    },
    "july": {
        "de": "Juli",
        "en": "July",
        "fr": "Juillet",
        "it": "Luglio",
        "es": "Julio",
    },
    "august": {
        "de": "August",
        "en": "August",
        "fr": "Ao&ucirc;t",
        "it": "Agosto",
        "es": "Agosto",
    },
    "september": {
        "de": "September",
        "en": "September",
        "fr": "Septembre",
        "it": "Settembre",
        "es": "Septiembre",
    },
    "october": {
        "de": "Oktober",
        "en": "October",
        "fr": "Octobre",
        "it": "Ottobre",
        "es": "Octubre",
    },
    "november": {
        "de": "November",
        "en": "November",
        "fr": "Novembre",
        "it": "Novembre",
        "es": "Noviembre",
    },
    "december": {
        "de": "Dezember",
        "en": "December",
        "fr": "D&eacute;cembre",
        "it": "Dicembre",
        "es": "Diciembre",
    },
    "publishing-notes": {
        "de": "Impressum",
        "en": "Publishing Notes",
        "fr": "Mentions l&eacute;gales",
        "it": "Impronta",
        "es": "Impresionante",
    },
    "data-protection": {
        "de": "Datenschutz",
        "en": "Data Protection",
        "fr": "Protection des donn&eacute;es",
        "it": "Protezione dei dati",
        "es": "Política de privacidad",
    },
    "langLabel": {
        "de": "Sprache ",
        "en": "Language",
        "fr": "Langue",
        "it": "Lingua",
        "es": "Idioma",
    },
    "countryLabel": {
        "de": "Land",
        "en": "Country",
        "fr": "Pays",
        "it": "Paese",
        "es": "Pa&iacute;s",
    },
    "langChange": {
        "de": "Sprache ändern",
        "en": "Change Language",
        "fr": "Changer la langue",
        "it": "Cambio di lingua",
        "es": "Cambiar de idioma",
    },
    "countryChange": {
        "de": "Land wählen",
        "en": "Change Country",
        "fr": "S&eacute;lectionner le pays",
        "it": "Seleziona paese",
        "es": "Seleccione un pa&iacute;s",
    },
    "search": {
        "de": "Suche",
        "en": "Search",
        "fr": "Chercher",
        "it": "Ricerca",
        "es": "Buscar en",
    },

    //SiteInfo // Maintenance / Announcement Banner
    /*
    "siteinfo": {
        "de": "Wartungsarbeiten xx.xx.22: ",
        "en": "Maintenance xx.xx.22: ",
        "fr": "Travaux de maintenance xx.xx.22: ",
        "it": "Lavori di manutenzione xx.xx.22: "
    },
    */
    "siteinfo": {
        "de": "Update vom 0x.0x.2022: ",
        "en": "Update as of 0x.0x.2022: ",
        "fr": "Mise à jour du 0x.0x.2022: ",
        "it": "Aggiornamento al 0x.0x.2022: ",
        "es": "Actualizaci&oacute;n de 0x.0x.2022:",
    },

// Mainmenu - Country Toggle
    "de_Ctoggle": {
        "de": "DE - Deutschland",
        "en": "DE - Germany",
        "fr": "DE - Allemagne",
        "it": "DE - Germania",
        "es": "DE - Alemania",
    },

//DE TSOs    
    "de_tso_Ctoggle": {
        "de": "DE - ÜNBs",
        "en": "DE - CTAs",
        "fr": "DE - CTAs",
        "it": "DE - CTAs",
        "es": "DE - CTAs"
    },
    "de_50h_Ctoggle": {
        "de": "DE - ÜNB - 50Hertz",
        "en": "DE - TSO - 50Hertz",
        "fr": "",
        "it": ""
    },
    "de_ten_Ctoggle": {
        "de": "DE - ÜNB - TENNET",
        "en": "DE - TSO - TENNET",
        "fr": "DE - ",
        "it": "DE - "
    }, 
    "de_amp_Ctoggle": {
        "de": "DE - ÜNB - Amprion",
        "en": "DE - TSO - Amprion",
        "fr": "DE - ",
        "it": "DE - "
    }, 
    "de_tbw_Ctoggle": {
        "de": "DE - ÜNB - TransnetBW",
        "en": "DE - TSO - TransnetBW",
        "fr": "DE - ",
        "it": "DE - "
    },
//DE Federal States
    "de_bl_Ctoggle": {
        "de": "DE - Bundesländer",
        "en": "DE - States",
        "fr": "DE - États fédéraux",
        "it": "DE - Stati federali",
				"es": "DE - Estados federales"
    },
    "de_bw_Ctoggle": {
        "de": "DE - Baden-Württemberg",
        "en": "DE - Baden-Württemberg",
        "fr": "DE - le Bade-Wurtemberg",
        "it": "DE - "
    },
    "de_by_Ctoggle": {
        "de": "DE - Bayern",
        "en": "DE - Bavaria",
        "fr": "DE - la Bavière",
        "it": "DE - "
    },
    "de_be_Ctoggle": {
        "de": "DE - Berlin",
        "en": "DE - Berlin",
        "fr": "DE - Berlin",
        "it": "DE - "
    },
    "de_bb_Ctoggle": {
        "de": "DE - Brandenburg",
        "en": "DE - Brandenburg",
        "fr": "DE - le Brandebourg",
        "it": "DE - "
    },
    "de_hb_Ctoggle": {
        "de": "DE - Bremen",
        "en": "DE - Bremen",
        "fr": "DE - Brême",
        "it": "DE - "
    },
    "de_hh_Ctoggle": {
        "de": "DE - Hamburg",
        "en": "DE - Hamburg",
        "fr": "DE - Hambourg",
        "it": "DE - "
    },
    "de_he_Ctoggle": {
        "de": "DE - Hessen",
        "en": "DE - Hesse",
        "fr": "DE - la Hesse",
        "it": "DE - "
    },
    "de_mv_Ctoggle": {
        "de": "DE - Mecklenburg-Vorpommern",
        "en": "DE - Mecklenburg Western Pomerania",
        "fr": "DE - Mecklembourg-Poméranie-Occidentale",
        "it": "DE - "
    },
    "de_ni_Ctoggle": {
        "de": "DE - Niedersachsen",
        "en": "DE - Lower Saxony",
        "fr": "DE - la Basse-Saxe",
        "it": "DE - "
    },
    "de_nw_Ctoggle": {
        "de": "DE - Nordrhein-Westfalen",
        "en": "DE - Northrhine-Westphalia",
        "fr": "DE - la Rhénanie-du-Nord-Westphalie",
        "it": "DE - "
    },
    "de_rp_Ctoggle": {
        "de": "DE - Rheinland-Pfalz",
        "en": "DE - Rhineland Palatinate",
        "fr": "DE - la Rhénanie-Palatinat",
        "it": "DE - "
    },
    "de_sl_Ctoggle": {
        "de": "DE - Saarland",
        "en": "DE - Saarland",
        "fr": "DE - la Sarre",
        "it": "DE - "
    },
    "de_sn_Ctoggle": {
        "de": "DE - Sachsen",
        "en": "DE - Saxony",
        "fr": "DE - la Saxe",
        "it": "DE - "
    },
    "de_st_Ctoggle": {
        "de": "DE - Sachsen-Anhalt",
        "en": "DE - Saxony-Anhalt",
        "fr": "DE - la Saxe-Anhalt",
        "it": "DE - "
    },
    "de_sh_Ctoggle": {
        "de": "DE - Schleswig-Holstein",
        "en": "DE - Schleswig Holstein",
        "fr": "DE - le Schleswig-Holstein",
        "it": "DE - "
    },
    "de_th_Ctoggle": {
        "de": "DE - Thüringen",
        "en": "DE - Thuringia",
        "fr": "DE - laThuringe",
        "it": "DE - "
    },
//CH
    "ch_Ctoggle": {
        "de": "CH - Schweiz",
        "en": "CH - Switzerland",
        "fr": "CH - Suisse",
        "it": "CH - Svizzera",
        "es": "CH - Suiza",
    },
    // -------------------------------
    "eu_Ctoggle": {
        "de": "EU27 - Europ\u00e4ische Union",
        "en": "EU27 - European Union",
        "fr": "EU27 - Union europ\u00e9enne",
        "it": "EU27 - Unione Europea",
        "es": "UE27 - Uni&oacute;n Europea",
    },
    "all_Ctoggle": {
        "de": "ALL - Europa",
        "en": "ALL - Europe",
        "fr": "ALL - Europe",
        "it": "ALL - Europa",
        "es": "TODOS - Europa",
    },
    // -------------------------------
    "al_Ctoggle": {
        "de": "AL - Albanien",
        "en": "AL - Albania",
        "fr": "AL - Albanie",
        "it": "AL - Albania",
        "es": "AL - Alban&eacute;s",
    },
    "am_Ctoggle": {
        "de": "AM - Armenien",
        "en": "AM - Armenia",
        "fr": "AM - Arm&eacute;nie",
        "it": "AM - Armenia",
        "es": "AM - Armenia",
    },
    "at_Ctoggle": {
        "de": "AT - \u00d6sterreich",
        "en": "AT - Austria",
        "fr": "AT - L'Autriche",
        "it": "AT - Austria",
        "es": "AT - \u00d6sterreich",
    },
    "az_Ctoggle": {
        "de": "AZ - Aserbaidschan",
        "en": "AZ - Azerbaijan",
        "fr": "AZ - Azerbaïdjan",
        "it": "AZ - Azerbaijan",
        "es": "AZ - Azerbaiy&aacute;n",
    },
    "ba_Ctoggle": {
        "de": "BA - Bosnien-Herzegowina",
        "en": "BA - Bosnia-Herzegovina",
        "fr": "BA - Bosnie-Herz&eacute;govine",
        "it": "BA - Bosnia ed Erzegovina",
        "es": "BA - Bosnia y Herzegovina",
    },
    "be_Ctoggle": {
        "de": "BE - Belgien",
        "en": "BE - Belgium",
        "fr": "BE - Belgique",
        "it": "BE - Belgio",
        "es": "BE - B&eacute;lgica",
    },
    "bg_Ctoggle": {
        "de": "BG - Bulgarien",
        "en": "BG - Bulgaria",
        "fr": "BG - Bulgarie",
        "it": "BG - Bulgaria",
        "es": "BG - B&uacute;lgaro",
    },
    "by_Ctoggle": {
        "de": "BY - Belarus",
        "en": "BY - Belarus",
        "fr": "BY - Bi&eacute;lorussie",
        "it": "BY - Belarus",
        "es": "BY - Bielorrusia",
    },
    "cy_Ctoggle": {
        "de": "CY - Zypern",
        "en": "CY - Cyprus",
        "fr": "CY - Chypre",
        "it": "CY - Cipro",
        "es": "CY - Zypern",
    },
    "cz_Ctoggle": {
        "de": "CZ - Tschechien",
        "en": "CZ - Czech Republic",
        "fr": "CZ - R&eacute;publique tch&egrave;que",
        "it": "CZ - Repubblica Ceca",
        "es": "CZ - Rep&uacute;blica Checa",
    },
    "dk_Ctoggle": {
        "de": "DK - Dänemark",
        "en": "DK - Denmark",
        "fr": "DK - Danemark",
        "it": "DK - Danimarca",
        "es": "DK - Dinamarca",
    },
    "ee_Ctoggle": {
        "de": "EE - Estland",
        "en": "EE - Estonia",
        "fr": "EE - Estonie",
        "it": "EE - Estonia",
        "es": "EE - Estonia",
    },
    "es_Ctoggle": {
        "de": "ES - Spanien",
        "en": "ES - Spain",
        "fr": "ES - Espagne",
        "it": "ES - Spagna",
        "es": "ES - Espa&ntilde;a",
    },
    "fi_Ctoggle": {
        "de": "FI - Finnland",
        "en": "FI - Finland",
        "fr": "FI - Finlande",
        "it": "FI - Finlandia",
        "es": "FI - Finlandia",
    },
    "gb_Ctoggle": {
        "de": "GB - Großbritannien",
        "en": "GB - Great Britain",
        "fr": "GB - Grande-Bretagne",
        "it": "GB - Regno Unito",
        "es": "GB - Gran Breta&ntilde;a",
    },
    "ge_Ctoggle": {
        "de": "GE - Georgien",
        "en": "GE - Georgia",
        "fr": "GE - G&eacute;orgie",
        "it": "GE - Georgia",
        "es": "GE: Georgien",
    },
    "gr_Ctoggle": {
        "de": "GR - Griechenland",
        "en": "GR - Greece",
        "fr": "GR - Gr&egrave;ce",
        "it": "GR - Grecia",
        "es": "GR - Grecia",
    },
    "hr_Ctoggle": {
        "de": "HR - Kroatien",
        "en": "HR - Croatia",
        "fr": "HR - Croatie",
        "it": "HR - Croazia",
        "es": "HR - Croacia",
    },
    "hu_Ctoggle": {
        "de": "HU - Ungarn",
        "en": "HU - Hungary",
        "fr": "HU - Hongrie",
        "it": "HU - Ungheria",
        "es": "HU - Hungr&iacute;a",
    },
    "ie_Ctoggle": {
        "de": "IE - Irland",
        "en": "IE - Ireland",
        "fr": "IE - Irlande",
        "it": "IE - Irlanda",
        "es": "IE - Irlanda",
    },
    "it_Ctoggle": {
        "de": "IT - Italien",
        "en": "IT - Italy",
        "fr": "IT - Italie",
        "it": "IT - Italia",
        "es": "IT - Italiano",
    },
    "lt_Ctoggle": {
        "de": "LT - Litauen",
        "en": "LT - Lithuania",
        "fr": "LT - Lituanie",
        "it": "LT - Lituania",
        "es": "LT - Lituania",
    },
    "lu_Ctoggle": {
        "de": "LU - Luxemburg",
        "en": "LU - Luxembourg",
        "fr": "LU - Luxembourg",
        "it": "LU - Lussemburgo",
        "es": "LU - Luxemburgo",
    },
    "lv_Ctoggle": {
        "de": "LV - Lettland",
        "en": "LV - Latvia",
        "fr": "LV - Lettonie",
        "it": "LV - Lettonia",
        "es": "LV - Letonia",
    },
    "md_Ctoggle": {
        "de": "MD - Moldau",
        "en": "MD - Moldova",
        "fr": "MD - Moldavie",
        "it": "MD - Moldavia",
        "es": "MD - Moldavia",
    },
    "me_Ctoggle": {
        "de": "ME - Montenegro",
        "en": "ME - Montenegro",
        "fr": "ME - Mont&eacute;n&eacute;gro",
        "it": "ME - Montenegro",
        "es": "ME - Montenegro",
    },
    "mk_Ctoggle": {
        "de": "MK - Nordmazedonien",
        "en": "MK - North Macedonia",
        "fr": "MK - Mac&eacute;doine du Nord",
        "it": "MK - Macedonia del Nord",
        "es": "MK - Macedonia del Norte",
    },
    "mt_Ctoggle": {
        "de": "MT - Malta",
        "en": "MT - Malta",
        "fr": "MT - Malte",
        "it": "MT - Malta",
        "es": "MT - Malta",
    },
    "nie_Ctoggle": {
        "de": "NIE - Nord Irland",
        "en": "NIE - Northern Ireland",
        "fr": "NIE - Irlande du Nord",
        "it": "NIE - Irlanda del Nord",
        "es": "NIE - Irlanda del Norte",
    },
    "nl_Ctoggle": {
        "de": "NL - Niederlande",
        "en": "NL - Netherlands",
        "fr": "NL - Pays-Bas",
        "it": "NL - Paesi Bassi",
        "es": "NL - Niederlande",
    },
    "no_Ctoggle": {
        "de": "NO - Norwegen",
        "en": "NO - Norway",
        "fr": "NO - Norv&egrave;ge",
        "it": "NO - Norvegia",
        "es": "NO - Noruega",
    },
    "pl_Ctoggle": {
        "de": "PL - Polen",
        "en": "PL - Poland",
        "fr": "PL - Pologne",
        "it": "PL - Polonia",
        "es": "ES - Polen",
    },
    "pt_Ctoggle": {
        "de": "PT - Portugal",
        "en": "PT - Portugal",
        "fr": "PT - Portugal",
        "it": "PT - Portogallo",
        "es": "PT - Portugal",
    },
    "ro_Ctoggle": {
        "de": "RO - Rumänien",
        "en": "RO - Romania",
        "fr": "RO - Roumanie",
        "it": "RO - Romania",
        "es": "RO - Ruman&iacute;a",
    },
    "rs_Ctoggle": {
        "de": "RS - Serbien",
        "en": "RS - Serbia",
        "fr": "RS - Serbie",
        "it": "RS - Serbia",
        "es": "RS - Serbio",
    },
    "ru_Ctoggle": {
        "de": "RU - Russland",
        "en": "RU - Russia",
        "fr": "RU - Russie",
        "it": "RU - Russia",
        "es": "RU - Rusia",
    },
    "se_Ctoggle": {
        "de": "SE - Schweden",
        "en": "SE - Sweden",
        "fr": "SE - Su&egrave;de",
        "it": "SE - Svezia",
        "es": "SE - Suecia",
    },
    "si_Ctoggle": {
        "de": "SI - Slowenien",
        "en": "SI - Slovenia",
        "fr": "SI - Slov&eacute;nie",
        "it": "SI - Slovenia",
        "es": "SI - Slowenien",
    },
    "sk_Ctoggle": {
        "de": "SK - Slowakei",
        "en": "SK - Slovak Republic",
        "fr": "SK - Slovaquie",
        "it": "SK - Slovacchia",
        "es": "SK - Eslovaquia",
    },
    "tr_Ctoggle": {
        "de": "TR - Türkei",
        "en": "TR - Turkey",
        "fr": "TR - Turquie",
        "it": "TR - Turchia",
        "es": "TR - Turqu&iacute;a",
    },
    "ua_Ctoggle": {
        "de": "UA - Ukraine",
        "en": "UA - Ukraine",
        "fr": "UA - Ukraine",
        "it": "UA - Ucraina",
        "es": "UA - Ucrania",
    },
    "uk_Ctoggle": {
        "de": "UK - Vereinigtes Königreich",
        "en": "UK - United Kingdom",
        "fr": "UK - Royaume-Uni",
        "it": "UK - Regno Unito",
        "es": "UK - Reino Unido",
    },
    "xk_Ctoggle": {
        "de": "XK - Kosovo",
        "en": "XK - Kosovo",
        "fr": "XK - Kosovo",
        "it": "XK - Kosovo",
        "es": "XK - Kosovo",
    },
    "ad_Ctoggle": {
        "de": "AD - Andorra",
        "en": "AD - Andorra",
        "fr": "AD - Andorre",
        "it": "AD - Andorra",
        "es": "AD - Andorra",
    },
    "mc_Ctoggle": {
        "de": "MC - Monaco",
        "en": "MC - Monaco",
        "fr": "MC - Monaco",
        "it": "MC - Monaco",
        "es": "MC - Monaco",
    },
    "sm_Ctoggle": {
        "de": "SM - San Marino",
        "en": "SM - San Marino",
        "fr": "SM - Saint-Marin",
        "it": "SM - San Marino",
        "es": "SM - San Marino",
    },
    "va_Ctoggle": {
        "de": "VA - Vatikanstadt",
        "en": "VA - Vatican City",
        "fr": "VA - Cit&eacute; du Vatican",
        "it": "VA - Citt&agrave; del Vaticano",
        "es": "VA - Ciudad del Vaticano",
    },
    "li_Ctoggle": {
        "de": "LI - Liechtenstein",
        "en": "LI - Liechtenstein",
        "fr": "LI - Liechtenstein",
        "it": "LI - Liechtenstein",
        "es": "LI - Liechtenstein",
    },

    "mainMenu.power": {
        "de": "Leistung",
        "en": "Power",
        "fr": "Puissance",
        "it": "Potenza",
        "es": "Potencia",
    },
    "mainMenu.frequency": {
        "de": "Frequenz",
        "en": "Frequency",
        "fr": "Fr&eacute;quence",
        "it": "Frequenza",
        "es": "Frecuencia",
    },
    "mainMenu.redispatch": {
        "de": "Redispatch",
        "en": "Redispatch",
        "fr": "Redispatch",
        "it": "Redispatch",
        "es": "Redispatch",
    },
    "mainMenu.energy_redispatch": {
        "de": "Redispatch",
        "en": "Redispatch",
        "fr": "Redispatch",
        "it": "Redispatch",
        "es": "Redispatch",
    },
    "mainMenu.power_redispatch": {
        "de": "Redispatch",
        "en": "Redispatch",
        "fr": "Redispatch",
        "it": "Redispatch",
        "es": "Redispatch",
    },
    "mainMenu.electricity-prod": {
        "de": "Stromerzeugung",
        "en": "Electricity Production ",
        "fr": "Production &eacute;lectrique",
        "it": "Produzione Elettrica",
        "es": "Generaci&oacute;n de energ&iacute;a",
    },
    "mainMenu.electricity-co2_intensity": {
        "de": "CO2-Intensität",
        "en": "CO2 intensity",
        "fr": "Intensit&eacute; CO2",
        "it": "Intensità CO2",
        "es": "Intensidad de CO2",
    },
    "mainMenu.electricity-prod-simulated": {
        "de": "Simulierte Stromerzeugung",
        "en": "Simulated Electricity Production",
        "fr": "Production &eacute;lectrique simul&eacute;e",
        "it": "Produzione Elettrica Simulata",
        "es": "Generaci&oacute;n de energ&iacute;a simulada",
    },
    "mainMenu.consumption-advice": {
        "de": "Stromampel",
        "en": "Electricity traffic light",
        "fr": "Feu de signalisation \u00e9lectrique",
        "it": "Semaforo elettrico",
        "es": "Sem\u00e1foro el\u00e9ctrico",
    },
    "mainMenu.electricity-frequency": {
        "de": "Frequenz",
        "en": "Frequency",
        "fr": "Fr&eacute;quence",
        "it": "Frequenza",
        "es": "Frecuencia",
    },
    "mainMenu.installed-power": {
        "de": "Installierte Leistung",
        "en": "Installed Power ",
        "fr": "Puissance install&eacute;e",
        "it": "Potenza Installata",
        "es": "Potencia instalada",
    },
    "mainMenu.scatter-chart": {
        "de": "Punktediagramme zur Stromerzeugung",
        "en": "Scatter Charts on Electricity Production",
        "fr": "Diagrammes de dispersion de la production d'&eacute;lectricit&eacute;",
        "it": "Grafici a Dispersione sulla Produzione Elettrica",
        "es": "Gr&aacute;ficos de puntos de generaci&oacute;n de energ&iacute;a",
    },
    "mainMenu.energy": {
        "de": "Energie",
        "en": "Energy",
        "fr": "&Eacute;nergie",
        "it": "Energia",
        "es": "Energ&iacute;a",
    },
    "mainMenu.bar-chart": {
        "de": "Säulendiagramme zur Stromerzeugung",
        "en": "Column charts on electricity generation",
        "fr": "Diagrammes à colonnes pour la production d'\u00e9lectricit\u00e9",
        "it": "Diagrammi a colonna per la generazione di elettricità",
        "es": "Diagramas de columnas para la generación de electricidad",
    },
	"mainMenu.bar-chart-states": {
		"de": "Säulendiagramme der Bundesländer",
		"en": "Column charts of states",
		"fr": "Diagrammes à colonnes des États",
		"it": "Diagrammi a colonna degli Stati",
		"es": "Diagramas de columnas de los Estados",
	},
    "mainMenu.pie-chart": {
        "de": "Kreisdiagramme zur Stromerzeugung",
        "en": "Pie Charts on Electricity Generation",
        "fr": "Diagrammes circulaires de la production d'&eacute;lectricit&eacute;",
        "it": "Grafici a Torta sulla Produzione Elettrica",
        "es": "Gr&aacute;ficos circulares de generaci&oacute;n de electricidad",
    },
    "mainMenu.percent-load": {
        "de": "Prozentuale Volllast",
        "en": "Percentage of Full Load",
        "fr": "Pourcentage de pleine charge",
        "it": "Percentuale di Pieno Carico",
        "es": "Porcentaje de carga completa",
    },
    "mainMenu.renewable": {
        "de": "Anteil Erneuerbarer Energien",
        "en": "Renewable Shares",
        "fr": "Part des &eacute;nergies renouvelables",
        "it": "Quota di energie rinnovabili",
        "es": "Cuota de energ&iacute;as renovables",
    },
    "mainMenu.ren-share-map": {
        "de": "Anteil Erneuerbarer Energien",
        "en": "Renewable Shares",
        "fr": "Part des &eacute;nergies renouvelables",
        "it": "Quota di energie rinnovabili",
        "es": "Cuota de energ&iacute;as renovables",
    },
    "mainMenu.storage": {
        "de": "Speicherf&uuml;llstände",
        "en": "Storage Filling Level",
        "fr": "Niveaux des r&eacute;servoirs",
        "it": "Livello degli Accumulatori",
        "es": "Niveles de memoria",
    },
    "mainMenu.import-export": { 
        "de": "Import und Export von Strom",
        "en": "Import and export of electricity",
        "fr": "Importation et exportation d'&eacute;lectricit&eacute;",
        "it": "Importazione ed esportazione di energia elettrica",
        "es": "Importaci&oacute;n y exportaci&oacute;n de electricidad",
},
    "mainMenu.import-export-map": {
        "de": "Import und Export",
        "en": "Import and Export",
        "fr": "Importations et exportations nationales et europ&eacute;ennes",
        "it": "Importazioni ed Esportazioni",
        "es": "Importaci&oacute;n y exportaci&oacute;n",
    },
    "mainMenu.environment": {
        "de": "Umwelt",
        "en": "Environment",
        "fr": "Environnement",
        "it": "Ambiente",
        "es": "Entorno",
    },
    "mainMenu.co2-emissions": {
        "de": "CO2-Emissionen der Stromerzeugung",
        "en": "CO2 emissions from electricity generation",
        "fr": "&Eacute;missions de CO2 liées à la production d'électricité",
        "it": "Emissioni di CO2 derivanti dalla produzione di energia elettrica",
        "es": "Emisiones de CO2 procedentes de la generación de electricidad",
    },    
    "mainMenu.emissions": {
        "de": "Emissionen",
        "en": "Emissions",
        "fr": "&Eacute;missions",
        "it": "Emissioni",
        "es": "Emissionen",
    },
    "mainMenu.power-emission": {
        "de": "Emissionen der Kohlekraftwerke",
        "en": "Emissions from coal-fired power plants",
        "fr": "Emissions des centrales &eacute;lectriques au charbon",
        "it": "Emissioni delle centrali elettriche a carbone",
        "es": "Emisiones de las centrales el&eacute;ctricas de carb&oacute;n",
    },
    "mainMenu.climate": {
        "de": "Klima",
        "en": "Climate",
        "fr": "Climat",
        "it": "Clima",
        "es": "Clima",
    },
    "mainMenu.hourly-climate": {
        "de": "Klima - Stundenwerte",
        "en": "Climate - hourly values",
        "fr": "Climat - valeurs horaires",
        "it": "Clima - valori orari",
        "es": "Clima - valores horarios",
    },
    "mainMenu.annual-avg": {
        "de": "Klima - Jahresmittelwerte",
        "en": "Climate - annual mean values",
        "fr": "Climat - moyennes annuelles",
        "it": "Clima - valori medi annuali",
        "es": "Clima - valores medios anuales",
    },
    "mainMenu.hydrology": {
        "de": "Hydrologie",
        "en": "Hydrology",
        "fr": "Hydrologie",
        "it": "Idrologia",
        "es": "Hidrolog&iacute;a",
    },
    "mainMenu.prices": {
        "de": "Preise",
        "en": "Prices",
        "fr": "Prix",
        "it": "Prezzi",
        "es": "Precios",
    },
    "mainMenu.spot-prices": {
        "de": "B&ouml;rsenstrompreise",
        "en": "Spot Market Prices ",
        "fr": "Prix d'&eacute;change de l'&eacute;lectricit&eacute;",
        "it": "Prezzi di Borsa dell’Energia Elettrica",
        "es": "Precios de la electricidad en B&amp;ouml;rsen",
    },
    "mainMenu.price-volume": {
        "de": "B&ouml;rsenstrompreise und Handelsvolumen",
        "en": "Price Volume ",
        "fr": "Prix d'&eacute;change de l'&eacute;lectricit&eacute; et volumes &eacute;chang&eacute;s",
        "it": "Prezzi di Borsa dell’Energia Elettrica e Volume degli Scambi",
        "es": "Precios de la electricidad en B&amp;ouml;rsen y vol&uacute;menes negociados",
    },
    "mainMenu.avg-spot": {
        "de": "Durchschnittliche B&ouml;rsenstrompreise",
        "en": "Average Spot Market Prices ",
        "fr": "Prix moyens d'&eacute;change de l'&eacute;lectricit&eacute;",
        "it": "Prezzi Medi di Borsa dell’Energia Elettrica",
        "es": "Precios medios de la electricidad en B&amp;ouml;rsen",
    },
    "mainMenu.scatter-spot": {
        "de": "Punktediagramme zu B&ouml;rsenstrompreisen",
        "en": "Scatter Charts on Spot Market Prices",
        "fr": "Diagrammes de dispersion des prix d'&eacute;change de l'&eacute;lectricit&eacute;",
        "it": "Grafici a Dispersione dei Prezzi di Borsa dell’Energia Elettrica",
        "es": "Gr&aacute;ficos de puntuaci&oacute;n de los precios de la electricidad en B&amp;ouml;rsen",
    },
    "mainMenu.market-values": {
        "de": "Marktwerte",
        "en": "Market values",
        "fr": "Valeur de march\u00e9",
        "it": "Valore di mercato",
        "es": "Valores de mercado",
    },
    "mainMenu.power-trade": {
        "de": "Au&szlig;enhandelsstatistik elektrischer Strom",
        "en": "Power Trade Statistics",
        "fr": "Statistiques du commerce ext&eacute;rieur en &eacute;lectricit&eacute;",
        "it": "Statistica del Commercio d’Energia Elettrica con l’Estero",
        "es": "Estad&iacute;sticas de comercio exterior de electricidad",
    },
    "mainMenu.eeg-account": {
        "de": "EEG-Konto",
        "en": "EEG account",
        "fr": "Compte EEG",
        "it": "Conto EEG",
        "es": "Cuenta EEG",
    },
    "mainMenu.remod": {
        "de": "Szenarien",
        "en": "Scenarios",
        "fr": "Sc&eacute;narios",
        "it": "Scenari",
        "es": "Escenarios",
    },
    "mainMenu.remod-installed-power": {
        "de": "Installierte Leistung",
        "en": "Installed Power",
        "fr": "Puissance install&eacute;e",
        "it": "Potenza Installata",
        "es": "Potencia instalada",
    },
    "mainMenu.remod-application-technologies": {
        "de": "Anwendungstechnologien",
        "en": "Application Technologies",
        "fr": "Technologies d'Application",
        "it": "Tecnologie Applicative",
        "es": "Tecnolog&iacute;as de aplicaci&oacute;n",
    },
    "mainMenu.remod-emissions": {
        "de": "Emissionen",
        "en": "Emissions",
        "fr": "&Eacute;missions",
        "it": "Emissioni",
        "es": "Emisiones",
    },
    "mainMenu.remod-power-profiles": {
        "de": "Stromprofile",
        "en": "Power Profiles",
        "fr": "Profils d'&Eacute;lectricit&eacute;",
        "it": "Profili di Produzione Elettrica",
        "es": "Perfiles actuales",
    },
    "mainMenu.remod-energies": {
        "de": "Energien",
        "en": "Power Sources",
        "fr": "Sources d'&Eacute;nergie",
        "it": "Energies",
        "es": "Energ&iacute;as",
    },
    "mainMenu.remod-flexibility": {
        "de": "Flexibilitätsbeiträge",
        "en": "Flexibility contributions",
        "fr": "Cotisations de flexibilit&eacute;",
        "it": "Contributi di flessibilità",
        "es": "Aportaciones de flexibilidad",
    },
    "mainMenu.chartmaps": {
        "de": "Karten",
        "en": "Maps",
        "fr": "Cartes",
        "it": "Mappe",
        "es": "Mapas",
    },
    "mainMenu.powermap": {
        "de": "Karte",
        "en": "Map",
        "fr": "Carte",
        "it": "Carta",
        "es": "Mapa",
    },
    "mainMenu.price_average_map": {
        "de": "Durchschnittliche B&ouml;rsenstrompreise",
        "en": "Average Spot Market Prices ",
        "fr": "Prix moyens d'&eacute;change de l'&eacute;lectricit&eacute;",
        "it": "Prezzi Medi di Borsa dell'Energia Elettrica",
        "es": "Precios medios de la electricidad en B&amp;ouml;rsen",
    },
    "mainMenu.info": {
        "de": "Infos",
        "en": "Infos",
        "fr": "Infos",
        "it": "Informazioni",
        "es": "Informaci&oacute;n",
    },
    "mainMenu.presentations": {
        "de": "Vorträge",
        "en": "Lectures",
        "fr": "Conf&eacute;rences",
        "it": "Conferenze",
        "es": "Conferencias",
    },
    "mainMenu.podcasts": {
        "de": "Podcasts",
        "en": "Podcasts",
        "fr": "Podcasts",
        "it": "Podcasts",
        "es": "Podcasts",
    },    
    "mainMenu.energy-charts-talks": {
        "de": "Energy-Charts Talks",
        "en": "Energy-Charts Talks",
        "fr": "Energy-Charts Talks",
        "it": "Energy-Charts Talks",
        "es": "Energy-Charts Talks",
    },
    "mainMenu.downloads": {
        "de": "Downloads",
        "en": "Downloads",
        "fr": "Downloads",
        "it": "Download",
        "es": "Descargas",
    },
    "mainMenu.api": {
        "de": "API",
        "en": "API",
        "fr": "API",
        "it": "API",
        "es": "API",
    },
    "mainMenu.explanations": {
        "de": "Erl&auml;uterungen",
        "en": "Explanations",
        "fr": "Notes explicatives",
        "it": "Note Esplicative",
        "es": "Explicaciones",
    },
    "mainMenu.faqs": {
        "de": "FAQs",
        "en": "FAQs",
        "fr": "FAQs",
        "it": "FAQ",
        "es": "Preguntas frecuentes",
    },
    "mainMenu.sources": {
        "de": "Quellen",
        "en": "Sources",
        "fr": "Sources",
        "it": "Fonti",
        "es": "Fuentes",
    },
    "mainMenu.contact": {
        "de": "Kontakt",
        "en": "Contact",
        "fr": "Nous contacter",
        "it": "Contatti",
        "es": "P&oacute;ngase en contacto con",
    },
    "mainMenu.radioactive-discharges": {
        "de": "Radioaktive Ableitungen",
        "en": "Radioactive discharges",
        "fr": "Rejets radioactifs",
        "it": "Scariche radioattive",
        "es": "Descargas radiactivas",
    },
    "home-content.welcome.general.headline": {
        "de": "Willkommen bei den Energy-Charts",
        "en": "Welcome to the Energy-Charts",
        "fr": "Bienvenu sur le site web Energy-Charts",
        "it": "Benvenuti su Energy-Charts",
        "es": "Bienvenido a los Energy-Charts",
    },
    "home-content.welcome.general.sub-headline": {
        "de": "Die Seite für interaktive Grafiken zu Stromproduktion und B&ouml;rsenstrompreisen",
        "en": "The site for interactive graphics displaying energy production and spot market prices",
        "fr": "Le site web pour des graphiques interactifs de la production d'&eacute;lectricit&eacute; et les prix de l'&eacute;lectricit&eacute; en bourse",
        "it": "Il sito dei grafici interattivi sulla produzione elettrica e i prezzi di borsa dell’energia elettrica",
        "es": "El sitio de los gr&aacute;ficos interactivos sobre la producci&oacute;n de electricidad y los precios de la electricidad en B&amp;ouml;rsen",
    },
    "home-content.welcome.general.content-1": {
        "de": "Willkommen! Wir m&ouml;chten mit dieser Webseite einen Beitrag zur Transparenz und Versachlichung der Diskussion um die Energiewende leisten.",
        "en": "Welcome! By making the data available on this website, it is our intent to promote transparent and objective discussions relating to all factors regarding the energy transformation.",
        "fr": "Bienvenu! Avec ce site web, nous souhaitons contribuer &agrave; la transparence et &agrave;  l'objectivation du d&eacute;bat sur la transformation du syst&egrave;me &eacute;nerg&eacute;tique.",
        "it": "Benvenuti! Con il presente sito web desideriamo fornire un contributo alla trasparenza e all’obiettività del dibattito sulla svolta energetica.",
        "es": "Bienvenido! Con esta web queremos contribuir a la transparencia y objetivaci&oacute;n del debate sobre la transici&oacute;n energ&eacute;tica.",
    },
    "home-content.welcome.CH.headline": {
        "de": "Willkommen bei den &#187;Swiss Energy-Charts&#171; dem Cockpit für die Schweizerische Energiewende",
        "en": "Welcome to the &#187;Swiss Energy-Charts&#171; the Cockpit for the Swiss Energy Transition",
        "fr": "Bienvenue sur le site de la &#187;Swiss Energy-Charts&#171; le cockpit de la transition &eacute;nerg&eacute;tique suisse",
        "it": "Benvenuti su &#187;Swiss Energy-Charts&#171; la cabina di pilotaggio per la transizione energetica svizzera",
        "es": "Bienvenido a &amp;#187;Swiss Energy-Charts&amp;#171; la cabina de mando de la transici&oacute;n energ&eacute;tica suiza",
    },
    "home-content.welcome.CH.sub-headline": {
        "de": "Interaktive Grafiken zu Stromproduktion und B&ouml;rsenstrompreisen",
        "en": "Interactive graphics displaying energy production and spot market prices",
        "fr": "Graphiques interactifs de la production d'&eacute;lectricit&eacute; et les prix de l'&eacute;lectricit&eacute; en bourse",
        "it": "Grafici interattivi sulla produzione elettrica e i prezzi di borsa dell’energia elettrica",
        "es": "Gr&aacute;ficos interactivos sobre la producci&oacute;n de electricidad y los precios de la electricidad en B&amp;ouml;rsen",
    },
    "home-content.welcome.CH.content-1": {
        "de": "Wir m&ouml;chten mit dieser Webseite einen Beitrag zur Transparenz und Versachlichung der Diskussion um die Energiewende leisten.",
        "en": "By making the data available on this website, it is our intent to promote transparent and objective discussions relating to all factors regarding the energy transformation.",
        "fr": "Avec ce site web, nous souhaitons contribuer &agrave; la transparence et &agrave;  l'objectivation du d&eacute;bat sur la transformation du syst&egrave;me &eacute;nerg&eacute;tique.",
        "it": "Con il presente sito web desideriamo fornire un contributo alla trasparenza e all’obiettività del dibattito sulla svolta energetica.",
        "es": "Con esta web queremos contribuir a la transparencia y objetivaci&oacute;n del debate sobre la transici&oacute;n energ&eacute;tica.",
    },
    "home-content.welcome.CH.sub-headline_neu": {
        "de": "Das Cockpit für die Schweizerische Energiewende mit interaktiven Grafiken zu Stromproduktion und B&ouml;rsenstrompreisen",
        "en": "The Cockpit for the Swiss Energy Transition with nteractive graphics displaying energy production and spot market prices",
        "fr": "...Graphiques interactifs de la production d'&eacute;lectricit&eacute; et les prix de l'&eacute;lectricit&eacute; en bourse",
        "it": "...Grafici interattivi sulla produzione elettrica e i prezzi di borsa dell’energia elettrica",
        "es": "...Gr&aacute;ficos interactivos sobre la producci&oacute;n de electricidad y los precios de la electricidad en B&amp;ouml;rsen",
    },
    "home-content.welcome.CH.content-1_neu": {
        "de": "Willkommen! Wir m&ouml;chten mit dieser Webseite einen Beitrag zur Transparenz und Versachlichung der Diskussion um die Energiewende leisten.",
        "en": "By making the data available on this website, it is our intent to promote transparent and objective discussions relating to all factors regarding the energy transformation.",
        "fr": "Avec ce site web, nous souhaitons contribuer &agrave; la transparence et &agrave;  l'objectivation du d&eacute;bat sur la transformation du syst&egrave;me &eacute;nerg&eacute;tique.",
        "it": "Con il presente sito web desideriamo fornire un contributo alla trasparenza e all’obiettività del dibattito sulla svolta energetica.",
        "es": "Con esta web queremos contribuir a la transparencia y objetivaci&oacute;n del debate sobre la transici&oacute;n energ&eacute;tica.",
    },    
    "home-content.instructions.section-1.headline":{
        "de": "Interaktiv. Anpassbar",
        "en": "Interactive. Customizable",
        "fr": "Interactif. Adaptable.",
        "it": "Una pagina interattiva e personalizzabile",
        "es": "Interactivo. Adaptable",
    },
    "home-content.instructions.section-1.general.content-1":{
        "de": "Sie können sich die passende Darstellung der Grafiken selbst konfigurieren, indem Sie zum Beispiel einen oder mehrere Energieträger auswählen, zwischen absoluter und prozentualer Darstellung wechseln und Zahlenwerte in einem eingeblendeten Fenster ablesen. Außerdem können Sie den Anzeigezeitraum für die Grafiken auswählen.",
        "en": "The site allows you to interactively customize the graphs to your own needs: You can select one or more energy sources or switch between graphs with absolute or percent values. The numerical values displayed in the graphs can be viewed in a pop-up window. Furthermore you can choose the time period to be viewed.",
        "fr": "Vous pouvez configurer vous-m&ecirc;me l'affichage appropri&eacute; des graphiques, par exemple en s&eacute;lectionnant une ou plusieurs sources d'&eacute;nergie, en passant de l'affichage absolu &agrave; l'affichage en pourcentage et en lisant les valeurs dans une fen&ecirc;tre qui s'affiche. Vous pouvez &eacute;galement s&eacute;lectionner la p&eacute;riode d'affichage des graphiques.",
        "it": "Potete configurare autonomamente la rappresentazione dei grafici selezionando per esempio uno o più vettori energetici, passando dai dati assoluti a quelli percentuali e viceversa o leggendo i valori numerici in una finestra pop-up. Inoltre, potete scegliere il periodo preso in esame dai grafici.",
        "es": "Usted mismo puede configurar la visualizaci&oacute;n adecuada de los gr&aacute;ficos, por ejemplo seleccionando uno o varios vectores energ&eacute;ticos, cambiando entre visualizaci&oacute;n absoluta y porcentual y leyendo los valores num&eacute;ricos en una ventana que aparece. Tambi&eacute;n puedes seleccionar el periodo de visualizaci&oacute;n de los gr&aacute;ficos.",
    },
    "home-content.instructions.section-1.general.content-2":{
        "de": "Die Legende bei jeder Grafik zeigt die verfügbaren Gr&ouml;ßen an. Durch Klicken auf die Elemente der Legende lassen sich die Größen aktivieren oder deaktivieren. Ausgegraute Elemente in der Legende bedeuten, dass eine Größe (z.B. Export) gerade nicht dargestellt wird, durch Anklicken aber hinzugefügt werden kann. Weitere Bedienhinweise finden Sie bei den jeweiligen Grafiken unter »Hinweise«.",
        "en": "The legend of each graph shows the available parameters, which are activated by clicking. Greyed out elements in the legend indicate that a parameter (e.g. export) is not shown currently but can be inserted by clicking on it. Other useful hints for operation can be found next to each chart under »usage tips«.",
        "fr": "La l&eacute;gende de chaque graphique indique les donn&eacute;es mesur&eacute;es disponibles. En cliquant sur les &eacute;l&eacute;ments de la l&eacute;gende, les donn&eacute;es peuvent &ecirc;tre activ&eacute;es ou d&eacute;sactiv&eacute;es. Les &eacute;l&eacute;ments gris&eacute;s dans la l&eacute;gende signifient qu'une donn&eacute;e (par exemple l'exportation) n'est actuellement pas affich&eacute;e, mais qu'elle peut &ecirc;tre ajout&eacute;e en cliquant dessus. D'autres notes d'utilisation se trouvent dans la section &quot;Aide&quot; des graphiques respectifs.",
        "it": "La legenda di ciascun grafico riporta le grandezze disponibili, che possono essere attivate cliccandoci sopra. Gli elementi inattivi nella legenda indicano che una grandezza (per es. esportazioni) non &egrave; al momento visualizzata, ma può essere aggiunta con un clic. Trovate ulteriori istruzioni per l’uso alla voce >>Suggerimenti<<.",
        "es": "La leyenda de cada gr&aacute;fico muestra los tama&ntilde;os disponibles. Haciendo clic en los elementos de la leyenda, los tama&ntilde;os pueden activarse o desactivarse. Los elementos de la leyenda que aparecen en gris significan que un tama&ntilde;o (por ejemplo, exportaci&oacute;n) no se muestra actualmente, pero que puede a&ntilde;adirse haciendo clic sobre &eacute;l. Encontrar&aacute; m&aacute;s instrucciones de uso con los gr&aacute;ficos correspondientes en &quot;Notas&quot;.",
    },
    "home-content.instructions.section-2.headline":{
        "de": "Datenquellen",
        "en": "Data sources",
        "fr": "Source des Donn&eacute;es",
        "it": "Fonte dei dati",
        "es": "Fuentes de datos",
    },
    "home-content.instructions.section-2.ise":{
        "de": "Fraunhofer-Institut für Solare Energiesysteme ISE",
        "en": "Fraunhofer Institute for Solar Energy Systems ISE",
        "fr": "Institut Fraunhofer pour les Syst&egrave;mes Energ&eacute;tiques Solaires ISE",
        "it": "Fraunhofer-Institut für Solare Energiesysteme ISE",
        "es": "Instituto Fraunhofer de Sistemas de Energ&iacute;a Solar ISE",
    },
    "home-content.instructions.section-2.ise-url":{
        "de": "https://ise.fraunhofer.de",
        "en": "https://ise.fraunhofer.de/en.html",
        "fr": "https://ise.fraunhofer.de",
        "it": "https://ise.fraunhofer.de",
        "es": "https://ise.fraunhofer.de",
    },
    "home-content.instructions.section-2.tnc":{
        "de": "TNC Consulting AG",
        "en": "TNC Consulting AG",
        "fr": "TNC Consulting AG",
        "it": "TNC Consulting AG",
        "es": "TNC Consulting AG",
    },
    "home-content.instructions.section-2.tnc-url":{
        "de": "https://www.tnc.ch/",
        "en": "https://www.tnc.ch/",
        "fr": "https://www.tnc.ch/",
        "it": "https://www.tnc.ch/",
        "es": "https://www.tnc.ch/",
    },
    "home-content.instructions.section-2.general.content-1":{
        "de": "Die Rohdaten werden von Wissenschaftlern des ",
        "en": "The raw data are retrieved by ",
        "fr": "Les données brutes sont récupérées toutes les heures ou tous les jours par les scientifiques de ",
        "it": "I dati grezzi vengono recuperati dagli scienziati del ",
        "es": "Los científicos del ",
    },
    "home-content.instructions.section-2.general.content-2":{
        "de": "aus zahlreichen Quellen stündlich oder täglich abgerufen und für die Darstellung aufbereitet.",
        "en": "scientists from numerous sources on an hourly or daily basis and processed for presentation.",
        "fr": "auprès de nombreuses sources et sont traitées pour la représentation.",
        "it": "da numerose fonti su base oraria o giornaliera ed elaborati per la presentazione.",
        "es": "obtienen los datos brutos de numerosas fuentes cada hora o cada día y los procesan para su presentación.",
    },
    "home-content.instructions.section-2.general.content-3":{
        "de": "Für Deutschland werden die Viertelstundenwerte der Leistung mit Korrekturfaktoren so skaliert, dass deren Summe über ein Jahr gleich der offiziellen Jahresenergie ist.",
        "en": "For Germany, the quarter-hourly power values are scaled with correction factors so that their sum over a year is equal to the official annual energy.",
        "fr": "Pour l'Allemagne, les valeurs de puissance au quart d'heure sont mises à l'échelle à l'aide de facteurs de correction de manière à ce que leur somme sur une année soit égale à l'énergie annuelle officielle.",
        "it": "Per la Germania, i valori di potenza su base trimestrale sono scalati con fattori di correzione in modo che la loro somma su un anno sia uguale all'energia annuale ufficiale.",
        "es": "Para Alemania, los valores de potencia cada cuarto de hora se escalan con factores de corrección para que su suma a lo largo de un año sea igual a la energía anual oficial.",
    },  
    "home-content.instructions.section-2.general.content-4":{
        "de": "Verschiedene Grafik- und Datenformate werden zum Download zur Verfügung gestellt.",
        "en": "Various graphic and data formats are made available for download.",
        "fr": "Différents formats de graphiques et de données sont mis à disposition pour le téléchargement.",
        "it": "Sono disponibili per il download diversi formati grafici e di dati.",
        "es": "Se pueden descargar diversos formatos gráficos y de datos.",
    },         
    "home-content.instructions.section-2.CH.content-1":{
        "de": "Die Daten werden von",
        "en": "The data are compiled from various neutral sources by",
        "fr": "Les donn&eacute;es sont compil&eacute;es par",
        "it": "I dati, provenienti da diverse fonti neutrali, sono raccolti da ",
        "es": "Los datos se recogen de",
    },
    "home-content.instructions.section-2.CH.content-2":{
        "de": "und von Wissenschaftlern des",
        "en": "and scientists of",
        "fr": "et par des scientifiques de",
        "it": "e dagli scienziati del ",
        "es": "y por cient&iacute;ficos de la",
    },
    "home-content.instructions.section-2.CH.content-3":{
        "de": "aus verschiedenen neutralen Quellen zusammengestellt.",
        "en": ".",
        "fr": ".",
        "it": ".",
        "es": "recopilada de diversas fuentes neutrales.",
    },
    "blog.map": {
        "de": "src/blog/markdown/de/blogMap.json",
        "en": "src/blog/markdown/en/blogMap.json",
        "fr": "src/blog/markdown/fr/blogMap.json",
        "it": "src/blog/markdown/de/blogMap.json",
        "es": "src/blog/markdown/de/blogMap.json",
    },
    "blog.error": {
        "de": "Keine Blogbeitr&auml;ge verfügbar",
        "en": "No blog posts available",
        "fr": "Pas d'articles de blog disponibles",
        "it": "Nessun post del blog disponibile",
        "es": "No hay entradas de blog disponibles",
    },
    "blog.empty": {
        "de": "Derzeit keine ver&ouml;ffentlichten Beitr&auml;ge",
        "en": "No posts currently published",
        "fr": "Actuellement, aucun article publi&eacute;",
        "it": "Attualmente nessun articolo pubblicato",
        "es": "Ninguna contribuci&oacute;n publicada por el momento",
    },
    "explanations.sub-heading-content": {
        "de": "In allen Grafiken werden Nettoleistungen und Nettoenergien dargestellt. Bruttozahlen werden nicht dargestellt.",
        "en": "The net power production and net energies are displayed in all charts. Gross values are not displayed.",
        "fr": "Les puissances et les &eacute;nergies figurent sous la forme de donn&eacute;es nettes dans tous les graphiques. Les chiffres bruts n’y sont pas pr&eacute;sent&eacute;s.",
        "it": "In tutti i grafici, potenza ed energia vengono riportate in valori netti. Le cifre lorde non vengono visualizzate.",
        "es": "En todos los gr&aacute;ficos se muestran las producciones netas y las energ&iacute;as netas. No se muestran las cifras brutas.",
    },
    "downloads.sub-heading-1": {
        "de": "Die folgenden Diagramme stehen als pdf-Dateien, z.B. für Zitierzwecke, zur Verfügung. Die Downloads werden regelmäßig aktualisiert.",
        "en": "The following charts are available as pdf files, e.g. for citation purposes. Downloads are updated regularly.",
        "fr": "",
        "it": "I seguenti grafici sono disponibili come file pdf, ad esempio per scopi di citazione. I download sono aggiornati regolarmente.",
        "es": "Los siguientes diagramas est&aacute;n disponibles como archivos pdf, por ejemplo, para fines de citaci&oacute;n. Las descargas se actualizan peri&oacute;dicamente.",
    },
    "downloads.sub-heading-2": {
        "de": "",
        "en": "",
        "fr": "",
        "it": "",
        "es": "",
    },
    "contact.sub-heading-content": {
        "de": "Falls Sie weitere Fragen haben sollten, kontaktieren Sie bitte den verantwortlichen Verfasser dieser Webseite:",
        "en": "If you have any further questions, please do not hesitate to contact the responsible editor of this website:",
        "fr": "Pour toutes autres questions, veuillez contacter le r&eacute;dacteur responsable de ce site Internet:",
        "it": "In caso di ulteriori domande, potete rivolgervi all’autore responsabile del presente sito web:",
        "es": "Si tiene m&aacute;s preguntas, p&oacute;ngase en contacto con el autor responsable de este sitio web:",
    },
    "csv_download_not_allowed": {
        "de": "Diese Daten können aufgrund von Lizenzbedingungen nicht im CSV/XLSX-Format heruntergeladen werden.",
        "en": "Due to license conditions, this data cannot be downloaded in CSV/XLSX format.",
        "fr": "En raison des conditions de licence, ces donn&eacute;es ne peuvent pas être t&eacute;l&eacute;charg&eacute;es au format CSV/XLSX.",
        "it": "A causa delle condizioni della licenza, questi dati non possono essere scaricati in formato CSV/XLSX.",
        "es": "Estos datos no pueden descargarse en formato CSV/XLSX debido a las condiciones de la licencia.",
    },
    "csv_download_for_some_elements_not_allowed": {
        "de": "Einige der Daten können aufgrund von Lizenzbedingungen nicht im CSV/XLSX-Format heruntergeladen werden und wurden deswegen dort entfernt:",
        "en": "Some of the data cannot be downloaded in CSV/XLSX format due to license conditions and have therefore been removed there:",
        "fr": "Certaines données ne peuvent pas être téléchargées au format CSV/XLSX en raison des conditions de licence et ont donc été supprimées:",
        "it": "Alcuni dati non possono essere scaricati in formato CSV/XLSX a causa delle condizioni di licenza e sono stati quindi rimossi:"
    },
    "map.europe": {
        "de": "Europa",
        "en": "Europe",
        "fr": "Europe",
        "it": "Europa",
        "es": "Europa",
    },
    "map.system-operator": {
        "de": "Netzbetreiber",
        "en": "System Operator",
        "fr": "Gestionnaire de R&eacute;seau d'&Eacute;lectricit&eacute;",
        "it": "Operatore di Rete",
        "es": "Operador de red",
    },
    "map.blockname": {
        "de": "Block",
        "en": "Unit",
        "fr": "Unit&eacute; de Centrale &Eacute;lectrique",
        "it": "Blocco",
        "es": "Bloque",
    },
    "map.status": {
        "de": "Betriebsstatus",
        "en": "Operating Status",
        "fr": "Statut Op&eacute;rationnel",
        "it": "Stato Operativo",
        "es": "Estado de funcionamiento",
    },
    "map.active": {
        "de": "aktiv",
        "en": "active",
        "fr": "actif",
        "it": "attivo",
        "es": "activo",
    },
    "map.out-of-service-2011": {
        "de": "Stillgelegt seit 2011",
        "en": "Out of Service since 2011",
        "fr": "Ferm&eacute; depuis 2011",
        "it": "Disattivato dal 2011",
        "es": "Fuera de servicio desde 2011",
    },
    "map.out-of-service-2012": {
        "de": "Stillgelegt seit 2012",
        "en": "Out of Service since 2012",
        "fr": "Ferm&eacute; depuis 2012",
        "it": "Disattivato dal 2012",
        "es": "Fuera de servicio desde 2012",
    },
    "map.out-of-service-2013": {
        "de": "Stillgelegt seit 2013",
        "en": "Out of Service since 2013",
        "fr": "Ferm&eacute; depuis 2013",
        "it": "Disattivato dal 2013",
        "es": "Fuera de servicio desde 2013",
    },
    "map.out-of-service-2014": {
        "de": "Stillgelegt seit 2014",
        "en": "Out of Service since 2014",
        "fr": "Ferm&eacute; depuis 2014",
        "it": "Disattivato dal 2014",
        "es": "Fuera de servicio desde 2014",
    },
    "map.out-of-service-2015": {
        "de": "Stillgelegt seit 2015",
        "en": "Out of Service since 2015",
        "fr": "Ferm&eacute; depuis 2015",
        "it": "Disattivato dal 2015",
        "es": "Fuera de servicio desde 2015",
    },
    "map.out-of-service-2016": {
        "de": "Stillgelegt seit 2016",
        "en": "Out of Service since 2016",
        "fr": "Ferm&eacute; depuis 2016",
        "it": "Disattivato dal 2016",
        "es": "Fuera de servicio desde 2016",
    },
    "map.out-of-service-2017": {
        "de": "Stillgelegt seit 2017",
        "en": "Out of Service since 2017",
        "fr": "Ferm&eacute; depuis 2017",
        "it": "Disattivato dal 2017",
        "es": "Fuera de servicio desde 2017",
    },
    "map.out-of-service-2018": {
        "de": "Stillgelegt seit 2018",
        "en": "Out of Service since 2018",
        "fr": "Ferm&eacute; depuis 2018",
        "it": "Disattivato dal 2018",
        "es": "Fuera de servicio desde 2018",
    },
    "map.out-of-service-2019": {
        "de": "Stillgelegt seit 2019",
        "en": "Out of Service since 2019",
        "fr": "Ferm&eacute; depuis 2019",
        "it": "Disattivato dal 2019",
        "es": "Fuera de servicio desde 2019",
    },
    "map.legally-bound-active": {
        "de": "Gesetzlich an Stilllegung gehindert",
        "en": "Legally Bound Active",
        "fr": "Emp&ecirc;ch&eacute; L&eacute;galement de Fermer",
        "it": "Impedito dalla Legge di Chiudere",
        "es": "Impedimento legal de desmantelamiento",
    },
    "map.seasonal-conservation": {
        "de": "Saisonale Konservierung",
        "en": "Seasonal Conservation",
        "fr": "Conservation Saisonni&egrave;re",
        "it": "Conservazione Stagionale",
        "es": "Conservaci&oacute;n estacional",
    },
    "map.security-availability": {
        "de": "Sicherheitsbereitschaft",
        "en": "Security Availability",
        "fr": "Gard&eacute en R&eacuteserve pour la S&eacutecurit&eacute",
        "it": "Preparazione alla Sicurezza",
        "es": "Preparaci&oacute;n para la seguridad",
    },
    "map.special-case": {
        "de": "Sonderfall",
        "en": "Special Case",
        "fr": "Cas Particulier",
        "it": "Caso Speciale",
        "es": "Caso especial",
    },
    "map.temporal-inactivity": {
        "de": "Vorläufig Stillgelegt",
        "en": "Temporarily Inactive",
        "fr": "Ferm&eacute; Provisoirement",
        "it": "Temporaneamente Spento",
        "es": "Cierre temporal",
    },
    "map.not-active": {
        "de": "nicht Aktiv",
        "en": "not Active",
        "fr": "non Activ&eacute;",
        "it": "non Attivato",
        "es": "No activo",
    },
    "map.title": {
        "de": "Interaktive Karte | Energy-Charts",
        "en": "Interactive Map | Energy-Charts",
        "fr": "Carte Interactive| Energy-Charts",
        "it": "Mappa Interattiva | Energy-Charts",
        "es": "Mapa interactivo | Energy-Charts",
    },
    "map.headerweatherdata": {
        "de": "Meteodaten",
        "en": "Weather Data",
        "fr": "Donn&eacute;es m&eacute;t&eacute;orologiques",
        "it": "Meteodati",
        "es": "Datos meteorol&oacute;gicos",
    },
    "mainMenu.Meteodaten": {
        "de": "Meteodaten",
        "en": "Weather Data",
        "fr": "Donn&eacute;es m&eacute;t&eacute;orologiques",
        "it": "Meteodati",
        "es": "Datos meteorol&oacute;gicos",
    },
    "map.weatherdatadisplay": {
        "de": "Meteodaten-Darstellung",
        "en": "Weather Data Display",
        "fr": "Affichage des Donn&eacute;es M&eacute;t&eacute;orologiques",
        "it": "Visualizzazione dei Dati Meteo",
        "es": "Visualizaci&oacute;n de datos meteorol&oacute;gicos",
    },
    "map.solarradiation": {
        "de": "Sonneneinstrahlung",
        "en": "Solar Radiation",
        "fr": "Rayonnement Solaire",
        "it": "Radiazione Solare",
        "es": "Radiaci&oacute;n solar",
    },
    "map.daily": {
        "de": "Täglich",
        "en": "Daily",
        "fr": "Par jour",
        "it": "Quotidiano",
        "es": "Diario",
    },
    "map.monthly": {
        "de": "Monatlich",
        "en": "Monthly",
        "fr": "Par mois",
        "it": "Mensile",
        "es": "Mensualmente",
    },
    "map.annual": {
        "de": "Jährlich",
        "en": "Annual",
        "fr": "Par an",
        "it": "Annuale",
        "es": "Anual",
    },
    "map.date": {
        "de": "Datum",
        "en": "Date",
        "fr": "Date",
        "it": "Data",
        "es": "Fecha",
    },
    "map.timeinterval": {
        "de": "Zeitliches Intervall",
        "en": "Time Interval",
        "fr": "Intervalle",
        "it": "Intervallo di Tempo",
        "es": "Intervalo de tiempo",
    },
    "map.year": {
        "de": "Jahr",
        "en": "Year",
        "fr": "Ann&eacute;e",
        "it": "Anno",
        "es": "A&ntilde;o",
    },
    "map.month": {
        "de": "Monat",
        "en": "Month",
        "fr": "Mois",
        "it": "Mese",
        "es": "Mes",
    },
    "map.radiationtype": {
        "de": "Art der Strahlung",
        "en": "Type of Radiation",
        "fr": "Type de Rayonnement",
        "it": "Tipo di Radiazione",
        "es": "Tipo de radiaci&oacute;n",
    },
    "map.valuerange": {
        "de": "Wertebereich",
        "en": "Value Range",
        "fr": "Gamme de Valeurs",
        "it": "Gamma di Valori",
        "es": "Rango de valores",
    },
    "map.relative": {
        "de": "Relativ",
        "en": "Relative",
        "fr": "Relatif",
        "it": "Relativamente",
        "es": "Relativ",
    },
    "map.fixed": {
        "de": "Fix",
        "en": "Fixed",
        "fr": "Fix&#233;",
        "it": "Fissare",
        "es": "Fijar",
    },
    "map.additionalinfo": {
        "de": "Informationen zu gezeigten Daten",
        "en": "Additional Information",
        "fr": "Informations sur les Donn&eacute;es Affich&eacute;es",
        "it": "Informazioni sui Dati Mostrati",
        "es": "Informaci&oacute;n sobre los datos mostrados",
    },
    "map.wind": {
        "de": "Wind",
        "en": "Wind",
        "fr": "&Eacute;olien",
        "it": "Vento",
        "es": "Viento",
    },
    "map.temperature": {
        "de": "Temperatur",
        "en": "Temperature",
        "fr": "Temp&eacute;rature",
        "it": "Temperatura",
        "es": "Temperatura",
    },
    "map.temperature-select": {
        "de": "Temperaturdaten auswählen",
        "en": "Select Temperature Data",
        "fr": "S&eacute;lectionner les Donn&eacute;es de Temp&eacute;rature",
        "it": "Seleziona i Dati della Temperatura",
        "es": "Seleccionar datos de temperatura",
    },
    "map.temperature-minimum": {
        "de": "Temperaturminimum",
        "en": "Minimum of Temperature",
        "fr": "Temp&eacute;rature Minimale",
        "it": "Temperatura Minima",
        "es": "Temperatura m&iacute;nima",
    },
    "map.temperature-maximum": {
        "de": "Temperaturmaximum",
        "en": "Maximum of Temperature",
        "fr": "Temp&eacute;rature Maximale",
        "it": "Temperatura massima",
        "es": "Temperatura m&aacute;xima",
    },
    "map.temperature-average": {
        "de": "Temperaturdurchschnitt",
        "en": "Average of Temperature",
        "fr": "Temp&eacute;rature Moyenne",
        "it": "Media della Temperatura",
        "es": "Temperatura media",
    },
    "map.precipitation": {
        "de": "Niederschlag",
        "en": "Precipitation",
        "fr": "Pr&eacute;cipitations",
        "it": "Precipitazione",
        "es": "Precipitaci&oacute;n",
    },
    "map.drought-index": {
        "de": "Trockenheitsindex",
        "en": "Aridity Index",
        "fr": "Indice de S&eacute;cheresse",
        "it": "Indice di Secchezza",
        "es": "&Iacute;ndice de sequedad",
    },
    "map.hot-days": {
        "de": "Heiße Tage",
        "en": "Hot Days",
        "fr": "Jours de Chaleur",
        "it": "Giorni Xaldi",
        "es": "D&iacute;as calurosos",
    },
    "map.ice-days": {
        "de": "Eistage",
        "en": "Ice Days",
        "fr": "Jours de Glace",
        "it": "Giorni di Ghiaccio",
        "es": "D&iacute;as de hielo",
    },
    "map.heatmap-colors-select": {
        "de": "Heatmap-Farben auswählen",
        "en": "Select Heatmap Colors",
        "fr": "S&eacute;lectionner les Couleurs de la Carte",
        "it": "Seleziona i Colori della Mappa di Calore",
        "es": "Seleccionar colores del mapa de calor",
    },
    "map.color": {
        "de": "Farbe",
        "en": "Color",
        "fr": "Couleur",
        "it": "Colore",
        "es": "Color",
    },
    "map.measuring-stations": {
        "de": "Messstationen",
        "en": "Measuring Stations",
        "fr": "Stations de Mesure",
        "it": "Stazioni di Misura",
        "es": "Estaciones de medici&oacute;n",
    },
    "map.show-measuring-stations": {
        "de": "Messstationen anzeigen",
        "en": "Show Measuring Stations",
        "fr": "Afficher les Stations de Mesure",
        "it": "Mostra le Stazioni di Misurazione",
        "es": "Mostrar estaciones de medici&oacute;n",
    },
    "map.show-credits": {
        "de": "Credits anzeigen",
        "en": "Show Credits",
        "fr": "Afficher les Sources",
        "it": "Mostra i Crediti",
        "es": "Mostrar cr&eacute;ditos",
    },
    "map.global-radiation": {
        "de": "Globale Strahlung",
        "en": "Global Radiation",
        "fr": "Rayonnement Solaire Global",
        "it": "Radiazione Globale",
        "es": "Radiaci&oacute;n global",
    },
    "map.diffuse-radiation": {
        "de": "Diffuse Strahlung",
        "en": "Diffuse Radiation",
        "fr": "Rayonnement Solaire Diffus",
        "it": "Radiazione Diffusa",
        "es": "Radiaci&oacute;n difusa",
    },
    "map.direct-radiation": {
        "de": "Direkte Strahlung",
        "en": "Direct Radiation",
        "fr": "Rayonnement Solaire Direct",
        "it": "Radiazione Diretta",
        "es": "Radiaci&oacute;n directa",
    },
    "map.type-of-visualisation": {
        "de": "Art der Darstellung",
        "en": "Type of Visualisation",
        "fr": "Type de Pr&eacute;sentation",
        "it": "Tipo di Presentazione",
        "es": "Tipo de presentaci&oacute;n",
    },
    "map.static-heatmap": {
        "de": "Statische Heatmap",
        "en": "Static Heatmap",
        "fr": "Carte de Chaleur Statique",
        "it": "Mappa di Calore Statica",
        "es": "Mapa de calor est&aacute;tico",
    },
    "map.animated": {
        "de": "Animiert",
        "en": "Animated",
        "fr": "Anim&eacute;",
        "it": "Animato",
        "es": "Animaci&oacute;n",
    },
    "map.other-functions": {
        "de": "Weitere Funktionen",
        "en": "Other Functions",
        "fr": "Autres Fonctions",
        "it": "Altre Funzioni",
        "es": "M&aacute;s funciones",
    },
    "map.original-data-climate": {
        "de": "Basisdaten (Klima)",
        "en": "Original Data (Climate)",
        "fr": "Donn&eacute;es de Base (Climat)",
        "it": "Dati di Base (Clima)",
        "es": "Datos b&aacute;sicos (clima)",
    },
    "map.involved-institutes": {
        "de": "Beteiligte Institute",
        "en": "Involved Institutes",
        "fr": "Institutions Participantes",
        "it": "Istituti Coinvolti",
        "es": "Institutos participantes",
    },
    "map.more-info": {
        "de": "Weitere Informationen",
        "en": "More Information",
        "fr": "Autres Informations",
        "it": "Ulteriori Informazioni",
        "es": "Para m&aacute;s informaci&oacute;n",
    },
    "map.drought-index-additional-info": {
        "de": "Darstellung des Trockenheitsindex nach de Martonne.",
        "en": "Visualized is the de Martonne aridity index.",
        "fr": "Repr&eacute;sentation de l'indice de s&eacute;cheresse selon de Martonne.",
        "it": "Rappresentazione dell'indice di siccità de Martonne.",
        "es": "Representaci&oacute;n del &iacute;ndice de sequ&iacute;a seg&uacute;n de Martonne.",
    },
    "map.additional-info-hot-days": {
        "de": "Dargestellt ist die Anzahl der Tage mit einer maximalen Lufttemperatur &gt;= 30°C",
        "en": "Visualized is the number of days with a maximum temperature of &gt;= 30°C",
        "fr": "Le nombre de jours avec une temp&eacute;rature maximale de l'air &gt;= 30°C est indiqu&eacute;",
        "it": "Il numero di giorni con una temperatura massima dell'aria &gt;= 30°C &egrave; indicato.",
        "es": "Se muestra el n&uacute;mero de d&iacute;as con una temperatura m&aacute;xima del aire &amp;gt;= 30°C",
    },
    "map.additional-info-ice-days": {
        "de": "Dargestellt ist die Anzahl der Tage mit einer maximalen Lufttemperatur &lt;= 0°C",
        "en": "Visualized is the number of days with a maximum temperature of &lt;= 0°C",
        "fr": "Le nombre de jours avec une temp&eacute;rature maximale de l'air &lt;= 0°C est indiqu&eacute;",
        "it": "Mostra il numero di giorni con temperatura massima dell'aria &lt;= 0°C",
        "es": "Se muestra el n&uacute;mero de d&iacute;as con una temperatura m&aacute;xima del aire &amp;lt;= 0°C",
    },
    "map.infrastructure-display": {
        "de": "Infrastruktur-Darstellung",
        "en": "Infrastructure Display",
        "fr": "Pr&eacute;sentation des Infrastructures",
        "it": "Visualizzazione dell'Infrastruttura",
        "es": "Representaci&oacute;n de las infraestructuras",
    },
    "map.power-plant-locations": {
        "de": "Kraftwerks-Standorte",
        "en": "Power Plant Locations",
        "fr": "Carte des centrales &eacute;lectriques",
        "it": "Ubicazioni delle Centrali Elettriche",
        "es": "Ubicaci&oacute;n de las centrales el&eacute;ctricas",
    },
    "map.infrastructure": {
        "de": "Infrastruktur",
        "en": "Infrastructure",
        "fr": "Infrastructure",
        "it": "Infrastruttura",
        "es": "Infraestructura",
    },
    "mainMenu.infrastruktur": {
        "de": "Infrastruktur",
        "en": "Infrastructure",
        "fr": "Infrastructure",
        "it": "Infrastruttura",
        "es": "Infraestructura",
    },
    "map.navigation": {
        "de": "Navigation",
        "en": "Navigation",
        "fr": "Navigation",
        "it": "Navigazione",
        "es": "Navegaci&oacute;n",
    },
    "map.all-available-countries": {
        "de": "Alle verfügbaren Länder",
        "en": "All available Countries",
        "fr": "Pays Disponibles",
        "it": "Tutti i paesi Disponibili",
        "es": "Todos los pa&iacute;ses disponibles",
    },
    "map.choose-country": {
        "de": "Land wählen",
        "en": "Choose Country",
        "fr": "S&eacute;lectionner un Pays",
        "it": "Seleziona il Paese",
        "es": "Seleccione un pa&iacute;s",
    },
    "map.group-power-plants": {
        "de": "Angezeigte Kraftwerke gruppieren",
        "en": "Group Power Plants",
        "fr": "Groupement des Centrales &Eacute;lectriques Affich&eacute;es ",
        "it": "Gruppo Visualizzato Centrali Elettriche",
        "es": "Centrales el&eacute;ctricas del grupo",
    },
    "map.show-all-powerplants": {
        "de": "Alle Kraftwerke anzeigen",
        "en": "Show all Power Plants",
        "fr": "Afficher Toutes les Centrales",
        "it": "Mostra Tutte le Centrali Elettriche",
        "es": "Mostrar todas las centrales",
    },
    "map.transmission-lines": {
        "de": "Stromleitungs-Verläufe",
        "en": "Transmission Lines",
        "fr": "Lignes &Eacute;lectriques",
        "it": "Percorsi delle Linee Elettriche",
        "es": "Trazados de l&iacute;neas el&eacute;ctricas",
    },
    "map.only-for-germany": {
        "de": "Diese Funktion ist aktuell nur für Deutschland verfügbar.",
        "en": "This function is only available for Germany.",
        "fr": "Cette fonction n'est actuellement disponible que pour l'Allemagne.",
        "it": "Questa funzione &egrave; attualmente disponibile solo per la Germania.",
        "es": "Por el momento, esta funci&oacute;n s&oacute;lo est&aacute; disponible para Alemania.",
    },
    "map.show-transmission-lines": {
        "de": "Stromleitungen",
        "en": "transmission Lines",
        "fr": "Lignes &Eacute;lectriques",
        "it": "Linee Elettriche",
        "es": "L&iacute;neas el&eacute;ctricas",
    },
    "map.loadingtime-10s": {
        "de": "> 10 Sekunden Ladezeit",
        "en": "> 10 seconds Loading Time",
        "fr": "> 10 Secondes de Chargement",
        "it": "> 10 Secondi di Tempo di Caricamento",
        "es": "&gt; 10 segundos de tiempo de carga",
    },
    "map.tsos": {
        "de": "Übertragungsnetzbetreiber",
        "en": "Transmission System Operators",
        "fr": "Gestionnaire de R&eacute;seau d'&Eacute;lectricit&eacute;",
        "it": "Operatori del Sistema di Trasmissione",
        "es": "Gestor de la red de transporte",
    },
    "map.choose-map-style": {
        "de": "Kartenstil auswählen",
        "en": "Choose Map Style",
        "fr": "S&eacute;lectionner Style de la Carte",
        "it": "Seleziona lo Stile della Carta",
        "es": "Seleccionar estilo de mapa",
    },
    "map.search-functions": {
        "de": "Suchfunktionen",
        "en": "Search Functions",
        "fr": "Fonctions de Recherche",
        "it": "Funzioni di Ricerca",
        "es": "Funciones de b&uacute;squeda",
    },
    "map.misc": {
        "de": "Sonstiges",
        "en": "Miscellaneous",
        "fr": "Divers",
        "it": "Altro",
        "es": "Otros",
    },
    "map.choose-datasource": {
        "de": "Datenquelle auswählen",
        "en": "Choose Datasource",
        "fr": "S&eacute;lectionner une Source de Donn&eacute;es",
        "it": "Seleziona l'Origine dei Dati",
        "es": "Seleccionar fuente de datos",
    },
    "map.choose-state-DE": {
        "de": "Bundesland auswählen",
        "en": "Choose State",
        "fr": "S&eacute;lectionner un &eacute;tat f&eacute;d&eacute;ral",
        "it": "Seleziona lo Stato",
        "es": "Seleccionar estado",
    },
    "map.choose-state-AT": {
        "de": "Bundesland auswählen",
        "en": "Choose State",
        "fr": "S&eacute;lectionner un &eacute;tat f&eacute;d&eacute;ral",
        "it": "Seleziona lo Stato",
        "es": "Seleccionar estado",
    },
    "map.choose-state-CH": {
        "de": "Kanton auswählen",
        "en": "Choose Canton",
        "fr": "S&eacute;lectionner un Canton",
        "it": "Seleziona il Cantone",
        "es": "Seleccione cant&oacute;n",
    },
    "map.choose-state-BE": {
        "de": "Provinz auswählen",
        "en": "Choose Province",
        "fr": "S&eacute;lectionner une Province",
        "it": "Seleziona la Provincia",
        "es": "Seleccione provincia",
    },
    "map.choose-state-NL": {
        "de": "Provinz auswählen",
        "en": "Choose Province",
        "fr": "S&eacute;lectionner une province",
        "it": "Seleziona la Provincia",
        "es": "Seleccione provincia",
    },
    "map.choose-state-HU": {
        "de": "Verwaltungsbezirk auswählen",
        "en": "Choose Administrative Region",
        "fr": "S&eacute;lectionner un District Administratif",
        "it": "Seleziona il Distretto Amministrativo",
        "es": "Seleccionar distrito administrativo",
    },
    "map.choose-state-SK": {
        "de": "Landesbezirk auswählen",
        "en": "Choose Administrative Region",
        "fr": "S&eacute;lectionner un district d'&eacute;tat",
        "it": "Seleziona la Contea",
        "es": "Seleccionar provincia",
    },
    "map.locate-me": {
        "de": "Wo bin ich?",
        "en": "Locate Me!",
        "fr": "O&ugrave; suis-je ?",
        "it": "Dove sono?",
        "es": "¿D&oacute;nde estoy?",
    },
    "map.search-place": {
        "de": "Ort auf der Karte suchen",
        "en": "Search for place on the map",
        "fr": "Chercher endroit sur la carte",
        "it": "Cerca un luogo sulla mappa",
        "es": "Buscar un lugar en el mapa",
    },
    "map.search": {
        "de": "Suchen",
        "en": "Search",
        "fr": "Chercher",
        "it": "Cerca",
        "es": "Buscar en",
    },
    "map.eex": {
        "de": "Leipziger Strombörse (EEX)",
        "en": "European Energy Exchange (EEX)",
        "fr": "Bourse de l'&eacute;nergie de Leipzig  (EEX)",
        "it": "Borsa Elettrica di Lipsia (EEX)",
        "es": "Bolsa de Leipzig (EEX)",
    },
    "map.bnetza": {
        "de": "Bundesnetzagentur (BNetzA)",
        "en": "Federal Network Agency (BNetzA)",
        "fr": "Agence f&eacute;d&eacute;rale des r&eacute;seaux (BNetzA)",
        "it": "Agenzia Fderale delle Reti (BNetzA)",
        "es": "Agencia Federal de Redes (BNetzA)",
    },
    "map.swiss": {
        "de": "BFE / BAFU",
        "en": "SFOE / FOEN",
        "fr": "OFEN / OFEV",
        "it": "UFE / UFAM",
        "es": "BFE / BAFU",
    },
    "map.swiss-long": {
        "de": "Schweizer Bundesamt für Energie (BFE) / Bundesamt für Umwelt (BAFU)",
        "en": "Swiss Federal Office of Energy (SFOE) / Federal Office for the Environment (FOEN)",
        "fr": "Office f&#233;d&#233;ral de l&#39;&#233;nergie (OFEN) / Office f&#233;d&#233;ral de l&#39;environnement (OFEV)",
        "it": "Ufficio federale dell'energia (UFE) / Ufficio federale dell'ambiente (UFAM)",
        "es": "Oficina Federal de la Energ&iacute;a (OFEE) / Oficina Federal del Medio Ambiente (OFMA)",
    },
    "map.solar": {
        "de": "Solar",
        "en": "Solar",
        "fr": "Solaire",
        "it": "Solare",
        "es": "Solar",
    },
    "map.mine_gas": {
        "de": "Grubengas",
        "en": "Mine Gas",
        "fr": "Gaz de Mine",
        "it": "Gas della Miniera",
        "es": "Gas de mina",
    },
    "map.fossil_coal-derived_gas": {
        "de": "Grubengas",
        "en": "Mine Gas",
        "fr": "Grubengas",
        "it": "Grubengas",
        "es": "Gas de mina",
    },
    "map.fossil_oil": {
        "de": "&Ouml;l",
        "en": "Oil",
        "fr": "P&eacute;trole",
        "it": "Olio",
        "es": "Aceite",
    },
    "oil": {
        "de": "&Ouml;l",
        "en": "Oil",
        "fr": "P&eacute;trole",
        "it": "Olio",
        "es": "Aceite",
    },
    "map.fossil_oil_shale": {
        "de": "&Ouml;lschiefer",
        "en": "Oil Shale",
        "fr": "Huile de Schiste",
        "it": "Olio di Ardesia",
        "es": "&amp;Ouml;l pizarra",
    },
    "map.fossil_peat": {
        "de": "Torf",
        "en": "Peat",
        "fr": "Tourbe",
        "it": "Torba",
        "es": "Torf",
    },
    "map.geothermal": {
        "de": "Geothermal",
        "en": "Geothermal",
        "fr": "G&eacute;othermie",
        "it": "Geotermico",
        "es": "Geotermia",
    },
    "map.marine": {
        "de": "Meeresenergie",
        "en": "Marine",
        "fr": "&Eacute;nergie Maritime",
        "it": "Energia dell'Oceano",
        "es": "Energ&iacute;a oce&aacute;nica",
    },
    "map.fossil_gas": {
        "de": "Gas",
        "en": "Gas",
        "fr": "Gaz",
        "it": "Gas",
        "es": "Gas",
    },
    "gas": {
        "de": "Gas",
        "en": "Gas",
        "fr": "Gaz",
        "it": "Gas",
        "es": "Gas",
    },
    "gas_base": {
        "de": "Gas (Base)",
        "en": "Gas (Base)",
        "fr": "Gaz (Base)",
        "it": "Gas (Base)",
        "es": "Gas (Base)",
    },
    "map.nuclear": {
        "de": "Kernenergie",
        "en": "Nuclear",
        "fr": "Nucl&eacute;aire",
        "it": "Energia Nucleare",
        "es": "Energ&iacute;a nuclear",
    },
    "map.fossil_hard_coal": {
        "de": "Steinkohle",
        "en": "Coal",
        "fr": "Houille",
        "it": "Carbone Duro",
        "es": "Hulla",
    },
    "map.hydro_run-of-river": {
        "de": "Laufwasser",
        "en": "Run of the River",
        "fr": "Hydraulique fil de l'eau",
        "it": "Acqua Corrente",
        "es": "Agua corriente",
    },
    "hydro_run_of_river": {
        "de": "Laufwasser",
        "en": "Run of the River",
        "fr": "Hydraulique fil de l'eau",
        "it": "Acqua Corrente",
        "es": "Agua corriente",
    },
    "map.hydro_pumped_storage": {
        "de": "Pumpspeicher",
        "en": "Pumped Storage",
        "fr": "Stockage par Pompage",
        "it": "Stoccaggio Pompato",
        "es": "Almacenamiento por bombeo",
    },
    "map.waste": {
        "de": "M&uuml;ll",
        "en": "Waste",
        "fr": "D&eacute;chets",
        "it": "Spazzatura",
        "es": "Basura",
    },
    "map.biomass": {
        "de": "Biomasse",
        "en": "Biomass",
        "fr": "Biomasse",
        "it": "Biomassa",
        "es": "Biomassa",
    },
    "map.hydro_water_reservoir": {
        "de": "Speicherwasser",
        "en": "Water Reservoir",
        "fr": "Eau de Stockage",
        "it": "Acqua di Stoccaggio",
        "es": "Agua almacenada",
    },
    "map.seasonal-store": {
        "de": "Speicherwasser",
        "en": "Water Reservoir",
        "fr": "Eau de Stockage",
        "it": "Acqua di stoccaggio",
        "es": "Agua almacenada",
    },

    "map.fossil_brown_coal_lignite": {
        "de": "Braunkohle",
        "en": "Lignite",
        "fr": "Lignite",
        "it": "Lignite",
        "es": "Lignito",
    },
    "map.wind_onshore": {
        "de": "Wind Onshore",
        "en": "Wind Onshore",
        "fr": "&Eacute;olien Terrestre",
        "it": "Eolico Onshore",
        "es": "E&oacute;lica terrestre",
    },
    "map.wind_offshore": {
        "de": "Wind Offshore",
        "en": "Wind Offshore",
        "fr": "&Eacute;olien en Mer",
        "it": "Eolico Offshore",
        "es": "E&oacute;lica marina",
    },
    "map.uranium": {
        "de": "Atomkraft",
        "en": "Uranium",
        "fr": "Nucl&eacute;aire",
        "it": "Nucleare",
        "es": "energ&iacute;a nuclear",
    },
    "map.other": {
        "de": "Sonstige",
        "en": "Other",
        "fr": "Autres",
        "it": "Altro",
        "es": "Otros",
    },
    "map.other_renewable": {
        "de": "Sonstige Erneuerbar",
        "en": "Other Renewable",
        "fr": "Autres &eacute;nergies Renouvelables",
        "it": "Altre Rinnovabili",
        "es": "Otras energ&iacute;as renovables",
    },
    "map.renewable": {
        "de": "Erneuerbare",
        "en": "Renewable",
        "fr": "Renouvelables",
        "it": "Rinnovabile",
        "es": "Renovable",
    },
    "map.fossil": {
        "de": "Nicht Erneuerbare / Fossile",
        "en": "Non-Renewable / Fossil",
        "fr": "Non renouvelable / Fossile",
        "it": "Non rinnovabile / Fossile",
        "es": "No renovables / F&oacute;siles",
    },
    "map.power-sources": {
        "de": "Energien",
        "en": "Power Sources",
        "fr": "Sources d'&Eacute;nergie",
        "it": "Energies",
        "es": "Energ&iacute;as",
    },
    "map.view": {
        "de": "Anzeigen",
        "en": "View",
        "fr": "Afficher",
        "it": "Vedi",
        "es": "Ver",
    },
    "map.list": {
        "de": "Liste",
        "en": "List",
        "fr": "Liste",
        "it": "Lista",
        "es": "Lista",
    },
    "map.power-plants": {
        "de": "Kraftwerke",
        "en": "Power Plants",
        "fr": "Centrales &Eacute;lectriques",
        "it": "Centrali Elettriche",
        "es": "Centrales el&eacute;ctricas",
    },
    "map.name": {
        "de": "Name",
        "en": "Name",
        "fr": "Nom",
        "it": "Nome",
        "es": "Nombre",
    },
    "map.compare-pp-info": {
        "de": "Für den Vergleich bei den Energy-Charts auswählen.",
        "en": "Select for comparison in the Energy-Charts.",
        "fr": "S&eacute;lectionnez pour comparaison &agrave; l'Energy-Charts.",
        "it": "Selezionare per il confronto all'Energy-Charts.",
        "es": "Seleccionar para comparar en los Energy-Charts.",
    },
    "map.compare-prod-data": {
        "de": "Produktionsdaten der ausgewählten Kraftwerke vergleichen.",
        "en": "Compare production data of selected power plants.",
        "fr": "Comparer les donn&eacute;es de production des centrales &eacute;lectriques s&eacute;lectionn&eacute;es.",
        "it": "Confrontare i dati di produzione delle centrali elettriche selezionate.",
        "es": "Comparar los datos de producci&oacute;n de las centrales seleccionadas.",
    },
    "map.info-power-plants-checkboxes": {
        "de": "Bitte ein oder mehrere Kraftwerke mittels der Checkboxen in der Liste auswählen.",
        "en": "Please select one or multiple power plants via the checkboxes in the list.",
        "fr": "Veuillez s&eacute;lectionner une ou plusieurs centrales &eacute;lectriques en utilisant les cases &agrave; cocher dans la liste.",
        "it": "Si prega di selezionare una o più centrali elettriche utilizzando le caselle di controllo nella lista.",
        "es": "Seleccione una o varias centrales utilizando las casillas de verificaci&oacute;n de la lista.",
    },
    "map.multiple-pp-at-same-loc": {
        "de": "Mehrere produktionsfremde Kraftwerke/Blöcke am selben Standort.",
        "en": "Multiple different power plants or blocks at the same location.",
        "fr": "Plusieurs centrales/unit&eacute;s au m&ecirc;me site.",
        "it": "Più centrali/unit&agrave; non di produzione sullo stesso sito.",
        "es": "Varias centrales/unidades no productivas en el mismo lugar.",
    },
    "map.info-which-pp-listed": {
        "de": "Gelistet werden Kraftwerke, die im jeweiligen Land liegen oder an denen Konzerne des jeweiligen Landes Beteiligungen halten. Teils sind die Kraftwerke am Ort der Firmenzentrale gemeldet.",
        "en": "Listed are power plants that are located in the respective country or in which corporations of the respective country hold interests. In some cases, the power plants are registered at the location of the company headquarters.",
        "fr": "Sont list&eacute;es les centrales &eacute;lectriques situ&eacute;es dans le pays concern&eacute; ou dans lesquelles des soci&eacute;t&eacute;s du pays concern&eacute; d&eacute;tiennent des parts. Certaines centrales sont enregistr&eacute;es sur le site du si&egrave;ge de l'entreprise.",
        "it": "Sono elencate le centrali elettriche che si trovano nel rispettivo paese o in cui le corporazioni del rispettivo paese detengono azioni. Alcune delle centrali sono registrate presso la sede della societ&agrave;.",
        "es": "En la lista figuran las centrales el&eacute;ctricas situadas en el pa&iacute;s respectivo o en las que participan empresas del pa&iacute;s respectivo. Algunas de las centrales el&eacute;ctricas est&aacute;n registradas en la sede de la empresa.",
    },
    "map.source": {
        "de": "Quelle",
        "en": "Source",
        "fr": "Source",
        "it": "Fonte",
        "es": "Esos",
    },
    "map.company": {
        "de": "Betreiber",
        "en": "Company",
        "fr": "Gestionnaire",
        "it": "Operatore",
        "es": "Operador",
    },
    "map.capacity": {
        "de": "Kapazit&auml;t",
        "en": "Capacity",
        "fr": "Capacit&eacute;",
        "it": "Capacità",
        "es": "Capacidad",
    },
    "startdate": {
        "de": "Beginn",
        "en": "Start Date",
        "fr": "D&eacute;but des op&eacute;rations",
        "it": "Iniziare",
        "es": "Inicio",
    },
    "map.enddate": {
        "de": "Laufzeitende",
        "en": "End Date",
        "fr": "Fin des op&eacute;rations",
        "it": "Fine del termine",
        "es": "Fin de plazo",
    },
    "map.tso": {
        "de": "Übertragungs&shy;netzbetreiber",
        "en": "TSO",
        "fr": "Gestionnaire du r&eacute;seau &eacute;lectrique",
        "it": "Operatori del sistema di trasmissione",
        "es": "Gestores de redes de transporte",
    },
    "map.info-icon-power-plants-click": {
        "de": "Icon anklicken, um alle Kraftwerke zu sehen.",
        "en": "Click on the icon to see all power plants.",
        "fr": "Cliquez sur l'ic &ocirc;ne pour voir toutes les centrales &eacute;lectriques.",
        "it": "Clicca sull'icona per vedere tutte le centrali.",
        "es": "Haga clic en el icono para ver todas las centrales.",
    },
    "map.voltage": {
        "de": "Spannung",
        "en": "Voltage",
        "fr": "Voltage",
        "it": "Tensione",
        "es": "Tensi&oacute;n",
    },
    "map.cables": {
        "de": "Leiterseile",
        "en": "Cables",
        "fr": "C &acirc;bles",
        "it": "Corde per scale",
        "es": "Cables conductores",
    },
    "map.wires": {
        "de": "Adern",
        "en": "Wires",
        "fr": "fils",
        "it": "Core",
        "es": "N&uacute;cleos",
    },
    "map.frequency": {
        "de": "Frequenz",
        "en": "Frequency",
        "fr": "Fr&eacute;quence",
        "it": "Frequenza",
        "es": "Frecuencia",
    },
    "map.single": {
        "de": "einfach",
        "en": "Single",
        "fr": "simple",
        "it": "semplicemente",
        "es": "simplemente",
    },
    "map.double": {
        "de": "zweifach",
        "en": "Double",
        "fr": "double",
        "it": "doppio",
        "es": "doble",
    },
    "map.triple": {
        "de": "dreifach",
        "en": "Triple",
        "fr": "triple",
        "it": "triplo",
        "es": "triple",
    },
    "map.quad": {
        "de": "vierfach",
        "en": "Quad",
        "fr": "quadruple",
        "it": "quadruplo",
        "es": "cu&aacute;druple",
    },
    "map.no-data": {
        "de": "Keine Daten vorhanden.",
        "en": "No Data available.",
        "fr": "Pas de donn&eacute;es disponibles.",
        "it": "Nessun dato disponibile.",
        "es": "No hay datos disponibles.",
    },
    "map.no-regions": {
        "de": "Keine Regionen verfügbar",
        "en": "No Regions available",
        "fr": "Pas de r&eacute;gions disponibles",
        "it": "Nessuna regione disponibile",
        "es": "No hay regiones disponibles",
    },
    "map.recommended-for": {
        "de": "Empfohlen für",
        "en": "Recommended for",
        "fr": "Recommand&eacute; pour",
        "it": "Raccomandato per",
        "es": "Recomendado para",
    },
    "map.less-suitable-for": {
        "de": "Weniger geeignet für",
        "en": "Less suitable for",
        "fr": "Moins appropri&eacute; pour",
        "it": "Meno adatto per",
        "es": "Menos adecuado para",
    },
    "map.black-white-map": {
        "de": "Schwarzweiße Karte",
        "en": "Black and White Map",
        "fr": "Carte en noir et blanc",
        "it": "Mappa in bianco e nero",
        "es": "Mapa en blanco y negro",
    },
    "map.street-map": {
        "de": "Straßenkarte",
        "en": "Street Map",
        "fr": "Carte routi&egrave;re",
        "it": "Mappa stradale",
        "es": "Mapa de carreteras",
    },
    "map.terrain-map": {
        "de": "Geländekarte",
        "en": "Terrain Map",
        "fr": "Carte du terrain",
        "it": "Mappa del terreno",
        "es": "Mapa del terreno",
    },
    "map.overlapping-display": {
        "de": "Überlagerte Darstellungen",
        "en": "Overlapping Display Types",
        "fr": "Repr&eacute;sentations superpos&eacute;es",
        "it": "Rappresentazioni sovrapposte",
        "es": "Representaciones superpuestas",
    },
    "map.flat-land": {
        "de": "Flachland",
        "en": "Flat Land",
        "fr": "Pays plat",
        "it": "Terra piatta",
        "es": "Tierras bajas",
    },
    "map.mountains": {
        "de": "Gebirge",
        "en": "Mountains",
        "fr": "Montagnes",
        "it": "Montagna",
        "es": "Monta&ntilde;as",
    },
    "map.power-line-courses": {
        "de": "Stromleitungs-Verläufe",
        "en": "Power Line Courses",
        "fr": "Cours des lignes &eacute;lectriques",
        "it": "Corsi di alimentazione",
        "es": "Trazados de l&iacute;neas el&eacute;ctricas",
    },
    "map.prod": {
        "de": "Produktion",
        "en": "Production",
        "fr": "production",
        "it": "produzione",
        "es": "Producci&oacute;n",
    },
    "map.cmp": {
        "de": "Vgl.",
        "en": "Cmp.",
        "fr": "Comparer",
        "it": "Confrontare",
        "es": "Vgl.",
    },
    "map.find": {
        "de": "Finden",
        "en": "Find",
        "fr": "Trouver",
        "it": "Trovare",
        "es": "Encuentre",
    },
    "map.area-display": {
        "de": "Gebiet anzeigen",
        "en": "Area Display",
        "fr": "Afficher zone",
        "it": "Area di visualizzazione",
        "es": "Zona de espect&aacute;culos",
    },
    "map.detail": {
        "de": "Detailgrad",
        "en": "Detail",
        "fr": "Degr&eacute; de d&eacute;tail",
        "it": "Livello di dettaglio",
        "es": "Nivel de detalle",
    },
    "map.regions": {
        "de": "Bundesländer",
        "en": "Regions",
        "fr": "&Eacute;tats f&eacute;d&eacute;raux",
        "it": "Stati",
        "es": "Estados federales",
    },
    "map.cities": {
        "de": "Städte",
        "en": "Cities",
        "fr": "Villes",
        "it": "Città",
        "es": "Ciudades",
    },
    "map.urban-districts": {
        "de": "Stadtkreise",
        "en": "Urban Districts",
        "fr": "Quartiers de la ville",
        "it": "Città-distretti",
        "es": "Distritos urbanos",
    },
    "map.title_combined_markers": {
        "de": "Icon anklicken, um alle Kraftwerke zu sehen.",
        "en": "Click on the icon to see all power plants.",
        "fr": "Cliquez sur l'ic &ocirc;ne pour voir toutes les centrales &eacute;lectriques.",
        "it": "Clicca sull'icona per visualizzare tutte le centrali",
        "es": "Haga clic en el icono para ver todas las centrales.",
    },
    "map.powerplant-data": {
        "de": "Kraftwerksdaten",
        "en": "Power Plant Data",
        "fr": "Donn&eacute;es sur les centrales &eacute;lectriques",
        "it": "Dati sulla centrale elettrica",
        "es": "Datos de la central",
    },
    "map.latest-update": {
        "de": "letzte Aktualisierung",
        "en": "Latest Update",
        "fr": "Derni &egrave;re mise &agrave; jour",
        "it": "ultimo aggiornamento",
        "es": "&uacute;ltima actualizaci&oacute;n",
    },
    "map.sources": {
        "de": "Quellen",
        "en": "Sources",
        "fr": "Sources",
        "it": "Fonti",
        "es": "Fuentes",
    },
    "map.tooltip-valuerange": {
        "de": "Relativ: Die Farbskala der Heatmap wird mit dem jeweiligen minimalen und maximalen Wert des aktuell ausgewählten Zeitraums erstellt. <br><br> Fix: Die Farbskala der Heatmap ist fix und unabhängig vom aktuell ausgewählten Zeitraum.",
        "en": "Relative: The color scale of the heat map is created with the respective minimum and maximum value of the currently selected period.<br> <br>Fix: The color scale of the heatmap is fixed and independent of the currently selected period.",
        "fr": "Relative: l'&eacute;chelle de couleur de la carte est cr&eacute;&eacute;e avec les valeurs minimales et maximales respectives de la p&eacute;riode de temps actuellement s&eacute;lectionn&eacute;.<br><br> Fix&#233;: L'&eacute;chelle de couleur de la carte est fixe et ind&eacute;pendante de la p&eacute;riode actuellement s&eacute;lectionn&eacute;e.",
        "it": "Relativo: la scala di colore della heatmap viene creata utilizzando il rispettivo valore minimo e massimo del periodo di tempo attualmente selezionato. <br><br> Fisso: La scala di colore della heatmap &egrave; fissa e indipendente dal periodo di tempo attualmente selezionato",
        "es": "Relativa: La escala de colores del mapa de calor se crea con los respectivos valores m&iacute;nimo y m&aacute;ximo del periodo de tiempo seleccionado actualmente. &lt;br&gt;&lt;br&gt; Fijo: la escala de colores del mapa de calor es fija e independiente del periodo de tiempo seleccionado actualmente.",
    },
    "map.station-name": {
        "de": "Stationsname",
        "en": "Station Name",
        "fr": "Nom de la station",
        "it": "Nome della stazione",
        "es": "nombre de la estaci&oacute;n",
    },
    "map.region": {
        "de": "Bundesland",
        "en": "Region",
        "fr": "&Eacute;tat f&eacute;d&eacute;ral",
        "it": "Stato",
        "es": "Estado",
    },
    "map.latitude": {
        "de": "Geografische Breite",
        "en": "Latitude",
        "fr": "Latitude",
        "it": "Latitudine",
        "es": "Latitud",
    },
    "map.longitude": {
        "de": "Geografische Länge",
        "en": "Longitude",
        "fr": "Longitude",
        "it": "Longitudine",
        "es": "Longitud geogr&aacute;fica",
    },
    "map.station_height": {
        "de": "Stationshöhe",
        "en": "Station Height",
        "fr": "Altitude",
        "it": "Altezza della stazione",
        "es": "Altura de la estaci&oacute;n",
    },
    "map.measurement": {
        "de": "Messwert",
        "en": "Measurement",
        "fr": "Valeur mesur&eacute;e",
        "it": "Valore misurato",
        "es": "Valor medido",
    },
    "map.link-test-station-data": {
        "de": "Link zu Stationsdaten",
        "en": "Link to Station Measurements",
        "fr": "Lien vers les donn&eacute;es de la station",
        "it": "Link ai dati della stazione",
        "es": "Enlace a los datos de la estaci&oacute;n",
    },
    "map.toast-no-data-header": {
        "de": "Keine Daten",
        "en": "No Data",
        "fr": "Pas de donn&eacute;es",
        "it": "Nessun dato",
        "es": "Sin datos",
    },
    "map.toast-no-data-body": {
        "de": "Für diese Auswahl sind aktuell keine Daten verfügbar.",
        "en": "No data currently available for this selection.",
        "fr": "Pas de donn&eacute;es disponibles actuellement pour cette s&eacute;lection.",
        "it": "Non sono attualmente disponibili dati per questa selezione.",
        "es": "Actualmente no se dispone de datos para esta selecci&oacute;n.",
    },
    "map.value": {
        "de": "Wert",
        "en": "Value",
        "fr": "Valeur",
        "it": "Valore",
        "es": "Valor",
    },
    "map.unit_name": {
        "de": "Block",
        "en": "Unit Name",
        "fr": "Unit&eacute;",
        "it": "Blocca",
        "es": "Bloque",
    },
    "swiss-countries.zh": {
        "de": "Z&#252;rich",
        "en": "Zurich",
        "fr": "Zurich",
        "it": "Zurigo",
        "es": "Z&amp;#252;rico",
    },
    "swiss-countries.be": {
        "de": "Bern",
        "en": "Bern",
        "fr": "Berne",
        "it": "Berna",
        "es": "Berna",
    },
    "swiss-countries.gr": {
        "de": "Graubünden",
        "en": "Graubünden",
        "fr": "Grisons",
        "it": "Grigioni",
        "es": "Grisones",
    },
    "swiss-countries.vs": {
        "de": "Wallis",
        "en": "Wallis",
        "fr": "Valais",
        "it": "Vallese",
        "es": "Wallis",
    },
    "swiss-countries.vd": {
        "de": "Waadt",
        "en": "Waadt",
        "fr": "Vaud",
        "it": "Vaud",
        "es": "Wade",
    },
    "swiss-countries.ti": {
        "de": "Tessin",
        "en": "Tessin",
        "fr": "Tessin",
        "it": "Ticino",
        "es": "Tessin",
    },
    "swiss-countries.sg": {
        "de": "St. Gallen",
        "en": "St. Gallen",
        "fr": "Saint-Gall",
        "it": "San Gallo",
        "es": "San Gall",
    },
    "swiss-countries.fr": {
        "de": "Freiburg",
        "en": "Fribourg",
        "fr": "Fribourg",
        "it": "Friburgo",
        "es": "Friburgo",
    },
    "swiss-countries.lu": {
        "de": "Luzern",
        "en": "Lucerne",
        "fr": "Lucerne",
        "it": "Lucerna",
        "es": "Lucerna",
    },
    "swiss-countries.ag": {
        "de": "Aargau",
        "en": "Aargau",
        "fr": "Argovie",
        "it": "Aargau",
        "es": "Argovia",
    },
    "swiss-countries.ur": {
        "de": "Uri",
        "en": "Uri",
        "fr": "Uri",
        "it": "Uri",
        "es": "Uri",
    },
    "swiss-countries.tg": {
        "de": "Thurgau",
        "en": "Thurgau",
        "fr": "Thurgovie",
        "it": "Thurgau",
        "es": "Thurgau",
    },
    "swiss-countries.sz": {
        "de": "Schwyz",
        "en": "Schwyz",
        "fr": "Schwyz",
        "it": "Schwyz",
        "es": "Schwyz",
    },
    "swiss-countries.ju": {
        "de": "Jura",
        "en": "Jura",
        "fr": "Jura",
        "it": "Jura",
        "es": "Jura",
    },
    "swiss-countries.ne": {
        "de": "Neuenburg",
        "en": "Neuch&#226;tel",
        "fr": "Neuch&#226;tel",
        "it": "Neuchâtel",
        "es": "Neuchâtel",
    },
    "swiss-countries.so": {
        "de": "Solothurn",
        "en": "Solothurn",
        "fr": "Soleure",
        "it": "Soletta",
        "es": "Solothurn",
    },
    "swiss-countries.gl": {
        "de": "Glarus",
        "en": "Glarus",
        "fr": "Glaris",
        "it": "Glarus",
        "es": "Glarus",
    },
    "swiss-countries.bl": {
        "de": "Basel-Landschaft",
        "en": "Basel-Country",
        "fr": "B&#226;le-Ville",
        "it": "Basel-Landschaft",
        "es": "Basilea-Landschaft",
    },
    "swiss-countries.ow": {
        "de": "Obwalden",
        "en": "Obwalden",
        "fr": "Obwald",
        "it": "Obwalden",
        "es": "Obwalden",
    },
    "swiss-countries.sh": {
        "de": "Schaffhausen",
        "en": "Schaffhausen",
        "fr": "Schaffhouse",
        "it": "Schaffhausen",
        "es": "Schaffhausen",
    },
    "swiss-countries.ge": {
        "de": "Genf",
        "en": "Geneva",
        "fr": "Gen&#232;ve",
        "it": "Ginevra",
        "es": "Ginebra",
    },
    "swiss-countries.nw": {
        "de": "Nidwalden",
        "en": "Nidwalden",
        "fr": "Nidwald",
        "it": "Nidwalden",
        "es": "Nidwalden",
    },
    "swiss-countries.ar": {
        "de": "Appenzell Ausserrhoden",
        "en": "Appenzell Ausserrhoden",
        "fr": "Appenzell Rhodes-Ext&#233;rieures",
        "it": "Appenzell Ausserrhoden",
        "es": "Appenzell Rodas Exteriores",
    },
    "swiss-countries.zg": {
        "de": "Zug",
        "en": "Zug",
        "fr": "Zoug",
        "it": "Zug",
        "es": "Zug",
    },
    "swiss-countries.ai": {
        "de": "Appenzell Innerrhoden",
        "en": "Appenzell Innerrhoden",
        "fr": "Appenzell Rhodes-Int&#233;rieures",
        "it": "Appenzell Innerrhoden",
        "es": "Appenzell Rodas Interiores",
    },
    "swiss-countries.bs": {
        "de": "Basel-Stadt",
        "en": "Basel-City",
        "fr": "B&#226;le-Ville",
        "it": "Basilea Città",
        "es": "Basilea-Ciudad",
    },

    "map.tooltip-radiationtype": {
        "de": "Globalstrahlung: Die gesamte an der Erdoberfläche auf eine horizontale Empfangsfläche auftreffende Solarstrahlung. (Summe aus Direkt- und Diffusstrahlung) <br><br>Direktstrahlung: auf direktem Weg eintreffende Solarstrahlung <br><br>Diffusstrahlung: durch Teilchen (z.B. Wolken) gestreutes Licht",
        "en": "Global radiation: The entire solar radiation that hits a horizontal receiving surface on the earth's surface. (Sum of direct and diffuse radiation) <br> <br> Direct radiation: solar radiation arriving directly <br> <br> Diffuse radiation: light scattered by particles (e.g. clouds)",
        "fr": "Rayonnement global: tout le rayonnement solaire qui frappe une surface r&#233;ceptrice horizontale &#224; la surface de la terre. (Somme du rayonnement direct et diffus) <br> <br> Rayonnement direct: rayonnement solaire arrivant directement <br> <br> Rayonnement diffus: lumi&#232;re diffus&#233;e par des particules (par exemple des nuages)",
        "it": "Radiazione globale: l'intera radiazione solare che colpisce una superficie ricevente orizzontale sulla superficie terrestre. (Somma della radiazione diretta e diffusa) <br> <br> Radiazione diretta: radiazione solare in arrivo diretto <br> <br> Radiazione diffusa: luce diffusa da particelle (es. Nuvole)",
        "es": "Radiaci&oacute;n global: la radiaci&oacute;n solar total que incide sobre la superficie terrestre en una superficie receptora horizontal. (Suma de radiaci&oacute;n directa y difusa) &lt;br&gt;&lt;br&gt;Radiaci&oacute;n directa: radiaci&oacute;n solar que llega directamente &lt;br&gt;&lt;br&gt;Radiaci&oacute;n difusa: luz dispersada por part&iacute;culas (por ejemplo, nubes).",
    },

    "map.height_above_ground": {
        "de": "H&ouml;he &uuml;ber Grund",
        "en": "Height above Ground",
        "fr": "Hauteur au-dessus du sol",
        "it": "Altezza dal suolo",
        "es": "H&amp;ouml;he &amp;uuml;ber suelo",
    },

    "map.copyright_copernicus": {
        "de": "Generiert mit Informationen vom Copernicus Climate Change Service",
        "en": "Generated with Copernicus Climate Change Service information",
        "fr": "G&#233;n&#233;r&#233; avec les informations du Copernicus Climate Change Service",
        "it": "Generato con le informazioni di Copernicus Climate Change Service",
        "es": "Elaborado con informaci&oacute;n del Servicio de Cambio Clim&aacute;tico de Copernicus",
    },

    "map.solar_info": {
        "de": "Bei den gezeigten Werten handelt es sich um durchschnittliche Tageswerte.",
        "en": "The values shown are average daily values.",
        "fr": "Les valeurs affich&#233;es sont des valeurs quotidiennes moyennes.",
        "it": "I valori visualizzati sono valori medi giornalieri.",
        "es": "Los valores indicados son valores medios diarios.",
    },

    "map.range": {
        "de": "Bereich: ",
        "en": "Range: ",
        "fr": "S&#233;lection: ",
        "it": "Selezione: ",
        "es": "Alcance:",
    },

    //========= Simulation translations =============
    "simulation": {
        "de": "Simulation",
        "en": "Simulation",
        "fr": "Simulation",
        "it": "Simulazione",
        "es": "Simulaci&oacute;n",
    },

    "power_simulation_desc": {
        "de": "Mit dem Simulator kann mit einfachen Mitteln der weitere Ausbau der erneuerbaren Energiequellen Wind und Solar simuliert werden. Für die Simulationen kann jede beliebige Woche von 2020 und 2021 als Basis benutzt werden. Die historischen Leistungen werden mit einem Skalierungsfaktor multipliziert, um die simulierten Leistungen zu erhalten. Der Skalierungsfaktor berechnet sich aus dem Verhältnis der simulierten installierten Leistung zur tatsächlich installierten Leistung in der ausgewählen Woche. Der komplette Berechnungsalgorithmus läuft im Browser, so dass er schnell reagiert und keinen Netzwerktraffic verursacht. Damit er auch in mobilen Endgeräten noch schnell genug rechnet, wurde er stark vereinfacht und kann nicht alle aktuellen und zukünftigen Anforderungen der Praxis korrekt abdecken.",
        "en": "With the simulator, the further expansion of the renewable energy sources wind and solar can be simulated with simple means. Any week between 2020 and 2021 can be used as a basis for the simulations. The historical performances are multiplied with a scaling factor to get the simulated performances. The scaling factor is calculated from the ratio of the simulated installed capacity to the actually installed capacity in the selected week. The complete calculation algorithm runs in the browser so that it reacts quickly and does not cause any network traffic. So that it can still calculate quickly enough in mobile devices, it has been greatly simplified and cannot correctly cover all current and future practical requirements.",
        "fr": "Avec le simulateur, la poursuite de l'expansion des sources d'&eacute;nergie renouvelables &eacute;olienne et solaire peut être simul&eacute;e avec des moyens simples. Toute semaine entre 2020 et 2021 peut être utilis&eacute;e comme base pour les simulations. Les performances historiques sont multipli&eacute;es par un facteur d'&eacute;chelle pour obtenir les performances simul&eacute;es. Le facteur d'&eacute;chelle est calcul&eacute; à partir du rapport entre la capacit&eacute; install&eacute;e simul&eacute;e et la capacit&eacute; r&eacute;ellement install&eacute;e au cours de la semaine s&eacute;lectionn&eacute;e. L'algorithme de calcul complet s'ex&eacute;cute dans le navigateur afin qu'il r&eacute;agisse rapidement et ne provoque aucun trafic r&eacute;seau. Afin qu'il puisse encore calculer assez rapidement dans les appareils mobiles, il a &eacute;t&eacute; consid&eacute;rablement simplifi&eacute; et ne peut pas couvrir correctement toutes les exigences pratiques actuelles et futures.",
        "it": "Con il simulatore &egrave; possibile simulare con mezzi semplici l'ulteriore espansione delle fonti di energia rinnovabile eolica e solare. Qualsiasi settimana tra il 2020 e il 2021 può essere utilizzata come base per le simulazioni. Le performance storiche vengono moltiplicate con un fattore di scala per ottenere le performance simulate. Il fattore di scala &egrave; calcolato dal rapporto tra la capacità installata simulata e la capacità effettivamente installata nella settimana selezionata. L'algoritmo di calcolo completo viene eseguito nel browser in modo che reagisca rapidamente e non causi traffico di rete. Affinch&eacute; possa ancora calcolare abbastanza rapidamente nei dispositivi mobili, &egrave; stato notevolmente semplificato e non può coprire correttamente tutti i requisiti pratici attuali e futuri.",
        "es": "Con el simulador se puede simular con medios sencillos la futura expansi&oacute;n de las fuentes de energ&iacute;a renovables e&oacute;lica y solar. Cualquier semana de 2020 y 2021 puede utilizarse como base para las simulaciones. Los resultados hist&oacute;ricos se multiplican por un factor de escala para obtener los resultados simulados. El factor de escala se calcula a partir de la relaci&oacute;n entre la potencia instalada simulada y la potencia instalada real en la semana seleccionada. El algoritmo de c&aacute;lculo completo se ejecuta en el navegador, por lo que reacciona r&aacute;pidamente y no provoca tr&aacute;fico de red. Para que siga calculando con suficiente rapidez en dispositivos m&oacute;viles, se ha simplificado mucho y no puede cubrir correctamente todos los requisitos pr&aacute;cticos actuales y futuros.",
    },

    "simulation_parameters": {
        "de": "Simulationsparameter",
        "en": "Simulation Parameters",
        "fr": "Param&egrave;tres de Simulation",
        "it": "Parametri di Simulazione",
        "es": "Par&aacute;metros de simulaci&oacute;n",
    },

    "simulation.solar_wind": {
        "de": "Solar und Wind",
        "en": "Solar and wind",
        "fr": "Solaire et &eacute;olien",
        "it": "Solare e vento",
        "es": "Solar y e&oacute;lica",
    },

    "simulation.solar_wind_explanation": {
        "de": "Die installierten Leistungen können linear skaliert werden. Mit den Skalierungsfaktoren werden die Viertelstundenwerte der Leistung berechnet. Eventuelle Wetter- oder Klimaänderungen werden bei der Simulation der Stromerzeugung nicht berücksichtigt.",
        "en": "The installed power can be scaled linearly. The quarter-hourly power values are calculated using the scaling factors. Any weather or climate changes are not taken into account when simulating electricity generation.",
        "fr": "La puissance install&eacute;e peut être mise à l'&eacute;chelle lin&eacute;airement. Les valeurs de puissance par quart d'heure sont calcul&eacute;es à l'aide des facteurs d'&eacute;chelle. Les changements m&eacute;t&eacute;orologiques ou climatiques ne sont pas pris en compte lors de la simulation de la production d'&eacute;lectricit&eacute;.",
        "it": "La potenza installata può essere scalata linearmente. I valori di potenza trimestrali vengono calcolati utilizzando i fattori di scala. Eventuali cambiamenti climatici o meteorologici non vengono presi in considerazione durante la simulazione della generazione di elettricità.",
        "es": "Las potencias instaladas pueden escalarse linealmente. Los factores de escala se utilizan para calcular los valores trimestrales de la potencia. Los posibles cambios meteorol&oacute;gicos o clim&aacute;ticos no se tienen en cuenta en la simulaci&oacute;n de la generaci&oacute;n de energ&iacute;a.",
    },

    "simulation.hydro_biomass": {
        "de": "Wasserkraft und Biomasse",
        "en": "Hydropower and biomass",
        "fr": "Hydro&eacute;lectricit&eacute; et biomasse",
        "it": "Energia idroelettrica e biomasse",
        "es": "Energ&iacute;a hidroel&eacute;ctrica y biomasa",
    },

    "simulation.hydro_biomass_explanation": {
        "de": "Diese Quellen können nicht skaliert werden, da kein weiterer Ausbau zu erwarten ist.",
        "en": "These sources cannot be scaled as no further expansion is to be expected.",
        "fr": "Ces sources ne peuvent pas être mises à l'&eacute;chelle car aucune autre expansion n'est à pr&eacute;voir.",
        "it": "Queste fonti non possono essere ridimensionate in quanto non &egrave; prevista un'ulteriore espansione.",
        "es": "Estas fuentes no pueden ampliarse, ya que no se espera que sigan creciendo.",
    },

    "simulation.load_explanation": {
        "de": "Die Skalierung der jährlichen Last erfolgt linear. Mit dem Skalierungsfaktor werden die Viertelstundenwerte der Last berechnet. Mögliche zukünftige Lastverschiebungen werden nicht berücksichtigt.",
        "en": "The annual load is scaled linearly. The quarter-hourly values of the load are calculated with the scaling factor. Possible future load shifts are not taken into account.",
        "fr": "La charge annuelle est mise à l'&eacute;chelle lin&eacute;airement. Les valeurs quarts d'heure de la charge sont calcul&eacute;es avec le facteur d'&eacute;chelle. Les &eacute;ventuels transferts de charge futurs ne sont pas pris en compte.",
        "it": "Il carico annuale viene scalato linearmente. I valori trimestrali del carico sono calcolati con il fattore di scala. Eventuali futuri spostamenti di carico non vengono presi in considerazione.",
        "es": "El escalado de la carga anual es lineal. El factor de escala se utiliza para calcular los valores trimestrales de la carga. No se tienen en cuenta los posibles desplazamientos futuros de la carga.",
    },

    "simulation.short_term_storage": {
        "de": "Kurzzeitspeicher",
        "en": "Short-term storage",
        "fr": "Stockage à court terme",
        "it": "Archiviazione a breve termine",
        "es": "Memoria a corto plazo",
    },

    "simulation.short_term_storage_explanation": {
        "de": "Batterien und Pumpspeicher sind zu Kurzzeitspeichern zusammengefasst. Sie sorgen für einen untertägigen Ausgleich zwischen Erzeugung und Verbrauch. Es wird angenommen, dass im Kurzzeitbereich die elektrische Speicherung günstiger ist als eine Langzeitspeicherung über Wasserstoff oder andere synthetische Gase oder Flüssigkeiten.",
        "en": "Batteries and pumped storage are combined to form short-term storage. They ensure an intra-day balance between generation and consumption. It is assumed that in the short term, electrical storage is cheaper than long-term storage using hydrogen or other synthetic gases or liquids.",
        "fr": "Les batteries et le stockage pomp&eacute; sont combin&eacute;s pour former un stockage à court terme. Ils assurent un &eacute;quilibre intra-journalier entre production et consommation. On suppose qu'à court terme, le stockage &eacute;lectrique est moins cher que le stockage à long terme utilisant de l'hydrog&egrave;ne ou d'autres gaz ou liquides synth&eacute;tiques.",
        "it": "Le batterie e l'accumulo con pompaggio vengono combinati per formare uno stoccaggio a breve termine. Garantiscono un equilibrio infragiornaliero tra generazione e consumo. Si presume che a breve termine l'accumulo elettrico sia più economico dello stoccaggio a lungo termine utilizzando idrogeno o altri gas o liquidi sintetici.",
        "es": "Las bater&iacute;as y la acumulaci&oacute;n por bombeo se combinan para formar instalaciones de almacenamiento a corto plazo. Proporcionan un equilibrio intradiario entre generaci&oacute;n y consumo. Se supone que, a corto plazo, el almacenamiento el&eacute;ctrico es m&aacute;s barato que el almacenamiento a largo plazo mediante hidr&oacute;geno u otros gases o l&iacute;quidos sint&eacute;ticos.",
    },

    "simulation.additional_power_generation": {
        "de": "Zusatzstromerzeugung",
        "en": "Additional power generation",
        "fr": "Production d'&eacute;nergie suppl&eacute;mentaire",
        "it": "Produzione di energia aggiuntiva",
        "es": "Generaci&oacute;n de energ&iacute;a auxiliar",
    },

    "simulation.additional_power_generation_explanation": {
        "de": "Die Zusatzstromerzeugung sollte in der Zukunft von fossilen zu erneuerbaren Quellen übergehen. Sie beteiligt sich nicht am untertägigen Ausgleich, um die installierte Leistung zu minimieren und die Volllaststunden zu erhöhen.",
        "en": "The additional generation of electricity should switch from fossil to renewable sources in the future. It does not participate in the within-day compensation in order to minimize the installed capacity and increase the full-load hours.",
        "fr": "La production d'&eacute;lectricit&eacute; suppl&eacute;mentaire devrait à l'avenir passer des sources fossiles aux sources renouvelables. Il ne participe pas à la compensation intra-journali&egrave;re afin de minimiser la capacit&eacute; install&eacute;e et d'augmenter les heures à pleine charge.",
        "it": "La generazione di elettricità aggiuntiva dovrebbe passare dalle fonti fossili a quelle rinnovabili in futuro. Non partecipa alla compensazione infragiornaliera al fine di ridurre al minimo la capacità installata e aumentare le ore a pieno carico.",
        "es": "La generaci&oacute;n el&eacute;ctrica suplementaria deber&iacute;a pasar de fuentes f&oacute;siles a renovables en el futuro. No participa en el equilibrio intradiario para minimizar la capacidad instalada y aumentar las horas de plena carga.",
    },

    "simulation.electrolysis": {
        "de": "Elektrolyse",
        "en": "Electrolysis",
        "fr": "&eacute;lectrolyse",
        "it": "Elettrolisi",
        "es": "Electr&oacute;lisis",
    },

    "simulation.electrolysis_explanation": {
        "de": "Elektrolyseure werden aufgrund ihrer hohen Investitionskosten so gefahren, dass sie möglichst viele Volllaststunden erreichen. Sie beteiligen sich nicht am untertägigen Ausgleich zwischen Erzeugung und Last.",
        "en": "Due to their high investment costs, electrolysers are operated in such a way that they achieve as many full-load hours as possible. They do not participate in the intraday balance between generation and load.",
        "fr": "En raison de leurs coûts d'investissement &eacute;lev&eacute;s, les &eacute;lectrolyseurs fonctionnent de mani&egrave;re à atteindre autant d'heures à pleine charge que possible. Ils ne participent pas à l'&eacute;quilibre intrajournalier entre production et charge.",
        "it": "A causa dei loro elevati costi di investimento, gli elettrolizzatori vengono utilizzati in modo tale da raggiungere il maggior numero possibile di ore a pieno carico. Non partecipano all'equilibrio infragiornaliero tra generazione e carico.",
        "es": "Debido a sus elevados costes de inversi&oacute;n, los electrolizadores funcionan de forma que alcancen el mayor n&uacute;mero posible de horas de plena carga. No participan en el equilibrio intradiario entre generaci&oacute;n y carga.",
    },

    "simulation.explanation_for_sim_params": {
        "de": "Erl&auml;uterungen zu den Simulationsparametern",
        "en": "Explanation of the simulation parameters",
        "fr": "Explications sur les param&egrave;tres de simulation",
        "it": "Spiegazione dei parametri di simulazione",
        "es": "Explicaci&oacute;n de los par&aacute;metros de simulaci&oacute;n",
    },

    "simulation.short_term_storage_generation": {
        "de": "Kurzzeitspeicher Erzeugung",
        "en": "Short-term storage generation",
        "fr": "Production de stockage à court terme",
        "it": "Generazione di storage a breve termine",
        "es": "Almacenamiento a corto plazo Generaci&oacute;n",
    },

    "simulation.short_term_storage_consumption": {
        "de": "Kurzzeitspeicher Verbrauch",
        "en": "Short-term storage consumption",
        "fr": "Consommation de stockage à court terme",
        "it": "Consumo di stoccaggio a breve termine",
        "es": "Consumo de memoria a corto plazo",
    },
    "map.tooltip-global": {
        "de": "Die gesamte an der Erdoberfläche auf eine horizontale Empfangsfläche auftreffende Solarstrahlung. (Summe aus Direkt- und Diffusstrahlung) ",
        "en": "The entire solar radiation that hits a horizontal receiving surface on the earth's surface. (Sum of direct and diffuse radiation) ",
        "fr": "tout le rayonnement solaire qui frappe une surface r&#233;ceptrice horizontale &#224; la surface de la terre. (Somme du rayonnement direct et diffus) ",
        "it": "l'intera radiazione solare che colpisce una superficie ricevente orizzontale sulla superficie terrestre. (Somma della radiazione diretta e diffusa)",
        "es": "Radiaci&oacute;n solar total que incide sobre una superficie receptora horizontal en la superficie terrestre. (Suma de la radiaci&oacute;n directa y difusa).",
    },
    "map.tooltip-direct" : {
        "de": "Auf direktem Weg eintreffende Solarstrahlung",
        "en": "solar radiation arriving directly",
        "fr": "rayonnement solaire arrivant directement",
        "it": "radiazione solare in arrivo diretto",
        "es": "Radiaci&oacute;n solar directa",
    },
    "map.tooltip-diffuse": {
        "de": "Durch Teilchen (z.B. Wolken) gestreutes Licht",
        "en": "Light scattered by particles (e.g. clouds)",
        "fr": "Lumi&#232;re diffus&#233;e par des particules (par exemple des nuages)",
        "it": "luce diffusa da particelle (es. Nuvole)",
        "es": "Luz dispersada por part&iacute;culas (por ejemplo, nubes)",
    },
    "map.wind10m_static": {
        "de": "10 Meter über Grund, statisch",
        "en": "10 metres above ground, static",
        "fr": "10 m&egrave;tres au-dessus du sol, statique",
        "it": "10 metri dal suolo, statico",
        "es": "10 metros sobre el suelo, est&aacute;tico",
    },
    "map.wind10m_animated" : {
        "de": "10 Meter über Grund, animiert",
        "en": "10 metres above ground, animated",
        "fr": "10 m&egrave;tres au-dessus du sol, animer",
        "it": "10 metri dal suolo, animare",
        "es": "10 metros sobre el suelo, animado",
    },
    "map.wind100m_static" : {
        "de": "100 Meter über Grund, statisch",
        "en": "100 metres above ground, static",
        "fr": "100 m&egrave;tres au-dessus du sol, statique",
        "it": "100 metri dal suolo, statico",
        "es": "100 metros sobre el suelo, est&aacute;tico",
    },
    "map.wind100m_animated" : {
        "de": "100 Meter über Grund, animiert",
        "en": "100 metres above ground, animated",
        "fr": "100 m&egrave;tres au-dessus du sol, animer",
        "it": "100 metri dal suolo, animare",
        "es": "100 metros sobre el suelo, animado",
    },
    "map.wind_10m" : {
        "de": "Wind 10 Meter über Grund",
        "en": "Wind 10 metres above ground",
        "fr": "Vent à 10 m&egrave;tres au-dessus du sol",
        "it": "Vento a 10 metri dal suolo",
        "es": "Viento a 10 metros del suelo",
    },
    "map.wind_100m" : {
        "de": "Wind 100 Meter über Grund",
        "en": "Wind 100 metres above ground",
        "fr": "Vent à 100 m&egrave;tres au-dessus du sol",
        "it": "Vento a 100 metri dal suolo",
        "es": "Viento a 100 metros del suelo",
    },
    "map.color-scale" : {
        "de": "Farbskala ",
        "en": "Color scale ",
        "fr": "&eacute;chelle de couleurs ",
        "it": "Scala dei colori ",
        "es": "Tinte",
    },
    "map.tooltip_valuerange_fixed" : {
        "de": "Die Skala umspannt einen vordefinierten Bereich, der mittels des untenstehenden Sliders verändert werden kann. Sie ist unabhängig vom aktuell ausgewählten Datensatz.",
        "fr": "L'&eacute;chelle couvre une plage pr&eacute;d&eacute;finie qui peut être modifi&eacute;e à l'aide du curseur ci-dessous. Il est ind&eacute;pendant de l'ensemble de donn&eacute;es actuellement s&eacute;lectionn&eacute;.",
        "it": "La scala si estende su un intervallo predefinito che può essere modificato utilizzando il cursore sottostante. &egrave; indipendente dal set di dati attualmente selezionato.",
        "en": "The scale spans a predefined range that can be changed using the slider below. It is independent of the currently selected data set.",
        "es": "La escala abarca un intervalo predefinido que puede modificarse mediante el control deslizante situado debajo. Es independiente del conjunto de datos seleccionado.",
    },
    "map.tooltip-valuerange-relative" : {
        "de": "Die Farbskala der Heatmap wird mit dem jeweiligen minimalen und maximalen Wert des aktuell ausgewählten Zeitraums erstellt.",
        "en": "The color scale of the heat map is created with the respective minimum and maximum value of the currently selected period.",
        "fr": "l'&eacute;chelle de couleur de la carte est cr&eacute;&eacute;e avec les valeurs minimales et maximales respectives de la p&eacute;riode de temps actuellement s&eacute;lectionn&eacute;.",
        "it": "la scala di colore della heatmap viene creata utilizzando il rispettivo valore minimo e massimo del periodo di tempo attualmente selezionato.",
        "es": "La escala de colores del mapa de calor se crea con el valor m&iacute;nimo y m&aacute;ximo respectivo del periodo seleccionado en ese momento.",
    },
    "map.pollutants" : {
        "de": "Schadstoffe",
        "en": "Pollutants",
        "fr": "Polluants",
        "it": "inquinanti",
        "es": "Contaminantes",
    },
    "no2": {
        "de": "Stickstoffdioxid (NO<sub>2</sub>)",
        "en": "Nitrogen dioxide (NO<sub>2</sub>)",
        "fr": "Dioxyde d'azote (NO<sub>2</sub>)",
        "it": "Biossido di azoto (NO<sub>2</sub>)",
        "es": "Di&oacute;xido de nitr&oacute;geno (NO&lt;sub&gt;2&lt;/sub&gt;)",
    },
    "ch4" : {
        "de": "Methan (CH<sub>4</sub>)",
        "en": "Methane (CH<sub>4</sub>)",
        "fr": "M&eacute;thane (CH<sub>4</sub>)",
        "it": "Metano (CH<sub>4</sub>)",
        "es": "Metano (CH&lt;sub&gt;4&lt;/sub&gt;)",
    },
    "co" : {
        "de": "Kohlenstoffmonoxid (CO)",
        "en": "Carbon monoxide (CO)",
        "fr": "Monoxyde de carbone (CO)",
        "it": "Monossido di carbonio (CO)",
        "es": "Mon&oacute;xido de carbono (CO)",
    },
    "aer" : {
        "de": "Aerosole",
        "en": "Aerosols",
        "fr": "A&eacute;rosols",
        "it": "Aerosol",
        "es": "Aerosole",
    },
    "map.wind_display" : {
        "de": "Windanzeige ",
        "en": "wind display",
        "fr": "Indicateur de vent ",
        "it": "Indicatore del vento ",
        "es": "Indicador de viento",
    },
    "off" : {
        "de": "Ausgeschaltet",
        "en": "turned off",
        "fr": "D&eacute;sactiv&eacute;",
        "it": "Spento",
        "es": "Apagado",
    },
    "arrows" : {
        "de": "Windpfeile",
        "en": "Windarrows",
        "fr": "Fl&egrave;ches du vent",
        "it": "Frecce del vento",
        "es": "Flechas de viento",
    },
    "map.tooltip-powerplantSource-eex" : {
        "de": "<b>EEX</b> Bei der Leipziger Strombörse (EEX) sind alle Kraftwerke gelistet, die eine Leistung von mehr als 100MW produzieren sowie alle, die freiwillig melden.",
        "en": "<b>EEX</b> The Leipzig Power Exchange (EEX) lists all power plants that produce a capacity of more than 100MW as well as all those that report voluntarily.",
        "fr": "<b>EEX</b> La bourse de l'&eacute;nergie de Leipzig (EEX) r&eacute;pertorie toutes les centrales &eacute;lectriques qui produisent une capacit&eacute; de plus de 100MW ainsi que toutes celles qui se d&eacute;clarent volontairement.",
        "it": "<b>EEX</b> La borsa elettrica di Lipsia (EEX) elenca tutte le centrali elettriche che producono una capacit&agrave; di pi&ugrave; di 100MW cos&igrave; come tutte quelle che segnalano volontariamente.",
        "es": "&lt;b&gt;EEX&lt;/b&gt; En la Bolsa de Electricidad de Leipzig (EEX) cotizan todas las centrales el&eacute;ctricas que producen una potencia superior a 100 MW y todas las que informan voluntariamente.",
    },
    "map.tooltip-powerplantSource-bnetza": {
        "de": "<b>BNetzA</b> Kraftwerksliste der Bundesnetzagentur, Kraftwerke mit einer Nennleistung von mindestens 10 MW",
        "en": "<b>BNetzA</b> Power plant list of the Federal Network Agency, power plants with a nominal capacity of at least 10 MW",
        "fr": "<b>BNetzA</b> Liste des centrales &eacute;lectriques de l'Agence f&eacute;d&eacute;rale des r&eacute;seaux, centrales &eacute;lectriques d'une capacit&eacute; nominale d'au moins 10 MW.",
        "it": "<b>BNetzA</b> Elenco delle centrali elettriche dell'Agenzia federale delle reti, centrali elettriche con una capacit&agrave; nominale di almeno 10 MW",
        "es": "&lt;b&gt;BNetzA&lt;/b&gt; Lista de centrales el&eacute;ctricas de la Agencia Federal de Redes, centrales el&eacute;ctricas con una potencia nominal de al menos 10 MW",
    },
    "map.tooltip-powerplantSource-swisslist": {
        "de": "<b>BFE / BAFU</b> Das BFE ist das Bundesamt für Energie der Schweiz, das BAFU das Bundesamt für Umwelt der Schweiz",
        "en": "<b>SFOE / FOEN</b> The SFOE is the Swiss Federal Office of Energy, the FOEN is the Swiss Federal Office for the Environment",
        "fr": "<b>OFEN / OFEV</b> L'OFEN est l'Office f&eacute;d&eacute;ral de l'&eacute;nergie, l'OFEV l'Office f&eacute;d&eacute;ral de l'environnement.",
        "it": "<b>UFE / UFAM</b> L'UFE &egrave; l'Ufficio federale dell'energia, l'UFAM l'Ufficio federale dell'ambiente",
        "es": "&lt;b&gt;FEO / FOEN&lt;/b&gt; La SFOE es la Oficina Federal Suiza de la Energ&iacute;a, la FOEN es la Oficina Federal Suiza del Medio Ambiente.",
    },
    "map.tooltip-powerplantSource-mastr": {
        "de": "<b>Marktstammdatenregister</b> Das Marktstammdatenregister ist das Register f&uuml;r den deutschen Strom- und Gasmarkt. Im MaStR sind vor allem die Stammdaten zu Strom- und Gaserzeugungsanlagen registriert.",
        "en": "<b>Marktstammdatenregister</b> The Market Master Data Register is the register for the German electricity and gas market. The MaStR primarily registers the master data for electricity and gas generation plants.",
        "fr": "<b>Marktstammdatenregister</b> Le registre des donn&eacute;es de base du march&eacute; est le registre pour le march&eacute; allemand de l'&eacute;lectricit&eacute; et du gaz. Le MaStR enregistre principalement les donn&eacute;es de base relatives aux installations de production d'&eacute;lectricit&eacute; et de gaz.",
        "it": "<b>Marktstammdatenregister</b> Il Market Master Data Register &egrave; il registro per il mercato tedesco dell'elettricit&agrave; e del gas. Il MaStR registra principalmente i dati principali degli impianti di produzione di elettricit&agrave; e gas.",
        "es": "&lt;b&gt;Registro de Datos Maestros del Mercado&lt;/b&gt; El Registro de Datos Maestros del Mercado es el registro del mercado alem&aacute;n de la electricidad y el gas. El MaStR registra principalmente los datos maestros de las centrales de generaci&oacute;n de electricidad y gas.",
    },

    "map.source-bnetza" : {
        "de": "Quelle: Bundesnetzagentur (BNetzA)",
        "en": "Source: Federal Network Agency (BNetzA)",
        "fr": "Source: Agence f&eacute;d&eacute;rale des r&eacute;seaux (BNetzA)",
        "it": "Fonte: Agenzia Federale delle Reti (BNetzA)",
        "es": "Fuente: Agencia Federal de Redes (BNetzA)",
    },
    "map.source-eex": {
        "de": "Quelle: Leipziger Strombörse (EEX)",
        "en": "Source: European Energy Exchange (EEX)",
        "fr": "Source: Bourse de l'&eacute;nergie de Leipzig  (EEX)",
        "it": "Fonte: Borsa Elettrica di Lipsia (EEX)",
        "es": "Fuente: Leipzig Power Exchange (EEX)",
    },
    "map.source-swisslist" : {
        "de": "Quelle: BFE / BAFU",
        "en": "Source: SFOE / FOEN",
        "fr": "Source: OFEN / OFEV",
        "it": "Fonte: UFE / UFAM",
        "es": "Aquellos: BFE / BAFU",
    },
    "map.source-mastr": {
        "de": "Quelle: Marktstammdatenregister",
        "en": "Source: Marktstammdatenregister",
        "fr": "Source: Marktstammdatenregister",
        "it": "Fonte: Marktstammdatenregister",
        "es": "Fuente: Registro de Datos Maestros de Mercado",
    },
    "map.scale" : {
        "de": "Skala ",
        "en": "Scale ",
        "fr": "S&eacute;chelle ",
        "it": "Scala ",
        "es": "Escala",
    },
    "map.pollutantdisplay": {
        "de": "Schadstoff-Darstellung",
        "en": "Pollutant representation",
        "fr": "Repr&eacute;sentation du polluant",
        "it": "Rappresentazione dell'inquinante",
        "es": "Representaci&oacute;n de contaminantes",
    },
    "map.high_values": {
        "de": "Farbe für hohe Werte",
        "en": "Color for high values",
        "fr": "Couleur pour les valeurs &eacute;lev&eacute;es",
        "it": "Colore per valori alti",
        "es": "Color para valores altos",
    },
    "map.middle_values": {
        "de": "Farbe für mittlere Werte",
        "en": "Color for medium values",
        "fr": "Couleur pour les valeurs moyennes",
        "it": "Colore per valori medi",
        "es": "Color para valores medios",
    },
    "map.low_values": {
        "de": "Farbe für niedrige Werte",
        "en": "Color for low values",
        "fr": "Couleur pour les valeurs faibles",
        "it": "Colore per valori bassi",
        "es": "Color para valores bajos",
    },
    "map.geojson-data": {
        "de": "Staaten- und Ländergrenzen",
        "en": "State and country borders",
        "fr": "Fronti&egrave;res des &Eacute;tats et des pays",
        "it": "Confini di stato e di paese",
        "es": "Fronteras estatales y nacionales",
    },
    "animation": {
        "de": "Animation",
        "en": "Animation",
        "fr": "Animation",
        "it": "Animazione",
        "es": "Animaci&oacute;n",
    },
    "unknown": {
        "de": "Unbekannt",
        "en": "Unknown",
        "fr": "inconnu",
        "it": "sconosciuto",
        "es": "Desconocido",
    },
    "map.adaptable": {
        "de": "Adaptierbar",
        "en": "Adaptable",
        "fr": "Adaptable",
        "it": "Adattabile",
        "es": "Adaptable",
    },
    "map.predefined": {
        "de": "Voreingestellt",
        "en": "Predefined",
        "fr": "Pr&eacute;r&eacute;glage",
        "it": "Preset",
        "es": "Preestablecido",
    },
    "getLatestDataset": {
        "de": "Neuesten Datensatz für diese Quelle und das gewählte Zeitintervall anzeigen",
        "en": "Show most recent record for this source and the selected time interval",
        "fr": "Affiche le jeu de donn&eacute;es le plus r&eacute;cent pour cette source et l'intervalle de temps s&eacute;lectionn&eacute;",
        "it": "Mostra il record pi&ugrave; recente per questa sorgente e l'intervallo di tempo selezionato",
        "es": "Mostrar el &uacute;ltimo registro de esta fuente y el intervalo de tiempo seleccionado",
    },
    "noCurrentData": {
        "de": "Es wurden keine aktuellen Daten für diese Quelle gefunden",
        "en": "No current data found for this source",
        "fr": "Aucune donn&eacute;e actuelle n'a &eacute;t&eacute; trouv&eacute;e pour cette source",
        "it": "Nessun dato attuale trovato per questa fonte",
        "es": "No se han encontrado datos actuales sobre esta fuente",
    },
    "lettering": {
        "de": "Beschriftung",
        "en": "Lettering",
        "fr": "&Eacute;tiquetage",
        "it": "Etichettatura",
        "es": "Etiquetado",
    },
    "show_labels": {
        "de": "Beschriftung einblenden",
        "en": "Show lettering",
        "fr": "L&eacute;gende du spectacle",
        "it": "Mostra didascalia",
        "es": "Mostrar pie de foto",
    },
    "chartconfig": {
        "de": "Konfiguration",
        "en": "Configuration",
        "fr": "Configuration",
        "it": "Configurazione",
        "es": "Configuraci&oacute;n",
    },
    "other": {
        "de": "Sonstiges",
        "en": "Other",
        "fr": "Autre",
        "it": "Altro",
        "es": "Otros",
    },
    "timeslider": {
        "de": "Zeitleiste anzeigen",
        "en": "Show timeline",
        "fr": "Afficher la chronologie",
        "it": "Mostra la cronologia",
        "es": "Mostrar cronolog&iacute;a",
    },
    "enableStepping": {
        "de": "Stufendiagramm",
        "en": "Step diagram",
        "fr": "Diagramme d'&eacute;tape",
        "it": "Diagramma del passo",
        "es": "Diagrama de etapas",
    },
    "map.hydro": {
        "de": "Wasserkraft",
        "en": "water power",
        "fr": "&Eacute;nergie hydraulique",
        "it": "potenza dell'acqua",
        "es": "Energ&iacute;a hidroel&eacute;ctrica",
    },
    "map.fossil_coal": {
        "de": "Kohle",
        "en": "Coal",
        "fr": "Charbon",
        "it": "Carbone",
        "es": "Carb&oacute;n",
    },
    "map.gas": {
        "de": "Gas",
        "en": "Gas",
        "fr": "Gaz",
        "it": "Gas",
        "es": "Gas",
    },
    "map.oil": {
        "de": "&Ouml;l",
        "en": "Oil",
        "fr": "Huile",
        "it": "Olio",
        "es": "Aceite",
    },
    "map.commissioning": {
        "de": "Inbetriebnahme",
        "en": "Commissioning",
        "fr": "Mise en service",
        "it": "Commissioning",
        "es": "Puesta en servicio",
    },
    "map.est_gen_gwh": {
        "de": "Geschätzte Erzeugung",
        "en": "Estimated generation",
        "fr": "Production estim&eacute;e",
        "it": "Generazione stimata",
        "es": "Producci&oacute;n estimada",
    },
    "map.group-power-plants-category": {
        "de": "Angezeigte Kraftwerke nach Produktionsart gruppieren",
        "en": "Group displayed power plants by production type",
        "fr": "Regroupement des centrales affich&eacute;es selon le type de production",
        "it": "Raggruppa le centrali visualizzate in base al tipo di produzione",
        "es": "Agrupar las centrales mostradas seg&uacute;n el tipo de producci&oacute;n",
    },
    "highest_voltage": {
        "de": "Höchstspannung",
        "en": "Highest voltage",
        "fr": "Tension la plus &eacute;lev&eacute;e",
        "it": "Tensione pi&ugrave; alta",
        "es": "Tensi&oacute;n m&aacute;xima",
    },
    "high_voltage": {
        "de": "Hochspannung",
        "en": "High voltage",
        "fr": "Haute tension",
        "it": "Alta tensione",
        "es": "Alta tensi&oacute;n",
    },
    "medium_voltage": {
        "de": "Mittelspannung",
        "en": "Medium voltage",
        "fr": "Moyenne tension",
        "it": "Media tensione",
        "es": "Media tensi&oacute;n",
    },
    "low_voltage": {
        "de": "Niederspannung",
        "en": "Low voltage",
        "fr": "Basse tension",
        "it": "Bassa tensione",
        "es": "Baja tensi&oacute;n",
    },
    "iso_voltage": {
        "de": "Gleichspannung",
        "en": "DC voltage",
        "fr": "Voltage DC",
        "it": "Tensione DC",
        "es": "Tensi&oacute;n continua",
    },
    "map.circuits": {
        "de": "Stromkreise",
        "en": "circuits",
        "fr": "circuits",
        "it": "circuiti",
        "es": "Circuitos",
    },
    "map.operator": {
        "de": "Betreiber",
        "en": "Operator",
        "it": "Operatore",
        "fr": "Op&eactute;rateur",
        "es": "Operador",
    },
    "map.phases": {
        "de": "Phasen",
        "en": "Phases",
        "it": "Fasi",
        "fr": "Phases",
        "es": "Fases",
    },
    "map.note": {
        "de": "Anmerkung",
        "en": "note",
        "it": "Nota",
        "fr": "Note",
        "es": "Nota",
    },
    "map.location": {
        "de": "Standort",
        "en": "Location",
        "it": "posizione",
        "fr": "emplacement",
        "es": "Ubicaci&oacute;n",
    },
    "map.no_data_geojson": {
        "de": "Für das ausgewählte Land sind aktuell keine Daten verfügbar",
        "en": "No data currently available for the selected country",
        "it": "Nessun dato &egrave; attualmente disponibile per il paese selezionato",
        "fr": "Aucune donn&acute;e n'est actuellement disponible pour le pays s&eacute;lectionn&eacute;",
        "es": "No hay datos disponibles actualmente para el pa&iacute;s seleccionado",
    },
    "map.choose-state": {
        "de": "Verwaltungsbezirk auswählen",
        "en": "Select administrative district",
        "fr": "S&eacute;lectionnez le district administratif",
        "it": "Seleziona il distretto amministrativo",
        "es": "Seleccionar distrito administrativo",
    },
    "map.pipeline-courses": {
        "de": "Pipeline-Verläufe",
        "en": "Pipeline routes",
        "fr": "Trac&eacute;s des pipelines",
        "it": "Percorsi dei gasdotti",
        "es": "Rutas de oleoductos",
    },
    "map.tsos_only_germany": {
        "de": "TSO-Daten sind aktuell nur für Deutschland verfügbar",
        "en": "TSO data are currently only available for Germany",
        "fr": "Les donn&eacute;es des GRT ne sont actuellement disponibles que pour l'Allemagne",
        "it": "Dati TSO attualmente disponibili solo per la Germania",
        "es": "Los datos de los GRT s&oacute;lo est&aacute;n disponibles para Alemania",
    },
    "voltage": {
        "de": "Spannung",
        "en": "Voltage",
        "it": "Voltaggio",
        "fr": "Voltage",
        "es": "Tensi&oacute;n",
    },
    "wires": {
        "de": "Kabel",
        "en": "Cables",
        "fr": "C&acirc;ble",
        "it": "Cavo",
        "es": "Cable",
    },
    "cables": {
        "de": "Kabel",
        "en": "Cables",
        "fr": "C&acirc;ble",
        "it": "Cavo",
        "es": "Cable",
    },
    "name": {
        "de": "Name",
        "en": "name",
        "fr": "Nom",
        "it": "Nome",
        "es": "Nombre",
    },
    "operator": {
        "de": "Betreiber",
        "en": "Operator",
        "fr": "Op&eacute;rateur",
        "it": "Operatore",
        "es": "Operador",
    },
    "location": {
        "de": "Standort",
        "en": "location",
        "it": "Posizione",
        "fr": "Localisation",
        "es": "Ubicaci&oacute;n",
    },
    "frequency": {
        "de": "Frequenz",
        "en": "Frequency",
        "fr": "Fr&eacute;quence",
        "it": "Frequenza",
        "es": "Frecuencia",
    },
    "circuits": {
        "de": "Schaltkreise",
        "en": "circuits",
        "fr": "circuits",
        "it": "circuiti",
        "es": "Circuitos",
    },
    "diameter": {
        "de": "Durchmesser",
        "en": "diameter",
        "it": "diametro",
        "fr": "diam&egrave;tre",
        "es": "Di&aacute;metro",
    },
    "note": {
        "de": "Anmerkung",
        "en": "note",
        "it": "nota",
        "fr": "note",
        "es": "Nota",
    },
    "usage": {
        "de": "Benutzung",
        "en": "usage",
        "fr": "utilisation",
        "it": "uso",
        "es": "Utilice",
    },
    "map.no-powerplant-data-for-country": {
        "de": "Für das ausgewählte Land sind aktuell keine Kraftwerksstandorte verfügbar",
        "fr": "Aucun site de centrale &eacute;lectrique n'est actuellement disponible pour le pays s&eacute;lectionn&eacute;.",
        "it": "Nessun sito di centrali elettriche &egrave; attualmente disponibile per il paese selezionato",
        "en": "There are currently no power plant sites available for the selected country",
        "es": "No hay centrales el&eacute;ctricas disponibles para el pa&iacute;s seleccionado.",
    },
    "map.source-global": {
        "de": "Quelle: Global Power Plant Database",
        "en": "Source: Global Power Plant Database",
        "fr": "Source: Global Power Plant Database",
        "it": "Fonte: Global Power Plant Database",
        "es": "Fuente: Global Power Plant Database",
    },
    "map.tooltip-powerplantSource-global": {
        "de": "<b>Global Power Plant Database</b> ist ein Open-Source-Projekt, dass Kraftwerksdaten aus verschiedenen nationalen und internationalen Quellen vereint.",
        "en": "<b>Global Power Plant Database</b> is an open source project that combines power plant data from various national and international sources.",
        "fr": "<b>Global Power Plant Database</b> est un projet open source qui combine des donn&eacute;es sur les centrales &eacute;lectriques provenant de diverses sources nationales et internationales.",
        "it": "<b>Global Power Plant Database</b> &egrave; un progetto open source che combina i dati delle centrali elettriche da varie fonti nazionali e internazionali.",
        "es": "&lt;b&gt;Global Power Plant Database&lt;/b&gt; es un proyecto de c&oacute;digo abierto que combina datos sobre centrales el&eacute;ctricas procedentes de diversas fuentes nacionales e internacionales.",
    },
    "map.wave_and_tidal": {
        "de": "Wellen- und Gezeiten",
        "en": "Wave and tidal",
        "fr": "Vagues et mar&eacute;es",
        "it": "Onde e maree",
        "es": "Olas y mareas",
    },
    "underground": {
        "en": "underground",
        "de": "unterirdisch",
        "fr": "souterrain",
        "it": "sotterraneo",
        "es": "subterr&aacute;neo",
    },
    "overground": {
        "de": "oberirdisch",
        "en": "overground",
        "fr": "sur le terrain",
        "it": "sopraelevata",
        "es": "en la superficie",
    },
    "surface": {
        "de": "Oberfl&auml;che",
        "en": "surface",
        "fr": "surface",
        "it": "superficie",
        "es": "Superficie",
    },
    "transmission": {
        "de": "&Uuml;bertragung",
        "en": "transmission",
        "fr": "transmission",
        "it": "trasmissione",
        "es": "&amp;Uml;transmisi&oacute;n",
    },
    "type": {
        "de": "Typ",
        "en": "type",
        "fr": "type",
        "it": "tipo",
        "es": "Tipo",
    },
    "long_name": {
        "de": "ausf&uuml;hrlicher Name",
        "en": "detailed name",
        "fr": "nom d&eacute;taill&eacute;",
        "it": "nome dettagliato",
        "es": "Nombre y apellidos",
    },
    "created_by": {
        "de": "erstellt von",
        "en": "created by",
        "fr": "cr&eacute;&eacute; par",
        "it": "creato da",
        "es": "creado por",
    },
    "highway": {
        "de": "Autobahn",
        "en": "highway",
        "fr": "autoroute",
        "it": "autostrada",
        "es": "Autopista",
    },
    "horse": {
        "de": "Pferd",
        "en": "horse",
        "fr": "cheval",
        "it": "cavallo",
        "es": "Caballo",
    },
    "description:de": {
        "de": "Beschreibung deutsch",
        "en": "Description german",
        "fr": "Description de l'allemand",
        "it": "Descrizione Tedesco",
        "es": "Descripci&oacute;n Alem&aacute;n",
    },
    "description:en": {
        "de": "Beschreibung englisch",
        "en": "Description english",
        "fr": "Description en anglais",
        "it": "Descrizione inglese",
        "es": "Descripci&oacute;n en ingl&eacute;s",
    },
    "old_operator": {
        "de": "ehemaliger Betreiber",
        "en": "former operator",
        "fr": "ancien op&eacute;rateur",
        "it": "ex operatore",
        "es": "antiguo operador",
    },
    "wikipedia": {
        "de": "wikipedia",
        "en": "wikipedia",
        "fr": "wikipedia",
        "it": "wikipedia",
        "es": "wikipedia",
    },
    "tracktype": {
        "de": "tracktype",
        "en": "tracktype",
        "fr": "Type de piste",
        "it": "tracktype",
        "es": "tipo de pista",
    },
    "website": {
        "de": "Website",
        "en": "website",
        "fr": "site web",
        "it": "sito web",
        "es": "P&aacute;gina web",
    },
    "bridge": {
        "de": "Br&uuml;cke",
        "en": "bridge",
        "fr": "pont",
        "it": "ponte",
        "es": "Br&amp;uuml;cke",
    },
    "pressure": {
        "de": "Druck",
        "en": "pressure",
        "fr": "pression",
        "it": "pressione",
        "es": "Imprimir",
    },
    "start_date": {
        "de": "Inbetriebnahme",
        "en": "Commissioning",
        "fr": "Mise en service",
        "it": "Commissioning",
        "es": "Puesta en servicio",
    },
    "motorcar": {
        "de": "Kraftfahrzeug",
        "en": "motorcar",
        "fr": "automobile",
        "it": "auto",
        "es": "Veh&iacute;culos de motor",
    },
    "motorcycle": {
        "de": "Motorrad",
        "en": "motorcycle",
        "fr": "moto",
        "it": "moto",
        "es": "Motorrad",
    },
    "underwater": {
        "de": "Unterwasser",
        "en": "underwater",
        "fr": "sous l'eau",
        "it": "subacqueo",
        "es": "Bajo el agua",
    },
    "note:de": {
        "de": "Anmerkung (deutsch)",
        "en": "Note (German)",
        "fr": "Note (allemand)",
        "it": "Nota (tedesco)",
        "es": "Nota (alem&aacute;n)",
    },
    "bicycle": {
        "de": "Fahrrad",
        "en": "bicycle",
        "fr": "v&eacute;lo",
        "it": "bicicletta",
        "es": "Bicicleta",
    },
    "foot": {
        "de": "Fu&szlig;",
        "en": "foot",
        "fr": "pied",
        "it": "piede",
        "es": "Fu&amp;szlig;",
    },
    "wikidata": {
        "de": "wikidata",
        "en": "wikidata",
        "fr": "wikidata",
        "it": "wikidata",
        "es": "wikidata",
    },
    "place:origin": {
        "de": "Ausgangsort",
        "en": "Starting point",
        "fr": "Point de d&eacute;part",
        "it": "Punto di partenza",
        "es": "Punto de partida",
    },
    "place:destination": {
        "de": "Zielort",
        "en": "Destination",
        "fr": "Destination",
        "it": "Destinazione",
        "es": "Zielort",
    },
    "distribution": {
        "de": "Vertrieb",
        "en": "distribution",
        "fr": "distribution",
        "it": "distribuzione",
        "es": "Distribuci&oacute;n",
    },
    "techinfo": {
        "de": "technische Informationen",
        "en": "technical information",
        "fr": "informations techniques",
        "it": "informazioni tecniche",
        "es": "informaci&oacute;n t&eacute;cnica",
    },
    "barrier": {
        "de": "Barriere",
        "en": "barrier",
        "fr": "barrier",
        "it": "barriera",
        "es": "Barreras",
    },
    "emergency_phone": {
        "de": "Telefonnotruf",
        "en": "Telephone emergency",
        "fr": "Appel d'urgence par t&eacute;l&eacute;phone",
        "it": "Chiamata di emergenza",
        "es": "Llamada de emergencia telef&oacute;nica",
    },
    "communication": {
        "de": "Kommunikation",
        "en": "communication",
        "fr": "communication",
        "it": "comunicazione",
        "es": "Comunicaci&oacute;n",
    },
    "short_name": {
        "de": "Kurzbezeichnung",
        "en": "Short name",
        "fr": "D&eacute;signation courte",
        "it": "Designazione breve",
        "es": "Denominaci&oacute;n breve",
    },
    "from": {
        "de": "von",
        "en": "from",
        "fr": "de",
        "it": "da",
        "es": "de",
    },
    "to": {
        "de": "zu",
        "en": "to",
        "fr": "&agrave;",
        "it": "a",
        "es": "zu",
    },
    "contact:phone": {
        "de": "Telefonischer Kontakt",
        "en": "Telephone contact",
        "fr": "Contact t&eacute;l&eacute;phonique",
        "it": "Contatto telefonico",
        "es": "Tel&eacute;fono de contacto",
    },
    "operator:type": {
        "de": "Art des Betreibers",
        "en": "Type of operator",
        "fr": "Type d'op&eacute;rateur",
        "it": "Tipo di operatore",
        "es": "Tipo de operador",
    },
    "pipeline:diameter": {
        "de": "Durchmesser der Pipeline",
        "en": "Pipeline diameter",
        "fr": "Diam&egrave;tre de la canalisation",
        "it": "Diametro della conduttura",
        "es": "Di&aacute;metro de la tuber&iacute;a",
    },
    "operator:wikidata": {
        "de": "wikidata des Betreibers",
        "en": "wikidata of the operator",
        "fr": "wikidata de l'op&eacute;rateur",
        "it": "wikidata dell'operatore",
        "es": "wikidatos del operador",
    },
    "pipeline": {
        "de": "Pipeline",
        "en": "pipeline",
        "fr": "pipeline",
        "it": "conduttura",
        "es": "Tuber&iacute;as",
    },
    "substance:wikidata": {
        "de": "Wikidata der Substanz",
        "en": "Substance Wikidata",
        "fr": "Wikidata de la substance",
        "it": "Wikidata della sostanza",
        "es": "Wikidata de la sustancia",
    },
    "overhead": {
        "de": "Overhead",
        "en": "overhead",
        "fr": "Transparent",
        "it": "sopraelevato",
        "es": "Sobrecarga",
    },
    "facility": {
        "de": "Einrichtung",
        "en": "facility",
        "fr": "installation",
        "it": "struttura",
        "es": "Instituci&oacute;n",
    },
    "undefined": {
        "de": "unbekannt",
        "en": "unknown",
        "fr": "inconnu",
        "it": "sconosciuto",
        "es": "desconocido",
    },
    "map.undefined": {
        "de": "unbekannt",
        "en": "unknown",
        "fr": "inconnu",
        "it": "sconosciuto",
        "es": "desconocido",
    },
    "indoor": {
        "de": "Innenbereich",
        "en": "indoor",
        "fr": "int&eacute;rieur",
        "it": "interno",
        "es": "Interior",
    },
    "tunnel": {
        "de": "Tunnel",
        "en": "tunnel",
        "fr": "tunnel",
        "it": "tunnel",
        "es": "T&uacute;nel",
    },
    "international": {
        "de": "international",
        "en": "international",
        "fr": "international",
        "it": "internazionale",
        "es": "internacional",
    },
    "underground;underwater": {
        "de": "unterirdisch, unter Wasser",
        "en": "underground, underwater",
        "fr": "souterrain, sous-marin",
        "it": "sotterraneo, subacqueo",
        "es": "bajo tierra, bajo el agua",
    },
    "outdoor": {
        "de": "im Freien",
        "en": "outdoor",
        "fr": "ext&eacute;rieur",
        "it": "all'aperto",
        "es": "al aire libre",
    },
    "undersea": {
        "de": "Unterwasserwelt",
        "en": "undersea",
        "fr": "sous-marine",
        "it": "sottomarino",
        "es": "Mundo submarino",
    },
    "avalanche_protection": {
        "de": "Lawinenschutz",
        "en": "avalanche protection",
        "fr": "avalanche protection",
        "it": "protezione da valanghe",
        "es": "Protecci&oacute;n contra avalanchas",
    },
    "inspection_gauge": {
        "de": "Pr&uuml;flehre",
        "en": "inspection gauge",
        "fr": "jauge d'inspection",
        "it": "calibro d'ispezione",
        "es": "Tuber&iacute;a",
    },
    "flow_direction": {
        "de": "Durchflussrichtung",
        "en": "flow direction",
        "fr": "direction du flux",
        "it": "direzione del flusso",
        "es": "Sentido del flujo",
    },
    "construction": {
        "de": "Konstruktion",
        "en": "construction",
        "fr": "construction",
        "it": "costruzione",
        "es": "Construcci&oacute;n",
    },
    "colour": {
        "de": "Farbe",
        "en": "colour",
        "fr": "couleur",
        "it": "colore",
        "es": "Color",
    },
    "industrial": {
        "de": "Industrie",
        "en": "industrial",
        "fr": "industriel",
        "it": "industriale",
        "es": "Industrie",
    },
    "rooftop": {
        "de": "Dachterrasse",
        "en": "rooftop",
        "fr": "sur le toit",
        "it": "tetto",
        "es": "Azotea",
    },
    "material": {
        "de": "Material",
        "en": "material",
        "fr": "mat&eacute;riel",
        "it": "materiale",
        "es": "Material",
    },
    "length": {
        "de": "L&auml;nge",
        "en": "length",
        "fr": "longueur",
        "it": "lunghezza",
        "es": "L&amp;auml;nge",
    },
    "owner": {
        "de": "Eigent&uuml;mer",
        "en": "owner",
        "fr": "propri&eacute;taire",
        "it": "proprietario",
        "es": "Propietario",
    },
    "position": {
        "de": "Position",
        "en": "position",
        "fr": "position",
        "it": "posizione",
        "es": "Posici&oacute;n",
    },
    "network": {
        "de": "Netzwerk",
        "en": "network",
        "fr": "r&eacute;seau",
        "it": "rete",
        "es": "Red",
    },
    "designation": {
        "de": "Bezeichnung",
        "en": "designation",
        "fr": "d&eacute;signation",
        "it": "denominazione",
        "es": "Designaci&oacute;n",
    },
    "submarine": {
        "de": "U-Boot",
        "en": "submarine",
        "fr": "sous-marin",
        "it": "sottomarino",
        "es": "Submarino",
    },
    "snowplowing": {
        "de": "Schneer&auml;umung",
        "en": "snowplowing",
        "fr": "d&eacute;neigement",
        "it": "spazzaneve",
        "es": "Limpieza de nieve",
    },
    "landuse": {
        "de": "Landnutzung",
        "en": "landuse",
        "fr": "utilisation des terres",
        "it": "uso del suolo",
        "es": "Uso del suelo",
    },
    "depth": {
        "de": "Tiefe",
        "en": "depth",
        "fr": "profondeur",
        "it": "profondit&agrave;",
        "es": "Profundidad",
    },
    "substation": {
        "de": "Umspannwerk",
        "en": "substation",
        "fr": "sous-station",
        "it": "sottostazione",
        "es": "Centro de transformaci&oacute;n",
    },
    "all_countries": {
        "de": "Alle europäischen Länder",
        "en": "All european countries",
        "fr": "Tous les pays d'Europe",
        "it": "Tutti i paesi europei",
        "es": "Todos los pa&iacute;ses europeos",
    },
    "hydrogen": {
        "de": "Wasserstoff",
        "en": "Hydrogen",
        "fr": "Hydrog&egrave;ne",
        "it": "Idrogeno",
        "es": "Hidr&oacute;geno",
    },
    "map.battery_storage": {
        "de": "Batteriespeicher",
        "en": "Battery storage",
        "fr": "Stockage sur batterie",
        "it": "Immagazzinamento della batteria",
        "es": "Almacenamiento en bater&iacute;a",
    },
    "CompanyName": {
        "de": "Firmenname",
        "en": "Company name",
        "fr": "Nom de l'entreprise",
        "it": "Nome dell'azienda",
        "es": "Nombre de la empresa",
    },
    "ProdConsName": {
        "de": "Kraftwerksname",
        "en": "Power plant name",
        "fr": "Nom de la centrale",
        "it": "Nome della centrale elettrica",
        "es": "Nombre de la central",
    },
    "StartDate": {
        "de": "Inbetriebnahme",
        "en": "Commissioning",
        "fr": "Mise en service",
        "it": "Commissioning",
        "es": "Puesta en servicio",
    },
    "EEG": {
        "de": "EEG-f&ouml;rderberechtigt",
        "en": "EEG-eligible",
        "fr": "&Eacute;ligible &agrave; l'EEG",
        "it": "EEG-eleggibile",
        "es": "EEG-elegible",
    },
    "HeatExtraction": {
        "de": "W&auml;rmeauskopplung",
        "en": "Heat extraction",
        "fr": "Extraction de chaleur",
        "it": "Estrazione di calore",
        "es": "Extracci&oacute;n de calor",
    },
    "isBorderPowerPlant": {
        "de": "Grenzkraftwerk",
        "en": "Border power plant",
        "fr": "Centrale frontali&egrave;re",
        "it": "Centrale elettrica di confine",
        "es": "Central el&eacute;ctrica fronteriza",
    },
    "Capacity (brutto)": {
        "de": "Bruttokapazität (MW)",
        "en": "Gross power (MW)",
        "fr": "Puissance brute (MW)",
        "it": "Capacit&agrave; lorda (MW)",
        "es": "Capacidad bruta (MW)",
    },
    "FeedInShare": {
        "de": "Einspeisung",
        "en": "Feed",
        "fr": "Alimentation",
        "it": "Feed-in",
        "es": "Alimentaci&oacute;n",
    },
    "TransformerLevel": {
        "de": "Spannungsebene",
        "en": "Voltage level",
        "fr": "Niveau de tension",
        "it": "Livello di tensione",
        "es": "Nivel de tensi&oacute;n",
    },
    "storageCapacity": {
        "de": "Speicherkapazit&auml;t (Mwh)",
        "en": "Storage capacity (Mwh)",
        "fr": "Capacit&eacute; de stockage (Mwh)",
        "it": "Capacit&agrave; di stoccaggio (Mwh)",
        "es": "Capacidad de almacenamiento (Mwh)",
    },
    "TSO": {
        "de": "&Uuml;bertragungsnetzbetreiber",
        "en": "Transmission system operator",
        "fr": "Gestionnaire du r&eacute;seau de transport",
        "it": "Operatore del sistema di trasmissione",
        "es": "Gestor de la red de transporte",
    },
    "Capacity (netto)": {
        "de": "Nettokapazität (MW)",
        "en": "Net power (MW)",
        "fr": "Puissance nette (MW)",
        "it": "Potenza netta (MW)",
        "es": "Capacidad neta (MW)",
    },
    "technology": {
        "de": "Technologie",
        "en": "Technology",
        "fr": "Technologie",
        "it": "Tecnologia",
        "es": "Tecnolog&iacute;a",
    },
    "PrimarySource": {
        "de": "Prim&auml;rquelle",
        "en": "Primary source",
        "fr": "Source primaire",
        "it": "Fonte primaria",
        "es": "En primer lugar, que",
    },
    "feedInGermanNetwork": {
        "de": "Einspeisung in ein deutsches Netz",
        "en": "Feeding into a German grid",
        "fr": "Alimentation d'un r&eacute;seau allemand",
        "it": "Alimentazione in una rete tedesca",
        "es": "Alimentaci&oacute;n de una red alemana",
    },
    "no-wind-data": {
        "de": "F&uuml;r die aktuelle Schadstoffansicht sind leider keine Winddaten vorhanden.",
        "en": "Unfortunately, no wind data is available for the current pollutant view.",
        "fr": "Malheureusement, aucune donn&eacute;e sur le vent n'est disponible pour la vue actuelle des polluants.",
        "it": "Sfortunatamente, non sono disponibili dati sul vento per l'attuale vista degli inquinanti.",
        "es": "Desgraciadamente, no se dispone de datos sobre el viento para la vista actual de los contaminantes.",
    },
    "hour": {
        "de": "Stunden",
        "en": "Hours",
        "fr": "Toutes les heures",
        "it": "Ora",
        "es": "Hora",
    },

    "map.fossil_coal_derived_gas": {
        "de": "Gas aus Kohle",
        "en": "Gas from coal",
        "fr": "Gaz de charbon",
        "it": "Gas da carbone",
        "es": "Gas de hulla",
    },
    "fossil_coal_derived_gas": {
        "de": "Gas aus Kohle",
        "en": "Gas from coal",
        "fr": "Gaz de charbon",
        "it": "Gas da carbone",
        "es": "Gas de hulla",
    },
    "pipeline:pressure": {
        "de": "Druck",
        "en": "Print",
        "fr": "Pression",
        "it": "Stampa",
        "es": "Imprimir",
    },
    "localisation": {
        "de": "Lokalisierung",
        "en": "Localization",
        "fr": "Localisation",
        "it": "Localizzazione",
        "es": "Localizaci&oacute;n",
    },
    "pipeline:type": {
        "de": "Rohrleitungstyp",
        "en": "Pipeline type",
        "fr": "Type de pipeline",
        "it": "Tipo di conduttura",
        "es": "Tipo de tuber&iacute;a",
    },
    "accuracy": {
        "de": "Genauigkeit",
        "en": "Accuracy",
        "fr": "Pr&eacute;cision",
        "it": "Precisione",
        "es": "Precisi&oacute;n",
    },
    "sorting": {
        "de": "Sortierung",
        "en": "Sorting",
        "fr": "Triage",
        "it": "Ordinamento",
        "es": "Clasificaci&oacute;n",
    },
    "default": {
        "de": "Standard",
        "en": "Standard",
        "fr": "Standard",
        "it": "Standard",
        "es": "Standard",
    },
    "ascending": {
        "de": "Aufsteigend",
        "en": "Ascending",
        "fr": "Croissant",
        "it": "Ascendente",
        "es": "Ascendente",
    },
    "descending": {
        "de": "Absteigend",
        "en": "Descending",
        "fr": "D&eacute;croissant",
        "it": "Discendente",
        "es": "Descendente",
    },
    "pageTitle_market_values": {
        "en": "Market values | Energy-Charts",
        "de": "Marktwerte | Energy-Charts",
        "fr": "Valeur de march\u00e9 | Energy-Charts",
        "it": "Valore di mercato | Energy-Charts",
        "es": "Valores de mercado | Energy-Charts",
    },
    "pageTitle_eeg_account": {
        "de": "EEG-Konto | Energy-Charts",
        "en": "EEG account | Energy-Charts",
        "fr": "Compte EEG | Energy-Charts",
        "it": "Conto EEG | Energy-Charts",
        "es": "Cuenta EEG | Energy-Charts",
    },
    "Source_detail": {
        "de": "Energietr&auml;ger",
        "en": "Energy source",
        "fr": "Source d'&eacute;nergie",
        "it": "Fonte di energia",
        "es": "Portador de energ&iacute;a",
    },
    "main_fuel": {
        "de": "Hautpbrennstoff",
        "en": "Main fuel",
        "fr": "Combustible pour la peau",
        "it": "Combustibile principale",
        "es": "Combustible principal",
    },
    "map.clusterSize": {
        "de": "# Kraftwerke",
        "en": "# Power plants",
        "fr": "# Centrales &eacute;lectriques",
        "it": "# Centrali elettriche",
        "es": "# Centrales el&eacute;ctricas",
    },
    "windpark_name": {
        "de": "Name des Windparks",
        "en": "Wind farm name",
        "fr": "Nom du parc &eacute;olien",
        "it": "Nome del parco eolico",
        "es": "Nombre del parque e&oacute;lico",
    },
    "rotor_diameter": {
        "de": "Rotordurchmesser",
        "en": "Rotor diameter",
        "fr": "Diam&egrave;tre du rotor",
        "it": "Diametro del rotore",
        "es": "Di&aacute;metro del rotor",
    },
    "rotor:diameter": {
        "de": "Rotordurchmesser",
        "en": "Rotor diameter",
        "fr": "Diam&egrave;tre du rotor",
        "it": "Diametro del rotore",
        "es": "Di&aacute;metro del rotor",
    },
    "hub_height": {
        "de": "Nabenh&ouml;he",
        "en": "Hub height",
        "fr": "Hauteur du moyeu",
        "it": "Altezza del mozzo",
        "es": "Altura del cubo",
    },
    "height:hub": {
        "de": "Nabenh&ouml;he",
        "en": "Hub height",
        "fr": "Hauteur du moyeu",
        "it": "Altezza del mozzo",
        "es": "Altura del cubo",
    },
    "water_depth": {
        "de": "Wassertiefe",
        "en": "Water depth",
        "fr": "Profondeur de l'eau",
        "it": "Profondit&agrave; dell'acqua",
        "es": "Profundidad del agua",
    },
    "distance_to_coast": {
        "de": "K&uuml;stenentfernung",
        "en": "Coastal distance",
        "fr": "Distance de la côte",
        "it": "Distanza costiera",
        "es": "Cristalizaci&oacute;n",
    },
    "battery_technology": {
        "de": "Batterie-Technologie",
        "en": "Battery technology",
        "fr": "Technologie des batteries",
        "it": "Tecnologia delle batterie",
        "es": "Tecnolog&iacute;a de bater&iacute;as",
    },
    "type_of_hydropower": {
        "de": "Art der Wasserkraftanlage",
        "en": "Type of hydropower plant",
        "fr": "Type d'installation hydro&eacute;lectrique",
        "it": "Tipo di impianto idroelettrico",
        "es": "Tipo de central hidroel&eacute;ctrica",
    },
    "map.choose-county": {
        "de": "Kreis ausw&auml;hlen",
        "en": "Select district",
        "fr": "S&eacute;lectionner le district",
        "it": "Seleziona il distretto",
        "es": "Seleccionar c&iacute;rculo",
    },
    "map.Capacity": {
        "de": "Installierte Leistung",
        "en": "Installed power",
		"fr": "Puissance installée",
		"it": "Potenza installata",
		"es": "Potencia instalada"
    },
    "map.capacity": {
        "de": "Installierte Leistung",
        "en": "Installed power",
		"fr": "Puissance installée",
		"it": "Potenza installata",
		"es": "Potencia instalada"
    },
    "Capacity": {
        "de": "Installierte Leistung",
        "en": "Installed power",
		"fr": "Puissance installée",
		"it": "Potenza installata",
		"es": "Potencia instalada"
    },
    "LinkingPoint": {
        "de": "Verkn&uuml;pfungspunkt",
        "en": "linking point",
        "fr": "point de liaison",
        "it": "punto di collegamento",
        "es": "Punto de enlace",
    },
    "UnitName": {
        "de": "Name der Einheit",
        "en": "Unit name",
        "fr": "Nom de l'unit&eacute;",
        "it": "Nome dell'unit&agrave;",
        "es": "Nombre de la unidad",
    },
    "map.states": {
        "de": "Bundesl&auml;nder",
        "en": "States",
        "fr": "&Eacute;tats f&eacute;d&eacute;raux",
        "it": "Stati federali",
        "es": "Estados federales",
    },
    "map.counties": {
        "de": "Landkreise",
        "en": "Counties",
        "fr": "Comt&eacute;s",
        "it": "Contee",
        "es": "Condados",
    },
    "map.other_storage": {
        "de": "Sonstige Speicher",
        "en": "Other storage",
        "fr": "Autre stockage",
        "it": "Altro stoccaggio",
        "es": "Otro almacenamiento",
    },
    "map.storage": {
        "de": "Speicher",
        "en": "Storage",
        "fr": "Stockage",
        "it": "Accumulo",
        "es": "Memoria",
    },
    "SecondarySource": {
        "de": "Sekund&auml;rquelle",
        "en": "Secondary source",
        "fr": "Source secondaire",
        "it": "Fonte secondaria",
        "es": "Fuente secundaria",
    },
    "date": {
        "de": "Datum",
        "en": "Date",
        "fr": "Date",
        "it": "Data",
        "es": "Fecha",
    },
    "PublicationTimeStamp": {
        "de": "Datum der Ver&ouml;ffentlichung",
        "en": "Publication date",
        "fr": "Date de publication",
        "it": "Data di pubblicazione",
        "es": "Fecha de publicaci&oacute;n",
    },
    "CompanyID": {
        "de": "Firmen-ID",
        "en": "Company ID",
        "fr": "ID de l'entreprise",
        "it": "ID azienda",
        "es": "Identificaci&oacute;n de la empresa",
    },
    "ControlArea": {
        "de": "&Uuml;bertragungsnetzbetreiber", 
        "en": "Transmission system operator", 
        "fr": "Gestionnaire de r&eacute;seau de transport", 
        "it": "Gestori del sistema di trasmissione",        
        "es": "Gestores de redes de transporte",
    },
    "EndDate": {
        "de": "Auslaufdatum",
        "en": "Expiration date",
        "fr": "Date d'expiration",
        "it": "Data di scadenza",
        "es": "Fecha de caducidad",
    },
    "Ausschliessliche Wirtschaftszone": {
        "de": "Ausschliessliche Wirtschaftszone",
        "en": "Exclusive economic zone",
        "fr": "Zone &eacute;conomique exclusive",
        "it": "Zona economica esclusiva",
        "es": "Zona econ&oacute;mica exclusiva",
    },
    "coordinateSource": {
        "de": "Quelle der Koordinaten",
        "en": "Coordinates source",
        "fr": "Source des coordonn&eacute;es",
        "it": "Fonte delle coordinate",
        "es": "Fuente de las coordenadas",
    },
    "mastr": {
        "de": "Marktstammdatenregister",
        "en": "Market Master Data Register",
        "fr": "Registre des donn&eacute;es de base de la marque",
        "it": "Registro dei dati principali del mercato",
        "es": "Registro de datos maestros de mercado",
    },
    "nominatim": {
        "de": "Von Nominatim aus MaStR-Adresse berechnet",
        "en": "Calculated by Nominatim from MaStR address",
        "fr": "Calcul&eacute; par Nominatim &agrave; partir de l'adresse MaStR",
        "it": "Calcolato da Nominatim dall'indirizzo MaStR",
        "es": "Calculado por Nominatim a partir de la direcci&oacute;n MaStR",
    },
    "Ausschließliche Wirtschaftszone": {
        "de": "Ausschlie&szlig;liche Wirtschaftszone",
        "en": "Exclusive economic zone",
        "fr": "Zone &eacute;conomique exclusive",
        "it": "Zona economica esclusiva",
        "es": "Zona econ&oacute;mica exclusiva",
    },
    "DFA": {
        "en":	"installation on building",
        "de":	"Gebäudeanlage",
        "fr":	"installation sur le bâtiment",
        "es":	"instalación en el edificio",
        "it":	"installazione su edificio"
    },
    "DFA_lt_30kWp": {
        "en":	"installation on building < 30 kWp",
        "de":	"Gebäudeanlage < 30 kWp",
        "fr":	"installation sur le bâtiment < 30 kWp",
        "es":	"instalación en el edificio < 30 kWp",
        "it":	"installazione su edificio < 30 kWp"
    },
    "DFA_ge_30kWp": {
        "en":	"installation on building ≥ 30 kWp",
        "de":	"Gebäudeanlage ≥ 30 kWp",
        "fr":	"installation sur le bâtiment ≥ 30 kWp",
        "es":	"instalación en el edificio ≥ 30 kWp",
        "it":	"installazione su edificio ≥ 30 kWp"
    },
    "FFA": {
        "en": "open space installation",
        "de": "Freiflächenanlage",
        "fr": "installation en plein air",
        "es": "planta de espacio abierto",
        "it": "impianto di spazio aperto",
    },
    "explanation_of_control_parameters": {
        "de": "Erklärung der Regelparameter",
        "en": "Explanation of the control parameters",
        "fr": "Explication des param&egrave;tres de r&eacute;gulation",
        "it": "Spiegazione dei parametri di controllo",
        "es": "Explicaci&oacute;n de los par&aacute;metros de control",
    },

    "frequency_explanation_box": {
        "de": `
			<p>Die beispielhafte Simulation zeigt die potenzielle Reaktion netzbildender Wechselrichter auf verschiedene Netzereignisse. Der Zeitbereich t = [0 s, 180 s] bildet den Verlauf der Netzfrequenz und der Spannungsamplitude zum Zeitpunkt der Systemauftrennung am 24.07.2021 nach, gemessen in La Cereal, Spanien, im Zentrum der Iberischen Halbinsel. Im folgenden Verlauf wurden Frequenz und Spannungsamplitude konstant gehalten mit einem Spannungseinbruch um 0.05 pu für t = [250 s, 300 s], sowie einem positiven Phasensprung bei tθpos = 350 s und einem negativen Phasensprung bei tθneg = 380 s. Die Phasensprünge sind durch Impulse im Frequenzverlauf mit ∆f = θstep*∆T nachgebildet.</p>
			
			<p>Die Simulation beruht auf einem vereinfachten Modell in dem Kopplungsterme zwischen Wirk- und Blindleistung vernachlässigt werden. Die Leistung des Wechselrichters ist 100 kW. Für die Bereitstellung von Wirk- und Blindleistung durch den NBWR werden jeweils zwei Ansätze dargestellt:</p>
			
			<ol>
				<li>P-Regler: proportionaler Leistungsregler (Parametrierbar über Ta,set und Kq)</li>
				<li>D-Regler: differentieller Leistungsregler (Parametrierbar über Kp und Kq)</li>
			</ol> 
			
			<p>Zum Vergleich können die Reaktionen eines Synchrongenerators (SG) mit unterschiedlichen Einstellungen eingeblendet werden:</p>
			
			<ol>
				<li>P_SG ohne PRL: Bereitstellung von Wirkleistung als Momentanreserve mit Ta = 12 s</li>
				<li>P_SG mit PRL: Bereitstellung von Wirkleistung als PRL mit droop = 0.035</li>
				<li>Q_SG mit konst. U: Bereitstellung von Blindleistung ohne Q(U)-Regelung</li>
				<li>Q_SG mit Q(U) droop Regler: Bereitstellung von Blindleistung mit Q(U)-Regelung</li>
			</ol> 
			
			<p>Weitere Informationen zu Modell und Reglerstruktur sind <a target="_blank" href="data/de/2022_Paper_NBWR_Energy_Charts.pdf">hier</a> zu entnehmen.</p>`,
        "en": `
			<p>The exemplary simulation shows the potential response of grid-forming inverters to various grid events. The time range t = [0 s, 180 s] reproduces the course of the grid frequency and voltage amplitude at the time of system disconnection on 24.07.2021, measured in La Cereal, Spain, in the center of the Iberian Peninsula. In the following trajectory, frequency and voltage amplitude were held constant with a voltage dip of 0.05 pu for t = [250 s, 300 s], as well as a positive phase jump at tθpos = 350 s and a negative phase jump at tθneg = 380 s. The phase jumps are reproduced by pulses in the frequency response with ∆f = θstep*∆T.</p>
			
			<p>The simulation is based on a simplified model in which coupling terms between active and reactive power are neglected. The power of the inverter is 100 kW. Two approaches are shown for the provision of active and reactive power by the grid-forming inverter:</p>
			
			<ol>
				<li>P-controller: proportional power controller (parameterizable via Ta,set and Kq)</li>
				<li>D-controller: differential power controller (parameterizable via Kp and Kq)</li>
			</ol> 
			
			<p>For comparison, the reactions of a synchronous generator (SG) with different settings can be shown:</p>
			
			<ol>
				<li>P_SG without primary control power: Provision of active power as instantaneous reserve with Ta = 12 s</li>
				<li>P_SG with primary control power: Provision of active power as PRL with droop = 0.035</li>
				<li>Q_SG with const. U: Provision of reactive power without Q(U) control</li>
				<li>Q_SG with Q(U) droop controller: Provision of reactive power with Q(U) control</li>
			</ol> 
			
			<p>More information on the model and controller structure can be found <a target="_blank" href="data/de/2022_Paper_NBWR_Energy_Charts.pdf">here</a> (in German).</p>`,
        "fr": `
			<p>L'exemple de simulation montre la r&eacute;action potentielle des onduleurs formant le r&eacute;seau à diff&eacute;rents &eacute;v&eacute;nements de r&eacute;seau. La plage de temps t = [0 s, 180 s] reproduit l'&eacute;volution de la fr&eacute;quence du r&eacute;seau et de l'amplitude de la tension au moment de la s&eacute;paration du syst&egrave;me le 24 juillet 2021, mesur&eacute;e à La Cereal, Espagne, au centre de la p&eacute;ninsule ib&eacute;rique. Dans le d&eacute;roulement suivant, la fr&eacute;quence et l'amplitude de la tension ont &eacute;t&eacute; maintenues constantes avec une chute de tension de 0,05 pu pour t = [250 s, 300 s], ainsi qu'un saut de phase positif à tθpos = 350 s et un saut de phase n&eacute;gatif à tθneg = 380 s. Les sauts de phase sont reproduits par des impulsions dans l'&eacute;volution de la fr&eacute;quence avec ∆f = θstep*∆T.</p>
			
			<p>La simulation repose sur un mod&egrave;le simplifi&eacute; dans lequel les termes de couplage entre la puissance active et la puissance r&eacute;active sont n&eacute;glig&eacute;s. La puissance de l'onduleur est de 100 kW. Deux approches sont repr&eacute;sent&eacute;es pour la mise à disposition de la puissance active et r&eacute;active par l'onduleur formant le r&eacute;seau :</p>
			
			<ol>
				<li>R&eacute;gulateur P : r&eacute;gulateur de puissance proportionnel (param&eacute;trable via Ta,set et Kq)</li>
				<li>R&eacute;gulateur D : r&eacute;gulateur de puissance diff&eacute;rentiel (param&eacute;trable via Kp et Kq)</li>
			</ol> 
			
			<p>A titre de comparaison, les r&eacute;actions d'un g&eacute;n&eacute;rateur synchrone (SG) peuvent être affich&eacute;es avec diff&eacute;rents r&eacute;glages :</p>
			
			<ol>
				<li>P_SG sans puissance de r&eacute;glage primaire: Mise à disposition de puissance active comme r&eacute;serve momentan&eacute;e avec Ta = 12 s</li>
				<li>P_SG avec puissance de r&eacute;glage primaire: Mise à disposition de puissance active comme PRL avec droop = 0,035</li>
				<li>Q_SG avec const. U: mise à disposition de puissance r&eacute;active sans r&eacute;gulation Q(U)</li>
				<li>Q_SG avec Q(U) droop r&eacute;gulateur: mise à disposition de puissance r&eacute;active avec r&eacute;gulation Q(U)</li>
			</ol> 
			
			<p>Pour plus d'informations sur le mod&egrave;le et la structure du r&eacute;gulateur, <a target="_blank" href="data/de/2022_Paper_NBWR_Energy_Charts.pdf">ici</a> (en allemand).</p>`,
        "it": `
			<p>La simulazione esemplare mostra la potenziale reazione degli inverter di rete a vari eventi di rete. L'intervallo di tempo t = [0 s, 180 s] simula l'andamento della frequenza di rete e dell'ampiezza della tensione al momento della disconnessione del sistema il 24.07.2021, misurata a La Cereal, in Spagna, nel centro della penisola iberica. Nella traiettoria seguente, la frequenza e l'ampiezza della tensione sono state mantenute costanti con un calo di tensione di 0,05 pu per t = [250 s, 300 s], oltre a un salto di fase positivo a tθpos = 350 s e un salto di fase negativo a tθneg = 380 s. I salti di fase sono simulati da impulsi nella curva di frequenza con ∆f = θstep*∆T.</p>
			
			<p>La simulazione si basa su un modello semplificato in cui i termini di accoppiamento tra potenza attiva e reattiva sono trascurati. La potenza dell'inverter &egrave; di 100 kW. Vengono illustrati due approcci per la fornitura di potenza attiva e reattiva da parte dell'inverter di rete:</p>
			
			<ol>
				<li>Regolatore P: regolatore di potenza proporzionale (parametrizzabile tramite Ta, set e Kq)</li>
				<li>D-controller: regolatore di potenza differenziale (parametrizzabile tramite Kp e Kq)</li>
			</ol> 
			
			<p>A titolo di confronto, si possono mostrare le reazioni di un generatore sincrono (SG) con diverse impostazioni:</p>
			
			<ol>
				<li>P_SG senza potenza di controllo primaria: Fornitura di potenza attiva come riserva istantanea con Ta = 12 s</li>
				<li>P_SG con potenza di controllo primaria: Fornitura di potenza attiva come PRL con droop = 0,035</li>
				<li>Q_SG con const. U: Fornitura di potenza reattiva senza controllo Q(U)</li>
				<li>Q_SG con regolatore droop Q(U): fornitura di potenza reattiva con controllo Q(U)</li>
			</ol> 
			
			<p>Ulteriori informazioni sul modello e sulla struttura del controllore sono disponibili <a target="_blank" href="data/de/2022_Paper_NBWR_Energy_Charts.pdf">qui</a> (in tedesco).</p>`,
        "es": `
            <p>La simulaci&oacute;n ejemplar muestra la reacci&oacute;n potencial de los inversores que forman la red a varios eventos de la red. El rango de tiempo t = [0 s, 180 s] simula el curso de la frecuencia de red y la amplitud de voltaje en el momento de la divisi&oacute;n del sistema el 24 de julio de 2021, medido en La Cereal, Espa&ntilde;a, en el centro de la Pen&iacute;nsula Ib&eacute;rica. . En el curso siguiente, la frecuencia y la amplitud de tensi&oacute;n se mantuvieron constantes con una ca&iacute;da de tensi&oacute;n de 0,05 pu para t = [250 s, 300 s], as&iacute; como un salto de fase positivo en tθpos = 350 s y un salto de fase negativo en tθneg = 380 s Los saltos de fase se deben a pulsos simulados en la respuesta de frecuencia con ∆f = θpaso*∆T.</p>
    
            <p>La simulaci&oacute;n se basa en un modelo simplificado en el que se desprecian los t&eacute;rminos de acoplamiento entre potencia activa y reactiva. La potencia del inversor es de 100 kW. Se muestran dos enfoques para la provisi&oacute;n de potencia activa y reactiva por parte del NBWR:</p>
            
            <ol>
                <li>Controlador P: controlador de potencia proporcional (parametrizable v&iacute;a Ta,set y Kq)</li>
                <li>Controlador D: controlador de potencia diferencial (parametrizable a trav&eacute;s de Kp y Kq)</li>
            </ol>
            
            <p>A modo de comparaci&oacute;n, las reacciones de un generador s&iacute;ncrono (SG) se pueden mostrar con diferentes configuraciones:</p>
            
            <ol>
                <li>P_SG sin PRL: Suministro de potencia activa como reserva instant&aacute;nea con Ta = 12 s</li>
                <li>P_SG con PRL: Suministro de potencia activa como PRL con droop = 0,035</li>
                <li>Q_SG con U constante: provisi&oacute;n de potencia reactiva sin control Q(U)</li>
                <li>Q_SG con controlador de ca&iacute;da Q(U): provisi&oacute;n de potencia reactiva con control Q(U)</li>
            </ol>
            
            <p>Puede encontrar m&aacute;s informaci&oacute;n sobre el modelo y la estructura del controlador <a target="_blank" href="data/de/2022_Paper_NBWR_Energy_Charts.pdf">aqu&iacute;</a>.</p>
        `
    },
    "consumption_advice_explanation_box_title": {
        "de": "Erl&auml;uterungen zur Stromampel",
        "en": "Explanation of the electricity traffic light",
        "fr": "Explications sur les feux de signalisation de l'&eacute;lectricit&eacute;",
        "it": "Spiegazioni sul semaforo dell'elettricit&agrave;",
        "es": "Explicaciones sobre el sem&aacute;foro de la electricidad",
    },

    "consumption_advice_explanation_box": {
        "de": `
        <p>Die Stromampel soll die Anpassung des Verbrauchs an die aktuelle Erzeugung erleichtern. Dazu wird <b>der Anteil der Erneuerbaren Energien an der elektrischen Last</b> als Grundlage verwendet.</p>
        
        <p>Die Ampelfarben werden durch einen Vergleich mit <b>dem Mittelwert des monatlichen Anteils der Erneuerbaren Energien des gleichen Monats der letzten fünf Jahre</b> bestimmt:</p>
        
        <ol>
            <li>Mittelwert ± 10 %: <b>Gelb</b></li>
            <li>Darüber oder Anteil Erneuerbarer Energien > 95 %: <b>Grün / Blau</b></li>
            <li>Darunter: <b>Rot</b></li>
        </ol> 
		
		<p>Hierbei wird eine Hysterese von 1 % verwendet, um zu häufige Farbwechsel an den Grenzen zu vermeiden.</p>
                
        <p>Die Vorhersage steht in aller Regel ab 19:00 Uhr für den nächsten Tag zur Verfügung. Die Daten werden in der Regel jede Stunde neu geladen, um immer die aktuellste Prognose zu zeigen.</p>
        `,
        "en": `
        <p>The electricity traffic light is intended to facilitate the management of demand to current generation. For this purpose, <b>the share of renewable energies of the electrical load</b> is used as a basis.</p>
        
        <p>The traffic light colors are determined by a comparison with <b>the average value of the monthly share of renewable energies of the same month of the last five years</b>:</p>
        
        <ol>
            <li>Average ± 10 %: <b>Yellow</b></li>
            <li>Above or share of renewables > 95 %: <b>Green / blue</b></li>
            <li>Below: <b>Red</b></li>
        </ol> 
		
		<p>A hysteresis of 1 % is used here to avoid too frequent color changes at the limits.</p>
                
        <p>The forecast is usually available from 19:00 for the next day. The data is usually reloaded every hour to always show the latest forecast. </p>
        `,
        "fr": `
        <p>Le feu de signalisation de l'&eacute;lectricit&eacute; a pour but de faciliter l'adaptation de la consommation à la production actuelle. À cette fin, <b>la part des &eacute;nergies renouvelables dans la charge &eacute;lectrique</b> sert de base.</p>
        
        <p>Les couleurs des feux de signalisation sont d&eacute;termin&eacute;es par une comparaison avec <b>la moyenne de la part mensuelle des &eacute;nergies renouvelables du même mois des cinq derni&egrave;res ann&eacute;es</b>:</p>

				<ol>
            <li>Moyenne ± 10 %: <b>Jaune</b></li>
            <li>Plus ou part des &eacute;nergies renouvelables > 95 %: <b>Vert / bleu</b></li>
            <li>Moins: <b>Rouge</b></li>
        </ol> 

		<p>Une hystérésis de 1 % est utilisée ici afin d'éviter des changements de couleur trop fréquents aux limites.</p>
                
        <p>En r&egrave;gle g&eacute;n&eacute;rale, les pr&eacute;visions sont disponibles à partir de 19h00 pour le lendemain. Les donn&eacute;es sont g&eacute;n&eacute;ralement recharg&eacute;es toutes les heures afin de toujours afficher les pr&eacute;visions les plus r&eacute;centes. </p>
        `,
        "it": `
        <p>Il semaforo dell'elettricità ha lo scopo di facilitare l'adeguamento del consumo alla produzione corrente. A tal fine, viene utilizzata come base <b>la quota di energie rinnovabili nel carico elettrico</b>.</p>
        
        <p>I colori dei semafori sono determinati dal confronto con <b>il valore medio della quota mensile di energie rinnovabili dello stesso mese degli ultimi cinque anni</b>:</p>
        			
				<ol>
            <li>Valore medio ± 10 %: <b>Giallo</b></li>
            <li>Al di sopra di questo valore o quota di energie rinnovabili > 95 %: <b>Verde / blu</b></li>
            <li>Tra questi: <b>Rosso</b></li>
        </ol> 
		
		<p>Si utilizza un'isteresi dell'1 % per evitare variazioni di colore troppo frequenti ai bordi.</p>
                
        <p>Le previsioni sono solitamente disponibili dalle 19:00 per il giorno successivo. I dati vengono solitamente ricaricati ogni ora per mostrare sempre le previsioni più aggiornate. </p>
        `,
        "es": `
            <p>El sem&aacute;foro deber&iacute;a facilitar el ajuste del consumo a la generaci&oacute;n actual. Para ello, se utiliza como base <b>la cuota de energ&iacute;as renovables en la carga el&eacute;ctrica</b>.</p>
        
         <p>Los colores del sem&aacute;foro se determinan comparando <b>la cuota mensual media de energ&iacute;as renovables para el mismo mes durante los últimos cinco años</b>:</p>
				 	
				<ol>
            <li>Valor medio ± 10 %: <b>Amarillo</b></li>
            <li>Por encima de este valor o cuota de renovables > 95 %: <b>Verde / azul</b></li>
            <li>Entre ellos: <b>Rojo</b></li>
        </ol> 
		 
		 <p>Aquí se utiliza una histéresis del 1 % para evitar cambios de color demasiado frecuentes en los bordes.</p>
                
         <p>La previsi&oacute;n suele estar disponible a partir de las 19:00 horas para el d&iacute;a siguiente. Los datos generalmente se recargan cada hora para mostrar siempre el pron&oacute;stico m&aacute;s reciente. </p>
        `
    },
    "hide_example": {
        "de": "Beispiel verbergen",
        "en": "Hide example",
        "fr": "Masquer l'exemple",
        "it": "Nascondi esempio",
        "es": "Ocultar ejemplo",
    },
    "show_example": {
        "de": "Beispiel anzeigen",
        "en": "Show example",
        "fr": "Afficher l'exemple",
        "it": "Mostra esempio",
        "es": "Mostrar ejemplo",
    },
    "control_parameters": {
        "de": "Regelparameter",
        "en": "Control parameters",
        "fr": "Param&egrave;tres de r&eacute;gulation",
        "it": "Parametri di controllo",
        "es": "Par&aacute;metros de control",
    },
    "description_control_parameter": {
        "de": "Erklärung der Regelparameter",
        "en": "Description of control parameters",
        "fr": "Explication des param&egrave;tres de r&eacute;gulation",
        "it": "Spiegazione dei parametri di controllo",
        "es": "Explicaci&oacute;n de los par&aacute;metros de control",
    },
    "generator:type": {
        "de": "Typ",
        "en": "type",
        "fr": "Type",
        "it": "tipo",
        "es": "Tipo",
    },
    "generator:model": {
        "de": "Generator-Modell",
        "en": "Generator model",
        "fr": "Mod&egrave;le de g&eacute;n&eacute;rateur",
        "it": "Modello di generatore",
        "es": "Modelo de generador",
    },
    "generator:source": {
        "de": "Energiequelle",
        "en": "Energy source",
        "fr": "Source d'&eacute;nergie",
        "it": "Fonte di energia",
        "es": "Fuente de energ&iacute;a",
    },
    "building": {
        "de": "Geb&auml;ude",
        "en": "Building",
        "fr": "Bâtiment",
        "it": "Costruire",
        "es": "Edificio",
    },
    "addr:country": {
        "de": "Land",
        "en": "Country",
        "fr": "Pays",
        "it": "Paese",
        "es": "Pa&iacute;s",
    },
    "addr:city": {
        "de": "Stadt",
        "en": "City",
        "fr": "Ville",
        "it": "Citt&agrave;",
        "es": "Ciudad",
    },
    "addr:housenumber": {
        "de": "Hausnummer",
        "en": "House number",
        "fr": "Num&eacute;ro de maison",
        "it": "Numero civico",
        "es": "N&uacute;mero de casa",
    },
    "addr:postcode": {
        "de": "Postleitzahl",
        "en": "Postal code",
        "fr": "Code postal",
        "it": "Codice postale",
        "es": "C&oacute;digo postal",
    },
    "addr:street": {
        "de": "Stra&szlig;e",
        "en": "Strasse",
        "fr": "Strasse",
        "it": "Strasse",
        "es": "Stra&amp;szlig;e",
    },
    "Power": {
        "de": "Leistung",
        "en": "Power",
        "fr": "Puissance",
        "it": "Potenza",
        "es": "Potencia",
    },
    "generator:method": {
        "de": "Methode",
        "en": "method",
        "fr": "Mode",
        "it": "metodo",
        "es": "M&eacute;todo",
    },
    "map.source-osm": {
        "de": "Quelle: Open Street Map (OSM)",
        "en": "Source: Open Street Map (OSM)",
        "fr": "Source: Open Street Map (OSM)",
        "it": "Fonte: Open Street Map (OSM)",
        "es": "Fuente: Open Street Map (OSM)",
    },
    "addr:place": {
        "de": "Siedlung",
        "en": "settlement",
        "fr": "r&egrave;glement",
        "it": "insediamento",
        "es": "Liquidaci&oacute;n",
    },
    "height": {
        "de": "H&ouml;he",
        "en": "Height",
        "fr": "Hauteur",
        "it": "Altezza",
        "es": "H&amp;ouml;he",
    },
    "model": {
        "de": "Modell",
        "en": "Modell",
        "fr": "Mod&egrave;le",
        "it": "Modell",
        "es": "Modelo",
    },
    "generator:place": {
        "de": "Ort des Generators",
        "en": "Generator location",
        "fr": "Emplacement du g&eacute;n&eacute;rateur",
        "it": "Posizione del generatore",
        "es": "Ubicaci&oacute;n del generador",
    },
    "generator:modules": {
        "de": "Generator-Module",
        "en": "Generator modules",
        "fr": "Modules de g&eacute;n&eacute;rateur",
        "it": "Moduli del generatore",
        "es": "M&oacute;dulos generadores",
    },
    "operator:wikipedia": {
        "de": "Wikipedia des Betreibers",
        "en": "Wikipedia of the operator",
        "fr": "Wikipedia de l'op&eacute;rateur",
        "it": "Wikipedia dell'operatore",
        "es": "Wikipedia del operador",
    },
    "manufacturer": {
        "de": "Hersteller",
        "en": "Manufacturer",
        "fr": "Fabricant",
        "it": "Produttore",
        "es": "Fabricante",
    },
    "manufacturer:type": {
        "de": "Modell",
        "en": "model",
        "fr": "mod&egrave;le",
        "it": "modello",
        "es": "Modelo",
    },
    "rotor:blades": {
        "de": "Rotorbl&auml;tter",
        "en": "Rotor blades",
        "fr": "Pales du rotor",
        "it": "Pale del rotore",
        "es": "Palas del rotor",
    },
    "plant:storage": {
        "de": "Speicherkapazit&auml;t",
        "en": "storage capacity",
        "fr": "capacit&eacute; de stockage",
        "it": "capacit&agrave; di stoccaggio",
        "es": "Capacidad de almacenamiento",
    },

    "generator:output:heat": {
        "de": "W&auml;rmeauskopplung",
        "en": "Heat extraction",
        "fr": "Extraction de chaleur",
        "it": "Estrazione di calore",
        "es": "Extracci&oacute;n de calor",
    },
    "plant:output:heat": {
        "de": "W&auml;rmeauskopplung",
        "en": "Heat extraction",
        "fr": "Extraction de chaleur",
        "it": "Estrazione di calore",
        "es": "Extracci&oacute;n de calor",
    },
    "map.tooltip-powerplantSource-osm": {
        "de": "&lt;b&gt;Open Street Map&lt;/b&gt; ist ein internationales Projekt mit dem Ziel, eine freie Weltkarte zu erschaffen.",
        "en": "&lt;b&gt;Open Street Map&lt;/b&gt; is an international project with the goal of creating a free map of the world.",
        "fr": "&lt;b&gt;Open Street Map&lt;/b&gt; est un projet international dont le but est de cr&eacute;er une carte du monde libre.",
        "it": "&lt;b&gt;Open Street Map&lt;/b&gt; &egrave; un progetto internazionale con lo scopo di creare una mappa gratuita del mondo.",
        "es": "Open Street Map&amp;lt;/b&amp;gt; es un proyecto internacional cuyo objetivo es crear un mapa gratuito del mundo.",
    },
    "plant:output:hot_water": {
        "de": "Warmwassererzeugung",
        "en": "Hot water generation",
        "fr": "Production d'eau chaude",
        "it": "Produzione di acqua calda",
        "es": "Producci&oacute;n de agua caliente",
    },
    "rotor:swept_area": {
        "de": "Rotorfl&auml;che",
        "en": "Rotor area",
        "fr": "Surface du rotor",
        "it": "Area del rotore",
        "es": "Superficie del rotor",
    },
    "generator:output:hot_water": {
        "de": "Warmwassererzeugung",
        "en": "Hot water generation",
        "fr": "Production d'eau chaude",
        "it": "Produzione di acqua calda",
        "es": "Producci&oacute;n de agua caliente",
    },
    "plant:source": {
        "de": "Energiequelle",
        "en": "Energy source",
        "fr": "Source d'&eacute;nergie",
        "it": "Fonte di energia",
        "es": "Fuente de energ&iacute;a",
    },
    "plant:method": {
        "de": "Methode",
        "en": "Method",
        "fr": "M&eacute;thode",
        "it": "Metodo",
        "es": "M&eacute;todo",
    },
    "plant:type": {
        "de": "Kraftwerkstyp",
        "en": "Power plant type",
        "fr": "Type de centrale &eacute;lectrique",
        "it": "Tipo di centrale elettrica",
        "es": "Tipo de central el&eacute;ctrica",
    },
    "plant:output:steam": {
        "de": "Dampferzeugung",
        "en": "Steam generation",
        "fr": "Production de vapeur",
        "it": "Generazione di vapore",
        "es": "Generaci&oacute;n de vapor",
    },
    "map.other_fossil": {
        "de": "Andere Fossil",
        "en": "Other fossil",
        "fr": "Autre fossile",
        "it": "Altro fossile",
        "es": "Otros f&oacute;siles",
    },
    "building:levels": {
        "de": "Stockwerke",
        "en": "Floors",
        "fr": "&Eacute;tages",
        "it": "Pavimenti",
        "es": "Suelos",
    },
    "generator:manufacturer": {
        "de": "Hersteller",
        "en": "Manufacturer",
        "fr": "Fabricant",
        "it": "Produttore",
        "es": "Fabricante",
    },
    "generator:solar:modules": {
        "de": "Anzahl Solarmodule",
        "en": "Number of solar modules",
        "fr": "Nombre de modules solaires",
        "it": "Numero di moduli solari",
        "es": "N&uacute;mero de m&oacute;dulos solares",
    },
    "generator:orientation": {
        "de": "Ausrichtung",
        "en": "Alignment",
        "fr": "Alignement",
        "it": "Allineamento",
        "es": "Alineaci&oacute;n",
    },
    "horizontal_axis": {
        "de": "Horizontalläufer",
        "en": "Horizontal axis",
        "fr": "Axe horizontal",
        "it": "Asse orizzontale",
        "es": "Rotor horizontal",
    },
    "solar_photovoltaic_panel": {
        "de": "Sonnenkollektor",
        "en": "Solar panel",
        "fr": "Panneau solaire",
        "it": "Pannello solare",
        "es": "Colector solar",
    },
    "solar_photovoltaic_panels": {
        "de": "Sonnenkollektor",
        "en": "Solar panel",
        "fr": "Panneau solaire",
        "it": "Pannello solare",
        "es": "Colector solar",
    },
    "yes": {
        "de": "ja",
        "en": "yes",
        "fr": "oui",
        "it": "s&igrave;",
        "es": "ja",
    },
    "combustion": {
        "de": "Verbrennung",
        "en": "combustion",
        "fr": "combustion",
        "it": "combustione",
        "es": "Combusti&oacute;n",
    },
    "steam_turbine": {
        "de": "Dampfturbine",
        "en": "Steam turbine",
        "fr": "Turbine &agrave; vapeur",
        "it": "Turbina a vapore",
        "es": "Turbina de vapor",
    },
    "waste": {
        "de": "Müll",
        "en": "waste",
        "fr": "d&eacute;chets",
        "it": "rifiuti",
        "es": "Basura",
    },
    "roof": {
        "de": "Dach",
        "en": "roof",
        "fr": "toit",
        "it": "tetto",
        "es": "Techo",
    },
    "solar": {
        "de": "Solar",
        "en": "Solar",
        "fr": "Solaire",
        "it": "Solare",
        "es": "Solar",
    },
    "small_installation": {
        "de": "Kleinanlage",
        "en": "Small plant",
        "fr": "Petite installation",
        "it": "Piccola pianta",
        "es": "Planta peque&ntilde;a",
    },
    "reciprocating_engine": {
        "de": "Hubkolbenmotor",
        "en": "reciprocating engine",
        "fr": "moteur &agrave; mouvement alternatif",
        "it": "motore alternativo",
        "es": "Motor de pist&oacute;n alternativo",
    },
    "diesel": {
        "de": "Diesel",
        "en": "Diesel",
        "fr": "Diesel",
        "it": "Diesel",
        "es": "Diesel",
    },
    "fission": {
        "de": "Spaltung",
        "en": "fission",
        "fr": "fission",
        "it": "fission",
        "es": "Dividir",
    },
    "anaerobic_digestion": {
        "de": "anaerobe G&auml;rung",
        "en": "anaerobic digestion",
        "fr": "digestion ana&eacute;robie",
        "it": "digestione anaerobica",
        "es": "digesti&oacute;n anaerobia",
    },
    "wood": {
        "de": "Holz",
        "en": "wood",
        "fr": "bois",
        "it": "legno",
        "es": "Madera",
    },
    "hydro": {
        "de": "Wasserkraft",
        "en": "Hydropower",
        "fr": "&Eacute;nergie hydraulique",
        "it": "Energia idroelettrica",
        "es": "Energ&iacute;a hidroel&eacute;ctrica",
    },
    "hot_water": {
        "de": "Warmwasser",
        "en": "hot water",
        "fr": "eau chaude",
        "it": "acqua calda",
        "es": "Agua caliente",
    },
    "oil_shale": {
        "de": "&Ouml;lschiefer",
        "en": "oil shale",
        "fr": "schiste bitumineux",
        "it": "scisto bituminoso",
        "es": "&amp;Ouml;l pizarra",
    },
    "steam_generator": {
        "de": "Dampfgenerator",
        "en": "Steam generator",
        "fr": "G&eacute;n&eacute;rateur de vapeur",
        "it": "Generatore di vapore",
        "es": "Generador de vapor",
    },
    "combined_cycle": {
        "de": "Gas-und-Dampfturbinen-Kraftwerk",
        "en": "Combined-cycle gas turbine power plant",
        "fr": "Centrale &eacute;lectrique &agrave; turbines &agrave; gaz et &agrave; vapeur",
        "it": "Centrale elettrica a ciclo combinato",
        "es": "Central de ciclo combinado",
    },
    "Power (brutto)": {
        "de": "Bruttoleistung",
        "en": "Power (gross)",
        "fr": "Puissance (brute)",
        "it": "Produzione (lorda)",
        "es": "Producci&oacute;n bruta",
    },
    "Bundesland": {
        "de": "Bundesland",
        "en": "State",
        "fr": "&Eacute;tat f&eacute;d&eacute;ral",
        "it": "Stato",
        "es": "Estado",
    },
    "Landkreis": {
        "de": "Landkreis",
        "en": "County",
        "fr": "Comt&eacute;",
        "it": "Contea",
        "es": "Condado",
    },
    "no_powerplants_in_region": {
        "de": "F&uuml;r die ausgew&auml;hlte Region sind aktuell keine Kraftwerke eingetragen.",
        "en": "There are currently no power plants registered for the selected region.",
        "fr": "Aucune centrale &eacute;lectrique n'est actuellement enregistr&eacute;e pour la r&eacute;gion s&eacute;lectionn&eacute;e.",
        "it": "Attualmente non ci sono centrali elettriche registrate per la regione selezionata.",
        "es": "Actualmente no hay centrales el&eacute;ctricas registradas para la regi&oacute;n seleccionada.",
    },
    "map.show-only-top-tenthousand-power-plants": {
        "de": "Aus Performance-Gr&uuml;nden werden nur die 10.000 Kraftwerke mit der h&ouml;chsten Kapazit&auml;t angezeigt. Um weitere Kraftwerke einzublenden, w&auml;hlen sie einen Verwaltungsbezirk aus.",
        "en": "For performance reasons, only the 10,000 power plants with the highest capacity are displayed. To show more power plants, select an administrative district.",
        "fr": "Pour des raisons de performance, seules les 10.000 centrales &eacute;lectriques avec la plus grande capacit&eacute; sont affich&eacute;es. Pour afficher d'autres centrales, s&eacute;lectionnez un district administratif.",
        "it": "Per motivi di prestazioni, vengono visualizzate solo le 10.000 centrali con la capacit&agrave; pi&ugrave; elevata. Per visualizzare altre centrali, selezionare un distretto amministrativo.",
        "es": "Por razones de rendimiento, s&oacute;lo se muestran las 10.000 centrales el&eacute;ctricas con mayor capacidad. Para mostrar m&aacute;s centrales el&eacute;ctricas, seleccione un distrito administrativo.",
    },
    "name:en": {
        "de": "englischer Name",
        "en": "English name",
        "fr": "nom anglais",
        "it": "Nome inglese",
        "es": "Nombre en ingl&eacute;s",
    },
    "name:nl": {
        "de": "niederl&auml;ndischer Name",
        "en": "Dutch name",
        "fr": "nom n&eacute;erlandais",
        "it": "Nome olandese",
        "es": "Nombre holand&eacute;s",
    },
    "name:fy": {
        "de": "friesischer Name",
        "en": "Frisian name",
        "fr": "nom frison",
        "it": "Nome frisone",
        "es": "Nombre frisio",
    },
    "name:ru": {
        "de": "russischer Name",
        "en": "Russian name",
        "fr": "nom russe",
        "it": "Nome russo",
        "es": "Nombre ruso",
    },
    "name:fr": {
        "de": "franz&ouml;sischer Name",
        "en": "French name",
        "fr": "nom français",
        "it": "Nome francese",
        "es": "Nombre en franc&eacute;s",
    },
    "name:bg": {
        "de": "belgischer Name",
        "en": "Belgian name",
        "fr": "nom belge",
        "it": "Nome belga",
        "es": "Nombre belga",
    },
    "name:ro": {
        "de": "rum&auml;nischer Name",
        "en": "Romanian name",
        "fr": "nom roumain",
        "it": "Nome rumeno",
        "es": "Nombre rumano",
    },
    "name:uk": {
        "de": "britischer Name",
        "en": "British name",
        "fr": "nom britannique",
        "it": "Nome britannico",
        "es": "Nombre brit&aacute;nico",
    },
    "name:de": {
        "de": "deutscher Name",
        "en": "German name",
        "fr": "nom allemand",
        "it": "Nome tedesco",
        "es": "Nombre alem&aacute;n",
    },
    "name:hy": {
        "de": "armenischer Name",
        "en": "Armenian name",
        "fr": "nom arm&eacute;nien",
        "it": "Nome armeno",
        "es": "Nombre armenio",
    },
    "name:sl": {
        "de": "slowenischer Name",
        "en": "Slovenian name",
        "fr": "nom slov&egrave;ne",
        "it": "Nome sloveno",
        "es": "Nombre esloveno",
    },
    "name:sr": {
        "de": "serbokroatischer Name",
        "en": "Serbo-Croatian name",
        "fr": "nom serbo-croate",
        "it": "Nome serbo-croato",
        "es": "Nombre serbocroata",
    },
    "name:be": {
        "de": "belarussicher Name",
        "en": "Belarusian name",
        "fr": "nom bi&eacute;lorusse",
        "it": "Nome bielorusso",
        "es": "Nombre bielorruso",
    },
    "name:pl": {
        "de": "polnischer Name",
        "en": "Polish name",
        "fr": "nom polonais",
        "it": "Nome polacco",
        "es": "Nombre polaco",
    },
    "name:it": {
        "de": "italienischer Name",
        "en": "Italian name",
        "fr": "nom italien",
        "it": "Nome italiano",
        "es": "Nombre italiano",
    },
    "seamark:landmark:category": {
        "de": "Kategorie Landmarke",
        "en": "Category landmark",
        "fr": "Cat&eacute;gorie Rep&egrave;re",
        "it": "Categoria Landmark",
        "es": "Categor&iacute;a Hito",
    },
    "seamark:type": {
        "de": "Typ Seemarke",
        "en": "Type sea mark",
        "fr": "Type Marque maritime",
        "it": "Tipo Seemark",
        "es": "Tipo Seemark",
    },
    "seamark:light:colour": {
        "de": "Seemarke Lichtfarbe",
        "en": "Sea mark light color",
        "fr": "Marque du lac Couleur de la lumi&egrave;re",
        "it": "Segno del mare Colore chiaro",
        "es": "Marca del mar Color claro",
    },
    "seamark:light:height": {
        "de": "Seemarke Feuerh&ouml;he",
        "en": "Lake mark fire height",
        "fr": "Marque maritime hauteur de feu",
        "it": "Segno del mare Altezza del fuoco",
        "es": "Seemark Feuerh&amp;ouml;he",
    },
    "seamark:light:period": {
        "de": "Seemarke Lichtperiode",
        "en": "Sea mark light period",
        "fr": "Marque maritime P&eacute;riode lumineuse",
        "it": "Segno di mare Periodo di luce",
        "es": "Marca de mar Periodo de luz",
    },
    "map.power": {
        "de": "Leistung",
        "en": "Power",
        "fr": "Puissance",
        "it": "Potenza",
        "es": "Potencia",
    },
    "generator:storage": {
        "de": "Speicherkapazit&auml;t",
        "en": "Storage capacity",
        "fr": "Capacit&eacute; de stockage",
        "it": "Capacit&agrave; di stoccaggio",
        "es": "Capacidad de almacenamiento",
    },
    "white": {
        "de": "wei&szlig;",
        "en": "white",
        "fr": "blanc",
        "it": "bianco",
        "es": "wei&amp;szlig;",
    },
    "fence": {
        "de": "Zaun",
        "en": "fence",
        "fr": "clôture",
        "it": "recinzione",
        "es": "Valla",
    },
    "battery-storage": {
        "de": "Batteriespeicher",
        "en": "Battery storage",
        "fr": "Stockage sur batterie",
        "it": "Accumulo a batteria",
        "es": "Almacenamiento en bater&iacute;a",
    },
    "container": {
        "de": "Container",
        "en": "container",
        "fr": "conteneur",
        "it": "contenitore",
        "es": "Contenedor",
    },
    "old_name": {
        "de": "alter Name",
        "en": "old name",
        "fr": "ancien nom",
        "it": "vecchio nome",
        "es": "nombre antiguo",
    },
    "year_of_construction": {
        "de": "Baujahr",
        "en": "Year of manufacture",
        "fr": "Ann&eacute;e de construction",
        "it": "Anno di produzione",
        "es": "A&ntilde;o de fabricaci&oacute;n",
    },
    "turbines": {
        "de": "Turbinen",
        "en": "Turbines",
        "fr": "Turbines",
        "it": "Turbine",
        "es": "Turbinas",
    },
    "generator:rotordiameter": {
        "de": "Rotordurchmesser",
        "en": "Rotor diameter",
        "fr": "Diam&egrave;tre du rotor",
        "it": "Diametro del rotore",
        "es": "Di&aacute;metro del rotor",
    },
    "alt_name": {
        "de": "Alternativer Name",
        "en": "Alternate name",
        "fr": "Nom alternatif",
        "it": "Nome alternativo",
        "es": "Nombre alternativo",
    },
    "generator:output:steam": {
        "de": "Dampferzeugung",
        "en": "Steam generation",
        "fr": "Production de vapeur",
        "it": "Generazione di vapore",
        "es": "Generaci&oacute;n de vapor",
    },
    "addr:housename": {
        "de": "Name des Geb&auml;udes",
        "en": "Name of the building",
        "fr": "Nom du bâtiment",
        "it": "Nome dell'edificio",
        "es": "Nombre del edificio",
    },
    "old_website": {
        "de": "vorherige Webseite",
        "en": "previous web page",
        "fr": "site web pr&eacute;c&eacute;dent",
        "it": "pagina web precedente",
        "es": "p&aacute;gina web anterior",
    },
    "site": {
        "de": "Standort",
        "en": "Location",
        "fr": "Site",
        "it": "Posizione",
        "es": "Ubicaci&oacute;n",
    },
    "image": {
        "de": "Grafik",
        "en": "Graphic",
        "fr": "Graphique",
        "it": "Grafica",
        "es": "Gr&aacute;fico",
    },
    "direction": {
        "de": "Richtung",
        "en": "direction",
        "fr": "direction",
        "it": "direzione",
        "es": "Direcci&oacute;n",
    },
    "source:plant:output:electricity": {
        "de": "Erzeugung",
        "en": "Generation",
        "fr": "G&eacute;n&eacute;ration",
        "it": "Generazione",
        "es": "Generaci&oacute;n",
    },
    "generator:output": {
        "de": "Erzeugung",
        "en": "Generation",
        "fr": "G&eacute;n&eacute;ration",
        "it": "Generazione",
        "es": "Generaci&oacute;n",
    },
    "generator:output:biogas": {
        "de": "Erzeugung von Biogas",
        "en": "Biogas production",
        "fr": "Production de biogaz",
        "it": "Produzione di biogas",
        "es": "Producci&oacute;n de biog&aacute;s",
    },
    "fence_type": {
        "de": "Art der Umz&auml;unung",
        "en": "Type of fencing",
        "fr": "Type de clôture",
        "it": "Tipo di recinzione",
        "es": "Tipo de conversi&oacute;n",
    },
    "parking": {
        "de": "Parkplatz",
        "en": "parking",
        "fr": "parking",
        "it": "parcheggio",
        "es": "Parkplatz",
    },
    "smoking": {
        "de": "Rauchen",
        "en": "smoking",
        "fr": "fumer",
        "it": "fumare",
        "es": "Fumar",
    },
    "wheelchair": {
        "de": "Rollstuhl",
        "en": "wheelchair",
        "fr": "fauteuil roulant",
        "it": "Sedia a rotelle",
        "es": "Silla de ruedas",
    },
    "SourceRedundant": {
        "de": "Energietr&auml;ger",
        "en": "Energy source",
        "fr": "Source d'&eacute;nergie",
        "it": "Fonte di energia",
        "es": "Portador de energ&iacute;a",
    },
    "no_label": {
        "de": "Nein",
        "en": "No",
        "fr": "Non",
        "it": "No",
        "es": "No",
    },
    "map.no-renewables-in-bnetza": {
        "de": "Solar- und Windkraftwerke werden in der BNetzA-Liste nicht mehr aufgef&uuml;hrt. Um diese anzusehen w&auml;hlen sie unter Quelle das Markstammdatenregister aus.",
        "en": "Solar and wind power plants are no longer listed in the BNetzA list. To view them, select the market master data register under Source.",
        "fr": "Les centrales solaires et &eacute;oliennes ne figurent plus dans la liste de la BNetzA. Pour les consulter, s&eacute;lectionnez le registre des donn&eacute;es de base du march&eacute; sous Source.",
        "it": "Gli impianti solari ed eolici non sono pi&ugrave; presenti nell'elenco di BNetzA. Per visualizzarli, selezionare il Registro dei dati anagrafici di mercato alla voce Origine.",
        "es": "Las centrales solares y e&oacute;licas ya no figuran en la lista BNetzA. Para verlas, seleccione el registro de datos maestros del mercado en Fuente.",
    },
    "vertical_axis": {
        "de": "Vertikaler Rotor",
        "en": "Vertical rotor",
        "fr": "Rotor vertical",
        "it": "Rotore verticale",
        "es": "Rotor vertical",
    },
    "osmotic": {
        "de": "Osmose",
        "en": "Osmosis",
        "fr": "Osmose",
        "it": "Osmosi",
        "es": "Osmosis",
    },
    "renewable_share_production_public": {
        "de": "Anteil EE an Erzeugung, &ouml;ffentlich",
        "en": "Share of RE in generation, public",
        "fr": "Part des SER dans la production, public",
        "it": "Quota di RE nella generazione, pubblico",
        "es": "Cuota de las ER en la generaci&oacute;n, &amp;ouml;p&uacute;blica",
    },
    "renewable_share_load_public": {
        "de": "Anteil EE an Last, &ouml;ffentlich",
        "en": "Share of RE in load, public",
        "fr": "Part des SER dans la charge, public",
        "it": "Quota di RE nel carico, pubblico",
        "es": "Cuota de las ER en la carga, &amp;ouml;p&uacute;blica",
    },
    "solar_share_production_public": {
        "de": "Anteil Solar an Erzeugung, &ouml;ffentlich",
        "en": "Share of solar in generation, public",
        "fr": "Part du solaire dans la production, public",
        "it": "Quota del solare nella generazione, pubblico",
        "es": "Cuota de la energ&iacute;a solar en la generaci&oacute;n, &amp;ouml;p&uacute;blica",
    },
    "solar_share_load_public": {
        "de": "Anteil Solar an Last, &ouml;ffentlich",
        "en": "Share of solar in load, public",
        "fr": "Part du solaire dans la charge, public",
        "it": "Quota del solare nel carico, pubblico",
        "es": "Proporci&oacute;n de energ&iacute;a solar en la carga, &amp;ouml;p&uacute;blica",
    },
    "wind_share_production_public": {
        "de": "Anteil Wind an Erzeugung, &ouml;ffentlich",
        "en": "Share of wind in generation, public",
        "fr": "Part du vent dans la production, public",
        "it": "Quota dell'eolico nella generazione, pubblico",
        "es": "Proporci&oacute;n de energ&iacute;a e&oacute;lica en la generaci&oacute;n, &amp;ouml;p&uacute;blica",
    },
    "wind_share_load_public": {
        "de": "Anteil Wind an Last, &ouml;ffentlich",
        "en": "Share of wind in load, public",
        "fr": "Part du vent dans la charge, public",
        "it": "Quota di vento nel carico, pubblico",
        "es": "Proporci&oacute;n de viento en la carga, &amp;ouml;ffentlich",
    },
    "renewable_share_production_all": {
        "de": "Anteil EE an Erzeugung, gesamt",
        "en": "Share of renewables in total generation",
        "fr": "Part des SER dans la production, total",
        "it": "Quota di RE sulla produzione totale",
        "es": "Cuota de las ER en la generaci&oacute;n total",
    },
    "renewable_share_load_all": {
        "de": "Anteil EE an Last, gesamt",
        "en": "Share of RE in total load",
        "fr": "Part des SER dans la charge, total",
        "it": "Quota di RE nel carico, totale",
        "es": "Porcentaje de ER en la carga, total",
    },
    "solar_share_production_all": {
        "de": "Anteil Solar an Erzeugung, gesamt",
        "en": "Share of solar in total generation",
        "fr": "Part du solaire dans la production, total",
        "it": "Quota del solare sulla produzione totale",
        "es": "Cuota de la energ&iacute;a solar en la generaci&oacute;n total",
    },
    "solar_share_load_all": {
        "de": "Anteil Solar an Last, gesamt",
        "en": "Share of solar in total load",
        "fr": "Part du solaire dans la charge, total",
        "it": "Quota del solare sul carico totale",
        "es": "Proporci&oacute;n de energ&iacute;a solar en la carga total",
    },
    "wind_share_production_all": {
        "de": "Anteil Wind an Erzeugung, gesamt",
        "en": "Share of wind in total generation",
        "fr": "Part du vent dans la production, total",
        "it": "Quota dell'eolico sulla produzione totale",
        "es": "Proporci&oacute;n de energ&iacute;a e&oacute;lica en la generaci&oacute;n total",
    },
    "wind_share_load_all": {
        "de": "Anteil Wind an Last, gesamt",
        "en": "Share of wind in total load",
        "fr": "Part du vent dans la charge, total",
        "it": "Quota di carico del vento, totale",
        "es": "Porcentaje de carga e&oacute;lica, total",
    },
    "No data": {
        "de": "Keine Daten",
        "en": "No data",
        "fr": "Pas de donn&eacute;es",
        "it": "Nessun dato",
        "es": "Sin datos",
    },
    "show_values": {
        "de": "Werte anzeigen",
        "en": "Show values",
        "fr": "Afficher les valeurs",
        "it": "Mostra i valori",
        "es": "Mostrar valores",
    },
    "wind_share_onshore": {
        "de": "Anteil Wind onshore",
        "en": "Anteil Wind onshore",
        "fr": "Anteil Wind onshore",
        "it": "Anteil Eolico onshore",
        "es": "Anteil E&oacute;lica terrestre",
    },
    "wind_share_offshore": {
        "de": "Anteil Wind offshore",
        "en": "Anteil Wind offshore",
        "fr": "Anteil Wind offshore",
        "it": "Anteil Wind offshore",
        "es": "Anteil Wind offshore",
    },
    "solar_share_of_generation": {
        "de": "Anteil Solar an der Erzeugung",
        "en": "Share of solar in generation",
        "fr": "Part du solaire dans la production",
        "it": "Quota del solare nella generazione",
        "es": "Cuota de la energ&iacute;a solar en la generaci&oacute;n",
    },
    "solar_share_of_load": {
        "de": "Anteil Solar an der Last",
        "en": "Share of solar in the load",
        "fr": "Part du solaire dans la charge",
        "it": "Quota del solare nel carico",
        "es": "Proporci&oacute;n de energ&iacute;a solar en la carga",
    },
    "wind_onshore_share_of_generation": {
        "de": "Anteil Wind onshore an der Erzeugung",
        "en": "Share of wind onshore in generation",
        "fr": "Part de l'&eacute;olien teresstre de la production",
        "it": "Quota dell'eolico onshore nella generazione",
        "es": "Porcentaje de generaci&oacute;n e&oacute;lica terrestre",
    },
    "wind_onshore_share_of_load": {
        "de": "Anteil Wind onshore an der Last",
        "en": "Share of wind onshore in load",
        "fr": "Part du vent onshore dans la charge",
        "it": "Quota dell'eolico onshore nel carico",
        "es": "Cuota de la energ&iacute;a e&oacute;lica terrestre en la carga",
    },
    "wind_offshore_share_of_generation": {
        "de": "Anteil Wind offshore an der Erzeugung",
        "en": "Share of wind offshore in generation",
        "fr": "Part de l'&eacute;olien en mer de la production",
        "it": "Quota dell'eolico offshore nella generazione",
        "es": "Porcentaje de generaci&oacute;n e&oacute;lica marina",
    },
    "wind_offshore_share_of_load": {
        "de": "Anteil Wind offshore an der Last",
        "en": "Share of wind offshore in load",
        "fr": "Part du vent offshore dans la charge",
        "it": "Quota di eolico offshore nel carico",
        "es": "Porcentaje de energ&iacute;a e&oacute;lica marina en la carga",
    },
    "renrewableShareMap_color_sclae_desc": {
        "de": "Die Farbskalen sind jeweils angepasst an den h&ouml;chsten gemessenen Jahreswert der jeweiligen Kategorie aller L&auml;nder.",
        "en": "The color scales are each adjusted to the highest measured annual value of the respective category of all countries.",
        "fr": "Les &eacute;chelles de couleur sont adapt&eacute;es &agrave; la valeur annuelle la plus &eacute;lev&eacute;e mesur&eacute;e dans chaque cat&eacute;gorie de tous les pays.",
        "it": "Le scale di colore sono state adattate al valore annuale pi&ugrave; alto misurato della rispettiva categoria di tutti i Paesi.",
        "es": "Las escalas de colores se adaptan al valor anual m&aacute;s alto medido de la categor&iacute;a respectiva de todos los pa&iacute;ses.",
    },
    "show_piechart": {
        "de": "Kreisdiagramm zur Erzeugung ansehen",
        "en": "View generation pie chart",
        "fr": "Voir le diagramme circulaire de la g&eacute;n&eacute;ration",
        "it": "Visualizza il grafico a torta della generazione",
        "es": "Ver el gr&aacute;fico circular de la generaci&oacute;n",
    },
    "biddingzone_no-no1": {
        "de": "Gebotszone Norwegen 1",
        "en": "Bidding Zone Norway 1",
        "fr": "Zone d'ench&egrave;res Norv&egrave;ge 1",
        "it": "Zona d'offerta Norvegia 1",
        "es": "Zona de licitaci&oacute;n Noruega 1",
    },
    "biddingzone_no-no2": {
        "de": "Gebotszone Norwegen 2",
        "en": "Bidding Zone Norway 2",
        "fr": "Zone d'ench&egrave;res Norv&egrave;ge 2",
        "it": "Zona d'offerta Norvegia 2",
        "es": "Zona de licitaci&oacute;n Noruega 2",
    },
    "biddingzone_no-no3": {
        "de": "Gebotszone Norwegen 3",
        "en": "Bidding Zone Norway 3",
        "fr": "Zone d'ench&egrave;res Norv&egrave;ge 3",
        "it": "Zona d'offerta Norvegia 3",
        "es": "Zona de licitaci&oacute;n Noruega 3",
    },
    "biddingzone_no-no4": {
        "de": "Gebotszone Norwegen 4",
        "en": "Bidding Zone Norway 4",
        "fr": "Zone d'ench&egrave;res Norv&egrave;ge 4",
        "it": "Zona d'offerta Norvegia 4",
        "es": "Zona de licitaci&oacute;n Noruega 4",
    },
    "biddingzone_no-no5": {
        "de": "Gebotszone Norwegen 5",
        "en": "Bidding Zone Norway 5",
        "fr": "Zone d'ench&egrave;res Norv&egrave;ge 5",
        "it": "Zona d'offerta Norvegia 5",
        "es": "Zona de licitaci&oacute;n Noruega 5",
    },
    "biddingzone_se-se1": {
        "de": "Gebotszone Schweden 1",
        "en": "Bidding Zone Sweden 1",
        "fr": "Zone d'ench&egrave;res Su&egrave;de 1",
        "it": "Zona d'offerta Svezia 1",
        "es": "Zona de licitaci&oacute;n Suecia 1",
    },
    "biddingzone_se-se2": {
        "de": "Gebotszone Schweden 2",
        "en": "Bidding Zone Sweden 2",
        "fr": "Zone d'ench&egrave;res Su&egrave;de 2",
        "it": "Zona d'offerta Svezia 2",
        "es": "Zona de licitaci&oacute;n Suecia 2",
    },
    "biddingzone_se-se3": {
        "de": "Gebotszone Schweden 3",
        "en": "Bidding Zone Sweden 3",
        "fr": "Zone d'ench&egrave;res Su&egrave;de 3",
        "it": "Zona d'offerta Svezia 3",
        "es": "Zona de licitaci&oacute;n Suecia 3",
    },
    "biddingzone_se-se4": {
        "de": "Gebotszone Schweden 4",
        "en": "Bidding Zone Sweden 4",
        "fr": "Zone d'ench&egrave;res Su&egrave;de 4",
        "it": "Zona d'offerta Svezia 4",
        "es": "Zona de licitaci&oacute;n Suecia 4",
    },
    "biddingzone_it-cso": {
        "de": "Gebotszone Zentral-S&uuml;d-Italien",
        "en": "Bidding Zone Central South Italy",
        "fr": "Zone d'ench&egrave;res centre-sud de l'Italie",
        "it": "Zona d'offerta Centro-Sud Italia",
        "es": "Zona de licitaci&oacute;n Italia Centro-Sur",
    },
    "biddingzone_it-cno": {
        "de": "Gebotszone Zentral-Nord-Italien",
        "en": "Bidding Zone Central North Italy",
        "fr": "Zone d'ench&egrave;res centre-nord de l'Italie",
        "it": "Zona d'offerta Centro Nord Italia",
        "es": "Zona de licitaci&oacute;n del centro-norte de Italia",
    },
    "biddingzone_it-no": {
        "de": "Gebotszone Nord-Italien",
        "en": "Bidding Zone Nord-Italien",
        "fr": "Zone d'ench&egrave;res Nord-Italien",
        "it": "Zona di offerta Nord-Italiano",
        "es": "Zona de licitaci&oacute;n Nord-Italien",
    },
    "biddingzone_it-calabria": {
        "de": "Gebotszone Kalabrien (Italien)",
        "en": "Bid Zone Calabria (Italy)",
        "fr": "Zone d'ench&egrave;res Calabre (Italie)",
        "it": "Area Bid Calabria (Italia)",
        "es": "Oferta Zona Calabria (Italia)",
    },

    "biddingzone_it-sic": {
        "de": "Gebotszone Sizilien (Italien)",
        "en": "Bidding Zone Sicily (Italy)",
        "fr": "Zone d'ench&egrave;res Sicile (Italie)",
        "it": "Zona di offerta Sicilia (Italia)",
        "es": "Zona de licitaci&oacute;n Sicilia (Italia)",
    },
    "biddingzone_it-so": {
        "de": "Gebotszone S&uuml;d-Italien",
        "en": "Bidding Zone South Italy",
        "fr": "Zone d'ench&egrave;res du sud de l'Italie",
        "it": "Zona d'offerta Sud Italia",
        "es": "Zona de licitaci&oacute;n del sur de Italia",
    },
    "biddingzone_it-sar": {
        "de": "Gebotszone Sardinien (Italien)",
        "en": "Bidding Zone Sardinia (Italy)",
        "fr": "Zone d'ench&egrave;res Sardaigne (Italie)",
        "it": "Area offerte Sardegna (Italia)",
        "es": "&Aacute;rea de licitaci&oacute;n Cerde&ntilde;a (Italia)",
    },
    "biddingzone_it-priolo": {
        "de": "Gebotszone Priolo (Italien)",
        "en": "Priolo bidding zone (Italy)",
        "fr": "Zone d'ench&egrave;res Priolo (Italie)",
        "it": "Zona d'asta di Priolo (Italia)",
        "es": "Zona de licitaci&oacute;n de Priolo (Italia)",
    },
    "biddingzone_it-north-at": {
        "de": "Gebotszone Italien-Nord - &Ouml;sterreich",
        "en": "Bid Zone Italy North - Austria",
        "fr": "Zone d'ench&egrave;res Italie-Nord - Autriche",
        "it": "Zona d'offerta Italia-Nord - Austria",
        "es": "Zona de licitaci&oacute;n Italia-Norte - Austria",
    },
    "biddingzone_it-north-ch": {
        "de": "Gebotszone Italien-Nord - Schweiz",
        "en": "Bid Zone Italy North - Switzerland",
        "fr": "Zone d'ench&egrave;res Italie-Nord - Suisse",
        "it": "Zona d'offerta Italia-Nord - Svizzera",
        "es": "Zona de licitaci&oacute;n Italia-Norte - Suiza",
    },
    "biddingzone_it-north-fr": {
        "de": "Gebotszone Italien-Nord - Frankreich",
        "en": "Bid Zone Italy North - France",
        "fr": "Zone d'ench&egrave;res Italie-Nord - France",
        "it": "Zona d'offerta Italia-Nord - Francia",
        "es": "Zona de licitaci&oacute;n Italia-Norte - Francia",
    },
    "biddingzone_it-north-si": {
        "de": "Gebotszone Italien-Nord - Slowenien",
        "en": "Bidding zone Italy North - Slovenia",
        "fr": "Zone d'ench&egrave;res Italie-Nord - Slov&eacute;nie",
        "it": "Zona d'offerta Italia Nord - Slovenia",
        "es": "Zona de licitaci&oacute;n Italia Norte - Eslovenia",
    },
    "biddingzone_it-foggia": {
        "de": "Gebotszone Foggia (Italien)",
        "en": "Gebotszone Foggia (Italien)",
        "fr": "Gebotszone Foggia (Italien)",
        "it": "Gebotszone Foggia (Italien)",
        "es": "Gebotszone Foggia (Italia)",
    },
    "biddingzone_it-rossano": {
        "de": "Gebotszone Rossano (Italien)",
        "en": "Gebotszone Rossano (Italien)",
        "fr": "Gebotszone Rossano (Italien)",
        "it": "Gebotszone Rossano (Italien)",
        "es": "Gebotszone Rossano (Italia)",
    },

    "biddingzone_it-brindisi": {
        "de": "Gebotszone Brindisi (Italien)",
        "en": "Bidding zone Brindisi (Italy)",
        "fr": "Zone d'ench&egrave;res de Brindisi (Italie)",
        "it": "Zona d'offerta Brindisi (Italia)",
        "es": "Zona de licitaci&oacute;n Brindisi (Italia)",
    },
    "biddingzone_fr-cor": {
        "de": "Gebotszone Korsika (Frankreich)",
        "en": "Bid Zone Corsica (France)",
        "fr": "Zone d'ench&egrave;res Corse (France)",
        "it": "Zona d'offerta Corsica (Francia)",
        "es": "Zona de licitaci&oacute;n C&oacute;rcega (Francia)",
    },
    "pageTitle_price_average_map": {
        "de": "B&ouml;rsenstrompreise - Karte | Energy-Charts",
        "en": "Exchange electricity prices - Map | Energy-Charts",
        "fr": "Prix de l'&eacute;lectricit&eacute; en bourse - carte | Energy-Charts",
        "it": "Prezzi dell'elettricit&agrave; in borsa - Mappa | Energy-Charts",
        "es": "Precios de la electricidad en bolsa - Mapa | Energy-Charts",
    },
    "biddingzone_dk-dk1": {
        "de": "Gebotszone D&auml;nemark 1",
        "en": "Bidding zone Denmark 1",
        "fr": "Zone d'ench&egrave;res Danemark 1",
        "it": "Zona d'offerta Danimarca 1",
        "es": "Zona de licitaci&oacute;n Dinamarca 1",
    },
    "biddingzone_dk-dk2": {
        "de": "Gebotszone D&auml;nemark 2",
        "en": "Bidding zone Denmark 2",
        "fr": "Zone d'ench&egrave;res Danemark 2",
        "it": "Zona d'offerta Danimarca 2",
        "es": "Zona de licitaci&oacute;n Dinamarca 2",
    },
    "biddingzone_gb-ork": {
        "de": "Gebotszone Orkney Inseln (Vereinigtes K&ouml;nigreich)",
        "en": "Orkney Islands bidding zone (United Kingdom)",
        "fr": "Zone d'ench&egrave;res des îles Orcades (Royaume-Uni)",
        "it": "Zona di candidatura delle Isole Orcadi (Regno Unito)",
        "es": "Zona de licitaci&oacute;n de las Islas Orcadas (Reino Unido)",
    },
    "biddingzone_gb-zet": {
        "de": "Gebotszone Shetlandinseln (Vereinigtes K&ouml;nigreich)",
        "en": "Shetland Islands bidding zone (United Kingdom)",
        "fr": "Zone d'ench&egrave;res Îles Shetland (Royaume-Uni)",
        "it": "Zona di candidatura delle Isole Shetland (Regno Unito)",
        "es": "Zona de licitaci&oacute;n de las Islas Shetland (Reino Unido)",
    },
    "biddingzone_gb-nir": {
        "de": "Gebotszone Nordirland (Vereinigtes K&ouml;nigreich)",
        "en": "Northern Ireland bidding zone (United Kingdom)",
        "fr": "Zone d'ench&egrave;res Irlande du Nord (Royaume-Uni)",
        "it": "Zona di offerta Irlanda del Nord (Regno Unito)",
        "es": "Zona de licitaci&oacute;n Irlanda del Norte (Reino Unido)",
    },
    "biddingzone_no-no2nsl": {
        "de": "Gebotszone North Sea Link (Norwegen)",
        "en": "North Sea Link bidding zone (Norway)",
        "fr": "Zone d'ench&egrave;res North Sea Link (Norv&egrave;ge)",
        "it": "Zona d'offerta del North Sea Link (Norvegia)",
        "es": "Zona de licitaci&oacute;n del enlace del Mar del Norte (Noruega)",
    },
    "biddingzone_es-ml": {
        "de": "Gebotszone Melilla (Spanien)",
        "en": "Bidding zone Melilla (Spain)",
        "fr": "Zone d'ench&egrave;res de Melilla (Espagne)",
        "it": "Zona d'offerta Melilla (Spagna)",
        "es": "Zona de licitaci&oacute;n Melilla (Espa&ntilde;a)",
    },
    "biddingzone_ua-cr": {
        "de": "Gebotszone Krim (Ukraine)",
        "en": "Crimea bidding zone (Ukraine)",
        "fr": "Zone d'ench&egrave;res de Crim&eacute;e (Ukraine)",
        "it": "Zona d'asta della Crimea (Ucraina)",
        "es": "Zona de licitaci&oacute;n de Crimea (Ucrania)",
    },

    "pageTitle_renewable_share_map": {
        "de": "Anteil erneuerbarer Energien - Karte | Energy-Charts",
        "en": "Renewable Shares - Map | Energy-Charts",
        "fr": "Part des \u00e9nergies renouvelables - carte | Energy-Charts",
        "it": "Quota di energie rinnovabili - mappa | Energy-Charts",
        "es": "Cuota de energ&iacute;as renovables - Mapa | Energy-Charts",
    },
    "nitrousOxide": {
        "de": "Distickstoffoxid (N₂O)",
        "en": "Nitrous oxide (N₂O)",
        "fr": "Oxyde nitreux (N₂O)",
        "it": "Protossido di azoto (N₂O)",
        "es": "&Oacute;xido nitroso (N₂O)",
    },
    "carbonDioxide": {
        "de": "Kohlendioxid (CO2)",
        "en": "Carbon dioxide (CO2)",
        "fr": "Dioxyde de carbone (CO2)",
        "it": "Anidride carbonica (CO2)",
        "es": "Di&oacute;xido de carbono (CO2)",
    },
    "arsenic": {
        "de": "Arsen (As)",
        "en": "Arsenic (As)",
        "fr": "Arsenic (As)",
        "it": "Arsenico (As)",
        "es": "Ars&eacute;nico (As)",
    },
    "lead": {
        "de": "Blei (Pb)",
        "en": "Lead (Pb)",
        "fr": "Plomb (Pb)",
        "it": "Piombo (Pb)",
        "es": "Plomo (Pb)",
    },
    "cadmium": {
        "de": "Kadmium (Cd)",
        "en": "Cadmium (Cd)",
        "fr": "Cadmium (Cd)",
        "it": "Cadmio (Cd)",
        "es": "Cadmio (Cd)",
    },
    "chromium": {
        "de": "Chrom (Cr)",
        "en": "Chromium (Cr)",
        "fr": "Chrome (Cr)",
        "it": "Cromo (Cr)",
        "es": "Cromo (Cr)",
    },
    "copper": {
        "de": "Kupfer (Cu)",
        "en": "Copper (Cu)",
        "fr": "Cuivre (Cu)",
        "it": "Rame (Cu)",
        "es": "Cobre (Cu)",
    },
    "nickel": {
        "de": "Nickel (Ni)",
        "en": "Nickel (Ni)",
        "fr": "Nickel (Ni)",
        "it": "Nichel (Ni)",
        "es": "N&iacute;quel (Ni)",
    },
    "mercury": {
        "de": "Quecksilber (Hg)",
        "en": "Mercury (Hg)",
        "fr": "Mercure (Hg)",
        "it": "Mercurio (Hg)",
        "es": "Mercurio (Hg)",
    },
    "zinc": {
        "de": "Zink (Zn)",
        "en": "Zinc (Zn)",
        "fr": "Zinc (Zn)",
        "it": "Zinco (Zn)",
        "es": "Zinc (Zn)",
    },
    "benzene": {
        "de": "Benzene (C₆H₆)",
        "en": "Benzene (C₆H₆)",
        "fr": "Benz&egrave;nes (C₆H₆)",
        "it": "Benzene (C₆H₆)",
        "es": "Benceno (C₆H₆)",
    },
    "chlorine": {
        "de": "Chlor (Cl)",
        "en": "Chlorine (Cl)",
        "fr": "Chlore (Cl)",
        "it": "Cloro (Cl)",
        "es": "Cloro (Cl)",
    },
    "pcdd_pcdf": {
        "de": "Dioxine + Furane (PCDD + PCDF)",
        "en": "Dioxins + Furans (PCDD + PCDF)",
        "fr": "Dioxines + Furanes (PCDD + PCDF)",
        "it": "Diossine + Furani (PCDD + PCDF)",
        "es": "Dioxinas + Furanos (PCDD + PCDF)",
    },
    "particulateMatter": {
        "de": "Feinstaub (PM10)",
        "en": "Particulate matter (PM10)",
        "fr": "Mati&egrave;res particulaires (PM10)",
        "it": "Particolato (PM10)",
        "es": "Part&iacute;culas (PM10)",
    },
    "fluorine": {
        "de": "Fluorine (F)",
        "en": "Fluorine (F)",
        "fr": "Fluorine (F)",
        "it": "Fluoro (F)",
        "es": "Fl&uacute;or (F)",
    },
    "carbonMonoxide": {
        "de": "Kohlenmonoxid (CO)",
        "en": "Carbon monoxide (CO)",
        "fr": "Monoxyde de carbone (CO)",
        "it": "Monossido di carbonio (CO)",
        "es": "Mon&oacute;xido de carbono (CO)",
    },
    "sulphurOxides": {
        "de": "Schwefeloxide (SOₓ/SO₂)",
        "en": "Sulfur oxides (SOₓ/SO₂)",
        "fr": "Oxydes de soufre (SOₓ/SO₂)",
        "it": "Ossidi di zolfo (SOₓ/SO₂)",
        "es": "&Oacute;xidos de azufre (SOₓ/SO₂)",
    },
    "nitrogenOxides": {
        "de": "Stickstoffoxide (NOₓ/NO₂)",
        "en": "Nitrogen oxides (NOₓ/NO₂)",
        "fr": "Oxydes d'azote (NOₓ/NO₂)",
        "it": "Ossidi di azoto (NOₓ/NO₂)",
        "es": "&Oacute;xidos de nitr&oacute;geno (NOₓ/NO₂)",
    },
    "methan": {
        "de": "Methan (CH4)",
        "en": "Methan (CH4)",
        "fr": "M&eacute;thane (CH4)",
        "it": "Metano (CH4)",
        "es": "Metano (CH4)",
    },
    "ammonia": {
        "de": "Ammoniak (NH₃)",
        "en": "Ammonia (NH₃)",
        "fr": "Ammoniac (NH₃)",
        "it": "Ammoniaca (NH₃)",
        "es": "Amon&iacute;aco (NH₃)",
    },
    "hardCoalPowerPlants": {
        "de": "Steinkohlekraftwerke",
        "en": "Hard coal-fired power plants",
        "fr": "Centrales &agrave; charbon",
        "it": "Centrali a carbone fossile",
        "es": "Centrales el&eacute;ctricas de hulla",
    },
    "multiColorScaleRedYellowGreen": {
        "de": "Rot-Gr&uuml;ne Farbskala",
        "en": "Red-Green color scale",
        "fr": "&Eacute;chelle de couleurs rouge-vert",
        "it": "Scala cromatica rosso-verde",
        "es": "Escala de color rojo-verde",
    },
    "multiColorScaleRedYellowBlue": {
        "de": "Rot-Blaue Farbskala",
        "en": "Red blue color scale",
        "fr": "&Eacute;chelle de couleurs rouge-bleu",
        "it": "Scala cromatica rosso-blu",
        "es": "Escala de color rojo-azul",
    },
    "accessibility": {
        "de": "Barrierefreiheit",
        "en": "Accessibility",
        "fr": "Accessibilit&eacute;",
        "it": "Accessibilit&agrave;",
        "es": "Accesibilidad",
    },

    "pageTitle_consumtionAdvice": {
        "de": "Stromampel",
        "en": "Electricity traffic light",
        "fr": "Feu de signalisation \u00e9lectrique",
        "it": "Semaforo elettrico",
        "es": "Sem\u00e1foro el\u00e9ctrico",
    },
    "intervalHighRenewableShares": {
        "de": "Zeitintervalle mit hohem Anteil erneuerbarer Energien",
        "en": "Time intervals with high share of renewable energies",
        "fr": "Intervalles de temps avec une part &eacute;lev&eacute;e d'&eacute;nergies renouvelables",
        "it": "Intervalli di tempo con una quota elevata di energie rinnovabili",
        "es": "Intervalos de tiempo con una alta cuota de energ&iacute;as renovables",
    },
    "intervalAvgRenewableShares": {
        "de": "Zeitintervalle mit durchschnittlichem Anteil erneuerbarer Energien",
        "en": "Time intervals with average share of renewable energies",
        "fr": "Intervalles de temps avec part moyenne d'&eacute;nergie renouvelable",
        "it": "Intervalli di tempo con quota media di energie rinnovabili",
        "es": "Intervalos de tiempo con cuota media de energ&iacute;as renovables",
    },
    "intervalLowRenewableShares": {
        "de": "Zeitintervalle mit geringem Anteil erneuerbarer Energien",
        "en": "Time intervals with low share of renewable energies",
        "fr": "Intervalles de temps avec une faible part d'&eacute;nergie renouvelable",
        "it": "Intervalli di tempo con una bassa quota di energie rinnovabili",
        "es": "Intervalos de tiempo con baja cuota de energ&iacute;as renovables",
    },
    "currentRenewableShare": {
        "de": "Aktueller Anteil EE",
        "en": "Current share of RE",
        "fr": "Part actuelle des &eacute;nergies renouvelables",
        "it": "Quota attuale di energie rinnovabili",
        "es": "Cuota actual de las energ&iacute;as renovables",
    },
    "highShareEE": {
        "de": "Hoher Anteil erneuerbarer Energien",
        "en": "High share of renewable energies",
        "fr": "Forte proportion d'&eactue;nergies renouvelables",
        "it": "Elevata percentuale di energie rinnovabili",
        "es": "Alta proporci&oacute;n de energ&iacute;as renovables",
    },
    "averageShareEE": {
        "de": "Durchschnittlicher Anteil erneuerbarer Energien",
        "en": "Average share of renewable energies",
        "fr": "proporci&oacute;n media d'&eactue;nergies renouvelables",
        "it": "percentuale media di energie rinnovabili",
        "es": "proporci&oacute;n media de energ&iacute;as renovables",
    },
    "lowShareEE": {
        "de": "Geringer Anteil erneuerbarer Energien",
        "en": "Low share of renewable energies",
        "fr": "faible part d'&eacute;nergies renouvelables",
        "it": "Bassa quota di energie rinnovabili",
        "es": "Baja proporci&oacute;n de energ&iacute;as renovables",
    },
    "low": {
        "de": "Gering",
        "en": "Low",
        "fr": "faible",
        "it": "basso",
        "es": "Bajo",
    },
    "high": {
        "de": "Hoch",
        "en": "High",
        "fr": "&eacute;lev&eacute;",
        "it": "alto",
        "es": "alto",
    },
    "prognosisForToday": {
        "de": "Prognose für heute",
        "en": "Forecast for today",
        "fr": "Pr&eacute;visions pour aujourd'hui",
        "it": "Previsioni per oggi",
        "es": "Previsi&oacute;n para hoy",
    },
    "prognosisForTomorrow": {
        "de": "Prognose für morgen",
        "en": "Forecast for tomorrow",
        "fr": "Pr&eacute;visions pour demain",
        "it": "Previsioni per domani",
        "es": "Previsi&oacute;n para ma&ntilde;ana",
    },
    "prognosisNotYetAvailable": {
        "de": "Es ist noch keine Prognose verfügbar",
        "en": "There is no forecast available yet",
        "fr": "Aucune pr&eacute;vision n'est encore disponible",
        "it": "Non sono ancora disponibili previsioni",
        "es": "A&uacute;n no hay previsiones",
    },
    "signalForToday": {
        "de": "Stromampel für heute",
        "en": "Electricity traffic light for today",
        "fr": "Feu de signalisation &eacute;lectrique pour aujourd'hui",
        "it": "Semaforo elettrico per oggi",
        "es": "Sem&aacute;foro el&eacute;ctrico para hoy",
    },
    "signalForTomorrow": {
        "de": "Stromampel für morgen",
        "en": "Electricity traffic light for tomorrow",
        "fr": "Feu de signalisation &eacute;lectrique pour demain",
        "it": "Semaforo elettrico per domani",
        "es": "Sem&aacute;foro el&eacute;ctrico para ma&ntilde;ana",
    },
    "signalNotYetAvailable": {
        "de": "Es sind noch keine Daten verfügbar",
        "en": "There is no data available yet",
        "fr": "Il n'y a pas encore de donn&eacute;es disponibles",
        "it": "Non ci sono ancora dati disponibili",
        "es": "A&uacute;n no hay datos disponibles",
    },
    "today": {
        "de": "heute",
        "en": "today",
        "fr": "aujourd'hui",
        "it": "oggi",
        "es": "hoy",
    },
    "Today": {
        "de": "Heute",
        "en": "Today",
        "fr": "Aujourd'hui",
        "it": "Oggi",
        "es": "Hoy",
    },
    "tomorrow": {
        "de": "morgen",
        "en": "tomorrow",
        "fr": "demain",
        "it": "domani",
        "es": "ma&ntilde;ana",
    },
    "pageTitle_import_export_map": {
        "de": "Import und Export | Karte",
        "en": "Import and Export | Map",
        "fr": "Importation et exportation | Carte",
        "it": "Importazione ed esportazione di mappe",
        "es": "Importar y exportar | Mapa",
    },
    "monoscale": {
        "de": "Monochrome Farbskala",
        "en": "Monochrome color scale",
        "fr": "&Eacute;chelle de couleurs monochrome",
        "it": "Scala colori monocromatica",
        "es": "Escala de colores monocroma",
    },
    "colorScales": {
        "de": "Farbskalen",
        "en": "Color scales",
        "fr": "&Eacute;chelles de couleurs",
        "it": "Scale cromatiche",
        "es": "Escalas de colores",
    },
    "mainMenu.infrastructure_meteodata": {
        "de": "Infrastruktur und Meteodaten",
        "en": "Infrastructure and meteorological data",
        "fr": "Infrastructure et donn&eacute;es m&eacute;t&eacute;orologiques",
        "it": "Infrastrutture e dati meteorologici",
        "es": "Infraestructura y datos meteorol&oacute;gicos",
    },
    "prognosisFor": {
        "de": "Prognose f&uuml;r den ",
        "en": "Forecast for ",
        "fr": "Pr&eacute;visions pour le ",
        "it": "Previsioni per il ",
        "es": "Previsi&oacute;n para el ",
    },
    "electricity": {
        "de": "Strom",
        "en": "Electricity",
        "fr": "&eacute;lectricit&eacute;",
        "it": "Elettricità",
        "es": "Electricidad",
    },
    "electricity_base": {
        "de": "Strom (Base)",
        "en": "Electricity (Base)",
        "fr": "&eacute;lectricit&eacute; (Base)",
        "it": "Elettricità (Base)",
        "es": "Electricidad (Base)",
    },
    "years": {
        "de": "Jahre",
        "en": "Years",
        "fr": "Ann&eacute;es",
        "it": "Anni",
        "es": "",
    },
    "quarters": {
        "de": "Quartale",
        "en": "Quarters",
        "fr": "Quarters",
        "it": "Quartieri",
        "es": "Cuartos",
    },
    "months": {
        "de": "Monate",
        "en": "Months",
        "fr": "Mois",
        "it": "Mesi",
        "es": "Meses",
    },
		"mainMenu.price_futures": {
				"de": "Liniendiagramme zu den Futures",
				"en": "Line charts of futures",
				"fr": "Graphiques linéaires des contrats à terme",
				"it": "Grafici lineari dei futures",
				"es": "Gráficos lineales de futuros",
		},
		"mainMenu.price_futures_bars": {
				"de": "Säulendiagramme zu den Futures",
				"en": "Bar charts of futures",
				"fr": "Graphiques en barres des contrats à terme",
				"it": "Grafici a barre dei futures",
				"es": "Gráficos de barras de futuros",
		},
    // radioactive discharges
    "total_alpha": {
        "de": "Alpha Gesamtwert",
        "en": "Alpha total value",
        "fr": "Total alpha",
        "it": "Totale Alfa",
        "es": "Alfa total",
    },
    "total_beta_gamma_excl_h-3": {
        "de": "Beta/Gamma Gesamtwert (exkl. H-3)",
        "en": "Beta/Gamma total (excl. H-3)",
        "fr": "Valeur totale bêta/gamma (sans H-3)",
        "it": "Totale Beta/Gamma (escluso H-3)",
        "es": "Beta/Gamma total (excl. H-3)",
    },
    "total_beta_gamma_excl_h-3_c-14": {
        "de": "Beta/Gamma Gesamtwert (exkl. H-3 und C-14)",
        "en": "Beta/Gamma total (excl. H-3 and C-14)",
        "fr": "Valeur totale bêta/gamma (excl. H-3 et C-14)",
        "it": "Totale Beta/Gamma (escl. H-3 e C-14)",
        "es": "Beta/Gamma total (excl. H-3 y C-14)",
    },
    "total_beta_gamma_excl_h-3_c-14_s-35": {
        "de": "Beta/Gamma Gesamtwert (exkl. H-3, C-14 und S-35)",
        "en": "Beta/Gamma total (excl. H-3, C-14 and S-35)",
        "fr": "Valeur totale bêta/gamma (excl. H-3, C-14 et S-35)",
        "it": "Totale Beta/Gamma (escl. H-3, C-14 e S-35)",
        "es": "Beta/Gamma total (excl. H-3, C-14 y S-35)",
    },
    "total_beta_gamma_excl_h-3_c-14_kr-85_i-129": {
        "de": "Beta/Gamma Gesamtwert (exkl. H-3, C-14, Kr-85 und I-129)",
        "en": "Beta/Gamma total (excl. H-3, C-14, Kr-85 and I-129)",
        "fr": "Total bêta/gamma (excl. H-3, C-14, Kr-85 et I-129)",
        "it": "Totale Beta/Gamma (eccetto H-3, C-14, Kr-85 e I-129)",
        "es": "Beta/Gamma total (excl. H-3, C-14, Kr-85 e I-129)",
    },
    "total_iodines_incl_i-131": {
        "de": "Jodisotope Gesamtwert (inkl. I-131)",
        "en": "Iodine isotope total (incl. I-131)",
        "fr": "Isotopes d'iode Valeur totale (y compris I-131)",
        "it": "Totale isotopi dello iodio (incluso I-131)",
        "es": "Total de is&oacute;topos de yodo (incl. I-131)",
    },
    "total_noble_gas": {
        "de": "Edelgase Gesamtwert",
        "en": "Noble gases Total value",
        "fr": "Gaz rares Valeur totale",
        "it": "Gas nobili Valore totale",
        "es": "Gases nobles Valor total",
    },
    "noble_gases_tritium": {
        "de": "Edelgase + Tritium",
        "en": "Noble gases + tritium",
        "fr": "Gaz nobles + tritium",
        "it": "Gas nobili + trizio",
        "es": "Gases nobles + tritio",
    },
    "noble_gases_tritium_particulates_iodines": {
        "de": "Edelgase + Tritium und Schwebstoffe + Jodisotope",
        "en": "Noble gases + tritium and suspended matter + iodine isotopes",
        "fr": "Gaz rares + tritium et mati&egrave;res en suspension + isotopes d'iode",
        "it": "Gas nobili + trizio e materia in sospensione + isotopi dello iodio",
        "es": "Gases nobles + tritio y materia en suspensi&oacute;n + is&oacute;topos de yodo",
    },
    "particulates_iodines": {
        "de": "Schwebstoffe + Jodisotope",
        "en": "Suspended solids + iodine isotopes",
        "fr": "Mati&egrave;res en suspension + isotopes d'iode",
        "it": "Materia in sospensione + isotopi dello iodio",
        "es": "Materia en suspensi&oacute;n + is&oacute;topos de yodo",
    },
    "uranium": {
        "de": "Uran",
        "en": "uranium",
        "fr": "uranium",
        "it": "uranio",
        "es": "uranio",
    },
    "nucleid_categories": {
        "de": "Nuklidkategorie",
        "en": "Nuclide category",
        "fr": "Cat&eacute;gorie de nucl&eacute;ides",
        "it": "Categoria di nuclidi",
        "es": "Categor&iacute;a de nucleido",
    },
    "release_types": {
        "de": "Ableitungstyp",
        "en": "Derivation type",
        "fr": "Type de d&eacute;rivation",
        "it": "Tipo di derivazione",
        "es": "Tipo de derivaci&oacute;n",
    },
    "airborne_discharges": {
        "de": "gasf&ouml;rmige Ableitungen",
        "en": "airborne discharges",
        "fr": "rejets gazeux",
        "it": "scarichi gassosi",
        "es": "vertidos gaseosos",
    },
    "liquid_discharges": {
        "de": "fl&uuml;ssige Ableitungen",
        "en": "liquid discharges",
        "fr": "les rejets liquides",
        "it": "scarichi liquidi",
        "es": "vertidos l&iacute;quidos",
    },
    "pageTitle_radioactiveDischarges": {
        "de": "Radioaktive Ableitungen | Energy-Charts",
        "en": "Radioactive discharges | Energy-Charts",
        "fr": "Rejets radioactifs | Energy-Charts",
        "it": "Scariche radioattive | Energy-Charts",
        "es": "Descargas radiactivas | Energy-Charts",
    },
    "PWR_long": {
        "de": "Druckwasserreaktor",
        "en": "pressurised water reactor",
        "fr": "r&eacute;acteur &agrave; eau pressuris&eacute;e",
        "it": "reattore ad acqua pressurizzata",
        "es": "reactor de agua a presi&oacute;n",
    },
    "BWR_long": {
        "de": "Siedewasserreaktor",
        "en": "boiling water reactor",
        "fr": "r&eacute;acteur &agrave; eau bouillante",
        "it": "reattore ad acqua bollente",
        "es": "reactor de agua en ebullici&oacute;n",
    },
    "GCR_long": {
        "de": "gasgek&uuml;hlter Reaktor",
        "en": "gas cooled reactor",
        "fr": "r&eacute;acteur refroidi par gaz",
        "it": "reattore raffreddato a gas",
        "es": "reactor refrigerado por gas",
    },
    "FBR_long": {
        "de": "schneller Brutreaktor",
        "en": "fast breeder reactor",
        "fr": "r&eacute;acteur &agrave; neutrons rapides",
        "it": "reattore a reattori veloci",
        "es": "reactor reproductor r&aacute;pido",
    },
    "NFRP_long": {
        "de": "Wiederaufbereitungsanlage f&uuml;r Kernbrennstoffe, standardm&auml;&szlig;ig meist ausgeblendet, da die entsprechenden Werte die der Atomkraftwerke teilweise weit &uuml;bersteigen",
        "en": "Nuclear fuel reprocessing plant, mostly hidden by default, since the corresponding values exceed those of nuclear power plants by far in some cases",
        "fr": "Usine de retraitement des combustibles nucl&eacute;aires, g&eacute;n&eacute;ralement masqu&eacute;e par d&eacute;faut, car les valeurs correspondantes d&eacute;passent parfois largement celles des centrales nucl&eacute;aires",
        "it": "Impianto di ritrattamento del combustibile nucleare, per lo pi&ugrave; nascosto per impostazione predefinita, in quanto i valori corrispondenti superano di gran lunga quelli delle centrali nucleari in alcuni casi.",
        "es": "Planta de reprocesamiento de combustible nuclear, en su mayor parte oculta por defecto, ya que los valores correspondientes a veces superan con creces los de las centrales nucleares.",
    },

    "HTR_long": {
        "de": "Hochtemperaturreaktor",
        "en": "High temperature reactor",
        "fr": "R&eacute;acteur &agrave; haute temp&eacute;rature",
        "it": "Reattore ad alta temperatura",
        "es": "Reactor de alta temperatura",
    },
    "WWER_long": {
        "de": "energetischer Wasser-Wasser-Reaktor",
        "en": "water-water energetic reactor",
        "fr": "r&eacute;acteur &eacute;nerg&eacute;tique eau-eau",
        "it": "reattore energetico acqua-acqua",
        "es": "reactor energ&eacute;tico agua-agua",
    },
    "LWGR_long": {
        "de": "Leichtwasser-Graphit-Reaktor",
        "en": "light water graphite reactor",
        "fr": "r&eacute;acteur graphite &agrave; eau l&eacute;g&egrave;re",
        "it": "reattore di grafite ad acqua leggera",
        "es": "reactor de grafito de agua ligera",
    },
    "PHWR_long": {
        "de": "Schwerer Druckwasserreaktor",
        "en": "Pressurised heavy water reactor",
        "fr": "R&eacute;acteur &agrave; eau lourde pressuris&eacute;e",
        "it": "Reattore ad acqua pesante pressurizzata",
        "es": "Reactor de agua pesada a presi&oacute;n",
    },
    "AGR_long": {
        "de": "Fortgeschrittener gasgek&uuml;hlter Reaktor",
        "en": "Advanced gas cooled Reactor",
        "fr": "R&eacute;acteur avanc&eacute; refroidi par gaz",
        "it": "Reattore avanzato raffreddato a gas",
        "es": "Reactor avanzado refrigerado por gas",
    },
    "SGHWR_long": {
        "de": "dampferzeugender Schwerwasserreaktor",
        "en": "steam generating heavy water reactor",
        "fr": "r&eacute;acteur &agrave; eau lourde g&eacute;n&eacute;rant de la vapeur",
        "it": "reattore ad acqua pesante con generazione di vapore",
        "es": "reactor de agua pesada generador de vapor",
    },
    "HWGR_long": {
        "de": "gasgek&uuml;hlter Schwerwasserreaktor",
        "en": "heavy water gas-cooled reactor",
        "fr": "r&eacute;acteur &agrave; eau lourde refroidi au gaz",
        "it": "reattore ad acqua pesante raffreddato a gas",
        "es": "reactor de agua pesada refrigerado por gas",
    },
    "abbreviation": {
        "de": "Abk&uuml;rzung",
        "en": "Abbreviation",
        "fr": "Abr&eacute;viation",
        "it": "Abbreviazione",
        "es": "Abreviatura",
    },
    "explanation": {
        "de": "Erkl&auml;rung",
        "en": "Explanation",
        "fr": "Explication",
        "it": "Spiegazione",
        "es": "Explicaci&oacute;n",
    },
    "reactor_types": {
        "de": "Reaktortypen",
        "en": "Reactor types",
        "fr": "Types de r&eacute;acteurs",
        "it": "Tipi di reattore",
        "es": "Tipos de reactores",
    },
    "meaning": {
        "de": "Bedeutung",
        "en": "Meaning",
        "fr": "Signification",
        "it": "Significato",
        "es": "Significado",
    },
    "PWR": {
        "de": "DWR",
        "fr": "REP",
        "en": "PWR",
        "it": "PWR",
        "es": "PWR",
    },
    "BWR": {
        "de": "SWR",
        "fr": "REB",
        "en": "BWR",
        "it": "BWR",
        "es": "BWR",
    },
    "FBR": {
        "de": "SBR",
        "fr": "RNR",
        "en": "FBR",
        "it": "FBR",
        "es": "FBR",
    },
    "NFRP": {
        "de": "WAA",
        "fr": "URC",
        "en": "NFRP",
        "it": "NFRP",
        "es": "NFRP",
    },
    "alpha_beta_gamma_radiation": {
        "de": "Alpha, Beta und Gammastrahlung",
        "en": "Alpha, beta and gamma radiation",
        "fr": "Rayons alpha, bêta et gamma",
        "it": "Radiazioni alfa, beta e gamma",
        "es": "Radiaci&oacute;n alfa, beta y gamma",
    },
    "radioactive_isotopes": {
        "de": "Radioaktive Isotope",
        "en": "Radioactive isotopes",
        "fr": "Isotopes radioactifs",
        "it": "Isotopi radioattivi",
        "es": "Is&oacute;topos radiactivos",
    },
    "others": {
        "de": "Andere",
        "en": "Other",
        "fr": "Autre",
        "it": "Altro",
        "es": "Otros",
    },
    "day_to_day": {
        "de": "Tag zu Tag",
        "en": "Day to day",
        "fr": "Au jour le jour",
        "it": "Giorno per giorno",
        "es": "D&iacute;a a d&iacute;a",
    },
    "until": {
        "de": "bis",
        "en": "until",
        "fr": "jusqu'à",
        "it": "a",
        "es": "a",
    },
    "account_balance": {
        "de": "Kontostand",
        "en": "Account balance",
        "fr": "Solde du compte",
        "it": "Saldo del conto",
        "es": "Saldo de la cuenta",
    },
    "account_balance_saldo": {
        "de": "Kontostands&auml;nderung",
        "en": "Account balance change",
        "fr": "Modification du solde du compte",
        "it": "Modifica del saldo del conto",
        "es": "Modificaci&oacute;n del saldo de la cuenta",
    },
    "eeg_account": {
        "de": "EEG-Konto",
        "en": "EEG account",
        "fr": "Compte EEG",
        "it": "Conto EEG",
        "es": "Cuenta EEG",
    },
    "account_saldo": {
        "de": "Saldo",
        "en": "Balance",
        "fr": "&Eacute;quilibre",
        "it": "Equilibrio",
        "es": "Saldo",
    },
    "forecasts": {
        "de": "Aussichten",
        "en": "Outlook",
        "fr": "Pr&eacute;visions",
        "it": "Previsioni",
        "es": "Previsiones",
    },
    "color_scheme": {
        "de": "Farbschema",
        "en": "Color scheme",
        "fr": "Sch&eacute;ma de couleurs",
        "it": "Schema di colore",
        "es": "Combinaci&oacute;n de colores",
    },
    "green_yellow_red": {
        "de": "Rot - Gelb - Gr&uuml;n",
        "en": "Red - Yellow - Green",
        "fr": "Rouge - Jaune - Vert",
        "it": "Rosso - Giallo - Verde",
        "es": "Rojo - Amarillo - Verde",
    },
    "blue_yellow_red": {
        "de": "Rot - Gelb - Blau",
        "en": "Red - Yellow - Blue",
        "fr": "Rouge - Jaune - Bleu",
        "it": "Rosso - Giallo - Blu",
        "es": "Rojo - Amarillo - Azul",
    },
    // "price_traffic_light": {
        // "de": "Stromampel Preis",
        // "en": "Current traffic light price",
        // "fr": "Prix des feux de circulation",
        // "it": "Prezzo attuale del semaforo",
        // "es": "Precio actual del sem&aacute;foro",
    // },
    "price_traffic_light": {
        "de": "Strompreisampel",
        "en": "Electricity price traffic light",
        "fr": "Feu de signalisation sur le prix",
        "it": "Semaforo dei prezzi dell'elettricità",
        "es": "Semáforo del precio de la electricidad",
    },
    "power_traffic_light": {
        "de": "Stromampel",
        "en": "Electricity traffic light",
        "fr": "Feuille de signalisation électrique ",
        "it": "Semaforo elettrico ",
        "es": "SemSemáforo eléctrico ",
    },

    "renewable_production": {
        "de": "Erneuerbare Erzeugung",
        "en": "Renewable generation",
        "fr": "Production renouvelable",
        "it": "Generazione rinnovabile",
        "es": "Generaci&oacute;n renovable",
    },
    "no_data_plain": {
        "de": "Keine Daten",
        "en": "No data",
        "fr": "Pas de donn&eacute;es",
        "it": "Nessun dato",
        "es": "Sin datos",
    },
    "map_bidding_zones": {
        "de": "Karte der Gebotszonen",
        "en": "Map of bidding zones",
        "fr": "Carte des zones d'ench&egrave;res",
        "it": "Mappa delle zone di offerta",
        "es": "Mapa de las zonas de licitaci&oacute;n",
    },
    "biddingzone_it-sacoac": {
        "de": "Gebotszone SACOAC (Korsika, Frankreich)",
        "en": "Bidding zone SACOAC (Corsica, France)",
        "fr": "Zone d'ench&egrave;res SACOAC (Corse, France)",
        "it": "Zona d'asta SACOAC (Corsica, Francia)",
        "es": "Zona de licitaci&oacute;n SACOAC (C&oacute;rcega, Francia)",
    },
    "biddingzone_it-sacodc": {
        "de": "Gebotszone SACODC",
        "en": "Bidding zone SACODC",
        "fr": "Zone d'ench&egrave;res SACODC",
        "it": "Zona d'asta SACODC",
        "es": "Zona de licitaci&oacute;n SACODC",
    },
    "conventional_production": {
        "de": "Konventionelle Erzeugung",
        "en": "Conventional generation",
        "fr": "Production conventionnelle",
        "it": "Generazione convenzionale",
        "es": "Generaci&oacute;n convencional",
    },
    "loads": {
        "de": "Lasten",
        "en": "Loads",
        "fr": "Charges",
        "it": "Carichi",
        "es": "Cargas",
    },

    "price_vs": {
        "de": "Preis vs ...",
        "en": "Price vs ...",
        "fr": "Prix vs ...",
        "it": "Prezzo vs ...",
        "es": "Precio vs ...",
    },
    "import_balance": {
        "en": "Import Balance",
        "de": "Import Saldo",
        "fr": "Solde des importations",
        "it": "Saldo del Importazione",
        "es": "Importar saldo",
    },
		"cross_border_electricity_trading": {
			"en": "Cross border electricity trading",
			"de": "Grenz\u00fcberschreitender Stromhandel",
			"fr": "Les \u00e9changes commerciaux aux fronti\u00e8res",
			"es": "Comercio transfronterizo de electricidad",
			"it": "Commercio transfrontaliero di energia elettrica"
		},
    "hydro_pumped_storage_consumption": {
        "en": "Hydro pumped storage consumption",
        "de": "Pumpspeicher Verbrauch",
        "fr": "Hydraulique STEP consommation",
        "it": "Pompaggio consumo",
        "es": "Consumo de almacenamiento por bombeo",
    },
    "simulate_expansion_targets_2030": {
        "de": "Ausbauziele 2030 simulieren",
        "en": "Ausbauziele 2030 simululieren",
        "fr": "simuler l'usine 2030",
        "it": "Ausbauziele 2030 simulazioni",
        "es": "Ausbauziele 2030 simulieren",
    },
    "ranking": {
        "de": "Ranking",
        "en": "Ranking",
        "fr": "Classement",
        "it": "Classifica",
        "es": "Clasificaci&oacute;n",
    },
    "mainMenu.power-heatmaps": {
        "de": "Heatmaps zur Stromerzeugung",
        "en": "Heatmaps for power generation",
        "fr": "Cartes de chaleur pour la production d'&eacute;lectricit&eacute;",
        "it": "Mappe di calore per la generazione di energia",
        "es": "Mapas de calor para la generaci&oacute;n de energ&iacute;a",
    },
	"mainMenu.power-forecast": {
		"de": "Prognosen und Ist-Werte",
		"en": "Forecasts and Actual Values",
		"fr": "Prévisions et valeurs réelles",
		"it": "Previsioni e valori effettivi",
		"es": "Pronósticos y valores reales",
	},
    "simulate_scenarios": {
        "de": "Szenarien simulieren",
        "en": "Simulate scenarios",
        "fr": "Simuler des sc&eacute;narios",
        "it": "Simulare scenari",
        "es": "Simular escenarios",
    },
    "government": {
        "de": "Bundesregierung",
        "en": "Federal Government",
        "fr": "Gouvernement f&eacute;d&eacute;ral",
        "it": "Governo federale",
        "es": "Gobierno federal",
    },
    "expansion_targets_2030": {
        "de": "Ausbauziele 2030",
        "en": "Expansion targets 2030",
        "fr": "Objectifs de d&eacute;veloppement pour 2030",
        "it": "Obiettivi di espansione 2030",
        "es": "Objetivos de expansi&oacute;n para 2030",
    },
    "scenario_a_2037": {
        "de": "Szenario A 2037",
        "en": "Scenario A 2037",
        "fr": "Sch&eacute;ma A 2037",
        "it": "Szenario A 2037",
        "es": "Escenario A 2037",
    },
    "scenario_b_2037": {
        "de": "Szenario B 2037",
        "en": "Scenario B 2037",
        "fr": "Sch&eacute;ma B 2037",
        "it": "Szenario B 2037",
        "es": "Escenario B 2037",
    },
    "scenario_c_2037": {
        "de": "Szenario C 2037",
        "en": "Scenario C 2037",
        "fr": "Sch&eacute;ma C 2037",
        "it": "Szenario C 2037",
        "es": "Escenario C 2037",
    },
    "remod_study": {
        "de": "FraunhoferISE Studie",
        "en": "FraunhoferISE Study",
        "fr": "&Eacute;tude FraunhoferISE",
        "it": "Studio FraunhoferISE",
        "es": "Estudio FraunhoferISE",
    },
    "net-dev-plan": {
        "de": "Netzentwicklungsplan",
        "en": "Network Development Plan",
        "fr": "Plan de d&eacute;veloppement du r&eacute;seau",
        "it": "Piano di sviluppo della rete",
        "es": "Plan de desarrollo de la red",
    },
    "simulate-scenarios": {
        "de": `<h5>Ausbauziele Bundesregierung 2030</h5>
							<p>Die Bundesregierung hat sich innerhalb des Osterpakets und der EEG Novelle 2023 das Ziel gesetzt, den Anteil der erneuerbaren Energien an der Bruttostromerzeugung auf mindestens 80% zu erhöhen. Dazu wurden unter anderem die Ausbaupfade für Solar- und Windenergie deutlich erhöht.</p>

						<h5>Netzentwicklungsplan</h5>
							<p>Der Netzentwicklungsplan wird von den vier deutschen Übertragungsnetzbetreibern TenneT, Amprion, 50Hertz und der TransnetBW entwickelt. Basierend auf den erarbeiteten Szenarien wird der Netzausbaubedarf ermittelt und das Netz entsprechend erweitert. Weitere Informationen sind <a href="https://www.netzentwicklungsplan.de/" target="_blank">hier</a> zu finden.</p>

						<h5>Fraunhofer Studie "Wege zu einem klimaneutralen Energiesystem"</h5>
							<p>In der Studie "Wege zu einem klimaneutralen Energiesystem" des FraunhoferISE werden mit dem Energiesystemmodell REMod vier Szenarien berechnet, wie Klimaneutralität in Deutschland bis 2045 erreicht werden kann. Den vier Szenarien liegen verschiedene gesellschaftliche Trends zu Grunde. Weitere Informationen sind <a href="https://www.ise.fraunhofer.de/de/veroeffentlichungen/studien/wege-zu-einem-klimaneutralen-energiesystem.html" target="_blank">hier</a> zu finden. Zudem werden verschiedene Daten unter dem Menü "Szenarien" (nur für Deutschland) visualisiert.</p> 
        `,
        "es": `
           <h5>Metas de expansión del gobierno federal para 2030</h5>
            <p>Dentro del paquete de Pascua y la Enmienda EEG 2023, el gobierno federal se ha fijado el objetivo de aumentar la participación de las energías renovables en la generación bruta de electricidad a al menos el 80 %. Entre otras cosas, las vías de expansión de la energía solar y eólica aumentaron significativamente.</p>
            
            <h5>Plan de Desarrollo de la Red</h5>
            <p>El plan de desarrollo de la red está siendo desarrollado por los cuatro operadores de sistemas de transmisión alemanes TenneT, Amprion, 50Hertz y TransnetBW. Según los escenarios desarrollados, se determina el requisito de expansión de la red y la red se expande en consecuencia. Puede encontrar más información <a href="https://www.netzentwicklungsplan.de/" target="_blank">aquí</a>.</p>
            
            <h5>Estudio de Fraunhofer "Formas de lograr un sistema energ&eacute;tico climáticamente neutro"</h5>
            <p>En el estudio "Formas de lograr un sistema de energía climáticamente neutral" de FraunhoferISE, se calculan cuatro escenarios con el modelo de sistema de energía REMod sobre cómo se puede lograr la neutralidad climática en Alemania para 2045. Los cuatro escenarios se basan en varias tendencias sociales. Hay más información disponible <a href="https://www.ise.fraunhofer.de/de/veroeffentlichungen/studien/wege-zu-ein-klimaneutralen-energiesystem.html" target="_blank">aquí</a> encontrar. Además, se visualizan varios datos en el menú "Escenarios" (solo para Alemania).</p> 
        `,
        "en": `
            <h5>Expansion goals of the federal government for 2030</h5>
            <p>Within the Easter package and the EEG Amendment 2023, the federal government has set itself the goal of increasing the share of renewable energies in gross electricity generation to at least 80%. Among other things, the expansion paths for solar and wind energy were significantly increased.</p>
            
            <h5>Network Development Plan</h5>
            <p>The network development plan is being developed by the four German transmission system operators TenneT, Amprion, 50Hertz and TransnetBW. Based on the scenarios developed, the network expansion requirement is determined and the network expanded accordingly. Further information can be found <a href="https://www.netzentwicklungsplan.de/" target="_blank">here</a>.</p>
            
            <h5>Fraunhofer study "Ways to a climate-neutral energy system"</h5>
            <p>In the study "Ways to a climate-neutral energy system" by FraunhoferISE, four scenarios are calculated with the energy system model REMod as to how climate neutrality can be achieved in Germany by 2045. The four scenarios are based on various social trends. Further information is available <a href="https://www.ise.fraunhofer.de/de/veroeffentlichungen/studien/wege-zu-ein-klimaneutralen-energiesystem.html" target="_blank">here</a>. In addition, various data are visualized under the "Scenarios" menu (only for Germany).</p>
        `,
        "it": `
            <h5>Obiettivi di espansione della Confederazione per il 2030</h5>
            <p>Nell'ambito del pacchetto di Pasqua e dell'emendamento EEG 2023, il governo federale si &egrave; posto l'obiettivo di aumentare la quota di energie rinnovabili nella produzione lorda di elettricità almeno all'80%. Tra le altre cose, i percorsi di espansione per l'energia solare ed eolica sono stati notevolmente aumentati.</p>
            
            <h5>Piano di sviluppo della rete</h5>
            <p>Il piano di sviluppo della rete &egrave; in fase di sviluppo da parte dei quattro gestori di sistemi di trasmissione tedeschi TenneT, Amprion, 50Hertz e TransnetBW. Sulla base degli scenari sviluppati, viene determinato il requisito di espansione della rete e la rete viene ampliata di conseguenza. Ulteriori informazioni sono disponibili <a href="https://www.netzentwicklungsplan.de/" target="_blank">qui</a>.</p>
            
            <h5>Studio Fraunhofer "Ways to a climate-neutral energy system"</h5>
            <p>Nello studio "Ways to a climate-neutral energy system" di FraunhoferISE, vengono calcolati quattro scenari con il modello di sistema energetico REMod su come raggiungere la neutralità climatica in Germania entro il 2045. I quattro scenari si basano su varie tendenze sociali. Ulteriori informazioni sono disponibili <a href="https://www.ise.fraunhofer.de/de/veroeffentlichungen/studien/wege-zu-ein-klimaneutralen-energiesystem.html" target="_blank">qui</a> Trovare. Inoltre, vari dati vengono visualizzati nel menu "Scenari" (solo per la Germania).</p>
        `,
        "fr": `
            <h5>Objectifs d'expansion du gouvernement f&eacute;d&eacute;ral pour 2030</h5>
            <p>Dans le cadre du paquet de Pâques et de l'amendement EEG 2023, le gouvernement f&eacute;d&eacute;ral s'est fix&eacute; pour objectif d'augmenter la part des &eacute;nergies renouvelables dans la production brute d'&eacute;lectricit&eacute; à au moins 80 %. Entre autres choses, les voies d'expansion pour l'&eacute;nergie solaire et &eacute;olienne ont &eacute;t&eacute; consid&eacute;rablement augment&eacute;es.</p>
            
            <h5>Plan de d&eacute;veloppement du r&eacute;seau</h5>
            <p>Le plan de d&eacute;veloppement du r&eacute;seau est en cours d'&eacute;laboration par les quatre op&eacute;rateurs de r&eacute;seau de transport allemands TenneT, Amprion, 50Hertz et TransnetBW. Sur la base des sc&eacute;narios d&eacute;velopp&eacute;s, l'exigence d'extension du r&eacute;seau est d&eacute;termin&eacute;e et le r&eacute;seau &eacute;tendu en cons&eacute;quence. Vous trouverez de plus amples informations <a href="https://www.netzentwicklungsplan.de/" target="_blank">ici</a>.</p>
            
            <h5>&eacute;tude Fraunhofer "Les voies vers un syst&egrave;me &eacute;nerg&eacute;tique climatiquement neutre"</h5>
            <p>Dans l'&eacute;tude « Ways to a climate-neutral energy system » de FraunhoferISE, quatre sc&eacute;narios sont calcul&eacute;s avec le mod&egrave;le de syst&egrave;me &eacute;nerg&eacute;tique REMod sur la mani&egrave;re dont la neutralit&eacute; climatique peut être atteinte en Allemagne d'ici 2045. Les quatre sc&eacute;narios sont bas&eacute;s sur diverses tendances sociales. De plus amples informations sont disponibles <a href="https://www.ise.fraunhofer.de/de/veroeffentlichungen/studien/wege-zu-ein-klimaneutralen-energiesystem.html" target="_blank">ici</a> trouver. De plus, diverses donn&eacute;es sont visualis&eacute;es dans le menu "Sc&eacute;narios" (uniquement pour l'Allemagne).</p>
        `
    },
    "scenarios_explanation_headlines": {
        "de": "Erkl&auml;rungen zu den verschiedenen Szenarien",
        "en": "Explanations of the different scenarios",
        "fr": "Explications sur les diff&eacute;rents sc&eacute;narios",
        "it": "Spiegazione dei diversi scenari",
        "es": "Explicaciones de los distintos escenarios",
    },
    "ns_Ctoggle": {
        "de": "Nordsee",
        "en": "North Sea",
        "fr": "Mer du Nord",
        "it": "Mare del Nord",
        "es": "Mar del Norte",
    },
    "day_ahead_auction_exaa": {
        "en": "Day Ahead Auction EXAA",
        "de": "Day Ahead Auktion EXAA",
        "fr": "Day Ahead Auction EXAA",
        "it": "Giorno prezzo spot avanti EXAA",
        "es": "Subasta del d&iacute;a anterior EXAA"
    },
    "day_ahead_auction": {
        "en": "Day Ahead Auction",
        "de": "Day Ahead Auktion",
        "fr": "Day Ahead Auction",
        "it": "Giorno prezzo spot avanti",
        "es": "subasta del d&iacute;a siguiente"
    },
    "intraday_continuous_average_price": {
        "en": "Intraday Continuous Average Price",
        "de": "Intraday kontinuierlich, Durchschnittspreis",
        "fr": "Intraday continuous, moyenne de prix",
        "it": "Prezzo medio intraday",
        "es": "Intrad&iacute;a continuo, precio medio"
    },
    "intraday_continuous_low_price": {
        "en": "Intraday Continuous Low Price",
        "de": "Intraday kontinuierlich, Niedrigstpreis",
        "fr": "Intraday continuous, prix le plus bas",
        "it": "Prezzo basso intraday",
        "es": "Precio m&iacute;nimo continuo intrad&iacute;a"
    },
    "intraday_continuous_high_price": {
        "en": "Intraday Continuous High Price",
        "de": "Intraday kontinuierlich, H&ouml;chstpreis",
        "fr": "Intraday continuous, prix le plus haut",
        "it": "Prezzo elevato intraday",
        "es": "Precio m&aacute;ximo continuo intrad&iacute;a"
    },
    "intraday_continuous_id1-price": {
        "en": "Intraday Continuous ID1-Price",
        "de": "Intraday kontinuierlich, ID1-Preis",
        "fr": "Intraday continuous ID1-prix",
        "it": "ID1 prezzo intraday",
        "es": "Intrad&iacute;a Continuo ID1-Precio"
    },
    "intraday_continuous_id3-price": {
        "en": "Intraday Continuous ID3-Price",
        "de": "Intraday kontinuierlich, ID3-Preis",
        "fr": "Intraday continuous ID3-prix",
        "it": "ID3 prezzo intraday",
        "es": "Intrad&iacute;a Continuo ID3-Precio"
    },
    "co2_emission_allowances_auction_de": {
        "en": "CO2 Emission Allowances, Auction DE",
        "de": "CO2 Emissionszertifikate, Auktion DE",
        "fr": "Quotas d'&eacute;mission europ&eacute;ens de CO2, Auction DE",
        "it": "Quote di emissioni europee di CO2, Auction DE",
        "es": "Derechos de emisi&oacute;n de CO2, subasta DE"
    },
    "co2_emission_allowances_auction_pl": {
        "en": "CO2 Emission Allowances, Auction PL",
        "de": "CO2 Emissionszertifikate, Auktion PL",
        "fr": "Quotas d'&eacute;mission europ&eacute;ens de CO2, Auction PL",
        "it": "Quote di emissioni europee di CO2, Auction PL",
        "es": "Derechos de emisi&oacute;n de CO2, subasta PL"
    },
    "co2_emission_allowances_auction_eu": {
        "en": "CO2 Emission Allowances, Auction EU",
        "de": "CO2 Emissionszertifikate, Auktion EU",
        "fr": "Quotas d'&eacute;mission europ&eacute;ens de CO2, Auction EU",
        "it": "Quote di emissioni europee di CO2, Auction EU",
        "es": "Derechos de emisi&ouml;n de CO2, subasta UE"
    },
    "mainMenu.price-heatmaps": {
        "de": "Heatmaps zu B&ouml;rsenstrompreisen",
        "en": "Heatmaps on exchange electricity prices",
        "fr": "Cartes de chaleur sur les prix de l'&eacute;lectricit&eacute; en bourse",
        "it": "Mappe di calore sui prezzi dell'elettricit&agrave; in borsa",
        "es": "Mapas de calor de los precios de intercambio de la electricidad",
    },
    "reset_scale_to_original": {
        "de": "Skala auf Ausgangswerte zur&uuml;cksetzen",
        "en": "Reset scale to initial values",
        "fr": "R&eacute;initialiser l'&eacute;chelle aux valeurs initiales",
        "it": "Ripristino della scala ai valori iniziali",
        "es": "Restablecer la escala a los valores iniciales",
    },
    "set_scale_ignore_extreme_values": {
        "de": "Wertebereich ohne Ber&uuml;cksichtigung von Ausrei&szlig;ern einstellen",
        "en": "Set value range without consideration of outliers",
        "fr": "R&eacute;gler la plage de valeurs sans prendre en compte les valeurs aberrantes",
        "it": "Impostare l'intervallo di valori senza tenere conto delle estensioni",
        "es": "Fijar el intervalo de valores sin considerar los valores at&iacute;picos",
    },
    "day_ahead_auction_it-centre-north": {
        "en": "Day Ahead Auction (IT-Centre-North)",
        "de": "Day Ahead Auktion (IT-Centre-North)",
        "fr": "Day Ahead Auction (IT-Centre-North)",
        "it": "Giorno prezzo spot avanti (IT-Centre-North)",
        "es": "Subasta del d&iacute;a anterior (IT-Centre-North)"
    },
    "day_ahead_auction_it-centre-south": {
        "en": "Day Ahead Auction (IT-Centre-South)",
        "de": "Day Ahead Auktion (IT-Centre-South)",
        "fr": "Day Ahead Auction (IT-Centre-South)",
        "it": "Giorno prezzo spot avanti (IT-Centre-South)",
        "es": "Subasta del d&iacute;a anterior (IT-Centre-South)"
    },
    "day_ahead_auction_it-north": {
        "en": "Day Ahead Auction (IT-North)",
        "de": "Day Ahead Auktion (IT-North)",
        "fr": "Day Ahead Auction (IT-North)",
        "it": "Giorno prezzo spot avanti (IT-North)",
        "es": "Subasta del d&iacute;a anterior (IT-North)"
    },
    "day_ahead_auction_it-rossano": {
        "en": "Day Ahead Auction (IT-Rossano)",
        "de": "Day Ahead Auktion (IT-Rossano)",
        "fr": "Day Ahead Auction (IT-Rossano)",
        "it": "Giorno prezzo spot avanti (IT-Rossano)",
        "es": "Subasta del d&iacute;a anterior (IT-Rossano)"
    },
    "day_ahead_auction_it-sacoac": {
        "en": "Day Ahead Auction (IT-SACOAC)",
        "de": "Day Ahead Auktion (IT-SACOAC)",
        "fr": "Day Ahead Auction (IT-SACOAC)",
        "it": "Giorno prezzo spot avanti (IT-SACOAC)",
        "es": "Subasta del d&iacute;a anterior (IT-SACOAC)"
    },
    "day_ahead_auction_it-sacodc": {
        "en": "Day Ahead Auction (IT-SACODC)",
        "de": "Day Ahead Auktion (IT-SACODC)",
        "fr": "Day Ahead Auction (IT-SACODC)",
        "it": "Giorno prezzo spot avanti (IT-SACODC)",
        "es": "Subasta del d&iacute;a anterior (IT-SACODC)"
    },
    "day_ahead_auction_it-sardinia": {
        "en": "Day Ahead Auction (IT-Sardinia)",
        "de": "Day Ahead Auktion (IT-Sardinia)",
        "fr": "Day Ahead Auction (IT-Sardinia)",
        "it": "Giorno prezzo spot avanti (IT-Sardinia)",
        "es": "Subasta del d&iacute;a anterior (IT-Sardinia)"
    },
    "day_ahead_auction_it-sicily": {
        "en": "Day Ahead Auction (IT-Sicily)",
        "de": "Day Ahead Auktion (IT-Sicily)",
        "fr": "Day Ahead Auction (IT-Sicily)",
        "it": "Giorno prezzo spot avanti (IT-Sicily)",
        "es": "Subasta del d&iacute;a anterior (IT-Sicily)"
    },
    "day_ahead_auction_it-south": {
        "en": "Day Ahead Auction (IT-South)",
        "de": "Day Ahead Auktion (IT-South)",
        "fr": "Day Ahead Auction (IT-South)",
        "it": "Giorno prezzo spot avanti (IT-South)",
        "es": "Subasta del d&iacute;a anterior (IT-South)"
    },
     "day_ahead_auction_dk1": {
        "en": "Day Ahead Auction (DK1)",
        "de": "Day Ahead Auktion (DK1)",
        "fr": "Day Ahead Auction (DK1)",
        "it": "Giorno prezzo spot avanti (DK1)",
        "es": "Subasta del d&iacute;a anterior (DK1)"
    },
    "day_ahead_auction_dk2": {
        "en": "Day Ahead Auction (DK2)",
        "de": "Day Ahead Auktion (DK2)",
        "fr": "Day Ahead Auction (DK2)",
        "it": "Giorno prezzo spot avanti (DK2)",
        "es": "Subasta del d&iacute;a anterior (DK2)"
    },
    "day_ahead_auction_no1": {
        "en": "Day Ahead Auction (NO1)",
        "de": "Day Ahead Auktion (NO1)",
        "fr": "Day Ahead Auction (NO1)",
        "it": "Giorno prezzo spot avanti (NO1)",
        "es": "Subasta del d&iacute;a anterior (NO1)"
    },
    "day_ahead_auction_no2": {
        "en": "Day Ahead Auction (NO2)",
        "de": "Day Ahead Auktion (NO2)",
        "fr": "Day Ahead Auction (NO2)",
        "it": "Giorno prezzo spot avanti (NO2)",
        "es": "Subasta del d&iacute;a anterior (NO2)"
    },
    "day_ahead_auction_no3": {
        "en": "Day Ahead Auction (NO3)",
        "de": "Day Ahead Auktion (NO3)",
        "fr": "Day Ahead Auction (NO3)",
        "it": "Giorno prezzo spot avanti (NO3)",
        "es": "Subasta del d&iacute;a anterior (NO3)"
    },
    "day_ahead_auction_no4": {
        "en": "Day Ahead Auction (NO4)",
        "de": "Day Ahead Auktion (NO4)",
        "fr": "Day Ahead Auction (NO4)",
        "it": "Giorno prezzo spot avanti (NO4)",
        "es": "Subasta del d&iacute;a anterior (NO4)"
    },
    "day_ahead_auction_no5": {
        "en": "Day Ahead Auction (NO5)",
        "de": "Day Ahead Auktion (NO5)",
        "fr": "Day Ahead Auction (NO5)",
        "it": "Giorno prezzo spot avanti (NO5)",
        "es": "Subasta del d&iacute;a anterior (NO5)"
    },
    "day_ahead_auction_no2nsl": {
        "en": "Day Ahead Auction (NO2NSL)",
        "de": "Day Ahead Auktion (NO2NSL)",
        "fr": "Day Ahead Auction (NO2NSL)",
        "it": "Giorno prezzo spot avanti (NO2NSL)",
        "es": "Subasta del d&iacute;a anterior (NO2NSL)"
    },
    "day_ahead_auction_se1": {
        "en": "Day Ahead Auction (SE1)",
        "de": "Day Ahead Auktion (SE1)",
        "fr": "Day Ahead Auction (SE1)",
        "it": "Giorno prezzo spot avanti (SE1)",
        "es": "Subasta del d&iacute;a anterior (SE1)"
    },
    "day_ahead_auction_se2": {
        "en": "Day Ahead Auction (SE2)",
        "de": "Day Ahead Auktion (SE2)",
        "fr": "Day Ahead Auction (SE2)",
        "it": "Giorno prezzo spot avanti (SE2)",
        "es": "Subasta del d&iacute;a anterior (SE2)"
    },
    "day_ahead_auction_se3": {
        "en": "Day Ahead Auction (SE3)",
        "de": "Day Ahead Auktion (SE3)",
        "fr": "Day Ahead Auction (SE3)",
        "it": "Giorno prezzo spot avanti (SE3)",
        "es": "Subasta del d&iacute;a anterior (SE3)"
    },
    "day_ahead_auction_se4": {
        "en": "Day Ahead Auction (SE4)",
        "de": "Day Ahead Auktion (SE4)",
        "fr": "Day Ahead Auction (SE4)",
        "it": "Giorno prezzo spot avanti (SE4)",
        "es": "Subasta del d&iacute;a anterior (SE4)"
    },
    "day_ahead_auction_ua-ips_uah": {
        "en": "Day Ahead Auction (UA-IPS)",
        "de": "Day Ahead Auktion (UA-IPS)",
        "fr": "Day Ahead Auction (UA-IPS)",
        "it": "Giorno prezzo spot avanti (UA-IPS)",
        "es": "Subasta del d&iacute;a anterior (UA-IPS)"
    },
    "day_ahead_auction_ua-bei_uah": {
        "en": "Day Ahead Auction (UA-BEI)",
        "de": "Day Ahead Auktion (UA-BEI)",
        "fr": "Day Ahead Auction (UA-BEI)",
        "it": "Giorno prezzo spot avanti (UA-BEI)",
        "es": "Subasta del d&iacute;a anterior (UA-BEI)"
    },

    "Resolution": {
        "de": "Auflösung",
        "en": "Resolution",
        "fr": "Résolution",
        "it": "Risoluzione",
        "es": "Resolución",
    },
    "Scale (for png/jpg)": {
        "de": "Skalierung (für png/jpg)",
        "en": "Scale (for png/jpg)",
        "fr": "Échelle (pour png/jpg)",
        "it": "Scala (per png/jpg)",
        "es": "Escala (para png/jpg)",
    },
    "Format": {
        "de": "Format",
        "en": "File type",
        "fr": "Type de fichier",
        "it": "Tipo di file",
        "es": "Tipo de fichero",
    },
    "redispatch_internal": {
        "de": "Redispatch intern",
        "en": "Redispatching Internal",
        "fr": "Redispatching Internal",
        "it": "Redispatching Internal",
        "es": "Redispatching Internal",
    },
    "redispatch_cross_border": {
        "de": "Redispatch grenzüberschreitend",
        "en": "Redispatching Cross Border",
        "fr": "Redispatching Cross Border",
        "it": "Redispatching Cross Border",
        "es": "Redispatching Cross Border",
    },
    "pageTitle_redispatch": {
        "de": "Redispatchma&szlig;nahmen | Energy-Charts",
        "en": "Redispatch measures | Energy charts",
        "fr": "Mesures de redispatching | Energy-Charts",
        "it": "Misure di ridispacciamento | Grafici energetici",
        "es": "Medidas de redistribuci&oacute;n | Energy-Charts",
    },
    "battery_storage_capacity": {
        "de": "Batteriespeicher Kapazit&auml;t",
        "en": "Battery storage capacity",
        "fr": "Capacit&eacute; de stockage de la batterie",
        "it": "Capacit&agrave; di accumulo della batteria",
        "es": "Capacidad de almacenamiento de la bater&iacute;a",
    },
    "battery_storage_power": {
        "de": "Batteriespeicher Leistung",
        "en": "Battery storage power",
        "fr": "Puissance de stockage de la batterie",
        "it": "Batteria di accumulo Potenza",
        "es": "Almacenamiento en bater&iacute;a Potencia",
    },
    "conventional_thermal": {
        "de": "Konvetionell-thermisch",
        "en": "Convection-thermal",
        "fr": "Convectionnel-thermique",
        "it": "Convezione termica",
        "es": "Convecci&oacute;n-t&eacute;rmica",
    },
    "district_heating_power": {
        "de": "Fernheizkraftwerke",
        "en": "District heating plants",
        "fr": "Centrales de chauffage urbain",
        "it": "Impianti di teleriscaldamento",
        "es": "Centrales de calefacci&oacute;n urbana",
    },
    "industry": {
        "de": "Industrie",
        "en": "Industrie",
        "fr": "Industrie",
        "it": "Industria",
        "es": "Industrie",
    },
    "landfill_gas": {
        "de": "Deponiegas",
        "en": "Deponiegas",
        "fr": "Deponiegas",
        "it": "Deponiegas",
        "es": "Deponiegas",
    },
    "marine": {
        "de": "Meeresenergie",
        "en": "Ocean Energy",
        "fr": "&Eacute;nergie marine",
        "it": "Energia marina",
        "es": "Energ&iacute;a oce&aacute;nica",
    },
    "other_non_renewable": {
        "de": "Andere nicht erneuerbare",
        "en": "Other non-renewable",
        "fr": "Autres &eacute;nergies non renouvelables",
        "it": "Altri non rinnovabili",
        "es": "Otros no renovables",
    },
    "small_chp": {
        "en": "Small-CHP",
        "de": "Klein-WKK",
        "fr": "Petites chaleur-force",
        "it": "Piccola cogenerazione",
        "es": "Fuerza calor&iacute;fica pequeña"
    },
    "waste_incinerators_with_heat": {
        "en": "Waste incinerators without heat",
        "de": "Kehrichtverbrennung ohne W\u00e4rme",
        "fr": "Incin\u00e9ration des ordures sans chaleur",
        "it": "Incenerimento dei rifiuti senza termica",
        "es": "Incineraci&oacute;n de residuos sin calor"
    },
    "seasonal_storage": {
        "de": "Speicherwasser",
        "en": "Storage water",
        "fr": "Eau de stockage",
        "it": "Acqua di stoccaggio",
        "es": "Agua almacenada",
    },
    "installed_by_country": {
        "de": "Installierte Leistung je Land",
        "en": "Installed capacity per country",
        "fr": "Puissance install&eacute;e par pays",
        "it": "Capacit&agrave; installata per paese",
        "es": "Capacidad instalada por pa&iacute;s",
    },
    "Location": {
        "de": "Standort",
        "en": "Location",
        "fr": "Site",
        "it": "Posizione",
        "es": "Ubicaci&oacute;n",
    },
    "MainDirection": {
        "de": "Hauptausrichtung",
        "en": "Main orientation",
        "fr": "Orientation principale",
        "it": "Orientamento principale",
        "es": "Orientaci&oacute;n principal",
    },
    "PumpedStorageTechnology": {
        "de": "Pumpspeichertechnologie",
        "en": "Pumped storage technology",
        "fr": "Technologie de pompage-turbinage",
        "it": "Tecnologia di pompaggio",
        "es": "Tecnolog&iacute;a de acumulaci&oacute;n por bombeo",
    },
    "BatteryTechnology": {
        "de": "Batterie-Technologie",
        "en": "Battery technology",
        "fr": "Technologie des batteries",
        "it": "Tecnologia delle batterie",
        "es": "Tecnolog&iacute;a de bater&iacute;as",
    },
    "DistanceToCoast": {
        "de": "Distanz zur K&uuml;ste",
        "en": "Distance from the coast",
        "fr": "Distance &agrave; la côte",
        "it": "Distanza dalla costa",
        "es": "Distancia a la costa",
    },
    "WaterDepth": {
        "de": "Wassertiefe",
        "en": "Water depth",
        "fr": "Profondeur de l'eau",
        "it": "Profondit&agrave; dell'acqua",
        "es": "Profundidad del agua",
    },
    "HubHeight": {
        "de": "Nabenh&ouml;he",
        "en": "Hub height",
        "fr": "Hauteur du moyeu",
        "it": "Altezza del mozzo",
        "es": "Altura del cubo",
    },
    "Technique": {
        "de": "Technik",
        "en": "Technology",
        "fr": "Technique",
        "it": "Tecnologia",
        "es": "Tecnolog&iacute;a",
    },
    "day_ahead_prices": {
        "de": "Day ahead B&ouml;rsenstrompreis",
        "en": "Day ahead exchange bidding",
        "fr": "Day ahead Prix de l'&eacute;lectricit&eacute; en bourse",
        "it": "Prezzo dell'energia elettrica in borsa con un giorno di anticipo",
        "es": "Precio de la electricidad en bolsa a un d&iacute;a vista",
    },
    "price_traffic_light_se1": {
        "de": "Strompreisampel, Gebotszone Schweden 1",
        "en": "Electricity price traffic light, bidding zone Sweden 1",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Su&egrave;de 1",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Svezia 1",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Suecia 1",
    },
    "price_traffic_light_se2": {
        "de": "Strompreisampel, Gebotszone Schweden 2",
        "en": "Electricity price traffic light, bidding zone Sweden 2",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Su&egrave;de 2",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Svezia 2",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Suecia 2",
    },
    "price_traffic_light_se3": {
        "de": "Strompreisampel, Gebotszone Schweden 3",
        "en": "Electricity price traffic light, bidding zone Sweden 3",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Su&egrave;de 3",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Svezia 3",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Suecia 3",
    },
    "price_traffic_light_se4": {
        "de": "Strompreisampel, Gebotszone Schweden 4",
        "en": "Electricity price traffic light, bidding zone Sweden 4",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Su&egrave;de 4",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Svezia 4",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Suecia 4",
    },
    "price_traffic_light_no1": {
        "de": "Strompreisampel, Gebotszone Norwegen 1",
        "en": "Electricity price traffic light, bidding zone Norway 1",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Norv&egrave;ge 1",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Norvegia 1",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Noruega 1",
    },
    "price_traffic_light_no2": {
        "de": "Strompreisampel, Gebotszone Norwegen 2",
        "en": "Electricity price traffic light, bidding zone Norway 2",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Norv&egrave;ge 2",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Norvegia 2",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Noruega 2",
    },
    "price_traffic_light_no3": {
        "de": "Strompreisampel, Gebotszone Norwegen 3",
        "en": "Electricity price traffic light, bidding zone Norway 3",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Norv&egrave;ge 3",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Norvegia 3",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Noruega 3",
    },
    "price_traffic_light_no4": {
        "de": "Strompreisampel, Gebotszone Norwegen 4",
        "en": "Electricity price traffic light, bidding zone Norway 4",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Norv&egrave;ge 4",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Norvegia 4",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Noruega 4",
    },
    "price_traffic_light_no2nsl": {
        "de": "Strompreisampel, Gebotszone North Sea Link",
        "en": "Electricity price traffic light, bidding zone North Sea Link",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res North Sea Link",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Mare del Nord Link",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Enlace del Mar del Norte",
    },
    "price_traffic_light_dk1": {
        "de": "Strompreisampel, Gebotszone D&auml;nemark 1",
        "en": "Electricity price traffic light, bidding zone Denmark 1",
        "fr": "Prix du feu de signalisation, zone d'ench&egrave;res Danemark 1",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Danimarca 1",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Dinamarca 1",
    },
    "price_traffic_light_dk2": {
        "de": "Strompreisampel, Gebotszone D&auml;nemark 2",
        "en": "Electricity price traffic light, bidding zone Denmark 2",
        "fr": "Prix du feu de signalisation, zone d'ench&egrave;res Danemark 2",
        "it": "Semaforo dei prezzi dell'elettricità, Zona d'offerta Danimarca 2",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Dinamarca 2",
    },
    "price_traffic_light_it-north": {
        "de": "Strompreisampel, Gebotszone Italien-Nord",
        "en": "Electricity price traffic light, bidding zone Italy-North",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res Italie-Nord",
        "it": "Prezzo del semaforo elettrico, Zona d'offerta Italia-Nord",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Italia-Norte",
    },
    "price_traffic_light_it-centre-north": {
        "de": "Strompreisampel, Gebotszone Zentral-Nord-Italien",
        "en": "Electricity price traffic light, bidding zone Central North Italy",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res du centre-nord de l'Italie",
        "it": "Prezzo del semaforo dell'energia elettrica, prezzo dell'energia elettrica zona Centro Nord Italia",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Centro Norte de Italia",
    },
    "price_traffic_light_it-centre-south": {
        "de": "Strompreisampel, Gebotszone Zentral-S&uuml;d-Italien",
        "en": "Electricity price traffic light, bidding zone Central South Italy",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res du centre-sud de l'Italie",
        "it": "Prezzo del semaforo elettrico, Zona d'offerta Centro-Sud Italia",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Centro-Sur de Italia",
    },
    "price_traffic_light_it-south": {
        "de": "Strompreisampel, Gebotszone S&uuml;d-Italien",
        "en": "Electricity price traffic light, bidding zone South Italy",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res du sud de l'Italie",
        "it": "Prezzo del semaforo elettrico, Zona d'offerta Sud Italia",
        "es": "Semáforo del precio de la electricidad, precio de la electricidad en la zona sur de Italia",
    },
    "price_traffic_light_it-sicily": {
        "de": "Strompreisampel, Gebotszone Sizilien",
        "en": "Electricity price traffic light, bidding zone Sicily",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res en Sicile",
        "it": "Prezzo del semaforo elettrico, Zona d'offerta Sicilia",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Sicilia",
    },
    "price_traffic_light_it-sardinia": {
        "de": "Strompreisampel, Gebotszone Sardinien",
        "en": "Electricity price traffic light, bidding zone Sardinia",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res en Sardaigne",
        "it": "Prezzo del semaforo elettrico, Zona d'offerta Sardegna",
        "es": "Semáforo del precio de la electricidad, Zona de licitaci&oacute;n Cerde&ntilde;a",
    },
    "price_traffic_light_fr": {
        "de": "Strompreisampel, Gebotszone Frankreich",
        "en": "Electricity price traffic light, bid zone France",
        "fr": "Feu de signalisation sur le prix, zone d'ench&egrave;res France",
        "it": "Prezzo del semaforo elettrico, zona d'asta Francia",
        "es": "Semáforo del precio de la electricidad, zona de licitaci&oacute;n Francia",
    },
    "price_traffic_light_fr-sacoac": {
        "de": "Strompreisampel, Gebotszone SACOAC",
        "en": "Electricity price traffic light, bidding zone SACOAC",
        "fr": "Prix des feux de circulation, zone d'ench&egrave;res SACOAC",
        "it": "Prezzo del semaforo elettrico, zona d'asta SACOAC",
        "es": "Semáforo del precio de la electricidad, zona de licitaci&oacute;n SACOAC",
    },
    "explanation_traffic_light_price": {
        "de": "<p>Die Ampelfarben werden auf Grundlage des Preises im Tagesverlauf berechnet: Dazu werden das 0,8 Quantil und das 0,2 Quantil berechnet. F&uuml;r L&auml;nder mit mehreren Gebotszonen wird jeweils eine Ampel pro Gebotszone zur Verf&uuml;gung gestellt.</p>" +
            "<ol>" +
                "<li><b>Grün / Blau:</b> Preis &lt; 0,2 Quantil oder Preis &le; 0,00 €/MWh</li>" +
                "<li><b>Gelb:</b> 0,2 Quantil &le; Preis &le; 0,8 Quantil</li>" +
                "<li><b>Rot:</b> Preis &gt; 0,8 Quantil</li>" +
            "</ol>",
        "en": "<p>The traffic light colors are calculated based on the price during the day: The 0.8 quantile and the 0.2 quantile are calculated for this purpose. For countries with multiple bidding zones, one traffic light is provided for each bidding zone.</p>" +
            "<ol>" +
                "<li><b>Green / Blue:</b> Price &lt; 0.2 quantil or price &le; 0.00 €/MWh</li>" +
                "<li><b>Yellow:</b> 0.2 quantile &le; price &le; 0.8 quantile</li>" +
                "<li><b>Red:</b> Price &gt; 0.8 quantile </li>" +
            "</ol>",
        "fr": "<p>Les couleurs des feux de signalisation sont calcul&eacute;es sur la base du prix au cours de la journ&eacute;e : Le quantile 0,8 et le quantile 0,2 sont calcul&eacute;s &agrave; cet effet. Pour les pays ayant plusieurs zones d'ench&egrave;res, un feu par zone d'ench&egrave;res est mis &agrave; disposition.</p>" +
            "<ol>" +
            "<li><b>Vert / Bleu:</b> Prix &lt; 0,2 quantile ou prix &le; 0,00 €/MWh</li>" +
            "<li><b>Jaune:</b> 0,2 qunatile &le; prix &le; 0,8 quantile</li>" +
            "<li><b>Rouge:</b> Prix &gt; 0,8 quantile</li>" +
            "</ol>",
        "it": "<p>I colori del semaforo sono calcolati in base al prezzo nel corso della giornata: A tal fine, vengono calcolati lo 0,8 quantile e lo 0,2 quantile. Per i Paesi con pi&ugrave; zone di offerta, viene fornito un semaforo per ogni zona di offerta.</p>" +
            "<ol>" +
            "<li><b>Verde / Blu:</b> Prezzo &lt; 0,2 quantile o prezzo &le; 0,00 €/MWh</li>" +
            "<li><b>Giallo:</b> 0,2 Quantile &le; Prezzo &le; 0,8 Quantile</li>" +
            "<li><b>Rosso:</b> Prezzo &gt; 0,8 quantile</li>" +
            "</ol>",
        "es": "<p>Los colores del sem&aacute;foro se calculan en funci&oacute;n del precio a lo largo del d&iacute;a: Para ello, se calculan el cuantil 0,8 y el cuantil 0,2. En el caso de los pa&iacute;ses con varias zonas de subasta, se proporciona un sem&aacute;foro por zona de subasta.</p>" +
            "<ol>" +
            "<li><b>Verde / Azul:</b> Precio &lt; 0,2 cuantil o Precio &le; 0,00 €/MWh</li>" +
            "<li><b>Amarillo:</b> 0,2 Cuantil &le; Precio &le; 0,8 Cuantil</li>" +
            "<li><b>Rojo:</b> Precio &gt; 0,8 cuantil</li>" +
            "</ol>"
    },
    "explanation_traffic_light_price_title": {
        "de": "Erl&auml;uterungen zur Strompreisampel",
        "en": "Explanation of the electricity price traffic light",
        "fr": "Explications sur le feu de signalisation sur le prix",
        "it": "Spiegazione del semaforo del prezzo dell'elettricità",
        "es": "Explicación del semáforo de precios de la electricidad",
    },
    "reference": {
        "de": "Bezugspunkt",
        "en": "Reference",
        "fr": "Référence",
        "it": "Riferimento",
        "es": "Referencia",
    },
    "relative_to_load": {
        "de": "Relativ zur Last",
        "en": "Relative to load",
        "fr": "Relatif à la charge",
        "it": "Relativo al carico",
        "es": "En relación con la carga",
    },
    "single_categories": {
        "de": "Einzelne Kategorien",
        "en": "Single categories",
        "fr": "Catégories uniques",
        "it": "Categorie singole",
        "es": "Categorías individuales",
    },
    "ren_non-ren": {
        "de": "Erneuerbar / Fossil",
        "en": "Renewable / Fossil",
        "fr": "Renouvelable / Fossile",
        "it": "Rinnovabili / Fossili",
        "es": "Renovables / Fósiles",
    },
    "netPower": {
        "de": "Nettoleistung",
        "en": "Net performance",
        "fr": "Performance nette",
        "it": "Prestazioni nette",
        "es": "Rendimiento neto",
    },
    "RotorDiameter": {
        "de": "Rotordurchmesser",
        "en": "Rotor diameter",
        "fr": "Diam&egrave;tre du rotor",
        "it": "Diametro del rotore",
        "es": "Di&aacute;metro del rotor",
    },
    "TypeName": {
        "de": "Typenbezeichnung",
        "en": "Type designation",
        "fr": "D&eacute;signation du type",
        "it": "Designazione del tipo",
        "es": "Designaci&oacute;n de tipo",
    },
    "MainDirectionAngle": {
        "de": "Neigungswinkel Hauptausrichtung",
        "en": "Inclination angle main orientation",
        "fr": "Angle d'inclinaison Orientation principale",
        "it": "Angolo di inclinazione Orientamento principale",
        "es": "&Aacute;ngulo de inclinaci&oacute;n Orientaci&oacute;n principal",
    },
    "StorageCapacity": {
        "de": "Kapazit&auml;t",
        "en": "Capacity",
        "fr": "Capacit&eacute;",
        "it": "Capacit&agrave;",
        "es": "Capacidad",
    },
    "hard_coal_import_absolute_values": { 
        "de": "Gesamtmenge Import",
        "en": "Total quantity import",
        "fr": "Quantit&eacute; totale import&eacute;e",
        "it": "Quantit&agrave; totale importata",
        "es": "Cantidad total importada",
    },
    "hard_coal_import_price": { 
        "de": "Gesamtkosten f&uuml;r Import",
        "en": "Total cost for import",
        "fr": "Coût total de l'importation",
        "it": "Costi totali per l'importazione",
        "es": "Costes totales de importaci&oacute;n",
    },
    "hard_coal_import_export_absolute": { 
        "de": "L&auml;nder (Tonnen)",
        "en": "Countries (tons)",
        "fr": "Pays (tonnes)",
        "it": "Paesi (tonnellate)", 
        "es": "Pa&iacute;ses (toneladas)",
    },
    "hard_coal_import_export_price": { 
        "de": "L&auml;nder (Euro)",
        "en": "Countries (Euro)",
        "fr": "Pays (Euros)",
        "it": "Paesi (Euro)",
        "es": "Pa&iacute;ses (Euros)",
    },
    "lignite_mining": { 
        "de": "Braunkohlenförderung",
        "en": "Lignite mining",
        "fr": "Extraction de lignite",
        "it": "Estrazione di lignite",
        "es": "Minería del lignito",
    },   
    "lignite_mining_absolute": { 
        "de": "Gesamt (Tonnen)",
        "en": "Total (tons)",
        "fr": "Total (tonnes)",
        "it": "Totale (tonnellate)",
        "es": "Total (toneladas)",
    },       
    
    "gas_import_export_absolute": { 
        "de": "Gesamt (Tonnen)",
        "en": "Total (tons)",
        "fr": "Total (tonnes)",
        "it": "Totale (tonnellate)",
        "es": "Total (toneladas)",
    },
    "gas_import_export_price": { 
        "de": "Gesamt (Euro)",
        "en": "Total (Euro)",
        "fr": "Total (euros)",
        "it": "Totale (euro)",
        "es": "Total (euros)",
    },
    "hydrogen_import_export_absolute": { 
        "de": "L&auml;nder (Tonnen)",
        "en": "Countries (tons)",
        "fr": "Pays (tonnes)",
        "it": "Paesi (tonnellate)",
        "es": "Pa&iacute;ses (toneladas)",
    },
    "hydrogen_import_export_price": { 
        "de": "L&auml;nder (Euro)",
        "en": "Countries (Euro)",
        "fr": "Pays (euro)",
        "it": "Paesi (Euro)",
        "es": "Pa&iacute;ses (Euro)",
    },
    "oil_import_export_absolute": { 
        "de": "L&auml;nder (Tonnen)",
        "en": "Countries (tons)",
        "fr": "Pays (tonnes)",
        "it": "Paesi (tonnellate)",
        "es": "Pa&iacute;ses (toneladas)",
    },
    "oil_import_export_price": { 
        "de": "L&auml;nder (Euro)",
        "en": "Countries (Euro)",
        "fr": "Pays (euro)",
        "it": "Paesi (Euro)",
        "es": "Pa&iacute;ses (Euro)",
    },    
    "uranium_enriched_import_export_absolute": { 
        "de": "Angereichertes Uran 235, L&auml;nder (Tonnen)",
        "en": "Enriched uranium 235, Countries (tons)",
        "fr": "Uranium enrichi 235, Pays (tonnes)",
        "it": "Uranio arricchito 235, Paesi (tonnellate)",
        "es": "Uranio enriquecido 235, Pa&iacute;ses (toneladas)",
    },
    "uranium_enriched_import_export_special": { 
        "de": "Angereichertes Uran 235, Länder (g spaltb. Isotope)",
        "en": "Enriched uranium 235, Countries (g of fissile isotopes)",
        "fr": "Uranium enrichi 235, Pays (g d'isotopes fissiles)",
        "it": "Uranio arricchito 235, Paesi (g di isotopi fissili)",
        "es": "Uranio enriquecido 235, Pa&iacute;ses (g de isótopos fisionables)",
    },
    "uranium_enriched_import_export_price": { 
        "de": "Angereichertes Uran 235, L&auml;nder (Euro)",
        "en": "Enriched uranium 235, Countries (Euro)",
        "fr": "Uranium enrichi 235, Pays (euro)",
        "it": "Uranio arricchito 235, Paesi (Euro)",
        "es": "Uranio enriquecido 235, Pa&iacute;ses (Euro)",
    },
    "uranium_natural_import_export_absolute": { 
        "de": "Nat&uuml;rliches Uran, L&auml;nder (Tonnen)",
        "en": "Natural uranium, Countries (tons)",
        "fr": "Uranium naturel, Pays (tonnes)",
        "it": "Uranio naturale, Paesei (tonnellate)",
        "es": "Uranio natural, Pa&iacute;s (toneladas)"
    },
    "uranium_natural_import_export_special": { 
        "de": "Nat&uuml;rliches Uran, L&auml;nder (g spaltb. Isotope)",
        "en": "Natural uranium, Countries (g of fissile isotopes)",
        "fr": "Uranium naturel, Pays (g d'isotopes fissiles)",
        "it": "Uranio naturale, Paesei (g di isotopi fissili)",
        "es": "Uranio natural, Pa&iacute;s (g de isótopos fisionables)"
    },
    "uranium_natural_import_export_price": { 
        "de": "Nat&uuml;rliches Uran, L&auml;nder (Euro)",
        "en": "Natural uranium, country (Euro)",
        "fr": "Uranium naturel, Pays (Euro)",
        "it": "Uranio naturale, Paesi (Euro)",
        "es": "Uranio natural, Pa&iacute;s (euros)"
    },
    "uranium_natural_compounds_import_export_absolute": { 
        "de": "Nat&uuml;rliches Uran und Verbindungen, L&auml;nder (Tonnen)",
        "en": "Natural uranium and compounds, country (tons)",
        "fr": "Uranium naturel et compos&eacute;s, Pays (tonnes)",
        "it": "Uranio naturale e composti, Paesi (tonnellate)",
        "es": "Uranio natural y compuestos, Pa&iacute;s (toneladas)"
    },
    "uranium_natural_compounds_import_export_special": { 
        "de": "Nat&uuml;rliches Uran und Verbindungen, L&auml;nder (g spaltb. Isotope)",
        "en": "Natural uranium and compounds, country (g of fissile isotopes)",
        "fr": "Uranium naturel et compos&eacute;s, Pays (g d'isotopes fissiles)",
        "it": "Uranio naturale e composti, Paesi (g di isotopi fissili)",
        "es": "Uranio natural y compuestos, Pa&iacute;s (g de isótopos fisionables)"
    },
    "uranium_natural_compounds_import_export_price": { 
        "de": "Nat&uuml;rliches Uran und Verbindungen, L&auml;nder (Euro)",
        "en": "Natural uranium and compounds, country (Euro)",
        "fr": "Uranium naturel et compos&eacute;s, Pays (Euro)",
        "it": "Uranio naturale e composti, Paesi (Euro)",
        "es": "Uranio natural y compuestos, Pa&iacute;s (Euro)",
    },
    "fuel_elements_import_export_absolute": { 
        "de": "Brennstoffelemente (unbestrahlt), L&auml;nder (Tonnen)",
        "en": "Fuel elements (unirradiated), country (tons)",
        "fr": "Éléments combustibles (non irradiés), Pays (tonnes)",
        "it": "Elementi combustibili (non irradiati), Paesi (tonnellate)",
        "es": "Elementos combustibles (no irradiados), Pa&iacute;s (toneladas)"
    },
    "fuel_elements_import_export_special": { 
        "de": "Brennstoffelemente (unbestrahlt), L&auml;nder (g spaltb. Isotope)",
        "en": "Fuel elements (unirradiated), country (g of fissile isotopes)",
        "fr": "Éléments combustibles (non irradiés), Pays (g d'isotopes fissiles)",
        "it": "Elementi combustibili (non irradiati), Paesi (g di isotopi fissili)",
        "es": "Elementos combustibles (no irradiados), Pa&iacute;s (g de isótopos fisionables)"
    },
    "fuel_elements_import_export_price": { 
        "de": "Brennstoffelemente (unbestrahlt), L&auml;nder (Euro)",
        "en": "Fuel elements (unirradiated), country (Euro)",
        "fr": "Éléments combustibles (non irradiés), Pays (Euro)",
        "it": "Elementi combustibili (non irradiati), Paesi (Euro)",
        "es": "Elementos combustibles (no irradiados), Pa&iacute;s (Euro)",
    },
    "belgium": {
        "de": "Belgien",       
        "en": "Belgium",       
        "fr": "Belgique",      
        "it": "Belgio",        
        "es": "B&eacute;lgica",
    },
    "denmark": {
            "de": "D&auml;nemark", 
            "en": "Denmark",       
            "fr": "Danemark",      
            "it": "Danimarca",     
            "es": "Dinamarca",     
    },
    "finland": {
            "de": "Finnland", 
            "en": "Finland",  
            "fr": "Finlande", 
            "it": "Finlandia",
            "es": "Finlandia",
    },
    "france": { 
            "de": "Frankreich",
            "en": "France",
            "fr": "France",
            "it": "Francia",
            "es": "Francia",
    },
    "italy": { 
            "de": "Italien",
            "en": "Italy",
            "fr": "Italie",
            "it": "Italia",
            "es": "Italia",
    },
    "netherlands": { 
            "de": "Niederlande",
            "en": "Netherlands",
            "fr": "Pays-Bas",
            "it": "Paesi Bassi",
            "es": "Pa&iacute;ses Bajos",
    },
    "austria": { 
            "de": "&Ouml;sterreich",
            "en": "Austria",
            "fr": "Autriche",
            "it": "Austria",
            "es": "Austria",
    },
    "poland": { 
            "de": "Polen",
            "en": "Poland",
            "fr": "Pologne",
            "it": "Polonia",
            "es": "Polonia",
    },
    "czech republic": { 
            "de": "Tschechische Republik",
            "en": "Czech republic",
            "fr": "R&eacute;publique tch&egrave;que",
            "it": "Repubblica Ceca",
            "es": "Rep&uacute;blica Checa",
    },
    "united kingdom": { 
            "de": "Vereinigtes K&ouml;nigreich",
            "en": "United kingdom",
            "fr": "Royaume-Uni",
            "it": "Regno Unito",
            "es": "Reino Unido",
    },
    "north macedonia": { 
            "de": "Nord-Mazedonien",
            "en": "North macedonia",
            "fr": "Mac&eacute;doine du Nord",
            "it": "Macedonia settentrionale",
            "es": "Macedonia del Norte",
    },
    "russian federation": { 
            "de": "Russische F&ouml;deration",
            "en": "Russian federation",
            "fr": "F&eacute;d&eacute;ration de Russie",
            "it": "Federazione russa",
            "es": "Federaci&oacute;n de Rusia",
    },
    "switzerland": { 
            "de": "Schweiz",
            "en": "Switzerland",
            "fr": "Suisse",
            "it": "Svizzera",
            "es": "Suiza",
    },
    "south africa": { 
            "de": "S&uuml;d-Afrika",
            "en": "South africa",
            "fr": "Afrique du Sud",
            "it": "Sudafrica",
            "es": "Sud&aacute;frica",
    },
    "united states of america": { 
            "de": "Vereinigte Staaten von Amerika",
            "en": "United states of america",
            "fr": "&Eacute;tats-Unis d'Am&eacute;rique",
            "it": "Stati Uniti d'America",
            "es": "Estados Unidos de Am&eacute;rica",
    },
    "hong kong": { 
            "de": "Hongkong",
            "en": "Hong Kong",
            "fr": "Hong Kong",
            "it": "Hong Kong",
            "es": "Hong Kong",
    },
    "islamic republic of iran": { 
            "de": "Islamische Republik Iran",
            "en": "Islamic republic of iran",
            "fr": "R&eacute;publique islamique d'Iran",
            "it": "Repubblica islamica dell'Iran",
            "es": "rep&uacute;blica isl&aacute;mica de ir&aacute;n",
    },
    "oman": { 
            "de": "Oman",
            "en": "Oman",
            "fr": "Oman",
            "it": "Oman",
            "es": "Om&aacute;n",
    },
    "taiwan": { 
            "de": "Taiwan",
            "en": "Taiwan",
            "fr": "Taïwan",
            "it": "Taiwan",
            "es": "Taiw&aacute;n",
    },
    "hungary": { 
            "de": "Ungarn",
            "en": "Hungary",
            "fr": "Hongrie",
            "it": "Ungheria",
            "es": "Hungr&iacute;a",
    },
    "norway": { 
            "de": "Norwegen",
            "en": "Norway",
            "fr": "Norv&egrave;ge",
            "it": "Norvegia",
            "es": "Noruega",
    },
    "ukraine": { 
            "de": "Ukraine",
            "en": "Ukraine",
            "fr": "Ukraine",
            "it": "Ucraina",
            "es": "Ucrania",
    },
    "egypt": { 
            "de": "&Auml;gypten",
            "en": "Egypt",
            "fr": "&Eacute;gypte",
            "it": "Egitto",
            "es": "Egipto",
    },
    "canada": { 
            "de": "Kanada",
            "en": "Canada",
            "fr": "Canada",
            "it": "Canada",
            "es": "Canad&aacute;",
    },
    "colombia": { 
            "de": "Kolumbien",
            "en": "Colombia",
            "fr": "Colombie",
            "it": "Colombia",
            "es": "Colombia",
    },
    "china": { 
            "de": "China",
            "en": "China",
            "fr": "Chine",
            "it": "Cina",
            "es": "China",
    },
    "japan": { 
            "de": "Japan",
            "en": "Japan",
            "fr": "Japon",
            "it": "Giappone",
            "es": "Jap&oacute;n",
    },
    "republic of korea": { 
            "de": "Republik Korea",
            "en": "Republic of korea",
            "fr": "R&eacute;publique de Cor&eacute;e",
            "it": "Repubblica di Corea",
            "es": "Rep&uacute;blica de Corea",
    },
    "saudi arabia": { 
            "de": "Saudi-Arabien",
            "en": "Saudi arabia",
            "fr": "Arabie Saoudite",
            "it": "Saudi arabia",
            "es": "Arabia Saud&iacute;",
    },
    "syrian arab republic": { 
            "de": "Syrische Arabische Republik",
            "en": "Syrian arab republic",
            "fr": "R&eacute;publique arabe syrienne",
            "it": "Repubblica araba siriana",
            "es": "rep&uacute;blica &aacute;rabe siria",
    },
    "thailand": { 
            "de": "Thailand",
            "en": "Thailand",
            "fr": "Thaïlande",
            "it": "Thailandia",
            "es": "Tailandia",
    },
    "uzbekistan": { 
            "de": "Usbekistan",
            "en": "Uzbekistan",
            "fr": "Ouzb&eacute;kistan",
            "it": "Uzbekistan",
            "es": "Uzbekist&aacute;n",
    },
    "united arab emirates": { 
            "de": "Vereinigte arabische Emirate",
            "en": "United arab emirates",
            "fr": "&Eacute;mirats arabes unis",
            "it": "Emirati Arabi Uniti",
            "es": "Emiratos &Aacute;rabes Unidos",
    },
    "romania": { 
            "de": "Rum&auml;nien",
            "en": "Romania",
            "fr": "Roumanie",
            "it": "Romania",
            "es": "Ruman&iacute;a",
    },
    "spain": { 
            "de": "Spanien",
            "en": "Spain",
            "fr": "Espagne",
            "it": "Spagna",
            "es": "Espa&ntilde;a",
    },
    "turkey": { 
            "de": "T&uuml;rkei",
            "en": "Turkey",
            "fr": "Turquie",
            "it": "Turchia",
            "es": "Turqu&iacute;a",
    },
    "libya": { 
            "de": "Libyen",
            "en": "Libya",
            "fr": "Libye",
            "it": "Libia",
            "es": "Libia",
    },
    "brazil": { 
            "de": "Brasilien",
            "en": "Brazil",
            "fr": "Br&eacute;sil",
            "it": "Brasile",
            "es": "Brasil",
    },
    "slovakia": { 
            "de": "Slowakei",
            "en": "Slovakia",
            "fr": "Slovaquie",
            "it": "Slovacchia",
            "es": "Eslovaquia",
    },
    "belarus": { 
            "de": "Wei&szlig;russland",
            "en": "Belarus",
            "fr": "B&eacute;larus",
            "it": "Bielorussia",
            "es": "Bielorrusia",
    },
    "kazakhstan": { 
            "de": "Kasachstan",
            "en": "Kazakhstan",
            "fr": "Kazakhstan",
            "it": "Kazakistan",
            "es": "Kazajst&aacute;n",
    },
    "katar": { 
            "de": "Katar",
            "en": "Katar",
            "fr": "Katar",
            "it": "Katar",
            "es": "Katar",
    },
    "singapore": { 
            "de": "Singapur",
            "en": "Singapore",
            "fr": "Singapour",
            "it": "Singapore",
            "es": "Singapur",
    },
    "sweden": { 
            "de": "Schweden",
            "en": "Sweden",
            "fr": "Su&egrave;de",
            "it": "Svezia",
            "es": "Suecia",
    },
    "peru": { 
            "de": "Peru",
            "en": "Peru",
            "fr": "P&eacute;rou",
            "it": "Per&ugrave;",
            "es": "Per&uacute;",
    },
    "luxembourg": { 
            "de": "Luxemburg",
            "en": "Luxembourg",
            "fr": "Luxembourg",
            "it": "Lussemburgo",
            "es": "Luxemburgo",
    },
    "slovenia": { 
            "de": "Slowenien",
            "en": "Slovenia",
            "fr": "Slov&eacute;nie",
            "it": "Slovenia",
            "es": "Eslovenia",
    },
    "chile": { 
            "de": "Chile",
            "en": "Chile",
            "fr": "Chili",
            "it": "Cile",
            "es": "Chile",
    },
    "georgia": { 
            "de": "Georgien",
            "en": "Georgia",
            "fr": "G&eacute;orgie",
            "it": "Georgia",
            "es": "Georgia",
    },
    "iraq": { 
            "de": "Irak",
            "en": "Iraq",
            "fr": "L'Irak",
            "it": "Iraq",
            "es": "Iraq",
    },
    "israel": { 
            "de": "Israel",
            "en": "Israel",
            "fr": "Israël",
            "it": "Israele",
            "es": "Israel",
    },
    "turkmenistan": { 
            "de": "Turkmenistan",
            "en": "Turkmenistan",
            "fr": "Turkm&eacute;nistan",
            "it": "Turkmenistan",
            "es": "Turkmenist&aacute;n",
    },
    "papua new guinea": { 
            "de": "Papua-Neuguinea",
            "en": "Papua new guinea",
            "fr": "Papouasie-Nouvelle-Guin&eacute;e",
            "it": "Papua Nuova Guinea",
            "es": "Pap&uacute;a Nueva Guinea",
    },
    "portugal": { 
            "de": "Portugal",
            "en": "Portugal",
            "fr": "Portugal",
            "it": "Portogallo",
            "es": "Portugal",
    },
    "cyprus": { 
            "de": "Zypern",
            "en": "Cyprus",
            "fr": "Chypre",
            "it": "Cipro",
            "es": "Chipre",
    },
    "malaysia": { 
            "de": "Malaysia",
            "en": "Malaysia",
            "fr": "Malaisie",
            "it": "Malesia",
            "es": "Malasia",
    },
    "mongolei": { 
            "de": "Mongolei",
            "en": "Mongolei",
            "fr": "Mongolei",
            "it": "Mongolei",
            "es": "Mongolei",
    },
    "latvia": { 
            "de": "Lettland",
            "en": "Latvia",
            "fr": "Lettonie",
            "it": "Lettonia",
            "es": "Letonia",
    },
    "algeria": { 
            "de": "Algerien",
            "en": "Algeria",
            "fr": "Alg&eacute;rie",
            "it": "Algeria",
            "es": "Argelia",
    },
    "jordanien": { 
            "de": "Jordanien",
            "en": "Jordanien",
            "fr": "Jordanie",
            "it": "Giordania",
            "es": "Jordania",
    },
    "australia": { 
            "de": "Australien",
            "en": "Australia",
            "fr": "Australie",
            "it": "Australia",
            "es": "Australia",
    },
    "greece": { 
            "de": "Griechenland",
            "en": "Greece",
            "fr": "Gr&egrave;ce",
            "it": "Grecia",
            "es": "Grecia",
    },
    "malta": { 
            "de": "Malta",
            "en": "Malta",
            "fr": "Malte",
            "it": "Malta",
            "es": "Malta",
    },
    "liechtenstein": { 
            "de": "Liechtenstein",
            "en": "Liechtenstein",
            "fr": "Liechtenstein",
            "it": "Liechtenstein",
            "es": "Liechtenstein",
    },
    "liberia": { 
            "de": "Liberia",
            "en": "Liberia",
            "fr": "Lib&eacute;ria",
            "it": "Liberia",
            "es": "Liberia",
    },
    "faroe islands": { 
            "de": "F&auml;r&ouml;er Inseln",
            "en": "Faroe islands",
            "fr": "Îles F&eacute;ro&eacute;",
            "it": "Isole Faroe",
            "es": "Islas Feroe",
    },
    "mexico": { 
            "de": "Mexiko",
            "en": "Mexico",
            "fr": "Mexique",
            "it": "Messico",
            "es": "M&eacute;xico",
    },
    "ghana": { 
            "de": "Ghana",
            "en": "Ghana",
            "fr": "Ghana",
            "it": "Ghana",
            "es": "Ghana",
    },
    "gambia": { 
            "de": "Gambia",
            "en": "Gambia",
            "fr": "Gambie",
            "it": "Gambia",
            "es": "Gambia",
    },
    "lebanon": { 
            "de": "Libanon",
            "en": "Lebanon",
            "fr": "Liban",
            "it": "Libano",
            "es": "L&iacute;bano",
    },
    "nigeria": { 
            "de": "Nigeria",
            "en": "Nigeria",
            "fr": "Nigeria",
            "it": "Nigeria",
            "es": "Nigeria",
    },
    "argentina": { 
            "de": "Argentinien",
            "en": "Argentina",
            "fr": "Argentine",
            "it": "Argentina",
            "es": "Argentina",
    },
    "armenia": { 
            "de": "Armenien",
            "en": "Armenia",
            "fr": "Arm&eacute;nie",
            "it": "Armenia",
            "es": "Armenia",
    },
    "Einfuhr von Steinkohle in Tonnen": { 
        "de": "Import von Steinkohle in Tonnen",
        "en": "Hard coal imports in tons",
        "fr": "Importations de houille en tonnes",
        "it": "Importazioni di carbon fossile in tonnellate",
        "es": "Importaciones de hulla en toneladas",
    },
    "Einfuhr von Steinkohle in Gigajoule": {
            "de": "Import von Steinkohle in Terrajoule",
            "en": "Import of hard coal in terrajoule",
            "fr": "Importation de charbon en terrajoules",
            "it": "Importazione di carbone fossile in terrajoule",
            "es": "Importaci&oacute;n de hulla en terrajulios",
    },
    "Einfuhr von Steinkohle in Euro": { 
        "de": "Import von Steinkohle in Euro",
        "en": "Hard coal imports in euros",
        "fr": "Importations de charbon en euros",
        "it": "Importazioni di carbon fossile in euro",
        "es": "Importaciones de hulla en euros",
    },
    "Einfuhr von Steinkohle in Euro/t": { 
            "de": "Import von Steinkohle in Euro pro Tonne",
            "en": "Hard coal imports in euros per tonne",
            "fr": "Importation de charbon en euros par tonne",
            "it": "Importazioni di carbon fossile in euro per tonnellata",
            "es": "Importaciones de hulla en euros por tonelada",
    },
    "pageTitle_energy_source_trade": { 
        "de": "Import und Export von Energietr&auml;gern | Energy-Charts",
        "en": "Import and export of energy sources | Energy-Charts",
        "fr": "Importation et exportation de sources d'&eacute;nergie | Energy-Charts",
        "it": "Importazione ed esportazione di fonti energetiche | Energy-Charts",
        "es": "Importaci&oacute;n y exportaci&oacute;n de fuentes de energ&iacute;a | Energy-Charts",
    },
    "mainMenu.energy-source-trade": { 
        "de": "Import und Export von Energietr&auml;gern",
        "en": "Import and export of energy sources",
        "fr": "Importation et exportation de sources d'&eacute;nergie",
        "it": "Importazione ed esportazione di fonti energetiche",
        "es": "Importaci&oacute;n y exportaci&oacute;n de fuentes de energ&iacute;a",
    },
    "saldo": { 
        "de": "Saldo",
        "en": "Balance",
        "fr": "&Eacute;quilibre",
        "it": "Equilibrio",
        "es": "Saldo",
    },
    "amount": { 
        "de": "Menge",
        "en": "Quantity",
        "fr": "Quantit&eacute;",
        "it": "Quantit&agrave;",
        "es": "Cantidad",
    },
    "natural_gas": { 
        "de": "Erdgas",
        "en": "Natural gas",
        "fr": "Gaz naturel",
        "it": "Gas naturale",
        "es": "Gas natural",
    },
    "mineral_oil": { 
        "de": "Mineral&ouml;l",
        "en": "Mineral oil",
        "fr": "Huile min&eacute;rale",
        "it": "Olio minerale",
        "es": "Aceite mineral",
    },
    "stock_exchange": { 
        "de": "Börse",
        "en": "Stock exchange",
        "fr": "March&eacute; boursier",
        "it": "Mercato azionario",
        "es": "Bolsa",
    },
    "measures_redispatch": { 
        "de": "Ma&szlig;nahmen",
        "en": "Measures",
        "fr": "Mesures",
        "it": "Misure",
        "es": "Medidas",
    },
    "energies_redispatch": { 
        "de": "Energien",
        "en": "Energies",
        "fr": "&Eacute;nergies",
        "it": "Energie",
        "es": "Energ&iacute;as",
    },
    "costs_redispatch": {
            "de": "Kosten",
            "en": "Costs",
            "fr": "Coûts",
            "it": "Costi",
            "es": "Costes",
    },    
    "redispatch_energies_sources": { 
            "de": "Quellen, absolut",
            "en": "Sources, absolute",
            "fr": "Sources, absolu",
            "it": "Fonti, assoluto",
            "es": "Fuentes, absolutas",
    },
    "redispatch_energies_sources_ratio": {
            "de": "Quellen, Anteil an Gesamtenergie",
            "en": "Sources, share of total energy",
            "fr": "Sources, part de l'&eacute;nergie totale",
            "it": "Fonti, quota dell'energia totale",
            "es": "Fuentes, porcentaje de la energ&iacute;a total",
    },
    "redispatch_energies_states": {
            "de": "Bundesl&auml;nder, absolut",
            "en": "Federal states, absolute",
            "fr": "L&auml;nder, en valeur absolue",
            "it": "Stati federali, assoluti",
            "es": "Estados federales, absolutos",
    },
    "redispatch_energies_nets": {
            "de": "Bundesl&auml;nder, nach Netzen (Q1/2015-Q2/2022)", 
            "en": "Federal states, by network (Q1/2015-Q2/2022)",     
            "fr": "L&auml;nder, par r&eacute;seau (T1/2015-Q2/2022)", 
            "it": "Stati federali, per rete (Q1/2015-Q2/2022)",       
            "es": "Estados federales, por red (1T/2015-2T/2022)",     
    },
    "redispatch_energies_costs_sources": { 
        "de": "Quellen, absolut (Q1/2015-Q2/2022)",
        "en": "Sources, absolute (Q1/2015-Q2/2022)",
        "fr": "Sources, absolu (T1/2015-Q2/2022)",
        "it": "Fonti, assoluto (Q1/2015-Q2/2022)",
        "es": "Fuentes, absolutas (1T/2015-2T/2022)",
    },
    "redispatch_energies_costs_states": {
            "de": "Bundesl&auml;nder, absolut (Q1/2015-Q2/2022)",
            "en": "Federal states, absolute (Q1/2015-Q2/2022)",
            "fr": "L&auml;nder, en valeur absolue (T1/2015-Q2/2022)",
            "it": "Stati federali, assoluti (Q1/2015-Q2/2022)",
            "es": "Estados federales, absolutos (1T/2015-2T/2022)",
    },
    "redispatch_energies_costs_nets": {
            "de": "Bundesl&auml;nder, nach Netzen (Q1/2015-Q2/2022)", 
            "en": "Federal states, by network (Q1/2015-Q2/2022)",     
            "fr": "L&auml;nder, par r&eacute;seau (T1/2015-Q2/2022)", 
            "it": "Stati federali, per rete (Q1/2015-Q2/2022)",       
            "es": "Estados federales, por red (1T/2015-2T/2022)",
    },
    "biomass_biogas": { 
        "de": "Biomasse einschlie&szlig;lich Biogas",
        "en": "Biomass including biogas",
        "fr": "Biomasse, y compris le biogaz",
        "it": "Biomassa, compreso il biogas",
        "es": "Biomasa, incluido el biog&aacute;s",
    },
    "pageTitle_energy_redispatch": { 
        "de": "Redispatch Energien | Energy-Charts",
        "en": "Redispatch energies | Energy-Charts",
        "fr": "Redispatch &eacute;nergie | Energy-Charts",
        "it": "Energie Redispatch | Energy-Charts",
        "es": "Redistribuci&oacute;n de energ&iacute;as | Energy-Charts",
    },
    "pageTitle_power_redispatch": {
            "de": "Redispatch Leistung | Energy-Charts",
            "en": "Redispatch power | Energy-Charts",
            "fr": "Puissance de redispatching | Energy-Charts",
            "it": "Potenza di ridispacciamento | Energy-Charts",
            "es": "Redistribuci&oacute;n de la energ&iacute;a | Energy-Charts",
    },
    "hydrogen_import_export_volume": { 
        "de": "L&auml;nder (Kubikmeter)",
        "en": "Countries (cubic meters)",
        "fr": "Pays (m&egrave;tres cubes)",
        "it": "Paesi (metri cubi)",
        "es": "Pa&iacute;ses (metros c&uacute;bicos)",
    },
    "gas_import_export_energy_tj": { 
        "de": "Gesamt (TJ)",
        "en": "Total (TJ)",
        "fr": "Total (TJ)",
        "it": "Totale (TJ)",
        "es": "Total (TJ)",
    },
    "gas_import_export_energy_twh": { 
        "de": "Gesamt (TWh)",
        "en": "Total (TWh)",
        "fr": "Total (TWh)",
        "it": "Totale (TWh)",
        "es": "Total (TWh)",
    },
    "BiddingZone": { 
        "de": "Gebotszone",
        "en": "Bidding zone",
        "fr": "Zone d'enchères",
        "it": "Zona d'offerta",
        "es": "Zona de licitación",
    },
    "redispatch_energies_costs_overall": { 
        "de": "Gesamt",
        "en": "Total",
        "fr": "total",
        "it": "totale",
        "es": "total",
    },
    "redispatch_energies_costs_specific": { 
        "de": "Spezifisch",
        "en": "Specific",
        "fr": "Sp&eacute;cifique",
        "it": "Specifico",
        "es": "Espec&iacute;fico",
    },
    "redispatch_energies_costs_relative": { 
        "de": "Spezifische Kosten:B&ouml;rsenstrompreis",
        "en": "Specific costs:Exchange electricity price",
        "fr": "Coûts sp&eacute;cifiques:prix de l'&eacute;lectricit&eacute; en bourse",
        "it": "Costi specifici: prezzo dell'elettricit&agrave; di scambio",
        "es": "Costes espec&iacute;ficos: Precio de intercambio de la electricidad",
    },
    "residential_and_commercial": { 
        "de": "Privater und gewerblicher Sektor",
        "en": "Residential and commercial sector",
        "fr": "Secteur résidentiel et commercial",
        "it": "Settore residenziale e commerciale",
        "es": "Sector residencial y comercial",
    },
    "transport": { 
        "de": "Transport",
        "en": "Transport",
        "fr": "Transport",
        "it": "Trasporto",
        "es": "Transporte",
    },
    "final_energies_residential_and_commercial": {
        "de": "Endenergie: Privater und gewerblicher Sektor",
        "en": "Final energies: Residential and commercial sector",
        "fr": "&eacute;nergie finale: Secteur résidentiel et commercial",
        "it": "Energia finale: Settore residenziale e commerciale",
        "es": "Energ&iacute;a final: Sector residencial y comercial",
    },
    "final_energies_transport": {
        "de": "Endenergie: Transport",
        "en": "Final energies: Transport",
        "fr": "&eacute;nergie finale: Transport",
        "it": "Energia finale: Transporto",
        "es": "Energ&iacute;a final: Transporte",
    },
    "final_energies_industry": {
        "de": "Endenergie: Industrie",
        "en": "Final energies: Industry",
        "fr": "&eacute;nergie finale: Industrie",
        "it": "Energia finale: Industria",
        "es": "Energ&iacute;a final: Industria",
    },
    "windmills_avg": { 
        "de": "Durchschnittswerte Windr&auml;der (alle Jahre, alle Monate)",
        "en": "Average values for wind turbines (all years, all months)",
        "fr": "Valeurs moyennes des &eacute;oliennes (toutes les ann&eacute;es, tous les mois)",
        "it": "Valori medi per le turbine eoliche (tutti gli anni, tutti i mesi)",
        "es": "Valores medios de los aerogeneradores (todos los a&ntilde;os, todos los meses)",
    },
    "windmills_avg_coastDistance": {
        "de": "Entfernunng zur K&uuml;ste (offshore)",
        "en": "Distance to the coast (offshore)",
        "fr": "Distance &agrave; la côte (offshore)",
        "it": "Distanza dalla costa (offshore)",
        "es": "Distancia a la costa (mar adentro)",
    },
    "windmills_avg_waterDepth": {
        "de": "Wassertiefe (offshore)",
        "en": "Water depth (offshore)",
        "fr": "Profondeur de l'eau (offshore)",
        "it": "Profondit&agrave; dell'acqua (offshore)",
        "es": "Profundidad del agua (mar adentro)",
    },
    "windmills_avg_diameter": { 
        "de": "Rotordurchmesser",         
        "en": "Rotor diameter", 
        "fr": "Diam&egrave;tre du rotor", 
        "it": "Diametro del rotore",      
        "es": "Di&aacute;metro del rotor",
    },
    "windmills_avg_height": { 
        "de": "Nabenh&ouml;he",
        "en": "hub height",
        "fr": "hauteur du moyeu",
        "it": "altezza del mozzo",
        "es": "altura del buje",
    },
    "windmills_avg_power": { 
        "de": "Leistung",
        "en": "Performance",
        "fr": "Puissance",
        "it": "Prestazioni",
        "es": "Rendimiento",
    },
    "map.traceGases": { 
        "de": "Spurengase",
        "en": "Trace gases",
        "fr": "Gaz &agrave; l'&eacute;tat de traces",
        "it": "Gas di traccia",
        "es": "Gases traza",
    },
    "traceGases": { 
        "de": "Spurengase",
        "en": "Trace gases",
        "fr": "Gaz &agrave; l'&eacute;tat de traces",
        "it": "Gas di traccia",
        "es": "Gases traza",
    },
    "aer_335_367": { 
        "de": "Aerosole (335nm - 367nm)",
        "en": "Aerosols (335nm - 367nm)",
        "fr": "A&eacute;rosols (335nm - 367nm)",
        "it": "Aerosol (335nm - 367nm)",
        "es": "Aerosoles (335nm - 367nm)",
    },
    "aer_340_380": { 
        "de": "Aerosole (340nm - 380nm)",
        "en": "Aerosols (340nm - 380nm)",
        "fr": "A&eacute;rosols (340nm - 380nm)",
        "it": "Aerosol (340nm - 380nm)",
        "es": "Aerosoles (340nm - 380nm)",
    },
    "aer_354_388": { 
        "de": "Aerosole (354nm - 380nm)",
        "en": "Aerosols (354nm - 380nm)",
        "fr": "A&eacute;rosols (354nm - 380nm)",
        "it": "Aerosol (354nm - 380nm)",
        "es": "Aerosoles (354nm - 380nm)",
    },
    "map.traceGasedisplay": { 
        "de": "Spurengase-Darstellung",
        "en": "Trace gas representation",
        "fr": "Repr&eacute;sentation des gaz &agrave; l'&eacute;tat de traces",
        "it": "Visualizzazione dei gas in traccia",
        "es": "Visualizaci&oacute;n de gases traza",
    },
	"day_ahead_license_text": {
		"en": "The day ahead auction data provided in the download is for private and internal use only. The utilization of any data, whether in its raw or derived form, for external or commercial purposes is expressly prohibited. Should you require licensing for market-related data, please direct your inquiries to the original data providers, including but not limited to EPEX SPOT SE and Nord Pool AS.",
		"de": "Die im Download bereitgestellten Daten zur Day Ahead Auktion sind ausschließlich für den privaten und internen Gebrauch bestimmt. Die Verwendung jeglicher Daten, ob in ihrer Rohform oder abgeleiteten Form, zu externen oder kommerziellen Zwecken ist ausdrücklich untersagt. Sollten Sie eine Lizenzierung für marktbezogene Daten benötigen, wenden Sie sich bitte direkt an die ursprünglichen Datenanbieter, einschließlich, aber nicht beschränkt auf EPEX SPOT SE and Nord Pool AS.",
		"fr": "Les données de l'enchère du Day Ahead Auction dans le téléchargement sont strictement réservées à un usage privé et interne uniquement. L'utilisation de toute donnée, qu'elle soit sous sa forme brute ou dérivée, à des fins externes ou commerciales est expressément interdite. Si vous nécessitez une licence pour des données liées au marché, veuillez adresser vos demandes aux fournisseurs de données originaux, y compris mais non limité à EPEX SPOT SE et Nord Pool AS.",
		"it": "I dati dell'asta del giorno prezzo spot avanti nel download sono strettamente riservati all'uso privato e interno solo. L'utilizzo di qualsiasi dato, sia nella sua forma grezza che derivata, per scopi esterni o commerciali è espressamente vietato. Se necessitate di una licenza per dati relativi al mercato, si prega di indirizzare le vostre richieste ai fornitori di dati originali, inclusi ma non limitati a EPEX SPOT SE e Nord Pool AS.",
		"es": "Los datos de la subasta del día siguiente proporcionados en la descarga son estrictamente para uso privado e interno solamente. El uso de cualquier dato, ya sea en su forma bruta o derivada, para fines externos o comerciales está expresamente prohibido. Si requiere licencia para datos relacionados con el mercado, dirija sus consultas a los proveedores de datos originales, incluido pero no limitado a EPEX SPOT SE y Nord Pool AS."
	},
	"day_ahead_license_text_CC_BY": {
		"en": "The Day Ahead Auction data provided in the download is intended exclusively for private and internal use. The use of any data, whether in its raw or derived form, for external or commercial purposes is expressly prohibited. Should you require licensing for market related data, please contact the original data providers directly, including but not limited to EPEX SPOT SE and Nord Pool AS. Deviating from this, the price data of the day ahead auction for the following bidding zones is published without any change under the CC BY 4.0 (creativecommons.org/licenses/by/4.0) license with the source Bundesnetzagentur | SMARD.de: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI",
		"de": "Die im Download bereitgestellten Daten zur Day Ahead Auktion sind ausschließlich für den privaten und internen Gebrauch bestimmt. Die Verwendung jeglicher Daten, ob in ihrer Rohform oder abgeleiteten Form, zu externen oder kommerziellen Zwecken ist ausdrücklich untersagt. Sollten Sie eine Lizenzierung für marktbezogene Daten benötigen, wenden Sie sich bitte direkt an die ursprünglichen Datenanbieter, einschließlich, aber nicht beschränkt auf EPEX SPOT SE und Nord Pool AS. Abweichend davon werden die Preisdaten der Day Ahead Auktion für folgende Gebotszonen unter der Lizenz CC BY 4.0 (creativecommons.org/licenses/by/4.0) mit der Quelle Bundesnetzagentur | SMARD.de unverändert veröffentlicht: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI",
		"fr": "Les données de l'enchère Day Ahead fournies dans le téléchargement sont exclusivement destinées à un usage privé et interne. L'utilisation de toute donnée, qu'elle soit brute ou dérivée, à des fins externes ou commerciales est expressément interdite. Si vous nécessitez une licence pour des données relatives au marché, veuillez contacter directement les fournisseurs de données originaux, y compris, mais sans s'y limiter, EPEX SPOT SE et Nord Pool AS. Déviant de cela, les données de prix de l'enchère Day Ahead pour les zones d'enchères suivantes sont publiées sans aucun changement sous la licence CC BY 4.0 (creativecommons.org/licenses/by/4.0) avec la source Bundesnetzagentur | SMARD.de : AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI.",
		"it": "I dati dell'asta Day Ahead forniti nel download sono destinati esclusivamente all'uso privato e interno. L'uso di qualsiasi dato, sia nella sua forma grezza che derivata, per scopi esterni o commerciali è espressamente vietato. Se necessitate di una licenza per dati relativi al mercato, si prega di contattare direttamente i fornitori di dati originali, inclusi ma non limitati a EPEX SPOT SE e Nord Pool AS. Divergendo da ciò, i dati sui prezzi dell'asta Day Ahead per le seguenti zone di offerta vengono pubblicati senza alcuna modifica sotto la licenza CC BY 4.0 (creativecommons.org/licenses/by/4.0) con la fonte Bundesnetzagentur | SMARD.de: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI.",
		"es": "Los datos de la subasta Day Ahead proporcionados en la descarga están destinados exclusivamente para uso privado e interno. El uso de cualquier dato, ya sea en su forma bruta o derivada, para fines externos o comerciales está expresamente prohibido. Si requiere licencia para datos relacionados con el mercado, contacte directamente a los proveedores de datos originales, incluidos, pero no limitados a, EPEX SPOT SE y Nord Pool AS. Desviándose de esto, los datos de precios de la subasta Day Ahead para las siguientes zonas de oferta se publican sin ningún cambio bajo la licencia CC BY 4.0 (creativecommons.org/licenses/by/4.0) con la fuente Bundesnetzagentur | SMARD.de: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI."
	},
	"day_ahead_license_text_only_CC_BY": {
		"en": "The Day Ahead Auction prices for the following bidding zones is published without any change under the CC BY 4.0 (creativecommons.org/licenses/by/4.0) license with the source Bundesnetzagentur | SMARD.de: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI",
		"de": "Die Preise der Day Ahead Auktionen werden für folgende Gebotszonen unter der Lizenz CC BY 4.0 (creativecommons.org/licenses/by/4.0) mit der Quelle Bundesnetzagentur | SMARD.de unverändert veröffentlicht: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI",
		"fr": "Les prix de l'enchère Day Ahead pour les zones d'enchères suivantes sont publiés sans aucun changement sous la licence CC BY 4.0 (creativecommons.org/licenses/by/4.0) avec la source Bundesnetzagentur | SMARD.de : AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI.",
		"it": "I prezzi dell'asta Day Ahead per le seguenti zone di offerta vengono pubblicati senza alcuna modifica sotto la licenza CC BY 4.0 (creativecommons.org/licenses/by/4.0) con la fonte Bundesnetzagentur | SMARD.de: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI.",
		"es": "Los precios de la subasta Day Ahead para las siguientes zonas de oferta se publican sin ningún cambio bajo la licencia CC BY 4.0 (creativecommons.org/licenses/by/4.0) con la fuente Bundesnetzagentur | SMARD.de: AT, BE, CH, CZ, DE-LU, DE-AT-LU, DK1, DK2, FR, HU, IT-NORTH, NL, NO2, PL, SE4, SI."
	},
	"state_all": { 
			"de": "Alle Bundesländer",
			"en": "All States",
			"fr": "Tous les États",
			"it": "Tutti gli Stati",
			"es": "Todos los Estados",
	},
	"state_bw": { 
			"de": "Baden-Württemberg",
			"en": "Baden-Württemberg",
			"fr": "Bade-Wurtemberg",
			"it": "Baden-Württemberg",
			"es": "Baden-Wurtemberg",
	},
	"state_by": { 
			"de": "Bayern",
			"en": "Bavaria",
			"fr": "Bavière",
			"it": "Baviera",
			"es": "Baviera",
	},
	"state_be": { 
			"de": "Berlin",
			"en": "Berlin",
			"fr": "Berlin",
			"it": "Berlino",
			"es": "Berlín",
	},
	"state_bb": { 
			"de": "Brandenburg",
			"en": "Brandenburg",
			"fr": "Brandebourg",
			"it": "Brandeburgo",
			"es": "Brandeburgo",
	},
	"state_hb": { 
			"de": "Bremen",
			"en": "Bremen",
			"fr": "Brême",
			"it": "Brema",
			"es": "Bremen",
	},
	"state_hh": { 
			"de": "Hamburg",
			"en": "Hamburg",
			"fr": "Hambourg",
			"it": "Amburgo",
			"es": "Hamburgo",
	},
	"state_he": { 
			"de": "Hessen",
			"en": "Hesse",
			"fr": "Hesse",
			"it": "Assia",
			"es": "Hesse",
	},
	"state_mv": { 
			"de": "Mecklenburg-Vorpommern",
			"en": "Mecklenburg-Western Pomerania",
			"fr": "Mecklembourg-Poméranie-Occidentale",
			"it": "Meclemburgo-Pomerania Anteriore",
			"es": "Mecklemburgo-Pomerania Occidental",
	},
	"state_ni": { 
			"de": "Niedersachsen",
			"en": "Lower Saxony",
			"fr": "Basse-Saxe",
			"it": "Bassa Sassonia",
			"es": "Baja Sajonia",
	},
	"state_nw": { 
			"de": "Nordrhein-Westfalen",
			"en": "North Rhine-Westphalia",
			"fr": "Rhénanie-du-Nord-Westphalie",
			"it": "Renania Settentrionale-Vestfalia",
			"es": "Renania del Norte-Westfalia",
	},
	"state_rp": { 
			"de": "Rheinland-Pfalz",
			"en": "Rhineland-Palatinate",
			"fr": "Rhénanie-Palatinat",
			"it": "Renania-Palatinato",
			"es": "Renania-Palatinado",
	},
	"state_sl": { 
			"de": "Saarland",
			"en": "Saarland",
			"fr": "Sarre",
			"it": "Saarland",
			"es": "Sarre",
	},
	"state_sn": { 
			"de": "Sachsen",
			"en": "Saxony",
			"fr": "Saxe",
			"it": "Sassonia",
			"es": "Sajonia",
	},
	"state_st": { 
			"de": "Sachsen-Anhalt",
			"en": "Saxony-Anhalt",
			"fr": "Saxe-Anhalt",
			"it": "Sassonia-Anhalt",
			"es": "Sajonia-Anhalt",
	},
	"state_sh": { 
			"de": "Schleswig-Holstein",
			"en": "Schleswig-Holstein",
			"fr": "Schleswig-Holstein",
			"it": "Schleswig-Holstein",
			"es": "Schleswig-Holstein",
	},
	"state_th": { 
			"de": "Thüringen",
			"en": "Thuringia",
			"fr": "Thuringe",
			"it": "Turingia",
			"es": "Turingia",
	},
	"state_ns": { 
		"de": "Nordsee (Offshore)",
		"en": "North Sea (Offshore)",
		"fr": "Mer du Nord (Offshore)",
		"it": "Mare del Nord (Offshore)",
		"es": "Mar del Norte (Offshore)",
	},
	"state_os": { 
		"de": "Ostsee (Offshore)",
		"en": "Baltic Sea (Offshore)",
		"fr": "Mer Baltique (Offshore)",
		"it": "Mar Baltico (Offshore)",
		"es": "Mar Báltico (Offshore)",
	},
	"stackname": { 
		"de": "Name", // German translation
		"en": "Name", // English translation
		"fr": "Nom", // French translation, assuming you want to use a generic placeholder
		"it": "Nome", // Italian translation, using a generic placeholder
		"es": "Nombre" // Spanish translation, using a generic placeholder
	},
	"absolute_relative": { 
		"de": "Absolut / relativ",
		"en": "Absolute / Relative",
		"fr": "Absolu / Relatif",
		"it": "Assoluto / Relativo",
		"es": "Absoluto / Relativo"
	},
	"absolute_values": { 
		"de": "absolute Werte",
		"en": "Absolute values",
		"fr": "Valeurs absolues",
		"it": "Valori assoluti",
		"es": "Valores absolutos"
	},
	"per_capita": { 
		"de": "Wert je Einwohner*in",
		"en": "Value per capita",
		"fr": "Valeur par habitant",
		"it": "Valore per abitante",
		"es": "Valor per cápita"
	},
	"per_area": { 
		"de": "Wert pro Fläche",
		"en": "Value per area",
		"fr": "Valeur par superficie",
		"it": "Valore per area",
		"es": "Valor por área"
	},
	"yAxisTitlePerArea": { 
		"de": "Energie (MWh pro km²)",
		"en": "Energy (MWh per km²)",
		"fr": "Énergie (MWh par km²)",
		"it": "Energia (MWh per km²)",
		"es": "Energía (MWh por km²)"
	},
	"yAxisTitlePerCapita": { 
		"de": "Energie (kWh pro Einwohner*in)",
		"en": "Energy (kWh per capita)",
		"fr": "Énergie (kWh par habitant)",
		"it": "Energia (kWh per abitante)",
		"es": "Energía (kWh por habitante)"
	},
	"yAxisTitleAbsoluteGWh": { 
		"de": "Energie (GWh)",
		"en": "Energy (GWh)",
		"fr": "Énergie (GWh)",
		"it": "Energia (GWh)",
		"es": "Energía (GWh)"
	},
	"yAxisTitleAbsoluteTWh": { 
		"de": "Energie (TWh)",
		"en": "Energy (TWh)",
		"fr": "Énergie (TWh)",
		"it": "Energia (TWh)",
		"es": "Energía (TWh)"
	}




};

export default dictionary;
